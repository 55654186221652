import React from 'react';
import './style/barcode.scss';
import {correctCode128Data, getBarCodeFontValue} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {copyToClipboard} from '../clipboard/ClipboardUtils';

interface Props {
    fontSizePx?: number;
    data: string;
    hideValue?: boolean;
}

export default class BarCode extends React.Component<Props> {
    render() {
        const data: string = correctCode128Data(this.props.data);
        return (
            <div className={'barcode-container'} title={data}>
                <span className={'barcode-container__barcode'} style={{fontSize: this.props.fontSizePx || 64}}
                      onDoubleClick={() => copyToClipboard(data)}>{getBarCodeFontValue(data)}</span>
                {!this.props.hideValue && (
                    <span className={'barcode-container__barcode-data'}>{data}</span>
                )}
            </div>
        );
    }
}
