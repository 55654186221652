import debounce from 'lodash/debounce';
import {LocalStorage} from "./LocalStorageUtils";

export function loadValue(id: string, defaultValue: number): number {
    const value: string | null = LocalStorage.getItem(id);
    return value ? Number.parseInt(value) : defaultValue;
}

export function onSplitterResize(id: string) {
    return debounce(function (value: number) {
        LocalStorage.setItem(id, value.toFixed())
    }, 200);
}
