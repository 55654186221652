import React from 'react';
import {Button, Result} from 'antd';
import {goToPage} from '../../../routing/AppRouting';
import {intl} from '../../../common/locale/IntlProvider';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

export default class Page404Controller extends React.Component {
    gotoHome = () => {
        goToPage(AppRoutes.ROOT, {}, true);
    };

    render() {
        // TODO: handle case without locale file. like this:
        const title: string = intl()
            ? intl().formatMessage({id: '404.title'})
            : '404 Page not found!';

        return (
            <Result status="404"
                    title={title}
                    subTitle="Sorry, the page you visited does not exist."
                    extra={[
                        <Button key="report">Сообщить разработчикам FieldMaster</Button>,
                        <Button type="primary" onClick={this.gotoHome} key="home">Back Home</Button>
                    ]}
            />
        );
    }
}
