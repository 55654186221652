import React from 'react';
import {Button, Space} from 'antd';
import LinkTo from '../../../routing/LinkTo';
import SearchControl from '../../../common/widgets/searchControl/SearchControl';
import './style/fmHeaderView.scss';
import {EmployeeURLData} from '../employee/EmployeeURLData';
import {goToPage} from '../../../routing/AppRouting';
import RefreshControl from '../../../common/widgets/refreshControl/RefreshControl';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {AppStore} from "../../../app/mobx/AppStore";
import {inject, observer} from "mobx-react";
import {CoreStore} from "../../core/mobx/CoreStore";
import {getAvatar} from "../employee/EmployeeUtils";
import AvatarWidget from "../../../common/widgets/avatarWidget/AvatarWidget";

type StoreProps = {
    appStore: AppStore;
    coreStore: CoreStore;
};

type Props = StoreProps;

@inject('appStore', 'coreStore')
@observer
export default class FMHeaderView extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<any>) {
        super(props);
        console.log('ClientMenuView: constructor', performance.now());
    }

    onSearch = (value: string) => {
        if (!isBarCode(value)) {
            return;
        }
        // 2. detect entity => and page for navigate
        const data: any = {employeeBarCode: value} as EmployeeURLData; // 001020000001
        goToPage(AppRoutes.FM_EMPLOYEE_DATA, data);
    };

    get isFMMenuVisible(): boolean {
        const {appStore} = this.props;
        return ![
            AppRoutes.ROOT,
            AppRoutes.BARCODE,
            AppRoutes.USER_PROFILE,
            AppRoutes.ACCOUNT_PROFILE,
        ].includes(appStore.currentPage);
    }

    render() {
        const {appStore, coreStore} = this.props;
        const {account} = coreStore;
        return (
            <nav className="fm-menu">
                <div className="fm-menu__logo">
                    <Space>
                        {account.avatar && <AvatarWidget src={getAvatar(account)} size={35} hidePreview={true}/>}
                        <span>{account.name}</span>
                    </Space>
                </div>
                {this.isFMMenuVisible && (
                    <div className="fm-menu__menu-items">
                        <Space>
                            <Button type={appStore.currentPage === AppRoutes.FM_HOME ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_HOME}>
                                    Главная
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_EMPLOYEE ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_EMPLOYEE}>
                                    Люди
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_FIELD ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_FIELD}>
                                    Участки
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_EQUIPMENT ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_EQUIPMENT}>
                                    Техника
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_WORK ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_WORK}>
                                    Работа
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_LOG_TYPE ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_LOG_TYPE}>
                                    JustLogIt
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_CALENDAR ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_CALENDAR}>
                                    Клендарь
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_SCANNER ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_SCANNER}>
                                    Сканеры
                                </LinkTo>
                            </Button>
                            <Button type={appStore.currentPage === AppRoutes.FM_REPORT ? "primary" : "default"}>
                                <LinkTo to={AppRoutes.FM_REPORT}>
                                    Отчеты
                                </LinkTo>
                            </Button>
                            {/*<Button type={appStore.currentPage === AppRoutes.FM_REPORTS ? "primary" : "default"}>*/}
                            {/*    <LinkTo to={AppRoutes.FM_REPORTS}>*/}
                            {/*        Отчеты*/}
                            {/*    </LinkTo>*/}
                            {/*</Button>*/}
                        </Space>
                    </div>
                )}
                {this.isFMMenuVisible && (
                    <div className="fm-menu__extra">
                        <Space>
                            <SearchControl
                                onSearch={this.onSearch}
                                isSearchEnabled={true}
                            />
                            <RefreshControl onRefresh={() => Promise.resolve()}/>
                        </Space>
                    </div>
                )}
            </nav>
        );
    }
}
