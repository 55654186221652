import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {LogTypeViewStore} from './mobx/LogTypeViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import LogTypePageView from "./LogTypePageView";
import {LogTypeURLData} from "./LogTypeURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    logTypeViewStore: LogTypeViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'logTypeViewStore')
@observer
export default class LogTypeController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('LogTypePageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('LogTypePageController: componentDidMount', performance.now());
        const {logTypeViewStore} = this.props;
        if (!logTypeViewStore.isDataLoaded) {
            await logTypeViewStore.loadData();
        } else {
            const urlData: LogTypeURLData | undefined = decryptObject<LogTypeURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.logTypeBarCode)) {
                logTypeViewStore.universalFilter = urlData!.logTypeBarCode!;
                logTypeViewStore.clearFilters();
                goToPage(AppRoutes.FM_CALENDAR);
            } else if (logTypeViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                logTypeViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {logTypeViewStore} = this.props;
        const urlData: LogTypeURLData | undefined = decryptObject<LogTypeURLData>(this.props.match.params.data);
        console.log('LogTypePageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', logTypeViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.logTypeBarCode)) {
            logTypeViewStore.universalFilter = urlData!.logTypeBarCode!;
            logTypeViewStore.clearFilters();
            goToPage(AppRoutes.FM_CALENDAR);
        } else if (isEmpty(urlData?.logTypeBarCode) && !logTypeViewStore.isViewReady) {
            logTypeViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'LogTypePageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('LogTypePageController: componentDidCatch', performance.now());
    }

    render() {
        const {logTypeViewStore} = this.props;
        const {isViewReady, isDataLoaded} = logTypeViewStore;

        if (!isDataLoaded) {
            return <p>Load work data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating work view....</p>;
        }

        return (
            <LogTypePageView/>
        );
    }
}
