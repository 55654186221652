import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../common/types/UpdateDataCommand";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export default class MoveTreeCommand<T extends TreeRecord> extends UpdateDataCommand<T> {
    constructor(private readonly table: string, private readonly dragId: number, private readonly id: number, private readonly dropPosition: number) {
        super();
    }

    execute(): Promise<UpsertResponse<T>> {
        return axiosGetInstance().post<UpsertResponse<T>>(`/tree/move/${this.dragId}/${this.id}/${this.dropPosition}/${this.table}`)
            .then((response: AxiosResponse<UpsertResponse<T>>) => response.data);
    }
}
