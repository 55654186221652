import React, {ReactText} from 'react';
import {Tree} from 'antd';
import './style/treeStructure.scss';
import {TreeStructureProps} from './TreeStructureProps';
import {DataNode, Key} from 'rc-tree/lib/interface';
import {EventDataNode} from 'rc-tree/es/interface';
import {LocalStorage, LocalStorageKey} from '../../utils/LocalStorageUtils';
import {NodeDragEventParams} from "rc-tree/lib/contextTypes";
import {getTreeNodes} from "./TreeStructureUtils";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";

interface OwnProps<P extends TreeRecord> {
    onSelect: (selectedKey: TreeData<P>) => void;
    onExpand: (expandedKeys: number[]) => void;
    onDrag: (dragId: number, id: number, dropPosition: number) => Promise<any>;
    onDoubleClick?: (selectedKey: number) => void;
}

interface State {
    isLocked: boolean;
}

export default class TreeStructure<T extends TreeRecord> extends React.Component<OwnProps<T> & TreeStructureProps<T>, State> {

    constructor(props: Readonly<OwnProps<T> & TreeStructureProps<T>>) {
        super(props);
        this.state = {
            isLocked: false,
        };
    }

    handleOnSelect = (selectedKeys: ReactText[], info: any) => {
        if (selectedKeys.length === 1) {
            const selectedId: number = Number.parseInt(selectedKeys[0].toString());
            const selectedTreeNode: TreeData<T> = findNodeById(this.props.treeData, selectedId)!;
            this.props.onSelect(selectedTreeNode!);
        }
    };

    handleOnDrop = async (options: NodeDragEventParams & {
        dragNode: EventDataNode;
        dragNodesKeys: Key[];
        dropPosition: number;
        dropToGap: boolean;
    }) => {
        // console.log("onDrop", options);
        const dragKey: number = options.dragNode.key as number;
        const dropKey: number = options.node.key as number;
        const dropPos = options.node.pos.split('-');
        const dropPosition = options.dropPosition - Number(dropPos[dropPos.length - 1]);
        if (options.node.pos === "0-0") {
            // console.info('disable drag around root');
            return;
        }
        if (!dragKey || !dropKey) {
            console.error(`Tree onDrop: dragKey or dropKey is empty (${dragKey} ${dropKey})!`);
            return;
        }
        this.setState({isLocked: true});
        if (options.dropToGap) {
            // TODO: Check if it's a real movement?
            // if (!isDragIdBetweenId(dragId, id, dropPosition)) {
            // }
        } else {
            // TODO: Check if it's a real movement?
            // if (!isMoveItemUnder(dragId, id)) {
            // }
        }
        await this.moveTreeItem(dragKey, dropKey, dropPosition);
        this.setState({isLocked: false});
    };

    moveTreeItem = async (dragId: number, id: number, dropPosition: number) => {
        if (dropPosition === 0) {
            console.info(`Move ID ${dragId} in the end and under ${id}`);
        } else {
            console.info(`Move ID ${dragId} ${dropPosition > 0 ? 'after' : 'before'} ${id}`);
        }
        await this.props.onDrag(dragId, id, dropPosition);
    };

    onDoubleClick = (e: React.MouseEvent, treeNode: EventDataNode) => {
        if (this.props.onDoubleClick) {
            this.props.onDoubleClick(Number.parseInt(treeNode.key.toString()));
        }
    };

    handleOnExpand = (expandedKeys: Key[], info: any) => {
        this.props.onExpand(expandedKeys.map((key: Key) => key as number));
        LocalStorage.setItem(LocalStorageKey.EMPLOYEE_TREE_NODES, expandedKeys.join(','));
    };

    render() {
        const {treeData, selectedNode, expandedKeys} = this.props;
        const dataNode: DataNode[] = getTreeNodes(treeData);
        const selectedId: number = selectedNode.id
        return (
            <div className="tree-structure">
                <Tree
                    // disabled={this.state.isLocked}
                    draggable={true}
                    blockNode={true}
                    // checkable
                    // defaultExpandAll={true}
                    treeData={dataNode}
                    defaultSelectedKeys={[selectedId]}
                    selectedKeys={[selectedId]}
                    onSelect={this.handleOnSelect}
                    expandedKeys={expandedKeys}
                    onExpand={this.handleOnExpand}
                    onDrop={this.handleOnDrop}
                    onDoubleClick={this.onDoubleClick}
                />
            </div>
        );
    }
}
