import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {UniqEmployeeValidator} from '../../../../../common/widgets/modalForm/validators/sync/UniqEmployeeValidator';
import EmployeeFirstNameOptionsProvider from './EmployeeFirstNameOptionsProvider';
import EmployeeLastNameOptionsProvider from './EmployeeLastNameOptionsProvider';
import EmployeeMiddleNameOptionsProvider from './EmployeeMiddleNameOptionsProvider';
import EmployeePositionNameOptionsProvider from './EmployeePositionNameOptionsProvider';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {DICTIONARY_GENDER} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";

export const EMPLOYEE_FORM_CONFIG: ModalFormConfig<EmployeeRecord> = {
    title: 'employee.form.title',
    tableName: 'employee',
    getNewRecord: () => {
        return {
            middle_name: '',
            can_login: false
        } as EmployeeRecord;
    },
    fieldConfig: [
        {
            label: 'employee.field.last_name',
            field_name: 'last_name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            // asyncValidators: [new Test2AsyncValidator(), new Test5AsyncValidator()],
            config: {
                autoCompleteOptionsProvider: new EmployeeLastNameOptionsProvider()
            }
        },
        {
            label: 'employee.field.first_name',
            field_name: 'first_name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new EmployeeFirstNameOptionsProvider()
            }
        },
        {
            label: 'employee.field.middle_name',
            field_name: 'middle_name',
            optional: true,
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new EmployeeMiddleNameOptionsProvider()
            }
        },
        {
            label: 'employee.field.position',
            field_name: 'position',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3})],
            config: {
                autoCompleteOptionsProvider: new EmployeePositionNameOptionsProvider()
            }
        },
        {
            label: 'employee.field.gender',
            field_name: 'gender',
            renderType: FormFieldRender.RADIO,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_GENDER),
                inputProps: {
                    optionType: 'button'
                }
            }
        },
        {
            label: 'employee.field.birthday',
            field_name: 'birthday',
            renderType: FormFieldRender.DATE,
            syncValidators: [new NonEmptyValidator()],
            config: {
                inputProps: {
                    placeholder: 'Date'
                }
            }
        },
        {
            label: 'employee.field.is_third_party_employee',
            field_name: 'is_third_party_employee',
            renderType: FormFieldRender.SWITCH,
            syncValidators: [],
            optional: true,
            config: {}
        },
        {
            label: 'employee.field.third_party_employee_cost',
            field_name: 'third_party_employee_cost',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [],
            optional: true,
            config: {}
        },
    ],
    syncValidators: [new UniqEmployeeValidator()]
};
