import React from 'react';
import {Badge} from 'antd';
import './style/treeNodeRenderer.scss';
import Icofont from 'react-icofont';
import classNames from "classnames";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";

export default function TreeNodeRenderer<T extends TreeData<any>>(data: T, hasChild: boolean, showCount: boolean = true) {
    return (
        <div className="tree-title" style={{color: data.color}}>
            {data.icon && <Icofont icon={`${data.icon}`} size={"1"}/>}
            <div className="tree-title__name">{data.name}</div>
            {showCount && <Badge count={data.count} className={classNames('tree-title__badge', {
                'tree-title__badge--node': hasChild
            })}/>}
        </div>
    );
}
