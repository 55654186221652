import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export default class LoadTreeCommand<T extends TreeRecord> extends Command<T[]> {
    constructor(private readonly table: string) {
        super();
    }

    execute(): Promise<T[]> {
        return axiosGetInstance().post<T[]>(`/tree/get/${this.table}`)
            .then((response: AxiosResponse<T[]>) => response.data);
    }
}
