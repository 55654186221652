import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {HarvestPivotOverviewRecord} from 'fm-shared-data/src/types/db/account/data/HarvestPivotOverviewRecord';
import {ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';

export default class LoadHarvestPivotOverviewCommand extends Command<HarvestPivotOverviewRecord[], ReportFilterParams> {
    execute(reportFilterParams: ReportFilterParams): Promise<HarvestPivotOverviewRecord[]> {
        return axiosGetInstance().post<HarvestPivotOverviewRecord[]>('/data/harvest/pivot/overview', reportFilterParams)
            .then((response: AxiosResponse<HarvestPivotOverviewRecord[]>) => response.data);
    }
}
