import React from "react";
import {FormikProps} from "formik/dist/types";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {EntityKey} from "fm-shared-data/src/types/db/common/EntityKey";
import {RecordTabProps} from "./RecordTabProps";

export type CustomRendererProps<T extends DataRecord, P = {}> = Partial<EntityKey> & RecordTabProps<T> & P;

// export interface CustomRendererProps<T extends DataRecord> extends Partial<EntityKey>, RecordTabProps<T> {
// }
//

export function withRecord<T extends DataRecord, P = {}>(Component: React.ComponentType<FormikProps<T>>, customProps: CustomRendererProps<T, P>) {
    // eslint-disable-next-line react/display-name
    return class extends React.Component<FormikProps<T>> {
        render() {
            return <Component {...this.props} {...customProps} />;
        }
    };
}