// Related to the FMEntity and DICTIONARY_FM_ENTITY_CONFIG dictionary and getBarcodeEntityId / getFMEntity !! REF THIS!!
export enum BarcodeEntityId {
    ACTION = 1,
    EMPLOYEE = 2,
    WORK = 3,
    FIELD = 4,
    INCIDENT_OFFLINE = 5,
    INCIDENT = 6,
    FINE_BONUS_OFFLINE = 7,
    FINE_BONUS = 8,
    OFFLINE_DEVICE = 9,
    EQUIPMENT = 10,
    EVENT_LOG_OFFLINE = 11,
    EVENT_LOG = 12,

    LOG_TYPE = 13,
    LOG_RECORD = 14,
    LOG_RECORD_OFFLINE = 15,

    SCANNER = 16, // TODO ???
    REPORT = 17,
}
