import {AxiosResponse} from 'axios';
import {axiosGetInstance} from '../../../../common/utils/AxiosUtils';
import Command from '../../../../common/types/Command';

export default class LoadAccountFileTypesCommand extends Command<string[]> {
    execute(): Promise<string[]> {
        return axiosGetInstance().post<string[]>('/file/types')
            .then((response: AxiosResponse<string[]>) => response.data);
    }
}
