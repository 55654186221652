import React from 'react';
import SetPasswordPageView from './SetPasswordPageView';
import './../style/loginPage.scss';
import {URLRouteProps} from '../../../../routing/URLRouteProps';
import {genURLToPage} from '../../../../routing/AppRouting';
import {Redirect} from 'react-router';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {SetPasswordURLData} from "fm-shared-data/src/types/auth/SetPasswordURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

export default class SetPasswordPageController extends React.Component<URLRouteProps> {

    render() {
        const urlData: SetPasswordURLData | undefined = decryptObject<SetPasswordURLData>(this.props.match.params.data);
        if (!urlData) {
            const url: string = genURLToPage(AppRoutes.ERROR_DATA, {url: this.props.match.url, error: "Incorrect URL data or data not found."});
            return <Redirect to={url} />;
        }
        return (
            <div className="login-container">
                <div className="login-container__form">
                    <SetPasswordPageView data={urlData}/>
                </div>
            </div>
        );
    }
}
