import {StringDate} from "../../core/StringDate";
import {Moment} from 'moment';
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";

export interface SyncSearchParams<D = StringDate> {
    device_key: string;

    start_date: D;
    end_date: D;
}

export function getSyncSearchParams(device_key: string, dateStart: Moment | null, dateEnd: Moment | null): SyncSearchParams {
    return {
        device_key,
        start_date: dateStart ? MomentUtils.momentToDate(dateStart) : null,    // TODO: control TZ of search params here
        end_date: dateEnd ? MomentUtils.momentToDate(dateEnd) : null           // TODO: control TZ of search params here
    } as SyncSearchParams;
}
