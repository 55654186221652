import React from "react";
import {DatePicker, Form} from "antd";
import {FieldProps, FormikErrors} from "formik";
import {Moment} from 'moment';
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";

const FormItem = Form.Item;

// the same DateFormItem but with showTime={true}
export const DateTimeFormItem = (props: FieldProps & Record<string, any>) => {
    const touched: boolean = !!props.form.touched[props.field.name];
    const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = props.form.errors[props.field.name];
    const hasErrorBoolean: boolean = !!hasError;
    const submitted: boolean = props.form.submitCount > 0;
    const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;
    const handleOnChange = (moment: Moment | null) => {
        props.form.setFieldValue(props.field.name, moment ? MomentUtils.momentToDate(moment) : undefined);
    };
    const onBlur = () => {
        props.form.setFieldTouched(props.field.name, true);
    };

    return (
        <div className="field-container">
            <FormItem
                label={props.label}
                hasFeedback={
                    touched || submitted ? true : false
                }
                key={props.field.name}
                help={showError ? hasError : false}
                validateStatus={props.form.isValidating ? "validating" : (showError ? "error" : (!props.required && !props.field.value ? "" : "success"))}
                required={props.required}>
                <DatePicker
                    showTime={true}
                    onBlur={onBlur}
                    onChange={handleOnChange}
                    value={props.field.value ? MomentUtils.dateToMoment(props.field.value) : null}
                    allowClear={true}
                    showToday={false}
                    disabled={props.disabled}
                    {...props.inputProps}/>
            </FormItem>
        </div>
    );
};
