import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface PhoneValidatorConfig {
    invalidMessageKey: string;
}

export class PhoneValidator extends SyncValidator<string | undefined, PhoneValidatorConfig> {

    constructor(config?: PhoneValidatorConfig) {
        super(config || {invalidMessageKey: 'modalForm.validation.phone'});
    }

    validate(value: string | undefined): FieldValidatorResult {
        if (!value) {
            return null;
        }
        return value.split('+').length > 2 ? formatMessage(this.config.invalidMessageKey) : null;
    }

}