import React from 'react';
import {CoreStore} from '../mobx/CoreStore';
import {inject, observer} from 'mobx-react';
import {Card, Spin} from 'antd';
import {formatMessage} from "../../../common/locale/IntlProvider";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {TabItemContent} from "../../../common/widgets/tab/TabItemContent";
import AccountTabGeneral from "./items/general/AccountTabGeneral";
import {withRecord} from "../../../common/widgets/tab/CustomRenderer";
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import './items/general/style/style.scss'
import AccountTabDevice from "./items/device/AccountTabDevice";
import SCRUDActions from "../../../common/widgets/scrudActions/SCRUDActions";

type StoreProps = {
    coreStore: CoreStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
    refreshInProgress: boolean;
}

@inject('coreStore')
@observer
export default class AccountProfileController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private readonly staticTabItems: TabItemContent<EmployeeRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: AccountTabGeneral,
            adminOnly: true
        },
        {
            key: 'tab2',
            tab: 'Тарифы',
            componentType: AccountTabDevice,
            adminOnly: true
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('AccountProfileController: constructor');
        this.state = {
            selectedTab: 'tab1',
            refreshInProgress: false
        };
    }

    getTabList = (): TabItemContent<EmployeeRecord>[] => {
        const {coreStore} = this.props;
        return this.staticTabItems.map((tabItem: TabItemContent<EmployeeRecord>) => {
            tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
            return tabItem;
        });
    };

    getContent = (): React.ReactNode => {
        const {coreStore} = this.props;
        const tabItem: TabItemContent<EmployeeRecord> = this.staticTabItems.find((item: TabItemContent<EmployeeRecord>) => item.key === this.state.selectedTab)!;
        const {componentProps = {}} = tabItem;
        const {componentType: MyComponentType} = tabItem;
        if (tabItem.withRecords) {
            const MyComponentWithRecord: any = withRecord<AccountRecord>(MyComponentType, {
                record: coreStore.account,
                entity_id: coreStore.account.id,
            });
            return <MyComponentWithRecord {...componentProps}/>;
            //     return <MyComponentType {...componentProps} record={selectedEntity}/>
        }
        return <MyComponentType {...componentProps}/>
    };

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    handleOnRefresh = (): Promise<any> => {
        this.setState({refreshInProgress: true});
        const {coreStore} = this.props;
        return coreStore.loadAccount().then(() => {
            this.setState({refreshInProgress: false});
        })
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  tabBarExtraContent={<SCRUDActions onRefresh={this.handleOnRefresh} isRefreshEnabled={!this.state.refreshInProgress}/>}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                <Spin spinning={this.state.refreshInProgress} wrapperClassName={'maxWidth'}>
                    {this.getContent()}
                </Spin>
            </Card>
        );
    }
}
