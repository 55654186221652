import {DictionaryConfig} from "./DictionaryConfig";
import {DataStoreEmployeeProvider} from "./providers/DataStoreEmployeeProvider";
import {DataStoreFieldProvider} from "./providers/DataStoreFieldProvider";
import {DataStoreWorkProvider} from "./providers/DataStoreWorkProvider";
import {DataStoreEquipmentProvider} from "./providers/DataStoreEquipmentProvider";

export const DICTIONARY_ENTITY_EMPLOYEE: DictionaryConfig = {
    dictionaryKey: 'dictionary_entity_employee',
    rawRecordsProvider: new DataStoreEmployeeProvider(),
};

export const DICTIONARY_ENTITY_FIELD: DictionaryConfig = {
    dictionaryKey: 'dictionary_entity_field',
    rawRecordsProvider: new DataStoreFieldProvider(),
};

export const DICTIONARY_ENTITY_WORK: DictionaryConfig = {
    dictionaryKey: 'dictionary_entity_work',
    rawRecordsProvider: new DataStoreWorkProvider(),
};

export const DICTIONARY_ENTITY_EQUIPMENT: DictionaryConfig = {
    dictionaryKey: 'dictionary_entity_equipment',
    rawRecordsProvider: new DataStoreEquipmentProvider(),
};
