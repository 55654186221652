import React from "react";
import {AutoComplete, Form} from "antd";
import {FieldProps, FormikErrors} from "formik";
import {LabeledValue} from "antd/lib/select";
import {AutoCompleteOptionType} from "./AutoCompleteOptionType";
import {FMKeyType} from "fm-shared-data/src/types/dictionary/FMKeyType";

const FormItem = Form.Item;

export const AutoCompleteFormItem = (props: FieldProps & Record<string, any>) => {
    const touched: boolean = !!props.form.touched[props.field.name];
    const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = props.form.errors[props.field.name];
    const hasErrorBoolean: boolean = !!hasError;
    const submitted: boolean = props.form.submitCount > 0;
    const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;
    const handleOnChange = (value: FMKeyType, option: LabeledValue) => {
        props.form.setFieldValue(props.field.name, value);
    };
    const onBlur = () => {
        props.form.setFieldTouched(props.field.name, true);
    };
    const filterFunction = (inputValue: string, option: AutoCompleteOptionType): boolean => {
        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    };

    return (
        <div className="field-container">
            <FormItem
                label={props.label}
                hasFeedback={
                    touched || submitted ? true : false
                }
                key={props.field.name}
                help={showError ? hasError : false}
                validateStatus={props.form.isValidating ? "validating" : (showError ? "error" : (!props.required && !props.field.value ? "" : "success"))}
                required={props.required}>
                <AutoComplete
                    onBlur={onBlur}
                    onChange={handleOnChange}
                    allowClear={true}
                    value={props.field.value ?? undefined}
                    options={props.options}
                    filterOption={filterFunction}
                    disabled={props.disabled}
                    placeholder={props.label}
                    {...props.inputProps}/>
            </FormItem>
        </div>
    );
};
