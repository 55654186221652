import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";

export interface ScannerRecord extends DataRecord, TreeFields, TagFields, AvatarFields {
    name: string;
    device_key: string;
    enabled: boolean;
}

export function scannerHash(rec: ScannerRecord): string {
    return rec.device_key.trim().toLowerCase();
}
