import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";

export default class DeleteEquipmentCommand extends Command<EquipmentRecord[], EquipmentRecord> {
    execute(equipmentRecord: EquipmentRecord): Promise<EquipmentRecord[]> {
        return axiosGetInstance().post<EquipmentRecord[]>(`/equipment/delete`, equipmentRecord)
            .then((response: AxiosResponse<EquipmentRecord[]>) => response.data);
    }
}
