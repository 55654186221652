import {AxiosResponse} from "axios";
import {InsertResponse} from "fm-shared-data/src/types/db/common/InsertResponse";
import {CommonCommentDetailed} from "fm-shared-data/src/types/db/common/CommonCommentDetailed";
import {CommonCommentRecord} from "fm-shared-data/src/types/db/common/CommonCommentRecord";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";

export default class AddCommentCommand extends Command<InsertResponse<CommonCommentDetailed>, Partial<CommonCommentRecord>> {

    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }

    execute(payload: Partial<CommonCommentRecord>): Promise<InsertResponse<CommonCommentDetailed>> {
        return axiosGetInstance().post<InsertResponse<CommonCommentDetailed>>(`/comment/add/${this.barcode_entity_id}/${this.entity_id}`, payload)
            .then((response: AxiosResponse<InsertResponse<CommonCommentDetailed>>) => response.data);
    }

}
