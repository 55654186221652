import React from 'react';
import './style/searchControl.scss';
import {Input} from 'antd';
import classNames from 'classnames';
import {SearchOutlined} from '@ant-design/icons/lib';
import {formatMessage} from '../../locale/IntlProvider';

interface Props {
    onSearch?: (value: string) => void;
    isSearchEnabled?: boolean;
}

interface State {
    isSearchVisible: boolean;
}

export default class SearchControl extends React.Component<Props, State> {
    state = {isSearchVisible: false};
    ref = React.createRef<Input>();

    onSearchClick = () => {
        if (this.props.isSearchEnabled) {
            this.setState({isSearchVisible: !this.state.isSearchVisible}, () => {
                if (this.state.isSearchVisible && this.ref.current) {
                    this.ref.current.focus();
                }
            });
        }
    };

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode === 13) {
            this.props.onSearch!((e.target as HTMLInputElement).value);
            (e.target as HTMLInputElement).value = '';
        }
    };

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.type === 'click') {
            // clear icon is clicked
            this.props.onSearch!('');
        }
    };

    render() {
        const iconSearch = (
            <SearchOutlined
                title={!this.state.isSearchVisible ? formatMessage('common.search') : formatMessage('common.close')}
                onClick={this.onSearchClick}
                className={classNames('section-header__icon', {
                    'section-header__icon--disabled': !this.props.isSearchEnabled,
                })}
            />
        );

        if (!this.props.onSearch) {
            return null;
        }

        return (
            <div
                className={classNames(
                    'search-control',
                    {'search-control--open': this.state.isSearchVisible},
                )}
            >
                {!this.state.isSearchVisible && iconSearch}
                {this.state.isSearchVisible && (
                    <Input
                        onKeyPress={this.handleKeyPress}
                        onChange={this.handleOnChange}
                        ref={this.ref}
                        suffix={iconSearch}
                        size="small"
                        placeholder={`${formatMessage('common.search')} ...`}
                        allowClear={true}
                    />
                )}
            </div>
        );
    }
}
