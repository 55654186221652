import React from 'react';
import {Card, Checkbox, List} from 'antd';
import {intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {FIELD_FORM_CONFIG} from './FieldFormConfig';
import {inject, observer} from 'mobx-react';
import {FieldViewStore} from '../../mobx/FieldViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddFieldCommand from './commands/AddFieldCommand';
import UpdateFieldCommand from './commands/UpdateFieldCommand';
import DeleteFieldCommand from './commands/DeleteFieldCommand';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import AvatarWidget from "../../../../../common/widgets/avatarWidget/AvatarWidget";
import {getAvatar} from "../../../employee/EmployeeUtils";

type StoreProps = {
    fieldViewStore: FieldViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: FieldRecord;
}

@inject('fieldViewStore')
@observer
export default class FieldListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('FieldListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as FieldRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {fieldViewStore} = this.props;
        fieldViewStore.universalFilter = value;
        fieldViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedFieldTreeRecord} = this.props.fieldViewStore;
        const newFieldRecord: FieldRecord = FIELD_FORM_CONFIG.getNewRecord();
        newFieldRecord.tree_ids = [selectedFieldTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newFieldRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {fieldViewStore} = this.props;
        const {selectedEntity} = fieldViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    onDelete = async () => {
        console.log(`Delete:`);
        const {fieldViewStore} = this.props;
        const {selectedEntity} = fieldViewStore;
        const employeeRecords: FieldRecord[] = await new DeleteFieldCommand().execute(selectedEntity);
        //unselect from selectedEntities ???
        fieldViewStore.setField(employeeRecords);
    };

    saveFieldRecordCommand = async (upsertValue: UpsertValue<FieldRecord>): Promise<UpsertResponse<FieldRecord>> => {
        const {fieldViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<FieldRecord> = isUpdate
            ? await new UpdateFieldCommand(upsertValue).execute()
            : await new AddFieldCommand(upsertValue).execute();
        fieldViewStore.setField(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveFieldRecord = async (upsertValue: UpsertValue<FieldRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('FieldListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('FieldListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedFields: FieldRecord[]) => {
        const {fieldViewStore} = this.props;
        fieldViewStore.setSelectedEntities(selectedFields);
    };

    onSelectAll = () => {
        const {fieldViewStore} = this.props;
        if (fieldViewStore.selectedEntities.length === 0) {
            fieldViewStore.selectedEntities = fieldViewStore.visibleEntities;
        } else {
            fieldViewStore.selectedEntities = [];
        }
    };

    render() {
        const {fieldViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleFieldCount, selectedEntities} = fieldViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const areManyEntitiesSelected: boolean = selectedEntities.length > 1;
        const isEmptyList: boolean = visibleFieldCount === 0;

        return (
            <div className="entity-container">
                <Card size="small"
                      className="fm-card"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : fieldViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'field.list.title'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={fieldViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              onDelete={this.onDelete}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}
                              isDeleteEnabled={isOneEntitySelected || areManyEntitiesSelected}/>}>
                    <div className="list-container">
                        <CheckedList selectedRecords={fieldViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: FieldRecord) => (
                                         <List.Item.Meta avatar={<AvatarWidget src={getAvatar(item)} size={50} hidePreview={true}/>}
                                                         title={item.name}
                                                         description={item.area}/>)}/>
                        {this.state.modalFormVisible && <ModalFormik config={FIELD_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveFieldRecordCommand}
                                                                     onSave={this.handleOnSaveFieldRecord}
                                                                     onCancel={this.handleOnModalFormClosed}/>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
