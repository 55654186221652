import React from 'react';
import {Col, Divider, Row} from 'antd';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {LogTypeViewStore} from '../../../../mobx/LogTypeViewStore';
import BarCode from '../../../../../../../common/widgets/barcode/BarCode';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import CEDAvatarsController from '../../../../../../../common/widgets/CEDAvatars/CEDAvatarsController';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import ManageTreeWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTreeWidget";
import ManageTagWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTagWidget";
import {getAvatar} from "../../../../../employee/EmployeeUtils";
import ManageAvatarWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageAvatarWidget";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import UpdateTreeIdsCommand from "../../../../../commands/tree/UpdateTreeIdsCommand";
import UpdateTagsCommand from "../../../../../commands/UpdateTagsCommand";
import UpdateAvatarCommand from "../../../../../commands/UpdateAvatarCommand";
import LogTypeTagAutoCompleteProvider from "./LogTypeTagAutoCompleteProvider";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    logTypeViewStore: LogTypeViewStore;
};

type Props = StoreProps;

@inject('logTypeViewStore', 'dataStore', 'coreStore')
@observer
export default class LogTypeTabGeneral extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    updateTree = (updateDataRecord: UpdateDataRecord<TreeFields>): Promise<boolean> => {
        return new UpdateTreeIdsCommand<LogTypeRecord>('log_type', updateDataRecord).execute().then((logTypeRecord: LogTypeRecord) => {
            const {dataStore, logTypeViewStore} = this.props;
            dataStore.updateLogType(logTypeRecord);
            logTypeViewStore.applyFiltersToView(updateDataRecord.id);
            return Promise.resolve(true);
        });
    };

    updateTag = (updateDataRecord: UpdateDataRecord<TagFields>): Promise<boolean> => {
        return new UpdateTagsCommand<LogTypeRecord>('log_type', updateDataRecord).execute().then((logTypeRecord: LogTypeRecord) => {
            const {dataStore, logTypeViewStore} = this.props;
            dataStore.updateLogType(logTypeRecord);
            return logTypeViewStore.refreshLogTypeTags(updateDataRecord.id).then(() => {
                return Promise.resolve(true);
            });
        });
    };

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        console.info(updateDataRecord);
        return new UpdateAvatarCommand<LogTypeRecord>('log_type', updateDataRecord).execute().then((logTypeRecord: LogTypeRecord) => {
            const {dataStore, logTypeViewStore} = this.props;
            dataStore.updateLogType(logTypeRecord);
            logTypeViewStore.applyFiltersToView(logTypeRecord.id);
            return Promise.resolve(true);
        });
    };

    render() {
        const {logTypeViewStore, coreStore} = this.props;
        const {logTypeTreeData, selectedEntity} = logTypeViewStore;
        const logTypeBarcodeData: string = fmBarcode(coreStore.user.schema, BarcodeEntityId.LOG_TYPE, selectedEntity.id);

        return (
            <span className={'maxWidth'}>
                <Row gutter={[10, 30]}>
                    <Col span={16}>
                        <Row gutter={[10, 30]}>
                            <Col span={24}>
                                <Divider orientation="left">Structure</Divider>
                                <ManageTreeWidget selectedEntity={selectedEntity} treeData={logTypeTreeData} updateCommand={this.updateTree}/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">Tags</Divider>
                                <ManageTagWidget selectedEntity={selectedEntity} treeData={logTypeTreeData} updateCommand={this.updateTag}
                                                 isAdminUser={coreStore.isAdminUser}
                                                 autoCompleteDataProvider={new LogTypeTagAutoCompleteProvider()}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={7}>
                        <ManageAvatarWidget selectedEntity={selectedEntity} updateCommand={this.updateAvatar}
                                            emptyAvatar={getAvatar(selectedEntity)}/>
                        <BarCode data={logTypeBarcodeData} fontSizePx={52}/>
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <CEDAvatarsController table={'log_type'} record={selectedEntity}/>
                        </div>
                    </Col>
                </Row>
            </span>
        );
    }
}
