import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {CalendarViewStore} from './mobx/CalendarViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import CalendarPageView from "./CalendarPageView";
import {CalendarURLData} from "./CalendarURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    calendarViewStore: CalendarViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'calendarViewStore')
@observer
export default class CalendarController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('CalendarPageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('CalendarPageController: componentDidMount', performance.now());
        const {calendarViewStore} = this.props;
        if (!calendarViewStore.isDataLoaded) {
            await calendarViewStore.loadData();
        } else {
            const urlData: CalendarURLData | undefined = decryptObject<CalendarURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.calendarBarCode)) {
                calendarViewStore.universalFilter = urlData!.calendarBarCode!;
                calendarViewStore.clearFilters();
                goToPage(AppRoutes.FM_CALENDAR);
            } else if (calendarViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                calendarViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {calendarViewStore} = this.props;
        const urlData: CalendarURLData | undefined = decryptObject<CalendarURLData>(this.props.match.params.data);
        console.log('CalendarPageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', calendarViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.calendarBarCode)) {
            calendarViewStore.universalFilter = urlData!.calendarBarCode!;
            calendarViewStore.clearFilters();
            goToPage(AppRoutes.FM_CALENDAR);
        } else if (isEmpty(urlData?.calendarBarCode) && !calendarViewStore.isViewReady) {
            calendarViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'CalendarPageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('CalendarPageController: componentDidCatch', performance.now());
    }

    render() {
        const {calendarViewStore} = this.props;
        const {isViewReady, isDataLoaded} = calendarViewStore;

        if (!isDataLoaded) {
            return <p>Load calendar data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating calendar view....</p>;
        }

        return (
            <CalendarPageView/>
        );
    }
}
