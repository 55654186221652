export enum FMDictionary {
    GENDER = 'gender',
    ROLE = 'role',
    WORK_PAYMENT_TYPE = 'work_payment_type'
}


// List for DICTIONARY_FM_DICTIONARY_CONFIG
export const FMDictionaryList: FMDictionary[] = [
    FMDictionary.GENDER,
    FMDictionary.ROLE,
    FMDictionary.WORK_PAYMENT_TYPE,
];