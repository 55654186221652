import React from 'react';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {FieldViewStore} from '../../../../mobx/FieldViewStore';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import GoogleMapReact, {ChangeEventValue, ClickEventValue, Coords, MapOptions} from 'google-map-react';
import {Alert, Button, Space, Spin} from "antd";
import {FieldMapMetadata} from "../../../../../../../common/widgets/mapField/FieldMapMetadata";
import {CustomRendererProps} from "../../../../../../../common/widgets/tab/CustomRenderer";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    fieldViewStore: FieldViewStore;
};

type Props = StoreProps & CustomRendererProps<FieldRecord>;

interface State {
    isMapInitiated: boolean;
    isEditMode: boolean;
    commandInProgress: boolean;
    coords: Coords[];
    center: Coords;
    zoom: number;
    hasField: boolean;
    userCenter: Coords;
    userZoom: number;
}

@inject('fieldViewStore', 'dataStore', 'coreStore')
@observer
export default class FieldMapTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private initialCenter: Coords = {lat: 51.3046125, lng: 29.6620749};
    private initialZoom: number = 4;

    private fieldPolygon: any;
    private map: any;
    private maps: any;

    mapOptions: MapOptions = {
        // fullscreenControl: false,
        mapTypeControl: true,
        mapTypeId: 'satellite' // default type
    };

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = this.calcInitialState(false);
    }

    calcInitialState = (isMapInitiated: boolean): State => {
        const {record} = this.props;
        if (record.map_metadata) {
            const fieldMapMetadata: FieldMapMetadata = JSON.parse(record.map_metadata);
            return {
                isMapInitiated,
                center: fieldMapMetadata.center,
                zoom: fieldMapMetadata.zoom,
                coords: fieldMapMetadata.coords,
                isEditMode: false,
                commandInProgress: false,
                hasField: fieldMapMetadata.coords.length > 0,
                userCenter: fieldMapMetadata.center,
                userZoom: fieldMapMetadata.zoom
            };
        }
        // get avg center and zoom from field records OR use initial
        return {
            isMapInitiated,
            center: this.initialCenter,
            zoom: this.initialZoom,
            coords: [],
            isEditMode: false,
            commandInProgress: false,
            hasField: false,
            userCenter: this.initialCenter,
            userZoom: this.initialZoom
        };
    }

    handleOnMapClick = (event: ClickEventValue) => {
        if (this.state.isEditMode) {
            const {coords} = this.state;
            coords.push({lat: event.lat, lng: event.lng})
            this.setState({coords})
            this.drawField();
        }
    };

    handleOnMapChange = (event: ChangeEventValue) => {
        this.setState({
            userCenter: event.center,
            userZoom: event.zoom
        })
    };

    handleApiLoaded = (map: any, maps: any) => {
        this.map = map;
        this.maps = maps;
        this.setState({isMapInitiated: true});
        this.drawField();
    }

    drawField = (coords?: Coords[]) => {
        if (this.fieldPolygon) {
            this.fieldPolygon.setMap(null);
        }
        this.fieldPolygon = new this.maps.Polygon({
            paths: coords || this.state.coords,
            strokeColor: "#8bff00",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#00c41b",
            fillOpacity: 0.35
        });
        this.fieldPolygon.setMap(this.map);
    };

    handleOnAdd = () => {
        this.setState({isEditMode: true});
        this.handleOnClear();
    };

    handleOnUndo = () => {
        const {coords} = this.state;
        coords.pop();
        this.setState({coords})
        this.drawField();
    };

    handleOnClear = () => {
        if (this.fieldPolygon) {
            this.setState({coords: []});
            this.fieldPolygon.setMap(null);
        }
    };

    handleOnSave = async () => {
        const {fieldViewStore, entity_id} = this.props;
        const {coords, userCenter, userZoom} = this.state;
        this.setState({commandInProgress: true});
        const fieldMapMetadata: FieldMapMetadata = {
            coords: coords,
            center: userCenter,
            zoom: userZoom
        };
        await fieldViewStore.updateMap(fieldMapMetadata, entity_id!);
        this.setState({commandInProgress: false, isEditMode: false});
    };

    handleOnCancel = async () => {
        const {fieldViewStore, dataStore, entity_id} = this.props;
        fieldViewStore.setField(dataStore.field, entity_id); // just set
    };

    getHelpMessage = (points: number): string => {
        if (points < 4) {
            return `Выберите ${points}-ю точку на карте`;
        }
        return 'Продолжайте обозначение участка на карте...';
    };

    render() {
        const {record, coreStore} = this.props;
        const {isMapInitiated, commandInProgress, center, zoom, coords, isEditMode, hasField} = this.state;
        const canEdit: boolean = coreStore.isAdminUser || record.created_by === coreStore.user.user_id;

        return (
            <Spin spinning={!isMapInitiated || commandInProgress} wrapperClassName={"maxWidth"}>
                <div style={{height: '500px', width: '700px'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: 'AIzaSyCkooR-tRXXxS3OdjG2f2Yg-SYQ4absYak'}}
                        defaultCenter={center}
                        defaultZoom={zoom}
                        options={this.mapOptions}
                        onClick={this.handleOnMapClick}
                        onChange={this.handleOnMapChange}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}
                    >
                        {/*<div lat={59.955413} lng={30.337844}>My Marker</div>*/}
                    </GoogleMapReact>
                    <br/>
                    {canEdit && <>
                        <Space>
                            {!isEditMode && <Button onClick={this.handleOnAdd} type={"primary"}>
                                {hasField ? 'Redraw field' : 'Add field'}
                            </Button>}
                            {isEditMode && <>
                                <Button onClick={this.handleOnClear} disabled={!coords.length}>Clear</Button>
                                <Button onClick={this.handleOnUndo} disabled={!coords.length}>Undo</Button>
                                <Button onClick={this.handleOnSave} type={"primary"}>Save</Button>
                                <Button onClick={this.handleOnCancel}>Cancel</Button>
                            </>}
                        </Space>
                        <br/><br/>
                        {isEditMode && coords.length < 5 && <Alert message={this.getHelpMessage(coords.length + 1)}/>}
                    </>
                    }
                </div>
            </Spin>
        );
    }
}
