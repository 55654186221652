import {ModalFormConfig} from '../../../../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {FormFieldRender} from '../../../../../../../../common/widgets/modalForm/type/FormFieldRender';
import {NonEmptyValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import PhoneTypeAutoCompleteProvider from './PhoneTypeAutoCompleteProvider';
import {PhoneValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/PhoneValidator';
import EmployeePhoneClipboard from './EmployeePhoneClipboard';
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";

export const EMPLOYEE_PHONE_FORM_CONFIG: ModalFormConfig<EmployeePhoneRecord, EmployeeRecord> = {
    title: 'employee_phone.modalForm.title',
    tableName: 'employee_phone',
    getNewRecord: (employee?: EmployeeRecord): EmployeePhoneRecord => {
        return {
            employee_id: employee!.id,
            confirmed: false
        } as EmployeePhoneRecord;
    },
    clipboardText: new EmployeePhoneClipboard(),
    fieldConfig: [
        {
            label: 'employee_phone.field.phone_type',
            field_name: 'phone_type',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 2})],
            config: {
                autoCompleteOptionsProvider: new PhoneTypeAutoCompleteProvider(),
                inputProps: {
                    placeholder: 'Mobile, Desk, Home, etc.',
                    maxLength: 20
                }
            },
        },
        {
            label: 'employee_phone.field.phone',
            field_name: 'phone',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3}), new PhoneValidator()],
            config: {
                inputProps: {
                    placeholder: '+XXX (XXX) XXX-XX-XX',
                    maxLength: 20
                }
            }
        },
        {
            label: 'employee_phone.field.confirmed',
            field_name: 'confirmed',
            renderType: FormFieldRender.SWITCH,
        },
        {
            label: 'file_record.field.comment',
            field_name: 'comment',
            optional: true,
            renderType: FormFieldRender.TEXT,
            config: {
                inputProps: {
                    autoSize: {minRows: 2, maxRows: 4}
                }
            }
        }
    ]
};
