import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../common/utils/AxiosUtils";
import Command from "../../../common/types/Command";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";

export default class UpdateAvatarCommand<T extends DataRecord> extends Command<T, UpdateDataRecord<AvatarFields>> {

    constructor(private readonly table: string, private readonly updateDataRecord: UpdateDataRecord<AvatarFields>) {
        super();
    }

    async execute(): Promise<T> {
        return axiosGetInstance().post<T>(`/avatar/update/${this.table}`, this.updateDataRecord)
            .then((response: AxiosResponse<T>) => response.data);
    }

}
