import React from "react";
import {Checkbox, Form} from 'antd';
import {FieldProps, FormikErrors} from "formik";
import {CheckboxValueType} from "antd/lib/checkbox/Group";

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

export const CheckboxFormItem = (props: FieldProps & Record<string, any>) => {
    const touched: boolean = !!props.form.touched[props.field.name];
    const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = props.form.errors[props.field.name];
    const hasErrorBoolean: boolean = !!hasError;
    const submitted: boolean = props.form.submitCount > 0;
    const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;
    const handleOnChange = (checkedValue: Array<CheckboxValueType>) => {
        const touched: boolean = !!props.form.touched[props.field.name];
        if (!touched) {
            props.form.setFieldTouched(props.field.name, true, false);
        }
        props.form.setFieldValue(props.field.name, checkedValue.map((checkboxValueType: CheckboxValueType) => checkboxValueType));
    };
    return (
        <div className="field-container">
            <FormItem
                label={props.label}
                hasFeedback={
                    touched || submitted ? true : false
                }
                key={props.field.name}
                help={showError ? hasError : false}
                validateStatus={props.form.isValidating ? "validating" : (showError ? "error" : (!props.required && !props.field.value ? "" : "success"))}
                required={props.required}>
                <CheckboxGroup
                    onChange={handleOnChange}
                    value={props.field.value}
                    disabled={props.disabled}
                    options={props.options}
                    {...props.inputProps}/>
            </FormItem>
        </div>
    );
};
