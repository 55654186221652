import {ModalFormConfig} from '../../modalForm/type/ModalFormConfig';
import {FormFieldRender} from '../../modalForm/type/FormFieldRender';
import {NonEmptyValidator} from "../../modalForm/validators/sync/NonEmptyValidator";
import {LengthValidator} from "../../modalForm/validators/sync/LengthValidator";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {DictionaryDecorator} from "../../dictionary/DictionaryDecorator";
import {DICTIONARY_ICOFONT} from "../../dictionary/StaticDictionatryConfig";

export const TREE_STRUCTURE_FORM_CONFIG: ModalFormConfig<TreeRecord> = {
    title: '',
    tableName: '',
    getNewRecord: (current: TreeRecord | undefined) => {
        return {
            parent_id: current?.parent_id
        } as TreeRecord;
    },
    fieldConfig: [
        {
            label: 'tree.field.name',
            field_name: "name",
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3, max: 20})],
        },
        {
            label: 'tree.field.icon',
            field_name: "icon",
            renderType: FormFieldRender.SINGLE_SELECT,
            optional: true,
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_ICOFONT),
                inputProps: {
                    // size: "large",
                    showSearch: true,
                    placeholder: 'Иконка группы'
                }
            }
        },
        {
            label: 'tree.field.color',
            field_name: "color",
            optional: true,
            renderType: FormFieldRender.COLOR_PICKER,
        }
    ]
};
