import {AxiosResponse} from "axios";
import Command from "../../../../../../../../../common/types/Command";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

export default class DeleteEmployeeEmailCommand extends Command<EmployeeEmailRecord[], EmployeeEmailRecord> {
    execute(record: EmployeeEmailRecord): Promise<EmployeeEmailRecord[]> {
        return axiosGetInstance().post<EmployeeEmailRecord[]>(`/employee/email/delete`, record)
            .then((response: AxiosResponse<EmployeeEmailRecord[]>) => response.data);
    }
}
