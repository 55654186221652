import {AxiosResponse} from "axios";
import Command from "../../../../../../../../../common/types/Command";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";

export default class DeleteEmployeePhoneCommand extends Command<EmployeePhoneRecord[], EmployeePhoneRecord> {
    execute(record: EmployeePhoneRecord): Promise<EmployeePhoneRecord[]> {
        return axiosGetInstance().post<EmployeePhoneRecord[]>(`/employee/phone/delete`, record)
            .then((response: AxiosResponse<EmployeePhoneRecord[]>) => response.data);
    }
}
