import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";

export default class DeleteFieldCommand extends Command<FieldRecord[], FieldRecord> {
    execute(fieldRecord: FieldRecord): Promise<FieldRecord[]> {
        return axiosGetInstance().post<FieldRecord[]>(`/field/delete`, fieldRecord)
            .then((response: AxiosResponse<FieldRecord[]>) => response.data);
    }
}
