import {AxiosResponse} from 'axios';
import {axiosGetInstance} from '../../../common/utils/AxiosUtils';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {UserRecord} from "fm-shared-data/src/types/db/core/UserRecord";
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";

export function fetchCurrentEmployee(): Promise<EmployeeRecord> {
    return axiosGetInstance().post<EmployeeRecord>('/employee/logged').then((response: AxiosResponse<EmployeeRecord>) => response.data);
}

export function fetchCurrentUser(): Promise<UserRecord> {
    return axiosGetInstance().post<UserRecord>('/user/logged').then((response: AxiosResponse<UserRecord>) => response.data);
}

export function fetchCurrentAccount(): Promise<AccountRecord> {
    return axiosGetInstance().post<AccountRecord>('/account/logged').then((response: AxiosResponse<AccountRecord>) => response.data);
}
