import React, {MouseEvent} from "react";
import {Button, Col, Form, message, Row, Typography} from 'antd';
import {CheckCircleTwoTone, InfoCircleTwoTone} from "@ant-design/icons";
import RequestConfirmationEmployeeEmailCommand from "../commands/RequestConfirmationEmployeeEmailCommand";
import {formatMessage} from "../../../../../../../../../common/locale/IntlProvider";
import {EmailValidator} from "../../../../../../../../../common/widgets/modalForm/validators/sync/EmailValidator";
import {CustomRendererProps} from "../../../../../../../../../common/widgets/tab/CustomRenderer";
import {FormikProps} from "formik/dist/types";
import {FormikErrors} from "formik";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";

const {Text} = Typography;
const FormItem = Form.Item;

// type Props = FieldProps & Record<string, any> & CustomRendererProps<EmployeeEmailRecord>;
type Props = FormikProps<any> & CustomRendererProps<EmployeeEmailRecord> & Record<string, any>;

interface State {
    isConfirmationSent: boolean;
    isConfirmationSending: boolean;
}

export default class EmailConfirmationCustomRenderer extends React.Component<Props, State> {
    state: State = {isConfirmationSent: false, isConfirmationSending: false};

    sendConfirmationEmail = async (e: MouseEvent) => {
        e.preventDefault();
        const {record} = this.props;
        const {email} = record!;
        this.setState({isConfirmationSending: true});
        new RequestConfirmationEmployeeEmailCommand().execute(record!).then(() => {
            message.success({content: formatMessage('employee_email.field.confirmation_sent') + ': ' + email}, 3);
            this.setState({isConfirmationSent: true, isConfirmationSending: false});
        })
    };

    render() {
        const confirmedEmail: boolean = !!this.props.record?.confirmed_on;
        const isEmailValid: boolean = new EmailValidator().validate(this.props.record?.email) === null;

        const touched: boolean = !!this.props.form.touched[this.props.field.name];
        const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = this.props.form.errors[this.props.field.name];
        const hasErrorBoolean: boolean = !!hasError;
        const submitted: boolean = this.props.form.submitCount > 0;
        const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;

        if (!this.props.record?.id) {
            return <Text type={"secondary"} style={{display: "flex"}}>
                &nbsp;&nbsp;
                <InfoCircleTwoTone style={{fontSize: 22}}/>&nbsp;&nbsp;
                Отправить подтверждение можно после создания адреса
            </Text>
        }
        return (
            <div className="field-container">
                <FormItem
                    label={this.props.label}
                    key={this.props.field.name}

                    hasFeedback={
                        touched || submitted ? true : false
                    }
                    help={showError ? hasError : false}
                    validateStatus={this.props.form.isValidating ? 'validating' : (showError ? 'error' : (!this.props.required && !this.props.field.name ? '' : 'success'))}
                    required={this.props.required}>
                    <Row align="middle">
                        <Col span={17}>
                            <Text type={confirmedEmail ? "success" : "warning"} style={{display: "flex"}}>
                                &nbsp;&nbsp;
                                {!confirmedEmail && <InfoCircleTwoTone style={{fontSize: 22}} twoToneColor="orange"/>}
                                {confirmedEmail && <CheckCircleTwoTone style={{fontSize: 22}} twoToneColor="#52c41a"/>}
                                &nbsp;&nbsp;
                                {confirmedEmail ? `${formatMessage('employee_email.field.confirmed_yes')} ${MomentUtils.clientDateToLabel(this.props.record.confirmed_on!)}` : formatMessage('employee_email.field.confirmed_no')}
                            </Text>
                        </Col>
                        {!confirmedEmail &&
                        <Col span={7}>
                            <Button disabled={!this.props.record?.email || this.state.isConfirmationSent || this.state.isConfirmationSending || !isEmailValid}
                                    loading={this.state.isConfirmationSending}
                                    type={"dashed"}
                                    onClick={this.sendConfirmationEmail}>
                                {this.state.isConfirmationSending && formatMessage('employee_email.field.confirmation_sending')}
                                {!this.state.isConfirmationSending && formatMessage(this.state.isConfirmationSent ? 'employee_email.field.confirmation_sent' : 'employee_email.field.confirmation_send')}
                            </Button>
                        </Col>
                        }
                    </Row>
                </FormItem>
            </div>
        );
    }
}
