import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {stores} from "../../../../app/mobx/Stores";
import DataProvider from "../../modalForm/type/DataProvider";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {getEmployeeName} from '../../../../pages/account/employee/EmployeeUtils';

export class DataStoreEmployeeProvider implements DataProvider<DictionaryRawRecord<EmployeeRecord>[]> {

    get(): DictionaryRawRecord<EmployeeRecord>[] {
        return stores.dataStore.employee.map((employee: EmployeeRecord) => {
            return {
                id: employee.id,
                name: getEmployeeName(employee),
                record: employee
            };
        }).sort((a: DictionaryRawRecord<EmployeeRecord>, b: DictionaryRawRecord<EmployeeRecord>): number => a.name.localeCompare(b.name));
    }
}
