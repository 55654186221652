import {FormFieldRender} from "client/src/common/widgets/modalForm/type/FormFieldRender";

export enum LogTypeFieldType {
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    TEXT = "TEXT",
    FM_ENTITY = "FM_ENTITY",            // DICTIONARY_FM_ENTITY_CONFIG
    FM_DICTIONARY = "FM_DICTIONARY",    // DICTIONARY_FM_DICTIONARY_CONFIG
    STRING_LOOKUP = "STRING_LOOKUP",
    PERCENT = "PERCENT",
    LINK = "LINK",
    EMAIL = "EMAIL",
    RATE = "RATE",
    DATE = "DATE",
    TIME = "TIME",
    DATETIME = "DATETIME",
}

// List for DICTIONARY_LOGTYPE_FIELD_TYPE
export const LogTypeFieldTypes: LogTypeFieldType[] = [
    LogTypeFieldType.NUMBER,
    LogTypeFieldType.STRING,
    LogTypeFieldType.BOOLEAN,
    LogTypeFieldType.TEXT,
    LogTypeFieldType.FM_ENTITY,
    LogTypeFieldType.FM_DICTIONARY,
    LogTypeFieldType.STRING_LOOKUP,
    LogTypeFieldType.PERCENT,
    LogTypeFieldType.LINK,
    LogTypeFieldType.EMAIL,
    LogTypeFieldType.RATE,
    LogTypeFieldType.DATE,
    LogTypeFieldType.TIME,
    LogTypeFieldType.DATETIME,
];

export interface LogTypeField {
    field_id: string;

    field_index: number;
    field_enabled: boolean;
    field_title?: string;
    field_type: LogTypeFieldType;
    field_unit?: string;
    field_required: boolean;
    field_prev: boolean;
    field_tab: boolean;
    field_dict_id: string;
    field_description?: string;
}

export function findFormFieldRender(field_type: LogTypeFieldType): FormFieldRender {
    switch (field_type) {
        case LogTypeFieldType.NUMBER:
            return FormFieldRender.NUMBER;
        case LogTypeFieldType.STRING:
            return FormFieldRender.INPUT;
        case LogTypeFieldType.BOOLEAN:
            return FormFieldRender.SWITCH;
        case LogTypeFieldType.TEXT:
            return FormFieldRender.TEXT;

        case LogTypeFieldType.FM_ENTITY:
        case LogTypeFieldType.FM_DICTIONARY:
            return FormFieldRender.SINGLE_SELECT;
        case LogTypeFieldType.STRING_LOOKUP:
            return FormFieldRender.AUTO_COMPLETE;

        case LogTypeFieldType.DATE:
            return FormFieldRender.DATE;
        case LogTypeFieldType.DATETIME:
            return FormFieldRender.DATETIME;

        case LogTypeFieldType.RATE:
            return FormFieldRender.RATE;

        case LogTypeFieldType.STRING:
        default:
            return FormFieldRender.INPUT;
    }
}
