export enum LocalStorageKey {
    LOCALE = 'locale',
    ACCESS_TOKEN = 'access_token',
    SIGNIN_EMAIL = 'signin_email',

    EMPLOYEE_FILTER_OPEN_PANELS = 'employee.filter.open.panels',
    EMPLOYEE_TREE_ID = 'employee.filter.tree.id',
    EMPLOYEE_TREE_NODES = 'employee.filter.tree.nodes',
    EMPLOYEE_PREDEFINED_FILTERS = 'employee.filter.predefined',
    EMPLOYEE_TAGS_FILTERS = 'employee.filter.tags',

    FIELD_FILTER_OPEN_PANELS = 'field.filter.open.panels',
    FIELD_TREE_ID = 'field.filter.tree.id',
    FIELD_TREE_NODES = 'field.filter.tree.nodes',
    FIELD_PREDEFINED_FILTERS = 'field.filter.predefined',
    FIELD_TAGS_FILTERS = 'field.filter.tags',

    EQUIPMENT_FILTER_OPEN_PANELS = 'equipment.filter.open.panels',
    EQUIPMENT_TREE_ID = 'equipment.filter.tree.id',
    EQUIPMENT_TREE_NODES = 'equipment.filter.tree.nodes',
    EQUIPMENT_PREDEFINED_FILTERS = 'equipment.filter.predefined',
    EQUIPMENT_TAGS_FILTERS = 'equipment.filter.tags',

    WORK_FILTER_OPEN_PANELS = 'work.filter.open.panels',
    WORK_TREE_ID = 'work.filter.tree.id',
    WORK_TREE_NODES = 'work.filter.tree.nodes',
    WORK_PREDEFINED_FILTERS = 'work.filter.predefined',
    WORK_TAGS_FILTERS = 'work.filter.tags',

    CALENDAR_FILTER_OPEN_PANELS = 'calendar.filter.open.panels',
    CALENDAR_TREE_ID = 'calendar.filter.tree.id',
    CALENDAR_TREE_NODES = 'calendar.filter.tree.nodes',
    CALENDAR_PREDEFINED_FILTERS = 'calendar.filter.predefined',
    CALENDAR_TAGS_FILTERS = 'calendar.filter.tags',

    LOGTYPE_FILTER_OPEN_PANELS = 'logType.filter.open.panels',
    LOGTYPE_TREE_ID = 'logType.filter.tree.id',
    LOGTYPE_TREE_NODES = 'logType.filter.tree.nodes',
    LOGTYPE_PREDEFINED_FILTERS = 'logType.filter.predefined',
    LOGTYPE_TAGS_FILTERS = 'logType.filter.tags',

    SCANNER_FILTER_OPEN_PANELS = 'scanner.filter.open.panels',
    SCANNER_TREE_ID = 'scanner.filter.tree.id',
    SCANNER_TREE_NODES = 'scanner.filter.tree.nodes',
    SCANNER_PREDEFINED_FILTERS = 'scanner.filter.predefined',
    SCANNER_TAGS_FILTERS = 'scanner.filter.tags',


    REPORT_FILTER_OPEN_PANELS = 'report.filter.open.panels',
    REPORT_TREE_ID = 'report.filter.tree.id',
    REPORT_TREE_NODES = 'report.filter.tree.nodes',
    REPORT_PREDEFINED_FILTERS = 'report.filter.predefined',
    REPORT_TAGS_FILTERS = 'report.filter.tags',
}

export class LocalStorage {
    static getItem(key: string): string | null {
        return localStorage.getItem(key);
    }

    static setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    static removeItem(key: string) {
        localStorage.removeItem(key);
    }
}
