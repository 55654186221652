import React from 'react';
import {Col, Image, message, Popconfirm, Row, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import classNames from "classnames";
import Dropzone, {DropEvent, DropzoneState, FileRejection} from 'react-dropzone';
import CropImageWidget from "./cropImage/CropImageWidget";
import {fileToBase64} from "./cropImage/ImageUtils";
import {formatMessage} from "../../../../../../../../common/locale/IntlProvider";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {MAX_IMAGE_SIZE_MB, sizeMbToB} from "fm-shared-utils/src/utils/file/FileUtils";

interface Props<T extends DataRecord & AvatarFields> {
    selectedEntity: T;
    updateCommand: (value: UpdateDataRecord<AvatarFields>) => Promise<boolean>;
    emptyAvatar: string;
}

interface State {
    isCropVisible: boolean;
    isAvatarUpdating: boolean;
    base64: string;
}

export default class ManageAvatarWidget<T extends DataRecord & AvatarFields> extends React.Component<Props<T>, State> {

    constructor(props: Props<T>) {
        super(props);
        this.state = {
            isCropVisible: false,
            isAvatarUpdating: false,
            base64: ''
        };
    }

    onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (fileRejections.length > 0) {
            message.error({content: formatMessage('dropzone.placeholder.files.any.maxSize', {max: MAX_IMAGE_SIZE_MB}), duration: 4});
        }
        if (acceptedFiles.length > 0) {
            this.setState({isAvatarUpdating: true});
            fileToBase64(acceptedFiles[0]).then((base64: string) => {
                this.setState({base64, isAvatarUpdating: false, isCropVisible: true});
            })
        }
    };

    handleOnCrop = (croppedImage?: string) => {
        this.setState({isCropVisible: false, isAvatarUpdating: true});
        const {selectedEntity} = this.props;
        const {edited_on} = selectedEntity;
        const updateDataRecord: UpdateDataRecord<AvatarFields> = {
            id: selectedEntity.id,
            edited_on: edited_on,// && clientDateToUTCDate(edited_on),
            data: {avatar: croppedImage}
        };
        this.props.updateCommand(updateDataRecord).then(() => {
            this.setState({isAvatarUpdating: false});
        });
    }

    handleCancel = () => {
        this.setState({isCropVisible: false})
    }

    onDeleteAvatar = () => {
        this.handleOnCrop();
    }

    render() {
        return (
            <Spin spinning={this.state.isAvatarUpdating} wrapperClassName={'avatar-edit-container'}>
                <Row>
                    <Col span={24} className={'col-center'}>
                        <Dropzone onDrop={this.onDrop}
                                  accept={['image/*']}
                                  maxSize={sizeMbToB(MAX_IMAGE_SIZE_MB)}
                                  maxFiles={1}>
                            {(dropzoneState: DropzoneState) => {
                                const {getRootProps, getInputProps, isDragAccept, isDragReject} = dropzoneState;
                                return (
                                    <div {...getRootProps()}
                                         className={classNames('dropzone',
                                             {'dropzone--manual-hover': !this.props.selectedEntity.avatar},
                                             {'dropzone--isDragAccept': isDragAccept}, {'dropzone--isDragReject': isDragReject})}>
                                        <div className={'edit-avatar'}>
                                            <Image src={this.props.selectedEntity.avatar || this.props.emptyAvatar} preview={false}/>
                                        </div>
                                        <input {...getInputProps()} />
                                        <span>{formatMessage('dropzone.placeholder.one.image')}</span>
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {this.props.selectedEntity.avatar && (
                            <Popconfirm placement={'right'}
                                        okType={'primary'}
                                        title={formatMessage('SCRUDActions.delete.image.confirmation')}
                                        okText={formatMessage('common.yes')}
                                        cancelText={formatMessage('common.no')}
                                        onConfirm={this.onDeleteAvatar}>
                                <DeleteOutlined className={'scrud-container__icon delete-avatar'}/>
                            </Popconfirm>
                        )}
                        {this.state.isCropVisible && (
                            <CropImageWidget imageBase64={this.state.base64} onCrop={this.handleOnCrop} onCancel={this.handleCancel}/>)}
                    </Col>
                </Row>
            </Spin>
        );
    }
}
