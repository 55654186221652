import {AxiosResponse} from "axios";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import {FieldMapMetadata} from "./FieldMapMetadata";
import {axiosGetInstance} from "../../utils/AxiosUtils";
import Command from "../../types/Command";

export default class UpdateFieldMapCommand extends Command<FieldRecord[], FieldMapMetadata> {

    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }

    execute(fieldMapMetadata: FieldMapMetadata): Promise<FieldRecord[]> {
        return axiosGetInstance().post<FieldRecord[]>(`/field/update/map/${this.barcode_entity_id}/${this.entity_id}`, fieldMapMetadata)
            .then((response: AxiosResponse<FieldRecord[]>) => response.data);
    }

}
