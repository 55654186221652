import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {ScannerViewStore} from './mobx/ScannerViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import ScannerPageView from "./ScannerPageView";
import {ScannerURLData} from "./ScannerURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    scannerViewStore: ScannerViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'scannerViewStore')
@observer
export default class ScannerController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('ScannerController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('ScannerController: componentDidMount', performance.now());
        const {scannerViewStore} = this.props;
        if (!scannerViewStore.isDataLoaded) {
            await scannerViewStore.loadData();
        } else {
            const urlData: ScannerURLData | undefined = decryptObject<ScannerURLData>(this.props.match.params.data);
            if (urlData?.device_key) {
                scannerViewStore.universalFilter = urlData!.device_key;
                scannerViewStore.clearFilters();
                goToPage(AppRoutes.FM_SCANNER);
            } else if (scannerViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                scannerViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {scannerViewStore} = this.props;
        const urlData: ScannerURLData | undefined = decryptObject<ScannerURLData>(this.props.match.params.data);
        console.log('ScannerController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', scannerViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.device_key)) {
            scannerViewStore.universalFilter = urlData!.device_key;
            scannerViewStore.clearFilters();
            goToPage(AppRoutes.FM_SCANNER);
        } else if (isEmpty(urlData?.device_key) && !scannerViewStore.isViewReady) {
            scannerViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'ScannerController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('ScannerController: componentDidCatch', performance.now());
    }

    render() {
        const {scannerViewStore} = this.props;
        const {isViewReady, isDataLoaded} = scannerViewStore;

        if (!isDataLoaded) {
            return <p>Load scanner data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating scanner view....</p>;
        }

        return (
            <ScannerPageView/>
        );
    }
}
