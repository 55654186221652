import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {FileDetailed} from "fm-shared-data/src/types/db/common/FileDetailed";
import {EntityKey} from "fm-shared-data/src/types/db/common/EntityKey";

export default class LoadFilesCommand extends Command<FileDetailed[], EntityKey> {
    execute(fileEntityKey: EntityKey): Promise<FileDetailed[]> {
        return axiosGetInstance().post<FileDetailed[]>('/file/records', fileEntityKey)
            .then((response: AxiosResponse<FileDetailed[]>) => response.data);
    }
}
