import React from 'react';
import ResetPasswordPageView from './ResetPasswordPageView';
import './../style/loginPage.scss';
import {URLRouteProps} from '../../../../routing/URLRouteProps';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {ResetPasswordURLData} from "fm-shared-data/src/types/auth/ResetPasswordURLData";

export default class ResetPasswordPageController extends React.Component<URLRouteProps> {

    render() {
        const urlData: ResetPasswordURLData | undefined = decryptObject<ResetPasswordURLData>(this.props.match.params.data);
        return (
            <div className="login-container">
                <div className="login-container__form">
                    <ResetPasswordPageView data={urlData}/>
                </div>
            </div>
        );
    }
}
