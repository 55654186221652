import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {FineBonusWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/FineBonusWorklogRequest';

export default class LoadFineBonusWorklogInfoRecordsCommand extends Command<FineBonusWorklogInfoRecord[], number> {
    execute(sync_process_id: number): Promise<FineBonusWorklogInfoRecord[]> {
        return axiosGetInstance().post<FineBonusWorklogInfoRecord[]>(`/sync/worklog/fineBonus/${sync_process_id}`)
            .then((response: AxiosResponse<FineBonusWorklogInfoRecord[]>) => response.data);
    }
}
