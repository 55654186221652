import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";

export default class DeleteReportCommand extends Command<ReportRecord[], ReportRecord> {
    execute(ReportRecord: ReportRecord): Promise<ReportRecord[]> {
        return axiosGetInstance().post<ReportRecord[]>(`/report/delete`, ReportRecord)
            .then((response: AxiosResponse<ReportRecord[]>) => response.data);
    }
}
