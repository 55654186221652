import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export default class DeleteTreeCommand<T extends TreeRecord> extends Command<TreeRecord[], TreeRecord> {
    constructor(private readonly table: string) {
        super();
    }

    execute(treeRecord: TreeRecord): Promise<TreeRecord[]> {
        return axiosGetInstance().post<TreeRecord[]>(`/tree/delete/${this.table}`, treeRecord)
            .then((response: AxiosResponse<TreeRecord[]>) => response.data);
    }
}
