import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {restoreEmployeePredefinedFilter} from '../components/filters/PredefinedEmployeeFilters';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import LoadTagsCommand from '../../commands/LoadTagsCommand';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export class EmployeeViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<EmployeeRecord>[] = [];

    visibleEntities: EmployeeRecord[] = [];
    selectedEntities: EmployeeRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    employeeTags: string[] = [];
    selectedEmployeeTags: string[] = [];
    isEmployeeTagsLoaded: Date | undefined;
    isEmployeeTagsLoading: boolean = true;

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            employeeTags: observable,
            selectedEmployeeTags: observable,
            isEmployeeTagsLoaded: observable,
            isEmployeeTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateEmployeeTreeRecord: action,
            setSelectedEmployeeTags: action,
            refreshEmployeeTags: action,

            selectedEntityIds: computed,
            visibleEmployeeCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            employeeTreeData: computed,
            selectedEmployeeTreeRecord: computed,

            setEmployee: action,

            selectedEmployeePhone: computed,
            selectedEmployeeEmail: computed,
            selectedEmployeeAddress: computed,
        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleEmployeeCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isEmployeeLoaded && !!this.dataStore.isEmployeeTreeLoaded &&
            !!this.isEmployeeTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleEmployeeCount > 0 && this.visibleEmployeeCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.employeeTreeRootId;
    }

    get employeeTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.employeeTreeData;
    }

    get selectedEmployeeTreeRecord(): TreeRecord {
        return this.dataStore.employeeTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    get selectedEmployeePhone(): EmployeePhoneRecord[] {
        if (this.selectedEntities.length === 1) {
            const selectedEmployeeId: number = this.selectedEntity.id;
            return this.dataStore.employeePhone.filter((record: EmployeePhoneRecord) => record.employee_id === selectedEmployeeId);
        }
        return [];
    }

    get selectedEmployeeEmail(): EmployeeEmailRecord[] {
        if (this.selectedEntities.length === 1) {
            const selectedEmployeeId: number = this.selectedEntity.id;
            return this.dataStore.employeeEmail.filter((record: EmployeeEmailRecord) => record.employee_id === selectedEmployeeId);
        }
        return [];
    }

    get selectedEmployeeAddress(): EmployeeAddressRecord[] {
        if (this.selectedEntities.length === 1) {
            const selectedEmployeeId: number = this.selectedEntity.id;
            return this.dataStore.employeeAddress.filter((record: EmployeeAddressRecord) => record.employee_id === selectedEmployeeId);
        }
        return [];
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isEmployeeLoaded) {
            loaders.push(this.dataStore.loadEmployee());
        }
        if (!this.dataStore.isEmployeeTreeLoaded) {
            loaders.push(this.dataStore.loadEmployeeTree());
        }
        if (!this.isEmployeeTagsLoaded) {
            loaders.push(this.loadEmployeeTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadEmployeeTags() {
        this.isEmployeeTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('employee').execute();
        runInAction(() => {
            this.employeeTags = tags;
            this.isEmployeeTagsLoading = false;
            this.isEmployeeTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.employeeTreeData, this.dataStore.employeeTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.EMPLOYEE_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.EMPLOYEE_PREDEFINED_FILTERS);

        //tags:
        this.selectedEmployeeTags = [];
        LocalStorage.removeItem(LocalStorageKey.EMPLOYEE_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (employee: EmployeeRecord) => (employee.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: EmployeeRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedEmployeeTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (employee: EmployeeRecord) => this.selectedEmployeeTags.filter(Boolean).every((eTag: string) => employee.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.employeeTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<EmployeeRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedEmployeeTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.employee
            : this.dataStore.employee.filter((employee: EmployeeRecord) => {
                return filters.every((fun: any) => fun(employee));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: EmployeeRecord | undefined = this.visibleEntities.find((employee: EmployeeRecord) => employee.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateEmployeeTreeRecord(employeeTreeRecords: TreeRecord[]) {
        this.dataStore.setEmployeeTree(employeeTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.employeeTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.employeeTreeData, this.dataStore.employeeTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedEmployeeTags(tags: string[]) {
        this.selectedEmployeeTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshEmployeeTags(trySelectId?: number) {
        await this.loadEmployeeTags();
        runInAction(() => {
            this.selectedEmployeeTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<EmployeeRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setEmployee(data: EmployeeRecord[], trySelectId?: number) {
        this.dataStore.setEmployee(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedEmployees: EmployeeRecord[]) {
        this.selectedEntities = selectedEmployees;
    }

    get selectedEntity(): EmployeeRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.employeeTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.EMPLOYEE_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.employeeTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.employeeTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.EMPLOYEE_TREE_NODES) || this.dataStore.employeeTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreEmployeePredefinedFilter();

        // tags:
        this.selectedEmployeeTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.EMPLOYEE_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.employeeTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.EMPLOYEE_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }

        if (isBarCode(this.universalFilter)) {
            const entityId: number = Number.parseInt(this.universalFilter.substr(-7));
            this.visibleEntities = this.visibleEntities.filter((employee: EmployeeRecord) => entityId === employee.id);
        } else { // full text search
            this.visibleEntities = this.visibleEntities.filter((employee: EmployeeRecord) => {
                const searchString: string = [
                    employee.first_name,
                    employee.middle_name,
                    employee.last_name,
                ].filter(Boolean).join().toLowerCase();
                return searchString.includes(this.universalFilter.toLowerCase());
            });
        }
        // this.selectedEntities = []; // clear any selections
    }

}
