import {StringDate} from "fm-shared-data/src/types/db/core/StringDate";

export const DB_TIMESTAMP_FIELDS: string[] = ['created_on', 'edited_on', 'deleted_on', 'valid_from', 'valid_to', 'validated_on', 'birthday', 'confirmed_on', 'process_start', 'process_end', 'device_datetime', 'event_date'];
export const NOTIFICATION_PERIOD_DAYS: number = 14;

export class DateUtils {

    public static toDate(stringDate: StringDate): Date {
        return typeof stringDate === "string" ? new Date(stringDate) : new Date(stringDate);
    }

    public static timestampToClientDate(timestamp: number): Date {
        return new Date(timestamp);
    }

    public static timestampToUTCDate(timestamp: number): Date {
        return new Date(timestamp - new Date().getTimezoneOffset() * 60 * 1000);
    }

    public static nowUTC(): number {
        return this.clientDateToUTCTimestamp(new Date());
    }

    public static clientDateToUTCDate(date: Date): Date {
        return new Date(date.valueOf() - new Date().getTimezoneOffset() * 60 * 1000);
    }

    public static clientDateToUTCTimestamp(date: StringDate): number {
        return this.clientDateToUTCDate(this.toDate(date)).valueOf();
    }

    public static diffSecDates(date1: StringDate, date2: StringDate = new Date()): number {
        return (this.toDate(date1).getTime() - this.toDate(date2).getTime()) / 1000;
    }

    public static diffMinDates(date1: StringDate, date2: StringDate = new Date()): number {
        return this.diffMinDates(date1, date2) / 60;
    }

    public static diffDayDates(date1: StringDate, date2: StringDate = new Date()): number {
        return this.diffMinDates(date1, date2) / 60 / 24;
    }

    public static notifyBeforeExpireDays(clientDate: StringDate | undefined, now: StringDate = new Date()): number {
        if (!clientDate) {
            return 0;
        }
        return this.diffDayDates(clientDate, now);
    }

    public static isSameDates(d1: StringDate | undefined, d2: StringDate | undefined): boolean {
        if (!d1 && !d2) {
            return true;
        } else if (!d1 || !d2) {
            return false;
        }
        return this.toDate(d1).valueOf() === this.toDate(d2).valueOf();
    }

}
