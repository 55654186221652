import React from 'react';
import './style/fmUserProfile.scss';
import LinkTo from '../../../routing/LinkTo';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import EmployeeAvatar from '../employeeAvatar/EmployeeAvatar';
import {inject, observer} from 'mobx-react';
import {CoreStore} from '../../../pages/core/mobx/CoreStore';
import {Space, Tooltip} from 'antd';
import Icofont from 'react-icofont';
import {getEmployeeName} from '../../../pages/account/employee/EmployeeUtils';

type StoreProps = {
    coreStore: CoreStore;
};

interface Props extends StoreProps {
    visible: boolean;
}

@inject('coreStore')
@observer
export default class FMUserProfile extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Space className={'user-profile'}>
                {this.props.coreStore.isAdminUser && <Tooltip title={'Admin power'}><Icofont icon={'flash'}/></Tooltip>}
                <div className="user-profile__user-name">
                    {getEmployeeName(this.props.coreStore.employeeRecord)}
                </div>
                <LinkTo to={AppRoutes.USER_PROFILE}>
                    <EmployeeAvatar hidePreview={true} employee={this.props.coreStore.employeeRecord}/>
                </LinkTo>
            </Space>
        );
    }
}
