import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../common/utils/AxiosUtils";
import Command from "../../../common/types/Command";

export default class LoadTagsCommand extends Command<string[]> {
    constructor(private readonly table: string) {
        super();
    }

    execute(): Promise<string[]> {
        return axiosGetInstance().get<string[]>(`/tag/get/${this.table}`)
            .then((response: AxiosResponse<string[]>) => response.data);
    }
}
