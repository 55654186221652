import {createIntl, createIntlCache, IntlShape} from 'react-intl';
import {goBack} from '../../routing/AppRouting';
import {LocalStorage, LocalStorageKey} from "../utils/LocalStorageUtils";
import {loadLocale} from "fm-shared-locale/src/common/LocaleUtils";

//TODO: ref it
export function loadLocaleFile(locale: string): Promise<IntlShape> {
    return new Promise<IntlShape>((resolve, reject) => {
        loadLocale(locale).then((jsonLocale: any) => {
            const newIntl: IntlShape = createIntl({locale, messages: jsonLocale}, createIntlCache());
            resolve(newIntl);
        }).catch((e) => {
            LocalStorage.removeItem(LocalStorageKey.LOCALE);
            console.error(`Can't load locale ${locale} !`, e);
            goBack();
            reject(e);
        });
    });
}
