import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import EquipmentNameOptionsProvider from './EquipmentNameOptionsProvider';
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import {UniqEquipmentValidator} from "../../../../../common/widgets/modalForm/validators/sync/UniqEquipmentValidator";

export const EQUIPMENT_FORM_CONFIG: ModalFormConfig<EquipmentRecord> = {
    title: 'equipment.card.title',
    tableName: 'equipment',
    getNewRecord: () => {
        return {
            name: '',
            visible_number: ''
        } as EquipmentRecord;
    },
    fieldConfig: [
        {
            label: 'tree.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new EquipmentNameOptionsProvider()
            }
        },
        {
            label: 'equipment.field.visible_number',
            field_name: 'visible_number',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
        },
    ],
    syncValidators: [new UniqEquipmentValidator()]
};
