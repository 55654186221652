import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export class PositiveNumberValidator extends SyncValidator<number | undefined> {

    constructor() {
        super();
    }

    validate(value: number | undefined = 0): FieldValidatorResult {
        return value > 0 ? null : formatMessage('modalForm.validation.positive.number');
    }

}
