import React from 'react';
import DataProvider from '../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {Typography} from 'antd';
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";

const {Text} = Typography;

export default class EmployeePhoneItemRenderer extends DataProvider<React.ReactNode, EmployeePhoneRecord> {
    get(phone: EmployeePhoneRecord): React.ReactNode {
        return <Text disabled={!!phone.deleted_by} type={phone.confirmed ? 'success' : undefined}>{phone.phone}</Text>;
    }
}
