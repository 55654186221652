import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import EquipmentTabInfoView from './EquipmentTabInfoView';
import {EquipmentViewStore} from '../../mobx/EquipmentViewStore';
import {inject, observer} from 'mobx-react';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import EquipmentTabGeneral from "./items/general/EquipmentTabGeneral";
import {CustomRendererProps, withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import './style/style.scss'
import {TabItemContent} from "../../../../../common/widgets/tab/TabItemContent";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import CommentsWidget from "../../../../../common/widgets/comments/CommentsWidget";
import ManageFileWidget from "../../../../../common/widgets/fileWidget/ManageFileWidget";
import RichTextWidget from "../../../../../common/widgets/richText/RichTextWidget";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {getLogTypeTabItems} from "../../../../../common/widgets/tab/TabItemUtil";
import {DataStore} from "../../../../../app/mobx/DataStore";
import ReportReactController from "../../../../../common/widgets/reportReact/ReportReactController";
import BadgeCard from "../../../../../common/widgets/badgeCard/BadgeCard";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import PrintBadge from '../../../../../common/widgets/printBadge/PrintBadge';

type StoreProps = {
    coreStore: CoreStore;
    equipmentViewStore: EquipmentViewStore;
    dataStore: DataStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('equipmentViewStore', 'coreStore', 'dataStore')
@observer
export default class EquipmentTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private readonly barcode_entity_id: BarcodeEntityId = BarcodeEntityId.EQUIPMENT;

    private readonly staticTabItems: TabItemContent<EquipmentRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: EquipmentTabGeneral
        },
        {
            key: 'tab2',
            tab: formatMessage('equipment.details.description.title'),
            disabled: false,
            withRecords: true,
            componentType: RichTextWidget,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<EquipmentRecord>
        },
        {
            key: 'tab3',
            tab: formatMessage('employee.details.files.title'),
            componentType: ManageFileWidget,
            withRecords: true,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<EquipmentRecord>
        },
        {
            key: 'tab4',
            tab: formatMessage('employee.details.comments.title'),
            disabled: false,
            withRecords: true,
            componentType: CommentsWidget,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<EquipmentRecord>
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('EquipmentTabController: constructor');
        this.state = {selectedTab: 'tab1'};
    }

    getTabItems = (): TabItemContent<EquipmentRecord>[] => {
        const logTypeRecordTabs: LogTypeRecord[] = this.props.dataStore.getLogTypeRecordTabs(this.barcode_entity_id);
        const logTypeTabItems: TabItemContent<EquipmentRecord>[] = getLogTypeTabItems<EquipmentRecord>(logTypeRecordTabs, this.barcode_entity_id, this.staticTabItems.length);
        return [...this.staticTabItems, ...logTypeTabItems];
    }

    componentDidMount(): void {
        console.log('EquipmentTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('EquipmentTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {equipmentViewStore} = this.props;
        const {length} = equipmentViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'equipment.card.title'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'equipment.card.title.many'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'equipment.card.help.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {equipmentViewStore, coreStore} = this.props;
        const {length} = equipmentViewStore.selectedEntities;
        if (length === 1) {
            const [selectedEntity] = equipmentViewStore.selectedEntities;
            return <PrintBadge>
                <BadgeCard key={selectedEntity.id}
                           title={selectedEntity.name}
                           subTitle={selectedEntity.visible_number}
                           barcodeData={fmBarcode(coreStore.user.schema, BarcodeEntityId.EQUIPMENT, selectedEntity.id)}/>
            </PrintBadge>;
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<EquipmentRecord>[] => {
        const {equipmentViewStore, coreStore} = this.props;
        const {length} = equipmentViewStore.selectedEntities;
        if (length === 1) {
            return this.getTabItems().map((tabItem: TabItemContent<EquipmentRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {equipmentViewStore, coreStore} = this.props;
        const {selectedEntities, selectedEntity} = equipmentViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<EquipmentRecord> = this.getTabItems().find((item: TabItemContent<EquipmentRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const isRecordCreator: boolean = selectedEntity.created_by === coreStore.user.user_id;
                const MyComponentWithRecord: any = withRecord<EquipmentRecord>(MyComponentType, {
                    record: selectedEntity,
                    entity_id: selectedEntity.id,
                    isRecordCreator
                });
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <ReportReactController title={selectedEntities.map((rec: EquipmentRecord) => rec.id).join(", ")}>
                {selectedEntities.map((rec: EquipmentRecord) => <BadgeCard key={rec.id} title={rec.name} subTitle={rec.visible_number}
                                                                           barcodeData={fmBarcode(coreStore.user.schema, BarcodeEntityId.EQUIPMENT, rec.id)}/>)}
            </ReportReactController>;
        } else {
            return <EquipmentTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
