import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {equipmentHash, EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqEquipmentValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqEquipmentValidator extends SyncValidator<EquipmentRecord, UniqEquipmentValidatorConfig> {

    constructor(config?: UniqEquipmentValidatorConfig) {
        super(config || {});
    }

    validate(value: EquipmentRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = equipmentHash(value);
            const isRecordExists: boolean = stores.dataStore.equipment
                .some((rec: EquipmentRecord) => value.id !== rec.id && valueHash === equipmentHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.equipment.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
