import {BarcodeEntityId} from "../../static/db/BarcodeEntityId";

export enum FMEntity {
    EMPLOYEE = "EMPLOYEE", //BarcodeEntityId.EMPLOYEE,
    FIELD = "FIELD", //BarcodeEntityId.FIELD,
    WORK = "WORK", //BarcodeEntityId.WORK,
    EQUIPMENT = "EQUIPMENT", //BarcodeEntityId.EQUIPMENT
}


// List for DICTIONARY_FM_ENTITY_CONFIG
export const FMEntityList: FMEntity[] = [
    FMEntity.EMPLOYEE,
    FMEntity.FIELD,
    FMEntity.WORK,
    FMEntity.EQUIPMENT,
];

export function getBarcodeEntityId(fmEntity: FMEntity): BarcodeEntityId {
    switch (fmEntity) {
        case FMEntity.EMPLOYEE:
            return BarcodeEntityId.EMPLOYEE;
        case FMEntity.FIELD:
            return BarcodeEntityId.FIELD;
        case FMEntity.WORK:
            return BarcodeEntityId.WORK;
        case FMEntity.EQUIPMENT:
            return BarcodeEntityId.EQUIPMENT;
    }
}
export function getFMEntity(barcodeEntityId: BarcodeEntityId): FMEntity {
    switch (barcodeEntityId) {
        case BarcodeEntityId.EMPLOYEE:
            return FMEntity.EMPLOYEE;
        case BarcodeEntityId.FIELD:
            return FMEntity.FIELD;
        case BarcodeEntityId.WORK:
            return FMEntity.WORK;
        case BarcodeEntityId.EQUIPMENT:
            return FMEntity.EQUIPMENT;
        default:
            return FMEntity.EMPLOYEE; // not a prod case!
    }
}