import React from "react";
import {Col, Divider, Row} from "antd";
import {formatMessage} from "../../locale/IntlProvider";
import AvatarWidget from "../avatarWidget/AvatarWidget";
import {getEmployeeAvatarByData} from "../../../pages/account/employee/EmployeeUtils";
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";
import {StringDate} from "fm-shared-data/src/types/db/core/StringDate";

export function getTooltipAvatar(titleKey: string, avatar: string, avatar_gender: string, avatar_name: string, on: StringDate): React.ReactNode {
    return (
        <Row gutter={[20, 0]} style={{marginBottom: 15}}>
            <Col span={24}>
                <Divider orientation="left">{formatMessage(titleKey)}</Divider>
            </Col>
            <Col span={2}>
            </Col>
            <Col>
                <AvatarWidget size={"large"} src={getEmployeeAvatarByData(avatar_gender, avatar)}/>
            </Col>
            <Col>
                <strong>{avatar_name}</strong><br/>
                {MomentUtils.clientDateToLabel(on)}
            </Col>
        </Row>
    );
}
