import React from 'react';
import {Card, DatePicker, Select} from 'antd';
import {inject, observer} from "mobx-react";
import './style/style.scss'
import {DataStore} from '../../../../app/mobx/DataStore';
import {RecordTabProps} from '../../tab/RecordTabProps';
import {ReportRecord} from 'fm-shared-data/src/types/db/account/report/ReportRecord';
import moment, {Moment} from 'moment';
import {RangeValue} from "rc-picker/lib/interface";
import SCRUDActions from '../../scrudActions/SCRUDActions';
import {DICTIONARY_ENTITY_EMPLOYEE, DICTIONARY_ENTITY_FIELD, DICTIONARY_ENTITY_WORK} from '../../dictionary/EntityDictionatryConfig';
import {DictionaryDecorator} from '../../dictionary/DictionaryDecorator';
import {ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';
import {LabeledValue} from 'antd/lib/select';
import HarvestPivotOverviewReport from './HarvestPivotOverviewReport';
import HarvestPivotPaymentReport from './HarvestPivotPaymentReport';
import FieldPivotOverviewReport from './FieldPivotOverviewReport';
import {getDefaultRanges} from '../../rangePicker/RangePickerUtils';
import TotalPivotPaymentReport from "./TotalPivotPaymentReport";
import debounce from "lodash/debounce";

const {RangePicker} = DatePicker;

type StoreProps = {
    dataStore: DataStore;
};

interface Props extends StoreProps, RecordTabProps<ReportRecord> {
    barcode_entity_id: number; // BarcodeEntityId.REPORT (17)
    entity_id: number;
    // children?: ReactNode;
}

interface State extends ReportFilterParams<Moment | null> {
    commandInProgress: boolean;
    isRangePickerOpen: boolean;

    refreshData?: Date;
}

@inject('dataStore')
@observer
export default class OpenReportController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    state: State = {
        commandInProgress: false,
        isRangePickerOpen: false,
        start_date: moment().startOf('day'),
        end_date: moment().endOf('day'),
    };

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('OpenReportController: constructor', performance.now());
    }

    componentDidMount() {
        this.loadedDictionaries().then(() => {
            this.loadData("componentDidMount");
        });
    }

    loadedDictionaries = (): Promise<any> => {
        const {dataStore} = this.props;
        const loaders: Promise<any>[] = []
        if (!dataStore.isEmployeeLoaded) {
            loaders.push(dataStore.loadEmployee());
        }
        if (!dataStore.isFieldLoaded) {
            loaders.push(dataStore.loadField());
        }
        if (!dataStore.isWorkLoaded) {
            loaders.push(dataStore.loadWork());
        }
        if (!dataStore.isEquipmentLoaded) {
            loaders.push(dataStore.loadEquipment());
        }
        if (loaders.length > 0) {
            return Promise.all(loaders);
        }
        return Promise.resolve("All dictionaries are loaded");
    }

    refreshCommand = async () => {
        this.setState({refreshData: new Date()});
    }

    handleOnChangeDateRange = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
        // handle No period here
        this.setState({
            start_date: dates && dates[0] ? dates[0].startOf('day') : null,
            end_date: dates && dates[1] ? dates[1].endOf('day') : null
        }, () => {
            if (!this.state.isRangePickerOpen && !dates) {
                this.loadData("handleOnChangeDateRange: CLEAR");
            }
        });
    }

    handleOpenChange = (isRangePickedOpen: boolean) => {
        this.setState({isRangePickerOpen: isRangePickedOpen}, () => {
            if (!isRangePickedOpen) {
                this.loadData("handleOpenChange");
            }
        });
    }

    loadData = debounce((src: string) => {
        // this.setState({commandInProgress: true});
        console.log("Load data with filters:", src);
        this.setState({refreshData: new Date()});
    }, 500);

    handleOnChangeEmployee = (value: number[]) => {
        this.setState({employee_id: value}, () => {
            this.loadData("handleOnChangeEmployee");
        });
    }

    handleOnClearEmployee = () => {
        this.setState({employee_id: undefined}, () => {
            this.loadData("handleOnClearEmployee");
        });
    }

    handleOnChangeWork = (value: number[]) => {
        this.setState({work_id: value}, () => {
            this.loadData("handleOnChangeWork");
        });
    }

    handleOnClearWork = () => {
        this.setState({work_id: undefined}, () => {
            this.loadData("handleOnClearWork");
        });
    }

    handleOnChangeField = (value: number[]) => {
        this.setState({field_id: value}, () => {
            this.loadData("handleOnChangeField");
        });
    }

    handleOnClearField = () => {
        this.setState({field_id: undefined}, () => {
            this.loadData("handleOnClearField");
        });
    }

    filterFunction = (inputValue: string, option: any): boolean => {
        return (option as LabeledValue).label?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
    };

    render() {
        const {record, entity_id, barcode_entity_id} = this.props;
        const {commandInProgress, start_date, end_date} = this.state;
        // const childrenWithProps = React.Children.map(this.props.children, child => {
        //     if (React.isValidElement(child)) {
        //         return React.cloneElement(child, { name: "aaaaaa", ...this.state });
        //     }
        //     return child;
        // });
        return (
            <Card size='small'
                  className="fm-card maxContent open-report-widget-container"
                  bordered={false}
                  title={
                      <>
                          <div>
                              <span className="filter__title">Период:</span>
                              <RangePicker className="filter__item"
                                           disabled={commandInProgress}
                                           allowEmpty={[true, true]}
                                           defaultValue={[start_date, end_date]}
                                           ranges={getDefaultRanges()}
                                           onChange={this.handleOnChangeDateRange}
                                           onOpenChange={this.handleOpenChange}
                              />
                              <span className="filter__title">Работник:</span>
                              <Select className="filter__item"
                                      allowClear={true}
                                      showSearch={true}
                                      filterOption={this.filterFunction}
                                      value={this.state.employee_id}
                                      onChange={this.handleOnChangeEmployee}
                                      onClear={this.handleOnClearEmployee}
                                      options={new DictionaryDecorator(DICTIONARY_ENTITY_EMPLOYEE).selectOptionsProvider}
                                      mode="multiple"
                              />
                          </div>
                          <span style={{display: 'block', height: 7}}></span>
                          <div>
                              <span className="filter__title">Работа:</span>
                              <Select className="filter__item"
                                      allowClear={true}
                                      showSearch={true}
                                      filterOption={this.filterFunction}
                                      value={this.state.work_id}
                                      onChange={this.handleOnChangeWork}
                                      onClear={this.handleOnClearWork}
                                      options={new DictionaryDecorator(DICTIONARY_ENTITY_WORK).selectOptionsProvider}
                                      mode="multiple"
                              />
                              <span className="filter__title">Участок:</span>
                              <Select className="filter__item"
                                      allowClear={true}
                                      showSearch={true}
                                      filterOption={this.filterFunction}
                                      value={this.state.field_id}
                                      onChange={this.handleOnChangeField}
                                      onClear={this.handleOnClearField}
                                      options={new DictionaryDecorator(DICTIONARY_ENTITY_FIELD).selectOptionsProvider}
                                      mode="multiple"
                              />
                          </div>
                      </>
                  }
                  extra={<SCRUDActions onRefresh={this.refreshCommand} isRefreshEnabled={!commandInProgress}/>}
            >
                {/*<Spin spinning={commandInProgress}>*/}
                {/*    <p>dateRange: {JSON.stringify(dateRange)}</p>*/}
                {/*    <p>employee_id: {this.state.employee_id}</p>*/}
                {/*    <p>work_id: {this.state.work_id}</p>*/}
                {/*    <p>field_id: {this.state.field_id}</p>*/}
                {/*    <p>source: {record.source}</p>*/}
                {/*    <p>{commandInProgress ? "commandInProgress" : null}</p>*/}
                {/*</Spin>*/}
                {/*{this.props.children}*/}
                {/*{childrenWithProps}*/}
                {record.source === "HarvestPivotOverviewReport" &&
                    <HarvestPivotOverviewReport {...this.props} {...this.state} />
                }
                {record.source === "HarvestPivotPaymentReport" &&
                    <HarvestPivotPaymentReport {...this.props} {...this.state} />
                }
                {record.source === "FieldPivotOverviewReport" &&
                    <FieldPivotOverviewReport {...this.props} {...this.state} />
                }
                {record.source === "TotalPivotPaymentReport" &&
                    <TotalPivotPaymentReport {...this.props} {...this.state} />
                }
            </Card>);
    }
}
