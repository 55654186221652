import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {HarvestWorklogInfoRecord} from "fm-shared-data/src/types/db/account/sync/HarvestWorklogRequest";

export default class LoadHarvestWorklogInfoRecordsCommand extends Command<HarvestWorklogInfoRecord[], number> {
    execute(sync_process_id: number): Promise<HarvestWorklogInfoRecord[]> {
        return axiosGetInstance().post<HarvestWorklogInfoRecord[]>(`/sync/worklog/harvest/${sync_process_id}`)
            .then((response: AxiosResponse<HarvestWorklogInfoRecord[]>) => response.data);
    }
}
