import DataProvider from '../../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {AutoCompleteOptionType} from '../../../../../../../../../common/widgets/modalForm/formItems/AutoCompleteOptionType';
import {stores} from '../../../../../../../../../app/mobx/Stores';
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

export default class EmailTypeAutoCompleteProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        const uniqTypes: Set<string> = new Set<string>();
        uniqTypes.add('Primary');
        uniqTypes.add('Work');
        dataStore.employeeEmail.forEach((item: EmployeeEmailRecord) => uniqTypes.add(item.email_type));
        return Array.from(uniqTypes).map((value: string) => {
            return {value};
        });
    }
}
