import {Area} from "react-easy-crop/types";

export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result?.toString() || '');
        };
        reader.onerror = (error: ProgressEvent<FileReader>) => {
            console.error(error);
            reject(error);
        }
    });
}

export function createImageFromUrl(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error: any) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    })
}

export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

export function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation: number = 0): Promise<string> {
    return new Promise((resolve, reject) => {
        return createImageFromUrl(imageSrc).then((image) => {
            try {

                const canvas: HTMLCanvasElement = document.createElement('canvas');
                const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

                const maxSize = Math.max(image.width, image.height);
                const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

                // set each dimensions to double largest dimension to allow for a safe area for the
                // image to rotate in without being clipped by canvas context
                canvas.width = safeArea;
                canvas.height = safeArea;

                // translate canvas context to a central location on image to allow rotating around the center.
                ctx.translate(safeArea / 2, safeArea / 2);
                ctx.rotate(getRadianAngle(rotation));
                ctx.translate(-safeArea / 2, -safeArea / 2);

                // draw rotated image and store data.
                ctx.drawImage(
                    image,
                    safeArea / 2 - image.width * 0.5,
                    safeArea / 2 - image.height * 0.5
                );
                const data: ImageData = ctx.getImageData(0, 0, safeArea, safeArea);

                // set canvas width to final desired crop size - this will clear existing context
                canvas.width = pixelCrop.width;
                canvas.height = pixelCrop.height;

                //TODO: change black color to white (out of the image)
                // canvas.style.backgroundColor = '#00F8'
                // ctx.globalCompositeOperation = 'destination-over';
                // ctx.fillStyle = "blue";
                // ctx.fillRect(0, 0, canvas.width, canvas.height);

                // paste generated rotate image with correct offsets for x,y crop values.
                ctx.putImageData(
                    data,
                    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
                    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
                );

                const base64: string = canvas.toDataURL('image/jpeg');
                // const base64: string = canvas.toDataURL('image/png');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                resolve(base64);

                // // As a blob
                // return new Promise(resolve => {
                //     canvas.toBlob(file => {
                //         resolve(URL.createObjectURL(file))
                //     }, 'image/jpeg')
                // })
            } catch (error: any) {
                reject(error);
            }
        });
    });
}

export function base64ToCanvasImage(base64: string): Promise<CanvasImageSource> {
    return new Promise<CanvasImageSource>((resolve, reject) => {
        const image: any = new Image();
        image.addEventListener('load', () => resolve(image))
        // image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = base64;
    })
}