import React from 'react';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {URLRouteProps} from '../../../routing/URLRouteProps';
import {ReportsURLData} from './ReportsURLData';

export default class ReportsController extends React.Component<URLRouteProps> {
    render() {
        const urlData: ReportsURLData | undefined = decryptObject<ReportsURLData>(this.props.match.params.data);
        return <p>{performance.now()}<br/>{JSON.stringify(urlData)}</p>;
    }
}
