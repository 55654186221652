import {EmployeeGender} from "fm-shared-data/src/types/db/account/employee/EmployeeGender";
import {Role} from "fm-shared-data/src/types/db/core/Role";
import {DictionaryConfig} from "./DictionaryConfig";
import {DictionaryStaticDataProvider} from "./providers/DictionaryStaticDataProvider";
import {ICOFONT_ALL} from "../icofont/IcofontUtils";
import {WORK_PAYMENT_TYPE} from "fm-shared-data/src/types/db/account/work/WorkRecord";
import {IcofontLabelRendererFunction} from "../tree/TreeStructureUtils";
import {LogTypeFieldTypes} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {FMEntityList} from "fm-shared-data/src/types/db/core/FMEntity";
import {FMDictionaryList} from "fm-shared-data/src/types/db/core/FMDictionary";
import {ReportTypeTypes} from 'fm-shared-data/src/types/db/account/report/ReportType';

export const DICTIONARY_ICOFONT: DictionaryConfig = {
    dictionaryKey: 'icofont',
    rawRecordsProvider: new DictionaryStaticDataProvider(ICOFONT_ALL),
    labelRendererFunction: IcofontLabelRendererFunction,
};

export const DICTIONARY_FM_ENTITY_CONFIG: DictionaryConfig = {
    dictionaryKey: 'fm_entity',
    rawRecordsProvider: new DictionaryStaticDataProvider(FMEntityList, 'fm_entity'),
};

export const DICTIONARY_FM_DICTIONARY_CONFIG: DictionaryConfig = {
    dictionaryKey: 'fm_dictionary',
    rawRecordsProvider: new DictionaryStaticDataProvider(FMDictionaryList, 'fm_dictionary'),
};

export const DICTIONARY_GENDER: DictionaryConfig = {
    dictionaryKey: "gender",
    rawRecordsProvider: new DictionaryStaticDataProvider([EmployeeGender.MALE, EmployeeGender.FEMALE, EmployeeGender.UNDEFINED], 'gender'),
}

export const DICTIONARY_ROLE: DictionaryConfig = {
    dictionaryKey: 'role',
    rawRecordsProvider: new DictionaryStaticDataProvider([Role.ADMIN, Role.USER], 'role'),
};

export const DICTIONARY_WORK_PAYMENT_TYPE: DictionaryConfig = {
    dictionaryKey: 'work_payment_type',
    rawRecordsProvider: new DictionaryStaticDataProvider([WORK_PAYMENT_TYPE.PIECEWORK, WORK_PAYMENT_TYPE.TIME_BASED], 'work_payment_type'),
};

export const DICTIONARY_LOGTYPE_FIELD_TYPE: DictionaryConfig = {
    dictionaryKey: 'log_type_field_type',
    rawRecordsProvider: new DictionaryStaticDataProvider(LogTypeFieldTypes, 'log_type_field_type'),
};

export const DICTIONARY_LOCALE: DictionaryConfig = {
    dictionaryKey: 'locale',
    rawRecordsProvider: new DictionaryStaticDataProvider(['en-US', 'ru-RU', 'uk-UA'], 'locale'),
};

export const DICTIONARY_REPORT_TYPE: DictionaryConfig = {
    dictionaryKey: 'report_type',
    rawRecordsProvider: new DictionaryStaticDataProvider(ReportTypeTypes, 'report_type'),
};
