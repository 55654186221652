import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import EmployeeTabInfoView from './EmployeeTabInfoView';
import {TabItemContent} from '../../../../../common/widgets/tab/TabItemContent';
import {EmployeeViewStore} from '../../mobx/EmployeeViewStore';
import {inject, observer} from 'mobx-react';
import EmployeeContactsTab from './items/contacts/EmployeeContactsTab';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import EmployeeTabGeneral from "./items/general/EmployeeTabGeneral";
import ManageFileWidget from "../../../../../common/widgets/fileWidget/ManageFileWidget";
import {CustomRendererProps, withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import './style/style.scss'
import CommentsWidget from "../../../../../common/widgets/comments/CommentsWidget";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {DataStore} from "../../../../../app/mobx/DataStore";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {getLogTypeTabItems} from "../../../../../common/widgets/tab/TabItemUtil";
import ReportReactController from "../../../../../common/widgets/reportReact/ReportReactController";
import BadgeCard from "../../../../../common/widgets/badgeCard/BadgeCard";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import PrintBadge from '../../../../../common/widgets/printBadge/PrintBadge';

type StoreProps = {
    coreStore: CoreStore;
    employeeViewStore: EmployeeViewStore;
    dataStore: DataStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('employeeViewStore', 'coreStore', 'dataStore')
@observer
export default class EmployeeTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private readonly barcode_entity_id: BarcodeEntityId = BarcodeEntityId.EMPLOYEE;

    private readonly staticTabItems: TabItemContent<EmployeeRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: EmployeeTabGeneral,
        },
        {
            key: 'tab2',
            tab: formatMessage('employee.details.contacts.title'),
            componentType: EmployeeContactsTab,
        },
        {
            key: 'tab3',
            tab: formatMessage('employee.details.files.title'),
            componentType: ManageFileWidget,
            withRecords: true,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<EmployeeRecord>
        },
        {
            key: 'tab4',
            tab: formatMessage('employee.details.comments.title'),
            disabled: false,
            withRecords: true,
            componentType: CommentsWidget,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<EmployeeRecord>
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('EmployeeTabController: constructor');
        this.state = {
            selectedTab: 'tab1',
        };
    }

    getTabItems = (): TabItemContent<EmployeeRecord>[] => {
        const logTypeRecordTabs: LogTypeRecord[] = this.props.dataStore.getLogTypeRecordTabs(this.barcode_entity_id);
        const logTypeTabItems: TabItemContent<EmployeeRecord>[] = getLogTypeTabItems<EmployeeRecord>(logTypeRecordTabs, this.barcode_entity_id, this.staticTabItems.length);
        return [...this.staticTabItems, ...logTypeTabItems];
    }

    componentDidMount(): void {
        console.log('EmployeeTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('EmployeeTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {employeeViewStore} = this.props;
        const {length} = employeeViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'employee.card.title'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'employee.card.title.many'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'employee.card.help.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {employeeViewStore, coreStore} = this.props;
        const {length} = employeeViewStore.selectedEntities;
        if (length === 1) {
            const [selectedEntity] = employeeViewStore.selectedEntities;
            return <PrintBadge>
                <BadgeCard key={selectedEntity.id}
                           title={selectedEntity.first_name}
                           subTitle={selectedEntity.last_name}
                           barcodeData={fmBarcode(coreStore.user.schema, BarcodeEntityId.EMPLOYEE, selectedEntity.id)}/>
            </PrintBadge>;
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<EmployeeRecord>[] => {
        const {employeeViewStore, coreStore} = this.props;
        const {length} = employeeViewStore.selectedEntities;
        if (length === 1) {
            return this.getTabItems().map((tabItem: TabItemContent<EmployeeRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {employeeViewStore, coreStore} = this.props;
        const {selectedEntities, selectedEntity} = employeeViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<EmployeeRecord> = this.getTabItems().find((item: TabItemContent<EmployeeRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const isRecordCreator: boolean = selectedEntity.created_by === coreStore.user.user_id;
                const MyComponentWithRecord: any = withRecord<EmployeeRecord>(MyComponentType, {
                    record: selectedEntity,
                    entity_id: selectedEntity.id,
                    isRecordCreator
                });
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <ReportReactController title={selectedEntities.map((rec: EmployeeRecord) => rec.id).join(", ")}>
                {selectedEntities.map((rec: EmployeeRecord) => <BadgeCard key={rec.id} title={rec.first_name}
                                                                          subTitle={rec.last_name}
                                                                          barcodeData={fmBarcode(coreStore.user.schema, BarcodeEntityId.EMPLOYEE, rec.id)}/>)}
            </ReportReactController>;
        } else {
            return <EmployeeTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
