import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";

export default class DeleteWorkCommand extends Command<WorkRecord[], WorkRecord> {
    execute(WorkRecord: WorkRecord): Promise<WorkRecord[]> {
        return axiosGetInstance().post<WorkRecord[]>(`/work/delete`, WorkRecord)
            .then((response: AxiosResponse<WorkRecord[]>) => response.data);
    }
}
