import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";

export default class LoadWorkCommand extends Command<WorkRecord[]> {
    execute(): Promise<WorkRecord[]> {
        return axiosGetInstance().post<WorkRecord[]>('/work/all')
            .then((response: AxiosResponse<WorkRecord[]>) => response.data);
    }
}
