import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {HarvestPivotPaymentRecord} from 'fm-shared-data/src/types/db/account/data/HarvestPivotPaymentRecord';
import {ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';

export default class LoadHarvestPivotPaymentsCommand extends Command<HarvestPivotPaymentRecord[], ReportFilterParams> {
    execute(reportFilterParams: ReportFilterParams): Promise<HarvestPivotPaymentRecord[]> {
        return axiosGetInstance().post<HarvestPivotPaymentRecord[]>('/data/harvest/pivot/payment', reportFilterParams)
            .then((response: AxiosResponse<HarvestPivotPaymentRecord[]>) => response.data);
    }
}
