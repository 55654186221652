import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";

export interface FieldRecord extends DataRecord, TreeFields, TagFields, AvatarFields {
    name: string;
    area: number;
    map_metadata?: string; // FieldMapMetadata
}

export function offlineFieldRecord(rec: FieldRecord): Partial<FieldRecord> {
    return {
        id: rec.id,
        name: rec.name,
        description: 'deprecated',
        area: rec.area,
        avatar: 'deprecated'
    }
}


export function fieldHash(rec: FieldRecord): string {
    return rec.name.trim().toLowerCase();
}
