import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import ScannerTabInfoView from './ScannerTabInfoView';
import {ScannerViewStore} from '../../mobx/ScannerViewStore';
import {inject, observer} from 'mobx-react';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import ScannerTabGeneral from "./items/general/ScannerTabGeneral";
import {CustomRendererProps, withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import './style/style.scss'
import {TabItemContent} from "../../../../../common/widgets/tab/TabItemContent";
import {ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import RichTextWidget from "../../../../../common/widgets/richText/RichTextWidget";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import SyncDataTab from './items/syncData/SyncDataTab';

type StoreProps = {
    coreStore: CoreStore;
    scannerViewStore: ScannerViewStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('scannerViewStore', 'coreStore')
@observer
export default class ScannerTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    readonly tabItems: TabItemContent<ScannerRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: ScannerTabGeneral
        },
        {
            key: 'tab2',
            tab: formatMessage('employee_email.field.description'),
            disabled: false,
            withRecords: true,
            componentType: RichTextWidget,
            componentProps: {
                barcode_entity_id: BarcodeEntityId.SCANNER // TODO ???
            } as CustomRendererProps<ScannerRecord>
        },
        {
            key: 'tab3',
            tab: 'Данные',
            componentType: SyncDataTab,
            withRecords: true,
            componentProps: {
                // barcode_entity_id: BarcodeEntityId.SCANNER // TODO ???
            } as CustomRendererProps<ScannerRecord>
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('ScannerTabController: constructor');
        this.state = {selectedTab: 'tab3'};
    }

    componentDidMount(): void {
        console.log('ScannerTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('ScannerTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {scannerViewStore} = this.props;
        const {length} = scannerViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'scanner.form.title'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'scanner.list.title'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'equipment.card.help.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {scannerViewStore} = this.props;
        const {length} = scannerViewStore.selectedEntities;
        if (length === 1) {
            return 'Actions for one';
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<ScannerRecord>[] => {
        const {scannerViewStore, coreStore} = this.props;
        const {length} = scannerViewStore.selectedEntities;
        if (length === 1) {
            return this.tabItems.map((tabItem: TabItemContent<ScannerRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {scannerViewStore} = this.props;
        const {selectedEntities, selectedEntity} = scannerViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<ScannerRecord> = this.tabItems.find((item: TabItemContent<ScannerRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const MyComponentWithRecord: any = withRecord<ScannerRecord>(MyComponentType, {
                    record: selectedEntity,
                    entity_id: selectedEntity.id,
                });
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <i>{intl().formatMessage({id: 'msg1'})} for {selectedEntities.length} record(s)</i>;
        } else {
            return <ScannerTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
