import React from 'react';
import {Button} from 'antd';
import {intl} from '../../locale/IntlProvider';
import Icofont from 'react-icofont';

interface Props {
    visible: boolean;
    onLogout: () => void;
}

export default class FMLogout extends React.Component<Props> {

    logoutHandler = () => {
        this.props.onLogout();
    };

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Button onClick={this.logoutHandler} title={intl().formatMessage({id: 'logout.title'})}>
                <Icofont icon="logout"/>
            </Button>
        );
    }
}
