import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {ScannerRecord} from 'fm-shared-data/src/types/db/account/scanner/ScannerRecord';

export default class LoadScannerListCommand extends Command<ScannerRecord[]> {
    execute(): Promise<ScannerRecord[]> {
        return axiosGetInstance().post<ScannerRecord[]>('/scanner/all')
            .then((response: AxiosResponse<ScannerRecord[]>) => response.data);
    }
}
