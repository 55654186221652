import React from 'react';
import {Form, Mentions} from 'antd';
import {FieldProps, FormikErrors} from 'formik';
import {LabeledValue} from 'antd/lib/select';

const {Option} = Mentions;
const FormItem = Form.Item;

export const MentionsFormItem = (props: FieldProps & Record<string, any>) => {
    const touched: boolean = !!props.form.touched[props.field.name];
    const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = props.form.errors[props.field.name];
    const hasErrorBoolean: boolean = !!hasError;
    const submitted: boolean = props.form.submitCount > 0;
    const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;
    const handleOnChange = (value: string) => {
        props.form.setFieldValue(props.field.name, value);
    };
    const onBlur = () => {
        props.form.setFieldTouched(props.field.name, true);
    };
    const options: LabeledValue[] = props.options || [];
    const formItemTooltip: string | undefined = props.formItemTooltip;
    return (
        <div className="field-container">
            <FormItem
                tooltip={formItemTooltip}
                label={props.label}
                hasFeedback={
                    touched || submitted ? true : false
                }
                key={props.field.name}
                help={showError ? hasError : false}
                validateStatus={props.form.isValidating ? 'validating' : (showError ? 'error' : (!props.required && !props.field.value ? '' : 'success'))}
                required={props.required}>
                <Mentions
                    onBlur={onBlur}
                    onChange={handleOnChange}
                    split={''}
                    autoSize={false}
                    value={props.field.value}
                    disabled={props.disabled}
                    {...props.inputProps}>
                    {options.map((option: LabeledValue) => {
                        return <Option key={option.key} value={`${option.value}`}>{option.label}</Option>;
                    })}
                </Mentions>
            </FormItem>
        </div>
    );
};
