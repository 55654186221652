import {ModalFormConfig} from "../modalForm/type/ModalFormConfig";
import {FormFieldRender} from "../modalForm/type/FormFieldRender";
import {NonEmptyValidator} from "../modalForm/validators/sync/NonEmptyValidator";
import {LengthValidator} from "../modalForm/validators/sync/LengthValidator";
import FileTypeAutoCompleteProvider from "./FileTypeAutoCompleteProvider";
import {FileDetailed} from "fm-shared-data/src/types/db/common/FileDetailed";

export const FILE_FORM_CONFIG: ModalFormConfig<FileDetailed, FileDetailed> = {
    title: 'file_record.modalForm.title',
    tableName: 'files',
    getNewRecord: (file?: FileDetailed): FileDetailed => {
        return {} as FileDetailed;
    },
    // clipboardText: new EmployeeAddressClipboard(),
    fieldConfig: [
        {
            label: 'file_record.field.file_name',
            field_name: 'file_name',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3})],
            config: {
                inputProps: {
                    placeholder: 'File name',
                    maxLength: 200
                }
            }
        },
        {
            label: 'file_record.field.type',
            field_name: 'type',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3})],
            config: {
                autoCompleteOptionsProvider: new FileTypeAutoCompleteProvider(),
                inputProps: {
                    placeholder: 'Document type',
                    maxLength: 20
                }
            },
        },
        {
            label: 'file_record.field.valid_from',
            field_name: 'valid_from',
            optional: true,
            renderType: FormFieldRender.DATE,
        },
        {
            label: 'file_record.field.valid_to',
            field_name: 'valid_to',
            optional: true,
            renderType: FormFieldRender.DATE,
        },
        {
            label: 'file_record.field.color',
            field_name: "color",
            optional: true,
            renderType: FormFieldRender.COLOR_PICKER,
        },
        {
            label: 'file_record.field.comment',
            field_name: 'comment',
            optional: true,
            renderType: FormFieldRender.TEXT,
            config: {
                inputProps: {
                    autoSize: {minRows: 2, maxRows: 3}
                }
            }
        }
        // {
        //     label: 'file_record.field.address_type',
        //     field_name: 'address_type',
        //     renderType: FormFieldRender.AUTO_COMPLETE,
        //     syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 2})],
        //     config: {
        //         autoCompleteOptionsProvider: new AddressTypeAutoCompleteProvider(),
        //         inputProps: {
        //             placeholder: 'Home, Registration, etc',
        //             maxLength: 20
        //         }
        //     },
        // },
    ]
};
