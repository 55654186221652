import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";

export default class AddFieldCommand extends UpdateDataCommand<FieldRecord> {

    constructor(private readonly upsertValue: UpsertValue<FieldRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<FieldRecord>> {
        return axiosGetInstance().post<UpsertResponse<FieldRecord>>(`/field/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<FieldRecord>>) => response.data);
    }

}
