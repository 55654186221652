import {RowClassParams} from 'ag-grid-community/dist/lib/entities/gridOptions';
import {ProcessState} from 'fm-shared-data/src/types/db/account/sync/ProcessState';
import {FieldWorklogRequest} from 'fm-shared-data/src/types/db/account/sync/FieldWorklogRequest';

export function getRowClassCommon(params: RowClassParams): string | string[] | undefined {
    const {data} = params;
    const {process_state = 0} = data;
    return `ag-row__process_state--${process_state}`;
}

export function getRowClassFieldWorklog(params: RowClassParams): string | string[] | undefined {
    const {data} = params;
    const {process_state = 0, work_id} = data as FieldWorklogRequest;
    if (work_id === 1 && process_state === 0) { // mark check-in/out as done
        return `ag-row__process_state--${ProcessState.PROCESS_DONE}`;
    }
    return getRowClassCommon(params);
}
