import React from 'react';
import {Card, List} from 'antd';
import ModalFormik from '../modalForm/ModalFormik';
import SCRUDActions from '../scrudActions/SCRUDActions';
import './style/employeePhoneTab.scss';
import {NoData} from '../noData/NoData';
import {ModalFormConfig} from '../modalForm/type/ModalFormConfig';
import DataProvider from '../modalForm/type/DataProvider';
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {copyToClipboard} from '../clipboard/ClipboardUtils';

interface Props<T extends DataRecord, P extends DataRecord = any> {
    title?: React.ReactNode;

    dataSource: T[];
    itemRenderer: DataProvider<React.ReactNode, T>;
    parentRecord?: P;

    modalFormConfig: ModalFormConfig<T>;

    saveCommand?: (upsertValue: UpsertValue<T>) => Promise<UpsertResponse<T>>;
    deleteCommand?: (record: T) => Promise<UpsertResponse<T>>;

    deleteEnabledRule?: DataProvider<boolean, T>;
    refreshCommand?: () => Promise<T[]>;
}

interface State<T extends DataRecord> {
    modalFormVisible: boolean;
    modalFormData: T;
}

export default class RecordList<T extends DataRecord, P extends DataRecord = any> extends React.Component<Props<T, P>, State<T>> {

    state = {
        isDataLoaded: false,
        modalFormVisible: false,
        modalFormData: {} as T
    };

    handleOnAdd = () => {
        this.setState({modalFormVisible: true, modalFormData: this.props.modalFormConfig.getNewRecord(this.props.parentRecord)});
    };

    handleOnEdit = (record: T) => () => {
        this.setState({modalFormVisible: true, modalFormData: record});
    };

    handleOnDelete = (record: T) => async () => {
        await this.props.deleteCommand!(record);
    };

    handleOnDoubleClick = (rec: T) => () => {
        const text: string | undefined = this.props.modalFormConfig.clipboardText?.get((rec));
        if (text) {
            copyToClipboard(text);
        }
    };

    handleOnFormikCancel = () => {
        this.setState({modalFormVisible: false});
    };

    handleOnSaveRecord = async (upsertValue: UpsertValue<T>) => {
        this.handleOnFormikCancel();
    };

    render() {
        const {deleteEnabledRule, modalFormConfig, saveCommand, deleteCommand} = this.props;

        return (
            <Card size='small'
                  title={this.props.title}
                  bordered={false}
                  extra={<SCRUDActions onAdd={this.handleOnAdd} isAddEnabled={true} onRefresh={this.props.refreshCommand} isRefreshEnabled={!!this.props.refreshCommand}/>}>
                <List size={'small'}
                      className={'list-row-color'}
                      locale={{emptyText: <NoData/>}}
                      dataSource={this.props.dataSource}
                      renderItem={(record: T) => (
                          <List.Item key={record.id} onDoubleClick={this.handleOnDoubleClick(record)}>
                              {this.props.itemRenderer.get(record)}
                              <SCRUDActions onEdit={this.handleOnEdit(record)}
                                            onDelete={this.handleOnDelete(record)}
                                            isEditEnabled={true}
                                            isDeleteEnabled={true}/>
                          </List.Item>
                      )}
                />
                {this.state.modalFormVisible && <ModalFormik config={modalFormConfig}
                                                             initialValues={this.state.modalFormData}
                                                             saveCommand={saveCommand}
                                                             deleteCommand={deleteCommand}
                                                             deleteEnabledRule={deleteEnabledRule}
                                                             onSave={this.handleOnSaveRecord}
                                                             onCancel={this.handleOnFormikCancel}/>
                }
            </Card>
        );
    }
}
