import {LanguageRecord} from './LanguageRecord';

// https://developers.google.com/recaptcha/docs/language

export const DEFAULT_LOCALE: LanguageRecord = {
    code: 'ru-RU',
    name: 'Русский',
    shortName: 'Ru',
};

export const SUPPORTED_LOCALES: LanguageRecord[] = [
    {
        code: 'en-US',
        name: 'English',
        shortName: 'En',
    },
    DEFAULT_LOCALE,
    {
        code: 'uk-UA',
        name: 'Українська',
        shortName: 'Uk',
    },
];
