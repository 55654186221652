import {DataRecord} from './DataRecord';
import {NameIconColorFields} from "./NameIconColorFields";

export interface TreeRecord extends DataRecord, NameIconColorFields {
    parent_id: number;
    index: number;
}

export function isRootRecord<T extends TreeRecord>(treeRecord: T): boolean {
    return treeRecord.id === treeRecord.parent_id;
}
