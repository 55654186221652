import React from "react";
import {LogTypeField} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {readLogTypeFields, validateLogTypeFields} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import {LogTypeFieldItem} from "./LogTypeFieldItem";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {Typography} from "antd";

const {Text} = Typography;

interface Props {
    logTypeRecord: LogTypeRecord;
    onChange: (newValue: any, field_name: string) => void;
}

export const LogTypeFields = (props: Props) => {
    const logTypeFields: LogTypeField[] = readLogTypeFields(props.logTypeRecord);
    const onChange = (newValue: any, dbFieldName: string) => {
        console.log(`LogTypeFields onChange: ${dbFieldName} set to ${newValue}`)
        props.onChange(newValue, dbFieldName);
        // const touched: boolean = !!props.form.touched[dbFieldName];
        // if (!touched) {
        //     props.form.setFieldTouched(dbFieldName, true, false);
        // }
        // props.form.setFieldValue(dbFieldName, newValue);
    }
    const validationResult: string | null = validateLogTypeFields(logTypeFields);

    return (
        <div className="logtype-container">
            {logTypeFields.map((logTypeField: LogTypeField) => {
                return <LogTypeFieldItem key={logTypeField.field_id}
                                         logTypeField={logTypeField}
                                         onChange={onChange}/>;
            })}
            {validationResult !== null && <Text type="danger">{validationResult}</Text>}
        </div>
    );
};
