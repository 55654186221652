export const ACCESS_TOKEN_NAME = 'access_token';

//TODO ref it
export function getCookie(cookie: string = '', name: string): string | undefined {
    if (!cookie) {
        return undefined;
    }
    const nameEQ: string = name + "=";
    const ca: string[] = cookie.split(';');
    for (let i: number = 0; i < ca.length; i++) {
        let c: string = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return undefined;
}