import React from "react";
import {Form, Input} from "antd";
import {FieldProps, FormikErrors} from "formik";

const FormItem = Form.Item;

export const ColorPickerFormItem = (props: FieldProps & Record<string, any>) => {
    const touched: boolean = !!props.form.touched[props.field.name];
    const hasError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined = props.form.errors[props.field.name];
    const hasErrorBoolean: boolean = !!hasError;
    const submitted: boolean = props.form.submitCount > 0;
    // const submittedError = hasError;// && submitted;
    // const touchedError = hasError && touched;
    const showError: boolean = submitted ? hasErrorBoolean : hasErrorBoolean && touched;
    const handleOnChange = (target: any) => {
        props.form.setFieldValue(props.field.name, target.target.value);
    };
    const onBlur = () => {
        // TODO?: in case just leave - do not mark as touched
        props.form.setFieldTouched(props.field.name, true);
    };
    console.info('FieldProps: ', props);
    return (
        <div className="field-container">
            <FormItem
                label={props.label}
                hasFeedback={
                    touched || submitted ? true : false
                    // (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
                }
                key={props.field.name}
                help={showError ? hasError : false}
                validateStatus={props.form.isValidating ? "validating" : (showError ? "error" : (!props.required && !props.field.value ? "" : "success"))}
                required={props.required}>
                <Input
                    type={"color"}
                    onBlur={onBlur}
                    allowClear={true}
                    onChange={handleOnChange}
                    value={props.field.value}
                    disabled={props.disabled}
                    {...props.inputProps}/>
            </FormItem>
        </div>
    );
};
