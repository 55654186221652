import React from 'react';
import Icofont from 'react-icofont';

export default class HomeDashboardController extends React.Component {
    render() {
        return (
            <div className="user-menu">
                <h1>Главная в приложении</h1>
                <h2>Dashboard, statistics, last updates</h2>
                <Icofont icon="compass" spin={true} size="2"/>
            </div>
        );
    }
}
