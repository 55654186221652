import PredefinedFilterItem from '../../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import isEmpty from 'lodash/isEmpty';
import {LocalStorage, LocalStorageKey} from "../../../../../common/utils/LocalStorageUtils";
import {DateUtils} from "fm-shared-utils/src/utils/datatime/DateUtils";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";

export const PREDEFINED_LOGTYPE_FILTERS: PredefinedFilterItem<LogTypeRecord>[] = [
    {
        key: 'filter_new',
        titleKey: 'employee.filters.predefined.filter_new',
        filterFunction: (record: LogTypeRecord) => {
            return DateUtils.diffDayDates(record.created_on) <= 7;
        }
    },
    {
        key: 'filter_recent',
        titleKey: 'employee.filters.predefined.filter_recent',
        filterFunction: (record: LogTypeRecord) => {
            const {edited_on} = record;
            return !!edited_on && DateUtils.diffDayDates(edited_on) <= 7;
        }
    },
    {
        key: 'filter_not_in_tree',
        titleKey: 'employee.filters.predefined.filter_not_in_tree',
        filterFunction: (record: LogTypeRecord) => {
            return record.tree_ids.length === 0;
        }
    },
    {
        key: 'filter_without_avatar',
        titleKey: 'employee.filters.predefined.filter_without_avatar',
        filterFunction: (record: LogTypeRecord) => {
            return !record.avatar;
        }
    },
];

export function restoreLogTypePredefinedFilter(): PredefinedFilterItem<LogTypeRecord>[] {
    const availableKeys: string[] = PREDEFINED_LOGTYPE_FILTERS.map((item: PredefinedFilterItem<LogTypeRecord>) => item.key);
    const validKeys: string[] = (LocalStorage.getItem(LocalStorageKey.LOGTYPE_PREDEFINED_FILTERS) || "")
        .split(',')
        .filter((saved: string) => availableKeys.includes(saved));
    if (isEmpty(validKeys)) {
        return [];
    }
    return PREDEFINED_LOGTYPE_FILTERS.filter((item: PredefinedFilterItem<LogTypeRecord>) => validKeys.includes(item.key));
}
