import {AxiosResponse} from "axios";
import Command from "../../../../../../../../../common/types/Command";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";

export default class DeleteEmployeeAddressCommand extends Command<EmployeeAddressRecord[], EmployeeAddressRecord> {
    execute(record: EmployeeAddressRecord): Promise<EmployeeAddressRecord[]> {
        return axiosGetInstance().post<EmployeeAddressRecord[]>(`/employee/address/delete`, record)
            .then((response: AxiosResponse<EmployeeAddressRecord[]>) => response.data);
    }
}
