import {SyncValidator} from "./SyncValidator";
import {FieldValidatorResult} from "../FieldValidatorTypes";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";

export const validateSync = (validators: SyncValidator[] = []) => (value: string): FieldValidatorResult => {
    if (!validators.length) {
        return null;
    }
    return validateSyncAll(value, validators);
}

export const validateSyncAll = (value: string, validators: SyncValidator[]): FieldValidatorResult => {
    const result: FieldValidatorResult[] = validators.map((validator: SyncValidator) => validator.validate(value)).filter(Boolean);
    return result.length ? result.join('; ') : null;
}

export function validateSyncDataRecord<T extends DataRecord>(dataRecord: T, validators: SyncValidator<T>[]): FieldValidatorResult {
    const result: FieldValidatorResult[] = validators.map((validator: SyncValidator<T>) => validator.validate(dataRecord)).filter(Boolean);
    return result.length ? result.join('; ') : null;
}