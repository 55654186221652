import React from 'react';
import {Button, Form, Typography} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import {intl} from '../../../../common/locale/IntlProvider';
import {ReCaptchaButton} from '../../../../common/widgets/reCaptcha/ReCaptchaButton';
import {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import LinkTo from '../../../../routing/LinkTo';
import {secureBody} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {axiosGetInstance} from '../../../../common/utils/AxiosUtils';
import {MailOutlined} from '@ant-design/icons/lib';
import {DEFAULT_LOCALE} from "fm-shared-locale/src/common/SupportedLocales";
import {LocalStorage, LocalStorageKey} from "../../../../common/utils/LocalStorageUtils";
import {ResponseError} from "fm-shared-data/src/types/api/ResponseError";
import {SignupBody} from "fm-shared-data/src/types/auth/SignupBody";
import {JWTToken} from "fm-shared-data/src/types/auth/JWTToken";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {ResponseErrorCode} from "fm-shared-data/src/types/api/ResponseErrorCode";
import {SigninURLData} from "fm-shared-data/src/types/auth/SigninURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

const {Paragraph} = Typography;

interface State {
    email: string;
    emailConfirmation: string;
    lockButton: boolean;
    lockForm: boolean;
    responseError: ResponseError | undefined;
    okMessage: string;
}

interface Props {
    data?: any;
}

export default class SignUpPageView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            emailConfirmation: '',
            lockButton: false,
            lockForm: false,
            responseError: undefined,
            okMessage: '',
        };
    }

    onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({email: value});
    };

    onChangeEmailConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({emailConfirmation: value});
    };

    handleStartLogin = () => {
        this.setState({lockButton: true, responseError: undefined, okMessage: ''});
    };

    handleLogin = async () => {
        const email: string = this.state.email.trim().toLowerCase();
        const axios: AxiosInstance = axiosGetInstance();
        const locale: string = LocalStorage.getItem(LocalStorageKey.LOCALE) || DEFAULT_LOCALE.code;
        const body: SignupBody = {
            email,
            locale
        };

        await axios.post<JWTToken>('/auth/signup', secureBody(body))
            .then((response: AxiosResponse<JWTToken>) => {
                this.setState({
                    lockButton: false,
                    lockForm: true,
                    okMessage: `Thank you for the registration! We sent an email with the final step of registration. Please check the email box. Feel free to contact us at support@fieldmaster.com.ua`
                });
                return response;
            })
            .catch((error: AxiosError<AxiosErrorResponseData>) => {
                const errorCode: string = error.response?.data.message || error.message;
                const responseError: ResponseError = {
                    errorCode: errorCode as ResponseErrorCode,
                    errorMessage: this.isUserAlreadyExists(errorCode)
                        ? `Account with email ${this.state.email} already exists.`
                        : `Cannot create new user (${errorCode}). Please contact support@fieldmaster.com.ua`
                };
                this.onErrorLogin(responseError);
                return {} as AxiosResponse<JWTToken>;
            });
    };

    handleReCaptchaInvalid = () => {
        this.onErrorLogin({
            errorCode: ResponseErrorCode.RECAPTCHA_ERROR,
            errorMessage: 'ReCaptcha validation error'
        });
    };

    onErrorLogin = (responseError: ResponseError) => {
        console.error('onErrorLogin ', JSON.stringify(responseError));
        this.setState({lockButton: false, responseError});
    };

    isFormValid = (): boolean => {
        return !!this.state.email.trim() && !!this.state.email && !!this.state.emailConfirmation &&
            this.state.email.trim().toLowerCase().localeCompare(this.state.emailConfirmation.trim().toLowerCase()) === 0;
    };

    isUserAlreadyExists = (errorCode: ResponseErrorCode | string | undefined): boolean => {
        return errorCode as ResponseErrorCode === ResponseErrorCode.SIGNUP_USER_ALREADY_EXISTS;
    };

    render() {
        const email: string = this.state.email.trim();
        const userEmailDomain: string = email.split('@')[1];
        const emailWWW: string = `www.${userEmailDomain}`.toLowerCase();
        const urlParams: SigninURLData = {
            email
        };
        return (
            <div>
                <h1>{intl().formatMessage({id: 'signup.title'})}</h1>
                <hr/>
                <Form layout={'vertical'} labelAlign={'left'}>
                    <FormItem label="Email">
                        <Input
                            name="email"
                            type="email"
                            value={this.state.email}
                            placeholder="email"
                            disabled={this.state.lockButton || this.state.lockForm}
                            onChange={this.onChangeEmail}
                        />
                    </FormItem>
                    <FormItem label="Confirm email">
                        <Input
                            name="emailConfirmation"
                            type="email"
                            value={this.state.emailConfirmation}
                            placeholder="Confirm email"
                            disabled={this.state.lockButton || this.state.lockForm}
                            onChange={this.onChangeEmailConfirmation}
                        />
                    </FormItem>
                    <FormItem>
                        <ReCaptchaButton
                            disabled={!this.isFormValid() || this.state.lockForm}
                            label="SignUp"
                            icon={<MailOutlined/>}
                            action="SignUp"
                            loading={this.state.lockButton}
                            onClick={this.handleStartLogin}
                            onValid={this.handleLogin}
                            onInvalid={this.handleReCaptchaInvalid}
                        />
                    </FormItem>
                </Form>
                {this.state.responseError && (
                    <Paragraph copyable={true} type={'danger'}>{this.state.responseError.errorMessage}</Paragraph>
                )}
                {this.isUserAlreadyExists(this.state.responseError?.errorCode) && (
                    <Paragraph type={'danger'}>
                        You can <LinkTo to={AppRoutes.SIGNIN_DATA} params={urlParams}>sign in</LinkTo> or <LinkTo to={AppRoutes.RESET_PASSWORD}
                                                                                                                  params={urlParams}>reset password</LinkTo>.
                    </Paragraph>
                )}
                {this.state.okMessage && (
                    <>
                        <Paragraph strong={true}>{this.state.okMessage}</Paragraph>
                        <Paragraph type={'secondary'}>Visit <a href={`http://${emailWWW}`} target="_blank" rel="noopener noreferrer">{emailWWW}</a></Paragraph>
                        <Button type={'primary'}>
                            <LinkTo to={AppRoutes.SIGNIN_DATA} params={urlParams}>Log in to fieldmaster.com.ua</LinkTo>
                        </Button>
                    </>
                )}
            </div>
        );
    }
}
