import React from 'react';
import {Col, Divider, message, Popconfirm, Row, Space, Spin, Tooltip} from 'antd';
import {inject, observer} from "mobx-react";
import {DataStore} from "../../../app/mobx/DataStore";
import './style/style.scss';
import {CustomRendererProps} from "../tab/CustomRenderer";
import Dropzone, {DropEvent, DropzoneState, FileRejection} from "react-dropzone";
import classNames from "classnames";
import {formatMessage} from "../../locale/IntlProvider";
import UploadFilesCommand from "./commands/UploadFilesCommand";
import {ColumnsType} from "antd/lib/table/interface";
import {CoreStore} from "../../../pages/core/mobx/CoreStore";
import {extension} from 'mime-types';
import {CheckOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {getTooltipAvatar} from "../tooltipContent/TooltipContentUtils";
import CEDAvatars from "../CEDAvatars/CEDAvatars";
import Command from "../../types/Command";
import InvalidateRecordCommand from "./commands/InvalidateRecordCommand";
import ValidateRecordCommand from "./commands/ValidateRecordCommand";
import DeleteFilesCommand from "./commands/DeleteFilesCommand";
import UpdateFileCommand from "./commands/UpdateFileCommand";
import RecordTable from "../recordTable/RecordTable";
import {FILE_FORM_CONFIG} from "./FileFormConfig";
import {FileDetailed} from "fm-shared-data/src/types/db/common/FileDetailed";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {getFileDownloadURL, MAX_FILE_SIZE_MB, MAX_FILES, sizeBToLabel, sizeMbToB} from "fm-shared-utils/src/utils/file/FileUtils";
import {DateUtils, NOTIFICATION_PERIOD_DAYS} from "fm-shared-utils/src/utils/datatime/DateUtils";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";
import {EntityKey} from "fm-shared-data/src/types/db/common/EntityKey";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
};

interface Props<T extends DataRecord> extends StoreProps, CustomRendererProps<T> {
    barcode_entity_id: number;
}

interface State {
    commandInProgress: boolean;
    isModalVisible: boolean;

    files?: FileDetailed[]
}

@inject('coreStore', 'dataStore')
@observer
export default class ManageFileWidget<T extends DataRecord> extends React.Component<Props<T>, State> {
    static defaultProps = {} as StoreProps;

    state: State = {
        commandInProgress: false,
        isModalVisible: false,
    };

    get fileEntityKey(): EntityKey {
        const {barcode_entity_id, record} = this.props;
        return {barcode_entity_id, entity_id: record.id};
    }

    componentDidMount() {
        const {dataStore} = this.props;
        if (!dataStore.areFilesLoaded(this.fileEntityKey)) {
            dataStore.loadFiles(this.fileEntityKey);
        }
    }

    onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        console.log(acceptedFiles, fileRejections, event);
        if (fileRejections.length > 0) {
            const content: string = [formatMessage('dropzone.placeholder.files.any.maxFiles', {max: MAX_FILES}), formatMessage('dropzone.placeholder.files.any.maxSize', {max: MAX_FILE_SIZE_MB})].join(', ');
            message.error({content, duration: 4});
        }
        if (acceptedFiles.length > 0) {
            // this.setState({commandInProgress: true});
            this.setState({
                commandInProgress: true
            });
            console.info(`Let's upload next files: ${acceptedFiles.length}`);
            new UploadFilesCommand(this.fileEntityKey).execute(acceptedFiles).then((result: string[]) => {
                console.info(result);
                this.props.dataStore.loadFiles(this.fileEntityKey).then(() => {
                    this.setState({commandInProgress: false});
                })
            });
        }
    };

    // table

    getColumns = (): ColumnsType<FileDetailed> => {
        return [
            {
                title: formatMessage('file_record.field.type'),
                dataIndex: 'type',
                key: 'type',
                width: '200px',
                ellipsis: true,
                render: (value: any, record: FileDetailed, index: number): React.ReactNode => {
                    return <span style={{color: `${record.color}`}}>{record.type}</span>;
                }
            },
            {
                title: formatMessage('file_record.field.file_name'),
                dataIndex: 'file_name',
                key: 'file_name',
                // width: '50%',
                ellipsis: true,
                render: (value: any, record: FileDetailed, index: number): React.ReactNode => {
                    const ext: string = extension(record.mimetype) || 'doc';
                    return (<><span className={`fiv-sqo fiv-icon-${ext}`}/> <a href={getFileDownloadURL(record)}
                                                                               rel="noreferrer"
                                                                               target='_blank'>{record.file_name}</a></>);
                }
            },
            {
                title: formatMessage('file_record.field.size'),
                dataIndex: 'size',
                width: '80px',
                key: 'size',
                render: (value: any, record: FileDetailed, index: number): React.ReactNode => {
                    return <span style={{color: `${record.color}`}}>{sizeBToLabel(record.size)}</span>;
                }
            },
            {
                dataIndex: 'actions',
                width: '60px',
                key: 'actions',
                render: (value: any, record: FileDetailed, index: number): React.ReactNode => {
                    const {
                        id,
                        validated_on,
                        validated_by_name,
                        validated_by_avatar,
                        validated_by_gender,
                        valid_from,
                        valid_to
                    } = record;
                    const isValidated: boolean = !!validated_on;

                    const daysBeforeExpired: number = DateUtils.notifyBeforeExpireDays(valid_to!);
                    const color: string = daysBeforeExpired > NOTIFICATION_PERIOD_DAYS
                        ? 'green'
                        : daysBeforeExpired > 0 ? 'orange' : 'red';
                    const isInvalidation: boolean = isValidated && this.isAdminUser;
                    return (
                        <Space>
                            <Tooltip title={isValidated
                                ? getTooltipAvatar('validate_record.validated.true', validated_by_avatar, validated_by_gender!, validated_by_name!, validated_on!)
                                : formatMessage('valid_from_to.title')} placement={'top'}>
                                <Popconfirm
                                    key={`valid_${id}`}
                                    disabled={isValidated && (isValidated && !this.isAdminUser)}
                                    placement={'right'}
                                    okType={'primary'}
                                    title={formatMessage(isInvalidation ? 'validate_record.invalidated.confirmation' : 'validate_record.validated.confirmation')}
                                    okText={formatMessage('common.yes')}
                                    cancelText={formatMessage('common.no')}
                                    onConfirm={this.handleOnValidateFile(id, isValidated)}>
                                    <CheckOutlined className={classNames(
                                        'scrud-container__icon',
                                        {'scrud-container__icon--validated': isValidated},
                                        {'scrud-container__icon--no-click': isValidated && !isInvalidation}
                                    )}/>
                                </Popconfirm>
                            </Tooltip>

                            {valid_to && (
                                <Tooltip title={<>
                                    <>
                                        <Divider orientation="left">{formatMessage('file_record.field.validity_period')}</Divider>
                                        {valid_from && <p>{formatMessage('file_record.field.valid_from')}&nbsp;<strong>{MomentUtils.clientDateToLabel(valid_from, false)}</strong></p>}
                                        <p>{formatMessage('file_record.field.valid_to')}&nbsp;<strong style={{color: color}}>{MomentUtils.clientDateToLabel(valid_to, false)}</strong></p>
                                    </>
                                </>} placement={'top'}>
                                    <ClockCircleOutlined style={{color: color}}/>
                                </Tooltip>
                            )}
                        </Space>
                    );
                }
            },
            {
                dataIndex: 'users',
                width: '80px',
                key: 'users',
                render: (value: any, record: FileDetailed, index: number): React.ReactNode => {
                    return <CEDAvatars record={record} size={"small"}/>;
                }
            },
        ];
    };

    handleOnRestore = (record: FileDetailed) => {
        const upsertValue: UpsertValue<FileDetailed> = {
            initialValue: record,
            updatedValue: {
                ...record,
                deleted_on: undefined,
                deleted_by: undefined
            }
        };
        return this.saveCommand(upsertValue);
    };

    handleOnValidateFile = (id: number, isValidated: boolean) => () => {
        this.setState({commandInProgress: true});
        const cmd: Command<boolean, number> = isValidated ? new InvalidateRecordCommand() : new ValidateRecordCommand();
        cmd.execute(id).then(() => {
            return this.props.dataStore.loadFiles(this.fileEntityKey).then(() => {
                this.setState({commandInProgress: false});
                message.success({content: formatMessage(isValidated ? 'validate_record.validated.false' : 'validate_record.validated.true')}, 3);
                return {} as UpsertResponse<FileDetailed>; //TODO
            })
        });
    };

    rowKey = (record: FileDetailed): React.Key => {
        return record.id;
    };

    deleteCommand = async (record: FileDetailed): Promise<UpsertResponse<FileDetailed>> => {
        return new DeleteFilesCommand().execute([{id: record.id, rnd: record.rnd}]).then(() => {
            return this.props.dataStore.loadFiles(this.fileEntityKey).then(() => {
                message.success({content: formatMessage('SCRUDActions.delete.completed')}, 3);
                return {} as UpsertResponse<FileDetailed>; //TODO
            })
        });
    };

    saveCommand = async (upsertValue: UpsertValue<FileDetailed>): Promise<UpsertResponse<FileDetailed>> => {
        const {dataStore} = this.props;
        const upsertResponse: UpsertResponse<FileDetailed> = await new UpdateFileCommand().execute(upsertValue);
        dataStore.setFiles(this.fileEntityKey, upsertResponse.list);
        return Promise.resolve(upsertResponse);
    };

    get isAdminUser(): boolean {
        const {coreStore} = this.props;
        return coreStore.isAdminUser;
    }

    render() {
        const {dataStore} = this.props;

        const {areFilesLoaded} = dataStore;
        if (!areFilesLoaded(this.fileEntityKey)) {
            return <Spin/>;
        }

        const files: FileDetailed[] = dataStore.getFiles(this.fileEntityKey);

        const tableTitle = (data: readonly FileDetailed[]): React.ReactNode => {
            return (<Dropzone onDrop={this.onDrop}
                              maxSize={sizeMbToB(MAX_FILE_SIZE_MB)}
                              maxFiles={MAX_FILES}
                              multiple={true}>
                {(dropzoneState: DropzoneState) => {
                    const {getRootProps, getInputProps, isDragAccept, isDragReject} = dropzoneState;
                    return (
                        <div {...getRootProps()}
                             className={classNames('dropzone',
                                 {'dropzone--isDragAccept': isDragAccept},
                                 {'dropzone--isDragReject': isDragReject})}>
                            <input {...getInputProps()} />
                            <span>{formatMessage(isDragReject ? 'dropzone.placeholder.files.any.maxFiles' : 'dropzone.placeholder.files.any', {max: MAX_FILES})}</span>
                        </div>
                    )
                }}
            </Dropzone>);
        }

        return (
            <Spin spinning={this.state.commandInProgress} wrapperClassName={'manage-files'}>
                <Row>
                    <Col span={24}>
                        <RecordTable tableTitle={tableTitle}
                                     dataSource={files}
                                     getColumns={this.getColumns}
                                     rowKey={this.rowKey}

                                     modalFormConfig={FILE_FORM_CONFIG}
                                     hideCard={true}
                                     isAddEnabled={false}
                                     isEditEnabled={true}
                                     isDeleteEnabled={true}
                                     deleteCommand={this.deleteCommand}
                                     saveCommand={this.saveCommand}
                                     restoreCommand={this.handleOnRestore}
                                     scroll={{y: 270}}
                                     isAdminUser={this.isAdminUser}
                        />
                    </Col>
                </Row>
            </Spin>);
    }
}
