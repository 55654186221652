import {ModalFormConfig} from '../../../../../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {FormFieldRender} from '../../../../../../../../../common/widgets/modalForm/type/FormFieldRender';
import {NonEmptyValidator} from '../../../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import EmailTypeAutoCompleteProvider from './EmailTypeAutoCompleteProvider';
import {EmailValidator} from '../../../../../../../../../common/widgets/modalForm/validators/sync/EmailValidator';
import EmployeeEmailClipboard from './EmployeeEmailClipboard';
import {DICTIONARY_DYNAMIC_EMPLOYEE_EMAIL_DOMAINS} from './DynamicDictionaryEmployeeEmailDomains';
import EmployeeEmailReadonlyProvider from "./EmployeeEmailReadonlyProvider";
import EmailConfirmationCustomRenderer from "./EmailConfirmationCustomRenderer";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {DictionaryDecorator} from "../../../../../../../../../common/widgets/dictionary/DictionaryDecorator";

export const EMPLOYEE_EMAIL_FORM_CONFIG: ModalFormConfig<EmployeeEmailRecord, EmployeeRecord> = {
    title: 'employee_email.modalForm.title',
    tableName: 'employee_email',
    getNewRecord: (employee?: EmployeeRecord): EmployeeEmailRecord => {
        return {
            employee_id: employee!.id
        } as EmployeeEmailRecord;
    },
    clipboardText: new EmployeeEmailClipboard(),
    fieldConfig: [
        {
            label: 'employee_email.field.email_type',
            field_name: 'email_type',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 2})],
            config: {
                autoCompleteOptionsProvider: new EmailTypeAutoCompleteProvider(),
                inputProps: {
                    placeholder: 'Private, Work, etc',
                    maxLength: 50
                }
            },
        },
        {
            label: 'employee_email.field.email',
            field_name: 'email',
            renderType: FormFieldRender.MENTIONS,
            readonly: new EmployeeEmailReadonlyProvider(),
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 3}), new EmailValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_DYNAMIC_EMPLOYEE_EMAIL_DOMAINS),
                inputProps: {
                    placeholder: 'Email',
                    maxLength: 50,
                    notFoundContent: null
                }
            }
        },
        {
            label: 'employee_email.field.confirmed_on',
            field_name: 'confirmed_on',
            optional: true,
            renderType: FormFieldRender.CUSTOM_RENDERER,
            renderer: EmailConfirmationCustomRenderer
        },
        {
            label: 'file_record.field.comment',
            field_name: 'comment',
            optional: true,
            renderType: FormFieldRender.TEXT,
            config: {
                inputProps: {
                    autoSize: {minRows: 2, maxRows: 4}
                }
            }
        }
    ]
};
