import {isRootRecord, TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";

export function convertTreeRecordsToTreeData<T extends TreeRecord, K extends TreeData<T>>(treeRecords: T[]): K[] {
    const rootRecord: T | undefined = treeRecords.find((treeRec: T) => isRootRecord(treeRec));
    if (!rootRecord) {
        return [];
    }
    const root: K = treeRecordsToTreeData(rootRecord);
    root.children = getKidsOf(rootRecord, treeRecords);
    root.count = 0;
    return [root];
}

function getKidsOf<T extends TreeRecord, K extends TreeData<T>>(parentRec: T, treeRecords: T[]): K[] {
    const kids: T[] = treeRecords
        .filter((rec: T) => rec.parent_id === parentRec.id && rec.id !== parentRec.id)
        .sort((a: T, b: T) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0));
    return kids.map((rec: T) => {
        const data: K = treeRecordsToTreeData(rec);
        data.children = getKidsOf(rec, treeRecords);
        data.count = 0;
        return data;
    });
}

function treeRecordsToTreeData<T extends TreeRecord, K extends TreeData<T>>(treeRecord: T): K {
    const result: K = {} as K; // in TS you can't use generic type
    result.id = treeRecord.id;
    result.name = treeRecord.name;
    result.icon = treeRecord.icon;
    result.color = treeRecord.color;
    result.children = [];
    result.record = treeRecord;
    result.count = 0;
    return result;
}

export function findNodeById<T extends TreeRecord, K extends TreeData<T>>(treeData: K[], id: number): K | undefined {
    let result: K | undefined;
    for (const node of treeData) {
        if (node.id === id) {
            return node;
        } else if (node.children.length > 0) {
            result = findNodeById(node.children as K[], id);
            if (!!result) {
                return result;
            }
        }
    }
}

export function calcTreeCounts<T extends TreeRecord, K extends TreeData<T>>(treeData: K[], map: Map<number, number>): number {
    return treeData.reduce((prev: number, tree: K) => {
        const treeCount: number = map.get(tree.id) || 0;
        prev += treeCount;
        if (tree.children.length) {
            const childCount: number = calcTreeCounts(tree.children, map);
            prev += childCount;
            tree.count = childCount + treeCount;
        } else {
            tree.count = treeCount;
        }
        return prev;
    }, 0);
}
