import React from 'react';
import {Spin} from 'antd';

interface Props {
    text?: string;
}

export default class FMLoader extends React.PureComponent<Props> {
    render() {
        return (
            <>
                <Spin spinning={true}></Spin>
                <span> {this.props.text}</span>
            </>
        );
    }
}
