import React from 'react';
import {Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Scatter, Tooltip as RETooltip, XAxis, YAxis} from 'recharts';
import {inject, observer} from 'mobx-react';
import {Card, Checkbox, DatePicker, message, Popconfirm, Radio, Rate, Space, Spin, Tooltip} from "antd";
import moment, {Moment} from 'moment';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import {RangeValue} from "rc-picker/lib/interface";
import SCRUDActions from "../../../../../../../common/widgets/scrudActions/SCRUDActions";
import {BarChartOutlined, HistoryOutlined, TableOutlined} from "@ant-design/icons";
import ModalFormik from "../../../../../../../common/widgets/modalForm/ModalFormik";
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";
import {LOGDATA_FORM_CONFIG} from "./LogDataFormConfig";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {LogDataFields} from "./LogDataFields";
import {LogTypeField, LogTypeFieldType} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {getRealFieldName, readLogTypeFields} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import {FMEntity, getBarcodeEntityId} from "fm-shared-data/src/types/db/core/FMEntity";
import {LogDataSearchParamsUI, LogDataViewStore} from "./mobx/LogDataViewStore";
import UpdateLogDataCommand from "./commands/UpdateLogDataCommand";
import AddLogDataCommand from "./commands/AddLogDataCommand";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import Icofont from "react-icofont";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './style/logDataTab.scss';
import DeleteLogDataCommand from "./commands/DeleteLogDataCommand";
import {getOptions} from "./LogDataFieldItemUtils";
import {FMOptionType} from "../../../../../../../common/widgets/modalForm/type/FormFieldConfig";
import {LabeledValue} from "antd/lib/select";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {CustomRendererProps} from "../../../../../../../common/widgets/tab/CustomRenderer";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";
import CEDAvatars from "../../../../../../../common/widgets/CEDAvatars/CEDAvatars";
import {formatMessage} from "../../../../../../../common/locale/IntlProvider";
import {DateRange, RangeViewType} from '../../../../../../../common/utils/DateRangeUtils';
import {getDefaultRanges} from '../../../../../../../common/widgets/rangePicker/RangePickerUtils';

const {RangePicker} = DatePicker;

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    logDataViewStore: LogDataViewStore;
};

export interface LogDataTabProps {
    log_type: number;
}

interface Props<T extends DataRecord> extends StoreProps, CustomRendererProps<T, LogDataTabProps> {
    // barcode_entity_id?: number; // value of FMEntity
    // entity_id?: number;
}

interface State {
    view: RangeViewType;

    dictionaryIsReady: boolean;

    modalFormVisible: boolean;
    modalFormData: LogDataRecord;

    // TODO: remove dates from state
    start_date: Moment | null;
    end_date: Moment | null;
}

const DEFAULT_DATE_START: Moment = moment().startOf('month');
const DEFAULT_DATE_END: Moment = moment().endOf('month');

@inject('logDataViewStore', 'dataStore', 'coreStore')
@observer
export default class LogDataTab<T extends DataRecord> extends React.Component<Props<T>, State> {
    static defaultProps = {} as StoreProps;

    gridRef = React.createRef<AgGridReact>();

    state = {
        view: RangeViewType.GRID,

        dictionaryIsReady: false,

        modalFormVisible: false,
        modalFormData: {} as LogDataRecord,

        start_date: null,
        end_date: null
    };

    //TODO: cache it and handle empty result
    getLogTypeRecord(): LogTypeRecord {
        const {dataStore, log_type} = this.props;
        return dataStore.logType.find((rec: LogTypeRecord) => rec.id === log_type)!;
    }

    getLogDataSearchParams = (): LogDataSearchParamsUI => {
        const {log_type, record} = this.props;
        const {start_date, end_date} = this.state;
        return {
            log_type,
            entity_id: record.id,
            start_date,
            end_date
        };
    }

    componentDidMount() {
        console.info('LogDataTab: componentDidMount');
        const {log_type, logDataViewStore} = this.props;
        const range: DateRange | undefined = logDataViewStore.getLogTypeRange(log_type);
        this.setState({
            start_date: range ? range.dateStart : DEFAULT_DATE_START,
            end_date: range ? range.dateEnd : DEFAULT_DATE_END
        }, () => {
            this.loadData();
        });
    }

    componentDidUpdate() {
        console.info('LogDataTab: componentDidUpdate');
    }

    loadData = async (forceLoad: boolean = false) => {
        const {logDataViewStore} = this.props;
        await this.checkAndLoadDictionaries(() => {
            if (forceLoad || !logDataViewStore.getData(this.getLogDataSearchParams())) {
                logDataViewStore.loadData(this.getPredefinedFMEntityFields());
            }
        });
    }

    handleOnChangeDateRange = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
        // handle No period here
        const {logDataViewStore} = this.props;
        this.setState({
            start_date: dates&& dates[0] ? dates[0].startOf('day') : null,
            end_date: dates && dates[1] ? dates[1].endOf('day') : null
        }, () => {
            logDataViewStore.changePeriod(this.getPredefinedFMEntityFields());
        });
    }

    onViewChange = (e: any) => {
        this.setState({view: e.target.value});
    }

    getNotLoadedDictionaries = (): Promise<any>[] => {
        const {dataStore} = this.props;
        const uniqMap: Map<string, Promise<any>> =
            this.enabledLogTypeFields.reduce((prev: Map<string, Promise<any>>, logTypeField: LogTypeField) => {
                if (logTypeField.field_type === LogTypeFieldType.FM_ENTITY) {
                    switch (logTypeField.field_dict_id as FMEntity) {
                        case FMEntity.EMPLOYEE: {
                            if (!dataStore.isEmployeeLoaded && !prev.has(logTypeField.field_dict_id)) {
                                prev.set(logTypeField.field_dict_id, dataStore.loadEmployee());
                            }
                            break;
                        }
                        case FMEntity.FIELD: {
                            if (!dataStore.isFieldLoaded && !prev.has(logTypeField.field_dict_id)) {
                                prev.set(logTypeField.field_dict_id, dataStore.loadField());
                            }
                            break;
                        }
                        case FMEntity.WORK: {
                            if (!dataStore.isWorkLoaded && !prev.has(logTypeField.field_dict_id)) {
                                prev.set(logTypeField.field_dict_id, dataStore.loadWork());
                            }
                            break;
                        }
                        case FMEntity.EQUIPMENT: {
                            if (!dataStore.isEquipmentLoaded && !prev.has(logTypeField.field_dict_id)) {
                                prev.set(logTypeField.field_dict_id, dataStore.loadEquipment());
                            }
                            break;
                        }
                    }
                }
                return prev;
            }, new Map<string, Promise<any>>());
        return Array.from(uniqMap.values());
    }

    checkAndLoadDictionaries = async (callbackFn: () => void) => {
        const notLoadedDictionaries: Promise<any>[] = this.getNotLoadedDictionaries();
        if (notLoadedDictionaries.length) {
            Promise.all(notLoadedDictionaries).then(() => {
                this.setState({dictionaryIsReady: true}, callbackFn);
            })
        } else {
            this.setState({dictionaryIsReady: true}, callbackFn);
        }
    }

    getPredefinedFMEntityFields = (): LogDataSearchParamsUI => {
        const {entity_id} = this.props;
        const result: LogDataSearchParamsUI = this.getLogDataSearchParams();
        if (this.hasPredefinedField) {
            const fieldLogTypeField: LogTypeField | undefined = this.findLogTypeField(this.enabledLogTypeFields);
            if (fieldLogTypeField) {
                return {
                    ...result,
                    ...(!!fieldLogTypeField && {[getRealFieldName(fieldLogTypeField)]: entity_id})
                }
            }
        }
        return result;
    }

    get hasPredefinedField(): boolean {
        const {barcode_entity_id, entity_id} = this.props;
        if (barcode_entity_id === BarcodeEntityId.LOG_TYPE) {
            return false;
        }
        return !!barcode_entity_id && !!entity_id;
    }

    findLogTypeField = (logTypeFields: LogTypeField[]): LogTypeField | undefined => {
        const {barcode_entity_id} = this.props;
        if (barcode_entity_id === BarcodeEntityId.LOG_TYPE) {
            return undefined;
        }
        return logTypeFields.find((item: LogTypeField) => {
            if (item.field_type !== LogTypeFieldType.FM_ENTITY) {
                return false;
            }
            return getBarcodeEntityId(item.field_dict_id as FMEntity) === barcode_entity_id;
        });
    }

    handleOnAdd = () => {
        // await this.checkAndLoadDictionaries(() => {
        const {log_type, entity_id} = this.props;
        const newRecord: LogDataRecord = LOGDATA_FORM_CONFIG.getNewRecord();
        newRecord.log_type = log_type;
        // predefine entity files based on log_type (find files and set employee id from record)
        // Add log record with predefined some entity_id in case open from Entity tab!
        if (this.hasPredefinedField) {
            const fieldLogTypeField: LogTypeField | undefined = this.findLogTypeField(this.enabledLogTypeFields);
            if (fieldLogTypeField) {
                newRecord[getRealFieldName(fieldLogTypeField)] = entity_id;
            }
        }
        this.setState({modalFormVisible: true, modalFormData: newRecord});
        // });
    }

    refreshCommand = () => {
        this.loadData(true);
    }

    saveLogDataRecordCommand = async (upsertValue: UpsertValue<LogDataRecord>): Promise<UpsertResponse<LogDataRecord>> => {
        // const {logDataViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.initialValue.id;
        const upsertResponse: UpsertResponse<LogDataRecord> = isUpdate
            ? await new UpdateLogDataCommand(upsertValue).execute()
            : await new AddLogDataCommand(upsertValue).execute();
        // logDataViewStore.setLogData(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    }

    handleOnSaveLogDataRecord = async (upsertValue: UpsertValue<LogDataRecord>) => {
        this.loadData(true);
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    get enabledLogTypeFields(): LogTypeField[] {
        return readLogTypeFields(this.getLogTypeRecord())
            .filter((rec: LogTypeField) => rec.field_enabled)
            .sort((a: LogTypeField, b: LogTypeField) => a.field_index > b.field_index ? 1 : -1);
    }

    disablePredefinedField = (logTypeFields: LogTypeField[]): LogTypeField[] => {
        const {barcode_entity_id} = this.props;
        if (barcode_entity_id && this.hasPredefinedField) {
            const fieldLogTypeField: LogTypeField | undefined = this.findLogTypeField(logTypeFields);
            return logTypeFields.map((logTypeField: LogTypeField) => {
                logTypeField.field_enabled = logTypeField.field_id !== fieldLogTypeField?.field_id;
                return logTypeField;
            });
        }
        return logTypeFields;
    }

    myComp = (props: any) => {
        return <Checkbox disabled={true} checked={props.value}/>
    }

    myCheck = (props: any) => {
        if (props.value === undefined || props.value === null) {
            return <></>;
        }
        return <Icofont icon={props.value ? "check-alt" : "minus"} size="2"/>
    }

    handleOnEditLogDataRecord = (data: LogDataRecord) => async () => {
        // await this.checkAndLoadDictionaries(() => {
        this.setState({modalFormVisible: true, modalFormData: data});
        // });
    }

    handleOnDeleteLogDataRecord = (data: LogDataRecord) => async () => {
        // const LogTypeRecords: LogTypeRecord[] = await new DeleteLogDataCommand().execute(deleteEntity);
        await new DeleteLogDataCommand().execute(data);
        this.loadData(true);
    }

    get isAdminUser(): boolean {
        const {coreStore} = this.props;
        return coreStore.isAdminUser;
    }

    handleOnRestore = (record: LogDataRecord) => () => {
        const upsertValue: UpsertValue<LogDataRecord> = {
            initialValue: record,
            updatedValue: {
                ...record,
                deleted_on: undefined,
                deleted_by: undefined
            }
        };
        this.saveLogDataRecordCommand(upsertValue).then(() => {
            message.success({content: formatMessage('restore_record.completed')}, 3);
            this.loadData(true);
        });
    };

    frameworkComponents = {
        'checkBoxCellRenderer': this.myCheck,
        // 'checkBoxCellRenderer': this.myComp
        // 'checkBoxCellRenderer': (props: any) => <p>{props.value ? 1 : 0}</p>
        // 'checkBoxCellRenderer': Checkbox
        'actionsCellRenderer': (props: any) => <Space className={"align-right"}>
            {this.isAdminUser && !!(props.data as LogDataRecord).deleted_on && <Tooltip title={formatMessage('restore_record.tooltip')} placement={"top"}>
                <Popconfirm
                    key={`restore_${(props.data as LogDataRecord).id}`}
                    placement={'right'}
                    okType={'primary'}
                    title={formatMessage('restore_record.confirmation')}
                    okText={formatMessage('common.yes')}
                    cancelText={formatMessage('common.no')}
                    onConfirm={this.handleOnRestore((props.data as LogDataRecord))}>
                    <HistoryOutlined className={'scrud-container__icon'}/>
                </Popconfirm>
            </Tooltip>}

            <SCRUDActions onEdit={this.handleOnEditLogDataRecord(props.data as LogDataRecord)}
                          isEditEnabled={true}
                          onDelete={this.handleOnDeleteLogDataRecord(props.data as LogDataRecord)}
                          isDeleteEnabled={true}/>
        </Space>,
        'cedAvatarsCellRenderer': (props: any) => <>
            <CEDAvatars className={"cedAvatars"} record={props.data as LogDataRecord} size={"small"}/>
        </>,
        'dateTimeCellRenderer': (props: any) => <>{MomentUtils.clientDateToLabel(props.value, false)}</>,
        'rateCellRenderer': (props: any) => <Rate disabled defaultValue={props.value}/>,
        'percentCellRenderer': (props: any) => <>{props.value}%</>,
        'entityCellRenderer': (props: any) => {
            // TODO REF it!
            if (!props.value) {
                return <></>;
            }
            const fieldId: string = props.colDef.field[5];
            const logTypeField: LogTypeField = this.enabledLogTypeFields.find((item: LogTypeField) => item.field_id.toString() === fieldId)!;
            const options: FMOptionType[] = getOptions(logTypeField);
            const fmOptionType: FMOptionType | undefined = options.find((fmOptionType: FMOptionType) => fmOptionType.value === props.value) as LabeledValue;
            return <>
                {fmOptionType?.label}
            </>
        },
        'dictCellRenderer': (props: any) => {
            // TODO REF it!
            if (!props.value) {
                return <></>;
            }
            const fieldId: string = props.colDef.field[5];
            const logTypeField: LogTypeField = this.enabledLogTypeFields.find((item: LogTypeField) => item.field_id.toString() === fieldId)!;
            const options: FMOptionType[] = getOptions(logTypeField);
            const fmOptionType: FMOptionType | undefined = options.find((fmOptionType: FMOptionType) => fmOptionType.value === props.value) as LabeledValue;
            return <>
                {fmOptionType?.label}
            </>
        },
        'simpleCellRenderer': (props: any) => <>{props.value}</>
    };

    getCellRenderer = (logTypeField: LogTypeField): string | undefined => {
        switch (logTypeField.field_type) {
            case LogTypeFieldType.PERCENT:
                return "percentCellRenderer";
            case LogTypeFieldType.RATE:
                return "rateCellRenderer";
            case LogTypeFieldType.FM_ENTITY:
                return "entityCellRenderer";
            case LogTypeFieldType.FM_DICTIONARY:
                return "dictCellRenderer";
            case LogTypeFieldType.NUMBER:
                return "simpleCellRenderer";
            case LogTypeFieldType.BOOLEAN:
                return "checkBoxCellRenderer";
            case LogTypeFieldType.DATE:
            case LogTypeFieldType.DATETIME:
            case LogTypeFieldType.TIME:
                return "dateTimeCellRenderer";
            default:
                return undefined;
        }
    }

    render() {
        const {log_type, logDataViewStore, dataStore} = this.props;
        if (!dataStore.isLogTypeLoaded) {
            return <p>Loading log types....</p>
        }
        const data: LogDataRecord[] | undefined = logDataViewStore.getData(this.getLogDataSearchParams());
        const isDataLoading: boolean = !data;
        const range: DateRange | undefined = logDataViewStore.getLogTypeRange(log_type);
        if (!range) {
            return <p>Loading dates....</p>
        }

        const viewLogTypeFields: LogTypeField[] = this.disablePredefinedField(this.enabledLogTypeFields);
        const cardTitle: React.ReactNode = <Space>
            <span>Данные за период: </span>
            <RangePicker
                disabled={isDataLoading}
                allowEmpty={[true, true]}
                defaultValue={[range.dateStart, range.dateEnd]}
                ranges={getDefaultRanges()}
                onChange={this.handleOnChangeDateRange}
            />
            <Radio.Group value={this.state.view} onChange={this.onViewChange} disabled={isDataLoading}>
                <Radio.Button value={RangeViewType.GRID}><TableOutlined/></Radio.Button>
                <Radio.Button value={RangeViewType.CHART}><BarChartOutlined/></Radio.Button>
            </Radio.Group>
        </Space>;

        const reData = [
            {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
            },
        ];

        const lineColors: string[] = ["#82ca9d", "#8884d8", "#bd82ca", "#8884d8", "#82ca9d", "#bd82ca", "#8884d8", "#82ca9d", "#bd82ca"]
        const getXValue = (data: LogDataRecord) => {
            return MomentUtils.dateToMoment(data.log_date).format('ll');
        };
        return (
            // <div className={"maxContent"}>
            <Spin spinning={!data} wrapperClassName={"spin-flex maxContent"}>
                <Card size='small'
                      className="fm-card"
                      title={cardTitle}
                      bordered={false}
                      extra={<SCRUDActions onAdd={this.handleOnAdd}
                                           isAddEnabled={!isDataLoading}
                                           onRefresh={this.refreshCommand}
                                           isRefreshEnabled={!!this.refreshCommand}/>}>
                    {this.state.view === RangeViewType.GRID &&
                        <AgGridReact ref={this.gridRef}
                            // domLayout={"autoHeight"}
                                     frameworkComponents={this.frameworkComponents}
                                     suppressLoadingOverlay={true}
                                     suppressCellSelection={true}
                                     className={"ag-theme-alpine maxContent fm-ag-grid"}
                                     rowData={data}
                                     defaultColDef={{
                                         sortable: true,
                                         // resizable: true,
                                         // filter: true,
                                         flex: 1,
                                         minWidth: 100,
                                     }}>
                            {/*<AgGridColumn headerName={"Type"} field="log_type" resizable={true} width={70}/>*/}
                            <AgGridColumn headerName={"Date"} field="log_date" resizable={true} cellRenderer="dateTimeCellRenderer"/>
                            {viewLogTypeFields.reduce((prev: React.ReactNode[], logTypeField: LogTypeField) => {
                                if (logTypeField.field_enabled) {
                                    prev.push(<AgGridColumn key={logTypeField.field_id}
                                                            headerName={logTypeField.field_title}
                                                            field={getRealFieldName(logTypeField)}
                                                            cellRenderer={this.getCellRenderer(logTypeField)}
                                                            filter={true}
                                                            resizable={true}
                                    />);
                                }
                                return prev;
                            }, [])}
                            <AgGridColumn headerName={""} field="id" cellRenderer="cedAvatarsCellRenderer" filter={false} maxWidth={75} width={75}/>
                            <AgGridColumn headerName={""} field="id" cellRenderer="actionsCellRenderer" filter={false} maxWidth={90} width={90}/>
                        </AgGridReact>
                    }
                    {this.state.view === RangeViewType.CHART && <span style={{width: "100%", height: "90%"}}>
                        {/*<ResponsiveContainer width="100%" height="100%">*/}
                        <ResponsiveContainer>
                                <ComposedChart
                                    // width={500}
                                    // height={300}
                                    data={data}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                  <CartesianGrid strokeDasharray="3 3"/>
                                    {/*<XAxis dataKey="log_date"/>*/}
                                    <XAxis dataKey={getXValue}/>
                                  <YAxis/>
                                  <RETooltip/>
                                  <Legend/>
                                    {viewLogTypeFields.reduce((prev: React.ReactNode[], logTypeField: LogTypeField) => {
                                        switch (logTypeField.field_type) {
                                            case LogTypeFieldType.NUMBER: {
                                                prev.push(<Line type="monotone"
                                                                name={logTypeField.field_title}
                                                                dataKey={getRealFieldName(logTypeField)}
                                                                stroke={lineColors[prev.length]}
                                                                activeDot={{r: 5}}/>);
                                                break;
                                            }
                                            case LogTypeFieldType.RATE: {
                                                prev.push(<Bar type="monotone"
                                                               name={logTypeField.field_title}
                                                               dataKey={getRealFieldName(logTypeField)}
                                                               fill={"orange"}/>);
                                                break;
                                            }
                                            case LogTypeFieldType.BOOLEAN: {
                                                prev.push(<Scatter type="monotone"
                                                                   name={logTypeField.field_title}
                                                                   dataKey={getRealFieldName(logTypeField)}
                                                                   fill="red"/>);
                                                break;
                                            }
                                            case LogTypeFieldType.PERCENT: {
                                                prev.push(<Area type="monotone"
                                                                name={logTypeField.field_title}
                                                                dataKey={getRealFieldName(logTypeField)}
                                                                stroke="#8884d8"
                                                                fill="#d7d5ff"/>);
                                                break;
                                            }
                                        }
                                        return prev;
                                    }, [])}
                                    {/*<Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}}/>*/}
                                    {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d"/>*/}
                                </ComposedChart>
                              </ResponsiveContainer>
                    </span>}
                    {/*<div>{logDataViewStore.getData(log_type)?.map((rec: LogDataRecord) => {*/}
                    {/*    return <p key={rec.id}>*/}
                    {/*        {rec.id}*/}
                    {/*        <Button onClick={() => this.onEdit(rec)}>Edit</Button>*/}
                    {/*        <Button onClick={() => this.onDelete(rec)}>Delete</Button>*/}
                    {/*    </p>*/}
                    {/*})}</div>*/}
                </Card>
                {this.state.modalFormVisible && this.state.dictionaryIsReady && <ModalFormik config={LOGDATA_FORM_CONFIG}
                                                                                             initialValues={this.state.modalFormData}
                                                                                             saveCommand={this.saveLogDataRecordCommand}
                                                                                             onSave={this.handleOnSaveLogDataRecord}
                                                                                             onCancel={this.handleOnModalFormClosed}
                                                                                             titleSuffix={this.getLogTypeRecord().name}>
                    <LogDataFields enabledLogTypeFields={viewLogTypeFields}/>
                </ModalFormik>
                }
            </Spin>
            // </div>
        );
    }
}
