import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {workHash, WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqEquipmentValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqWorkValidator extends SyncValidator<WorkRecord, UniqEquipmentValidatorConfig> {

    constructor(config?: UniqEquipmentValidatorConfig) {
        super(config || {});
    }

    validate(value: WorkRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = workHash(value);
            const isRecordExists: boolean = stores.dataStore.work
                .some((rec: WorkRecord) => value.id !== rec.id && valueHash === workHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.work.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
