import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import LogTypeTabInfoView from './LogTypeTabInfoView';
import {LogTypeViewStore} from '../../mobx/LogTypeViewStore';
import {inject, observer} from 'mobx-react';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import LogTypeTabGeneral from "./items/general/LogTypeTabGeneral";
import {CustomRendererProps, withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import './style/style.scss'
import {TabItemContent} from "../../../../../common/widgets/tab/TabItemContent";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import RichTextWidget from "../../../../../common/widgets/richText/RichTextWidget";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import LogDataTab, {LogDataTabProps} from "./items/logData/LogDataTab";

type StoreProps = {
    coreStore: CoreStore;
    logTypeViewStore: LogTypeViewStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('logTypeViewStore', 'coreStore')
@observer
export default class LogTypeTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    readonly tabItems: TabItemContent<LogTypeRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: LogTypeTabGeneral
        },
        {
            key: 'tab2',
            tab: formatMessage('employee_email.field.description'),
            disabled: false,
            withRecords: true,
            componentType: RichTextWidget,
            componentProps: {
                barcode_entity_id: BarcodeEntityId.LOG_TYPE
            } as CustomRendererProps<LogTypeRecord>
        },
        {
            key: 'tab3',
            tab: formatMessage('logTab.title'),
            disabled: false,
            withRecords: true,
            componentType: LogDataTab,
            componentProps: {
                barcode_entity_id: BarcodeEntityId.LOG_TYPE,
            } as CustomRendererProps<LogTypeRecord>
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('LogTypeTabController: constructor');
        this.state = {selectedTab: 'tab3'};
    }

    componentDidMount(): void {
        console.log('LogTypeTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('LogTypeTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {logTypeViewStore} = this.props;
        const {length} = logTypeViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'logType.card.title'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'logType.card.title.many'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'equipment.card.help.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {logTypeViewStore} = this.props;
        const {length} = logTypeViewStore.selectedEntities;
        if (length === 1) {
            return 'Actions for one';
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<LogTypeRecord>[] => {
        const {logTypeViewStore, coreStore} = this.props;
        const {length} = logTypeViewStore.selectedEntities;
        if (length === 1) {
            return this.tabItems.map((tabItem: TabItemContent<LogTypeRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {logTypeViewStore, coreStore} = this.props;
        const {selectedEntities, selectedEntity} = logTypeViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<LogTypeRecord> = this.tabItems.find((item: TabItemContent<LogTypeRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const isRecordCreator: boolean = selectedEntity.created_by === coreStore.user.user_id;
                const MyComponentWithRecord: any = withRecord<LogTypeRecord, LogDataTabProps>(MyComponentType, {
                    record: selectedEntity,
                    entity_id: selectedEntity.id,
                    isRecordCreator,
                    log_type: selectedEntity.id // LogTypeTabController specific code
                });
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <i>{intl().formatMessage({id: 'msg1'})} for {selectedEntities.length} record(s)</i>;
        } else {
            return <LogTypeTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
