import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";

export interface EquipmentRecord extends DataRecord, TreeFields, TagFields, AvatarFields {
    name: string;
    visible_number: string;
}

export function offlineEquipmentRecord(rec: EquipmentRecord): Partial<EquipmentRecord> {
    return {
        id: rec.id,
        short_name: `${rec.name} - deprecated. use name`,
        full_name: `${rec.name} - deprecated. use name`,
        name: rec.name,
        visible_number: rec.visible_number,
        description: 'deprecated',
        icon_file: 'deprecated',
        avatar: 'deprecated'
    }
}

export function equipmentHash(rec: EquipmentRecord): string {
    return rec.name.trim().toLowerCase();
}
