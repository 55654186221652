import React from 'react';
import {Card, Checkbox, List} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {REPORT_FORM_CONFIG} from './ReportFormConfig';
import {inject, observer} from 'mobx-react';
import {ReportViewStore} from '../../mobx/ReportViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddReportCommand from './commands/AddReportCommand';
import UpdateReportCommand from './commands/UpdateReportCommand';
import DeleteReportCommand from './commands/DeleteReportCommand';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";
import AvatarWidget from "../../../../../common/widgets/avatarWidget/AvatarWidget";

type StoreProps = {
    reportViewStore: ReportViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: ReportRecord;
}

@inject('reportViewStore')
@observer
export default class ReportListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('ReportListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as ReportRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {reportViewStore} = this.props;
        reportViewStore.universalFilter = value;
        reportViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedReportTreeRecord} = this.props.reportViewStore;
        const newReportRecord: ReportRecord = REPORT_FORM_CONFIG.getNewRecord();
        newReportRecord.tree_ids = [selectedReportTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newReportRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {reportViewStore} = this.props;
        const {selectedEntity} = reportViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    onDelete = async () => {
        console.log(`Delete:`);
        const {reportViewStore} = this.props;
        const {selectedEntity} = reportViewStore;
        const ReportRecords: ReportRecord[] = await new DeleteReportCommand().execute(selectedEntity);
        //unselect from selectedEntities ???
        reportViewStore.setReport(ReportRecords);
    };

    saveReportRecordCommand = async (upsertValue: UpsertValue<ReportRecord>): Promise<UpsertResponse<ReportRecord>> => {
        const {reportViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<ReportRecord> = isUpdate
            ? await new UpdateReportCommand(upsertValue).execute()
            : await new AddReportCommand(upsertValue).execute();
        reportViewStore.setReport(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveReportRecord = async (upsertValue: UpsertValue<ReportRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('ReportListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('ReportListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedReports: ReportRecord[]) => {
        const {reportViewStore} = this.props;
        reportViewStore.setSelectedEntities(selectedReports);
    };

    onSelectAll = () => {
        const {reportViewStore} = this.props;
        if (reportViewStore.selectedEntities.length === 0) {
            reportViewStore.selectedEntities = reportViewStore.visibleEntities;
        } else {
            reportViewStore.selectedEntities = [];
        }
    };

    render() {
        const {reportViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleReportCount, selectedEntities} = reportViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const isEmptyList: boolean = visibleReportCount === 0;

        return (
            <div className="entity-container">
                <Card size="small"
                      className="fm-card"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : reportViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'report.title.names'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={reportViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              onDelete={this.onDelete}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}
                              isDeleteEnabled={isOneEntitySelected && selectedEntities[0]?.report_type >= 10}/>}>
                    <div className="list-container">
                        <CheckedList selectedRecords={reportViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: ReportRecord) => (
                                         <List.Item.Meta title={item.name}
                                                         description={formatMessage(`dictionary.report_type.${item.report_type}`)}/>)}/>
                        {this.state.modalFormVisible && <ModalFormik config={REPORT_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveReportRecordCommand}
                                                                     onSave={this.handleOnSaveReportRecord}
                                                                     onCancel={this.handleOnModalFormClosed}/>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
