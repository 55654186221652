import React from 'react';
import LinkTo from '../../../routing/LinkTo';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {Button, Space} from 'antd';
import {intl} from '../../locale/IntlProvider';

interface Props {
    visible: boolean;
    isAdminUser: boolean;
}

export default class FMCoreMenu extends React.Component<Props> {
    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Space>
                <Button>
                    <LinkTo to={AppRoutes.ROOT}>
                        {intl().formatMessage({id: 'core.menu.root'})}
                    </LinkTo>
                </Button>
                <Button>
                    <LinkTo to={AppRoutes.FM_HOME}>
                        FM Home
                    </LinkTo>
                </Button>
                <Button>
                    <LinkTo to={AppRoutes.ACCOUNT_PROFILE}>
                        Account
                    </LinkTo>
                </Button>
                {this.props.isAdminUser && (
                    <Button>
                        <LinkTo to={AppRoutes.BARCODE}>
                            Barcodes
                        </LinkTo>
                    </Button>
                )}
            </Space>
        );
    }
}
