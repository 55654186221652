import {AxiosResponse} from "axios";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";

export default class ValidateRecordCommand extends Command<boolean, number> {
    execute(id: number): Promise<boolean> {
        return axiosGetInstance().post<boolean>(`/file/validate/${id}`)
            .then((response: AxiosResponse<boolean>) => response.data);
    }
}
