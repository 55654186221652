import React, {ChangeEvent} from 'react';
import BarCode from '../barcode/BarCode';
import {axiosGetInstance} from '../../utils/AxiosUtils';
import {AxiosResponse} from 'axios';
import {inject, observer} from 'mobx-react';
import {CoreStore} from '../../../pages/core/mobx/CoreStore';
import './style/barcodeGenerator.scss';
import {Input, Select} from 'antd';
import FMLoader from '../fmLoader/FMLoader';
import {BarcodeEntityRecord} from "fm-shared-data/src/types/db/core/BarcodeEntityRecord";
import {BarcodeActionRecord} from "fm-shared-data/src/types/db/core/BarcodeActionRecord";
import {fmBarcode, isActionBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";

const {Option} = Select;

type StoreProps = {
    coreStore: CoreStore;
};

interface Props extends StoreProps {
    test?: string;
}

interface State {
    barcodeData: string;
    dataIsLoaded: boolean;

    barcodeEntities: BarcodeEntityRecord[];
    barcodeActions: BarcodeActionRecord[];

    selectedEntityId: number;
    selectedActionId: number;
    id: number;
}

@inject('coreStore')
@observer
export default class BarcodeGeneratorController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            barcodeData: '',
            dataIsLoaded: false,
            barcodeEntities: [],
            barcodeActions: [],
            selectedEntityId: 0,
            selectedActionId: 0,
            id: 0,
        };
    }

    componentDidMount(): void {
        console.log('BarcodeGeneratorController: componentDidMount', new Date());
        Promise.all([
            axiosGetInstance().post<BarcodeEntityRecord[]>(`/app/barcode/entities`)
                .then((response: AxiosResponse<BarcodeEntityRecord[]>) => {
                    this.setState({barcodeEntities: response.data, selectedEntityId: response.data[0].id});
                }),
            axiosGetInstance().post<BarcodeActionRecord[]>(`/app/barcode/actions`)
                .then((response: AxiosResponse<BarcodeActionRecord[]>) => {
                    this.setState({barcodeActions: response.data, selectedActionId: response.data[0].id});
                })
        ]).then(() => {
            this.setState({dataIsLoaded: true});
        })
    }

    componentDidUpdate(): void {
        console.log('BarcodeGeneratorController: componentDidUpdate', new Date());
    }

    createBarcodeData = (): string => {
        return fmBarcode(
            this.props.coreStore.user.schema,
            this.state.selectedEntityId,
            isActionBarcode(this.state.selectedEntityId) ? this.state.selectedActionId.toString() : this.state.id.toString());
    };

    handleEntityChange = (value: any) => {
        this.setState({selectedEntityId: value});
    };

    handleActionChange = (value: any) => {
        this.setState({selectedActionId: value});
    };

    handleIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        this.setState({id: Number.parseInt(value) || 0});
    };


    render() {
        return (
            <div className={'barcodeGenerator'}>
                {!this.state.dataIsLoaded && <FMLoader/>}
                {this.state.dataIsLoaded && (
                    <div>
                        <h3>Состав штрих-кода (12 цифр):</h3>
                        <ul>
                            <li>3 - Код акаунта (account.id)</li>
                            <li>2 - Тип сущности (core.barcode_entity.id)</li>
                            <li>7 - Идентификатор сущности в таблице (id или core.barcode_action.id в случае, если это
                                штрих-код команда core.barcode_entity.id = 1)
                            </li>
                        </ul>
                        <div className={'values'}>
                            <Input disabled={true} value={this.props.coreStore.user.schema.replace('account_', '')}
                                   style={{width: 100, textAlign: 'center'}}/>
                            <Select defaultValue={this.state.selectedEntityId.toString()} style={{width: 200}}
                                    onChange={this.handleEntityChange}>
                                {this.state.barcodeEntities.map((barcodeEntity: BarcodeEntityRecord) => <Option
                                    key={barcodeEntity.id} value={barcodeEntity.id.toString()}
                                    title={barcodeEntity.description}>{barcodeEntity.entity}</Option>)}
                            </Select>

                            {isActionBarcode(this.state.selectedEntityId) && (
                                <Select defaultValue={this.state.selectedEntityId.toString()} style={{width: 400}}
                                        onChange={this.handleActionChange}>
                                    {this.state.barcodeActions.map((barcodeAction: BarcodeActionRecord) => <Option
                                        key={barcodeAction.id} value={barcodeAction.id.toString()}
                                        title={barcodeAction.description}>{barcodeAction.action}</Option>)}
                                </Select>
                            )}

                            {!isActionBarcode(this.state.selectedEntityId) && (
                                <Input value={this.state.id} onChange={this.handleIdChange}
                                       style={{width: 400, textAlign: 'center'}} maxLength={7}/>
                            )}
                        </div>
                        <BarCode data={this.createBarcodeData()} fontSizePx={96}/>
                    </div>
                )}

            </div>
        );
    }
}
