import React from "react";
import {FormFieldRender} from "./type/FormFieldRender";
import {DateFormItem} from "./formItems/DateFormItem";
import {DateTimeFormItem} from "./formItems/DateTimeFormItem";
import {RadioFormItem} from "./formItems/RadioFormItem";
import {CheckboxFormItem} from "./formItems/CheckboxFormItem";
import {SingleCheckboxFormItem} from "./formItems/SingleCheckboxFormItem";
import {SwitchFormItem} from "./formItems/SwitchFormItem";
import {SingleSelectFormItem} from "./formItems/SingleSelectFormItem";
import {MultiSelectFormItem} from "./formItems/MultiSelectFormItem";
import {AutoCompleteFormItem} from "./formItems/AutoCompleteFormItem";
import {TextFormItem} from "./formItems/TextFormItem";
import {MentionsFormItem} from "./formItems/MentionsFormItem";
import {ColorPickerFormItem} from "./formItems/ColorPickerFormItem";
import {InputFormItem} from "./formItems/InputFormItem";
import {InputNumberFormItem} from "./formItems/InputNumberFormItem";
import {RateFormItem} from "./formItems/RateFormItem";

export function getFormFieldRender(renderType: FormFieldRender): React.ComponentType<any> {
    switch (renderType) {
        case FormFieldRender.DATE:
            return DateFormItem;
        case FormFieldRender.DATETIME:
            return DateTimeFormItem;
        case FormFieldRender.RADIO:
            return RadioFormItem;
        case FormFieldRender.CHECKBOX:
            return CheckboxFormItem;
        case FormFieldRender.SINGLE_CHECKBOX:
            return SingleCheckboxFormItem;
        case FormFieldRender.SWITCH:
            return SwitchFormItem;
        case FormFieldRender.RATE:
            return RateFormItem;
        case FormFieldRender.SINGLE_SELECT:
            return SingleSelectFormItem;
        case FormFieldRender.MULTI_SELECT:
            return MultiSelectFormItem;
        case FormFieldRender.AUTO_COMPLETE:
            return AutoCompleteFormItem;
        case FormFieldRender.TEXT:
            return TextFormItem;
        case FormFieldRender.MENTIONS:
            return MentionsFormItem;
        case FormFieldRender.COLOR_PICKER:
            return ColorPickerFormItem;
        case FormFieldRender.NUMBER:
            return InputNumberFormItem;
        default:
            return InputFormItem;
    }
}