import React from 'react';
import {Avatar} from 'antd';
import './style/style.scss';
import {getEmployeeAvatarByData} from "../../../pages/account/employee/EmployeeUtils";
import AvatarWidget from "../avatarWidget/AvatarWidget";
import {getTooltipAvatar} from "../tooltipContent/TooltipContentUtils";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {CEDAvatarFields} from "fm-shared-data/src/types/db/common/CEDAvatarFields";

interface Props<T extends DataRecord & CEDAvatarFields> {
    record: T;
    size?: 'large' | 'small' | 'default' | number;
    className?: string;
}

export default class CEDAvatars<T extends CEDAvatarFields & DataRecord> extends React.Component<Props<T>> {

    render() {
        const {size = 'default', className = '', record} = this.props;
        const {
            created_on, created_by_avatar, created_by_name, created_by_gender,
            edited_on, edited_by_avatar, edited_by_name, edited_by_gender,
            deleted_on, deleted_by_avatar, deleted_by_name, deleted_by_gender
        } = record;
        return (
            <Avatar.Group className={`ced-avatar-group ${className}`}>
                <AvatarWidget size={size} src={getEmployeeAvatarByData(created_by_gender, created_by_avatar)}
                              className={'avatar-widget__created'}
                              tooltip={getTooltipAvatar('CEDAvatar.created.tooltip', created_by_avatar, created_by_gender!, created_by_name!, created_on!)}
                />
                {edited_on && (
                    <AvatarWidget size={size} src={getEmployeeAvatarByData(edited_by_gender!, edited_by_avatar)}
                                  className={'avatar-widget__edited'}
                                  tooltip={getTooltipAvatar('CEDAvatar.edited.tooltip', edited_by_avatar!, edited_by_gender!, edited_by_name!, edited_on!)}
                    />
                )}
                {deleted_on && (
                    <AvatarWidget size={size} src={getEmployeeAvatarByData(deleted_by_gender!, deleted_by_avatar)}
                                  className={'avatar-widget__deleted'}
                                  tooltip={getTooltipAvatar('CEDAvatar.deleted.tooltip', deleted_by_avatar!, deleted_by_gender!, deleted_by_name!, deleted_on!)}
                    />
                )}
            </Avatar.Group>
        );
    }
}
