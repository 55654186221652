import {ParentDataRecord} from "fm-shared-data/src/types/db/common/ParentDataRecord";
import {ParentTreeData} from "fm-shared-data/src/types/db/common/ParentTreeData";

export function convertParentDataRecordsToParentData<T extends ParentDataRecord>(parentDataRecords: T[]): ParentTreeData<T>[] {
    const result: ParentTreeData<T>[] = [];
    const map: Map<number, ParentTreeData<T>> = new Map<number, ParentTreeData<T>>();
    parentDataRecords.forEach((record: T) => {
        if (!record.parent_id) {
            const newRec: ParentTreeData<T> = {record, children: []};
            result.push(newRec);
            map.set(record.id, newRec);
        } else {
            const newSubRec: ParentTreeData<T> = {record, children: []};
            const existRec: ParentTreeData<T> | undefined = map.get(record.parent_id);
            if (existRec) {
                existRec.children.push(newSubRec);
                map.set(record.id, newSubRec);
            }
        }
    });
    return result;
}