import React from 'react';
import Icofont from 'react-icofont';
import './style/treeNodeRenderer.scss';
import {NameIconColorFields} from "fm-shared-data/src/types/db/common/NameIconColorFields";

export default function NameIconColorRenderer<R extends NameIconColorFields>({name, icon, color}: R): React.ReactNode {
    return (
        <div className="tree-title" style={{color: color}}>
            {icon && <Icofont icon={`${icon}`} size={"1"}/>}
            <div className="tree-title__name">{name}</div>
        </div>
    );
}
