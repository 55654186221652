export enum ReportType {
    INTERNAL_PIVOT = 1,
    INTERNAL_BADGE = 2,
    EXTERNAL_JASPER = 10,
}

// List for DICTIONARY_REPORT_TYPE
export const ReportTypeTypes: ReportType[] = [
    ReportType.INTERNAL_PIVOT,
    ReportType.INTERNAL_BADGE,
    ReportType.EXTERNAL_JASPER,
];
