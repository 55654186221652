import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {WorkViewStore} from './mobx/WorkViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import WorkPageView from "./WorkPageView";
import {WorkURLData} from "./WorkURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    workViewStore: WorkViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'workViewStore')
@observer
export default class WorkController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('WorkPageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('WorkPageController: componentDidMount', performance.now());
        const {workViewStore} = this.props;
        if (!workViewStore.isDataLoaded) {
            await workViewStore.loadData();
        } else {
            const urlData: WorkURLData | undefined = decryptObject<WorkURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.workBarCode)) {
                workViewStore.universalFilter = urlData!.workBarCode!;
                workViewStore.clearFilters();
                goToPage(AppRoutes.FM_WORK);
            } else if (workViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                workViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {workViewStore} = this.props;
        const urlData: WorkURLData | undefined = decryptObject<WorkURLData>(this.props.match.params.data);
        console.log('WorkPageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', workViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.workBarCode)) {
            workViewStore.universalFilter = urlData!.workBarCode!;
            workViewStore.clearFilters();
            goToPage(AppRoutes.FM_WORK);
        } else if (isEmpty(urlData?.workBarCode) && !workViewStore.isViewReady) {
            workViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'WorkPageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('WorkPageController: componentDidCatch', performance.now());
    }

    render() {
        const {workViewStore} = this.props;
        const {isViewReady, isDataLoaded} = workViewStore;

        if (!isDataLoaded) {
            return <p>Load work data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating work view....</p>;
        }

        return (
            <WorkPageView/>
        );
    }
}
