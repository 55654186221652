import React from 'react';
import {Card, message, Popconfirm, Spin, Table, Tooltip} from 'antd';
import ModalFormik from '../modalForm/ModalFormik';
import SCRUDActions from '../scrudActions/SCRUDActions';
import './style/style.scss';
import {ModalFormConfig} from '../modalForm/type/ModalFormConfig';
import DataProvider from '../modalForm/type/DataProvider';
import {ColumnsType, ColumnType, TableRowSelection} from 'antd/lib/table/interface';
import {Key} from "rc-table/lib/interface";
import {HistoryOutlined} from "@ant-design/icons";
import {formatMessage} from "../../locale/IntlProvider";
import RefreshControl from "../refreshControl/RefreshControl";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";

// interface Props<T extends DataRecord & CEDAvatarFields & ValidatedFields, P extends DataRecord = any> {
interface Props<T extends DataRecord, P extends DataRecord = any> {
    hideCard?: boolean;
    title?: React.ReactNode;

    dataSource: T[];
    getColumns: () => ColumnsType<T>;
    rowKey: (record: T) => Key

    tableTitle?: (data: readonly T[]) => React.ReactNode;

    parentRecord?: P;

    modalFormConfig?: ModalFormConfig<T>;

    isAddEnabled?: boolean;
    isEditEnabled?: boolean;
    isDeleteEnabled?: boolean;
    saveCommand?: (upsertValue: UpsertValue<T>) => Promise<UpsertResponse<T>>;
    deleteCommand?: (record: T) => Promise<UpsertResponse<T>>;
    restoreCommand?: (record: T) => Promise<UpsertResponse<T>>;

    deleteEnabledRule?: DataProvider<boolean, T>;

    isAdminUser: boolean;

    scroll?: any;

    refreshCommand?: () => Promise<any>;
    rowSelection?: TableRowSelection<T>;
}

interface State<T extends DataRecord> {
    commandInProgress: boolean;
    modalFormVisible: boolean;
    modalFormData: T;
    selectedRowKeys: Key[];
}

// export default class RecordTable<T extends DataRecord & CEDAvatarFields & ValidatedFields, P extends DataRecord = any> extends React.Component<Props<T, P>, State<T>> {
export default class RecordTable<T extends DataRecord, P extends DataRecord = any> extends React.Component<Props<T, P>, State<T>> {

    state = {
        commandInProgress: false,
        isDataLoaded: false,
        modalFormVisible: false,
        modalFormData: {} as T,
        selectedRowKeys: []
    };

    handleOnAdd = () => {
        this.setState({modalFormVisible: true, modalFormData: this.props.modalFormConfig!.getNewRecord(this.props.parentRecord)});
    };

    handleOnEdit = (record: T) => () => {
        if (this.props.isEditEnabled) {
            this.setState({modalFormVisible: true, modalFormData: record});
        }
    };

    handleOnDelete = (record: T) => () => {
        this.setState({commandInProgress: true});
        this.props.deleteCommand!(record).then(() => {
            this.setState({commandInProgress: false});
        })
    };

    handleOnRestore = (record: T) => () => {
        this.setState({commandInProgress: true});
        this.props.restoreCommand!(record).then(() => {
            this.setState({commandInProgress: false});
            message.success({content: formatMessage('restore_record.completed')}, 3);
        })
    };

    handleOnFormikCancel = () => {
        this.setState({modalFormVisible: false});
    };

    handleOnSaveRecord = async (upsertValue: UpsertValue<T>) => {
        this.handleOnFormikCancel();
    };

    private actionColumn: ColumnType<T> = {
        dataIndex: 'actions',
        width: '100px',
        key: 'actions',
        render: (value: any, record: T, index: number): React.ReactNode => {
            return (
                <SCRUDActions onEdit={this.handleOnEdit(record)}
                              onDelete={this.handleOnDelete(record)}
                              isEditEnabled={this.props.isEditEnabled}
                              isDeleteEnabled={this.props.isDeleteEnabled}
                              className={'scrud-container__align-right'}>
                    {this.props.isAdminUser && !!record.deleted_on && (
                        <Tooltip title={formatMessage('restore_record.tooltip')} placement={"top"}>
                            <Popconfirm
                                key={`restore_${record.id}`}
                                placement={'right'}
                                okType={'primary'}
                                title={formatMessage('restore_record.confirmation')}
                                okText={formatMessage('common.yes')}
                                cancelText={formatMessage('common.no')}
                                onConfirm={this.handleOnRestore(record)}>
                                <HistoryOutlined className={'scrud-container__icon'}/>
                            </Popconfirm>
                        </Tooltip>
                    )}
                </SCRUDActions>
            );
        }
    };

    onRowSelectChange = (selectedRowKeys: Key[], selectedRows: T[]) => {
        this.setState({selectedRowKeys});
    };

    handleOnRefresh = (): Promise<any> => {
        this.setState({commandInProgress: true});
        return this.props.refreshCommand!().then(() => {
            this.setState({commandInProgress: false});
            return true;
        })
    };

    render() {
        const {deleteEnabledRule, modalFormConfig, saveCommand, deleteCommand, restoreCommand, hideCard, refreshCommand, rowSelection} = this.props;
        const columns: ColumnsType<T> = this.props.getColumns();
        if (modalFormConfig || saveCommand || deleteCommand || restoreCommand) {
            columns.push(this.actionColumn);
        }

        // const rowSelection: TableRowSelection<T> = {
        //     selectedRowKeys: this.state.selectedRowKeys,
        //     onChange: this.onRowSelectChange,
        // };

        const getTable = (): React.ReactNode => {
            return (
                <Table dataSource={this.props.dataSource}
                       columns={columns}
                       size={'small'}
                       rowKey={this.props.rowKey}
                       title={this.props.tableTitle}
                    // rowSelection={rowSelection}
                       pagination={false}
                       sticky={true}
                    // tableLayout={'fixed'}
                       className={'record-table'}
                       scroll={this.props.scroll}
                       rowSelection={rowSelection}
                       onRow={(record: T, index?: number) => {
                           return {
                               onDoubleClick: this.handleOnEdit(record)
                           }
                       }}
                />
            );
        };

        return (
            <Spin spinning={this.state.commandInProgress}>
                <>
                    {hideCard && (
                        getTable()
                    )}
                    {!hideCard && (
                        <Card size='small'
                              title={this.props.title}
                              bordered={false}
                              extra={<SCRUDActions isAddEnabled={this.props.isAddEnabled}>
                                  {refreshCommand && <RefreshControl onRefresh={this.handleOnRefresh}/>}
                              </SCRUDActions>}>
                            {getTable()}
                        </Card>
                    )}
                    {modalFormConfig && this.state.modalFormVisible && <ModalFormik config={modalFormConfig}
                                                                                    initialValues={this.state.modalFormData}
                                                                                    saveCommand={saveCommand}
                                                                                    deleteCommand={deleteCommand}
                                                                                    deleteEnabledRule={deleteEnabledRule}
                                                                                    onSave={this.handleOnSaveRecord}
                                                                                    onCancel={this.handleOnFormikCancel}/>
                    }
                </>
            </Spin>
        );
    }
}
