import {AxiosResponse} from "axios";
import Command from "../../../../../../../../../common/types/Command";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

export default class RequestConfirmationEmployeeEmailCommand extends Command<boolean, EmployeeEmailRecord> {
    execute(record: EmployeeEmailRecord): Promise<boolean> {
        return axiosGetInstance().post<boolean>(`/employee/email/send_confirmation`, record)
            .then((response: AxiosResponse<boolean>) => response.data);
    }
}
