import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {restoreReportPredefinedFilter} from "../components/filters/PredefinedReportFilters";
import LoadTagsCommand from "../../commands/LoadTagsCommand";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {ReportRecord} from 'fm-shared-data/src/types/db/account/report/ReportRecord';

export class ReportViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<ReportRecord>[] = [];

    visibleEntities: ReportRecord[] = [];
    selectedEntities: ReportRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    reportTags: string[] = [];
    selectedReportTags: string[] = [];
    isReportTagsLoaded: Date | undefined;
    isReportTagsLoading: boolean = true;

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            reportTags: observable,
            selectedReportTags: observable,
            isReportTagsLoaded: observable,
            isReportTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateReportTreeRecord: action,
            setSelectedReportTags: action,
            refreshReportTags: action,

            selectedEntityIds: computed,
            visibleReportCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            reportTreeData: computed,
            selectedReportTreeRecord: computed,

            setReport: action,
        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleReportCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isReportLoaded && !!this.dataStore.isReportTreeLoaded && !!this.isReportTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleReportCount > 0 && this.visibleReportCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.reportTreeRootId;
    }

    get reportTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.reportTreeData;
    }

    get selectedReportTreeRecord(): TreeRecord {
        return this.dataStore.reportTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isReportLoaded) {
            loaders.push(this.dataStore.loadReport());
        }
        if (!this.dataStore.isReportTreeLoaded) {
            loaders.push(this.dataStore.loadReportTree());
        }
        if (!this.isReportTagsLoaded) {
            loaders.push(this.loadReportTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadReportTags() {
        this.isReportTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('report').execute();
        runInAction(() => {
            this.reportTags = tags;
            this.isReportTagsLoading = false;
            this.isReportTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.reportTreeData, this.dataStore.reportTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.REPORT_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.REPORT_PREDEFINED_FILTERS);

        //tags:
        this.selectedReportTags = [];
        LocalStorage.removeItem(LocalStorageKey.REPORT_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (report: ReportRecord) => (report.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: ReportRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedReportTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (report: ReportRecord) => this.selectedReportTags.filter(Boolean).every((eTag: string) => report.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.reportTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<ReportRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedReportTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.report
            : this.dataStore.report.filter((record: ReportRecord) => {
                return filters.every((fun: any) => fun(record));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: ReportRecord | undefined = this.visibleEntities.find((report: ReportRecord) => report.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateReportTreeRecord(reportTreeRecords: TreeRecord[]) {
        this.dataStore.setReportTree(reportTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.reportTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.reportTreeData, this.dataStore.reportTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedReportTags(tags: string[]) {
        this.selectedReportTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshReportTags(trySelectId?: number) {
        await this.loadReportTags();
        runInAction(() => {
            this.selectedReportTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<ReportRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setReport(data: ReportRecord[], trySelectId?: number) {
        this.dataStore.setReport(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedReports: ReportRecord[]) {
        this.selectedEntities = selectedReports;
    }

    get selectedEntity(): ReportRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.reportTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.REPORT_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.reportTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.reportTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.REPORT_TREE_NODES) || this.dataStore.reportTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreReportPredefinedFilter();

        // tags:
        this.selectedReportTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.REPORT_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.reportTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.REPORT_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }

        if (isBarCode(this.universalFilter)) {
            const entityId: number = Number.parseInt(this.universalFilter.substr(-7));
            this.visibleEntities = this.visibleEntities.filter((report: ReportRecord) => entityId === report.id);
        } else { // full text search
            this.visibleEntities = this.visibleEntities.filter((report: ReportRecord) => {
                const searchString: string = report.name.toLowerCase();
                return searchString.includes(this.universalFilter.toLowerCase());
            });
        }
        // this.selectedEntities = []; // clear any selections
    }

}
