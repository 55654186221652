import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";
import {NameIconColorFields} from "../../common/NameIconColorFields";

export interface CalendarRecord extends DataRecord, TreeFields, TagFields, AvatarFields, NameIconColorFields {
    comment?: string;
}

export function offlineCalendarRecord(rec: CalendarRecord): Partial<CalendarRecord> {
    return {
        id: rec.id,
        name: rec.name,
        icon: rec.icon,
        color: rec.color,
        avatar: 'deprecated'
    }
}

export function calendarHash(rec: CalendarRecord): string {
    return rec.name.trim().toLowerCase();
}
