import React from 'react';
import ErrorPageView from './ErrorPageView';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import {XHRErrorURLData} from "fm-shared-data/src/types/api/XHRErrorURLData";
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";

export default class ErrorPageController extends React.Component<URLRouteProps> {
    render() {
        const {data} = this.props.match.params;
        const defaultUrlData: XHRErrorURLData = {url: 'n/a', error: `An error occurred with an incorrect/empty data format: '${data}'`};
        const urlData: XHRErrorURLData = data
            ? decryptObject<XHRErrorURLData>(data) || defaultUrlData
            : defaultUrlData;
        return <ErrorPageView data={urlData}/>;
    }
}
