import React from 'react';
import {generatePath, Link} from 'react-router-dom';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {encryptString} from "fm-shared-utils/src/utils/crypto/CryptoUtils";

interface Props {
    to: AppRoutes;
    params?: any;
}

export default class LinkTo extends React.Component<Props> {
    render() {
        let to = '';
        if (this.props.params) {
            to = generatePath(this.props.to, {
                data: encryptString(JSON.stringify(this.props.params)),
            });
        } else if (this.props.to !== AppRoutes.ROOT) {
            to = generatePath(this.props.to);
        }
        return <Link to={to}>{this.props.children}</Link>;
    }
}
