import {ModalFormConfig} from '../../../../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {FormFieldRender} from '../../../../../../../../common/widgets/modalForm/type/FormFieldRender';
import {NonEmptyValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import AddressTypeAutoCompleteProvider from './AddressTypeAutoCompleteProvider';
import EmployeeAddressClipboard from './EmployeeAddressClipboard';
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";

export const EMPLOYEE_ADDRESS_FORM_CONFIG: ModalFormConfig<EmployeeAddressRecord, EmployeeRecord> = {
    title: 'employee_address.modalForm.title',
    tableName: 'employee_address',
    getNewRecord: (employee?: EmployeeRecord): EmployeeAddressRecord => {
        return {
            employee_id: employee!.id,
            confirmed: false
        } as EmployeeAddressRecord;
    },
    clipboardText: new EmployeeAddressClipboard(),
    fieldConfig: [
        {
            label: 'employee_address.field.address_type',
            field_name: 'address_type',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 2})],
            config: {
                autoCompleteOptionsProvider: new AddressTypeAutoCompleteProvider(),
                inputProps: {
                    placeholder: 'Home, Registration, etc',
                    maxLength: 20
                }
            },
        },
        {
            label: 'employee_address.field.address',
            field_name: 'address',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator({min: 10})],
            config: {
                inputProps: {
                    placeholder: 'Country, ZipCode, City, Street, building, atp',
                    maxLength: 100
                }
            }
        },
        {
            label: 'employee_address.field.confirmed',
            field_name: 'confirmed',
            renderType: FormFieldRender.SWITCH,
        },
        {
            label: 'file_record.field.comment',
            field_name: 'comment',
            optional: true,
            renderType: FormFieldRender.TEXT,
            config: {
                inputProps: {
                    autoSize: {minRows: 2, maxRows: 4}
                }
            }
        }
    ]
};
