import DataProvider from "../../../../../../../../../common/widgets/modalForm/type/DataProvider";
import {FieldValidatorResult} from "../../../../../../../../../common/widgets/modalForm/validators/FieldValidatorTypes";
import {formatMessage} from "../../../../../../../../../common/locale/IntlProvider";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

export default class EmployeeEmailReadonlyProvider extends DataProvider<FieldValidatorResult, EmployeeEmailRecord> {
    get(email: EmployeeEmailRecord): FieldValidatorResult {
        return email?.confirmed_on ? formatMessage('employee_email.field.email_confirmed_readonly') : null;
    }
}
