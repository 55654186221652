import React from 'react';
import {inject, observer} from 'mobx-react';
import {EmployeeViewStore} from '../../../../../mobx/EmployeeViewStore';
import {Button, Col, message, Row, Space, Spin} from 'antd';
import {DataStore} from '../../../../../../../../app/mobx/DataStore';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ResetPasswordCommand from '../../../../../../../common/login/resetPassword/ResetPasswordCommand';
import {LocalStorage, LocalStorageKey} from '../../../../../../../../common/utils/LocalStorageUtils';
import ModalFormik from '../../../../../../../../common/widgets/modalForm/ModalFormik';
import {EMPLOYEE_WEB_ACCESS_FORM_CONFIG} from './EmployeeWebAccessFormConfig';
import UpdateEmployeeCommand from '../../../../list/commands/UpdateEmployeeCommand';
import {formatMessage} from '../../../../../../../../common/locale/IntlProvider';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {SignupBody} from "fm-shared-data/src/types/auth/SignupBody";
import {Role} from "fm-shared-data/src/types/db/core/Role";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";

type StoreProps = {
    dataStore: DataStore;
    employeeViewStore: EmployeeViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    employee?: EmployeeRecord;

    commandInProgress: boolean;
    resetPasswordSent: boolean;
}

@inject('employeeViewStore', 'dataStore')
@observer
export default class EmployeeTabWebAccess extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Props) {
        super(props);
        // const {employeeViewStore} = this.props;
        // const {selectedEntity} = employeeViewStore;

        this.state = {
            modalFormVisible: false,
            employee: undefined,
            commandInProgress: false,
            resetPasswordSent: false
        };
    }

    componentDidMount() {
        const {dataStore} = this.props;
        if (!dataStore.isEmployeeEmailLoaded) {
            dataStore.loadEmployeeEmail();
        }
    }

    handleCloseAccess = () => {
        const {employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;
        const updatedEmployee: EmployeeRecord = {...selectedEntity};
        updatedEmployee.can_login = false;
        updatedEmployee.email = undefined;
        const upsertValue: UpsertValue<EmployeeRecord> = {
            initialValue: selectedEntity,
            updatedValue: updatedEmployee
        };
        this.setState({commandInProgress: true});
        this.saveEmployeeRecordCommand(upsertValue).then(() => {
            this.setState({commandInProgress: false});
        });
    };

    handleResetPassword = () => {
        const {employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;
        const email: string = selectedEntity.email!.trim().toLowerCase();
        const locale: string = LocalStorage.getItem(LocalStorageKey.LOCALE)!;
        const signupBody: SignupBody = {
            email,
            locale
        };

        this.setState({commandInProgress: true});

        new ResetPasswordCommand().execute(signupBody).then(() => {
            this.setState({commandInProgress: false, resetPasswordSent: true});
            message.success({content: 'Please check email box.'});
        });
    };

    handleUpdateAccess = () => {
        const {employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;
        const newEmployee: EmployeeRecord = {...selectedEntity};
        if (!newEmployee.can_login) {
            newEmployee.can_login = true;
            newEmployee.roles = [Role.USER];
        }
        this.setState({modalFormVisible: true, employee: newEmployee});
    };

    handleOnSaveRecord = (upsertValue: UpsertValue<EmployeeRecord>) => {
        this.setState({modalFormVisible: false, employee: undefined});
    };

    handleOnFormikCancel = () => {
        this.setState({modalFormVisible: false, employee: undefined});
    };

    saveEmployeeRecordCommand = async (upsertValue: UpsertValue<EmployeeRecord>): Promise<UpsertResponse<EmployeeRecord>> => {
        const {dataStore, employeeViewStore} = this.props;
        const upsertResponse: UpsertResponse<EmployeeRecord> = await new UpdateEmployeeCommand(upsertValue).execute();
        dataStore.setEmployee(upsertResponse.list);
        employeeViewStore.applyFiltersToView(employeeViewStore.selectedEntity.id);
        return Promise.resolve(upsertResponse);
    };

    render() {
        const {dataStore, employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;

        if (!dataStore.isEmployeeEmailLoaded) {
            return <Spin/>;
        }
        return (
            <Spin spinning={this.state.commandInProgress}>
                <Row gutter={[20, 20]}>
                    <Col span={16}>
                        <Space>
                            {selectedEntity.can_login && <CheckOutlined style={{color: 'green'}}/>}
                            {!selectedEntity.can_login && <CloseOutlined style={{color: 'red'}}/>}
                            {formatMessage(selectedEntity.can_login ? 'employee_web_access.true' : 'employee_web_access.false')}
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Button danger={true}
                                    disabled={!selectedEntity.can_login}
                                    onClick={this.handleCloseAccess}>
                                Запретить доступ
                            </Button>
                            <Button disabled={!selectedEntity.can_login || this.state.resetPasswordSent}
                                    onClick={this.handleResetPassword}>
                                Сбросить пароль
                            </Button>
                            <Button type={'primary'} onClick={this.handleUpdateAccess}>
                                {selectedEntity.can_login ? 'Изменить доступ' : 'Открыть доступ'}
                            </Button>
                        </Space>
                    </Col>

                    {this.state.modalFormVisible && <ModalFormik config={EMPLOYEE_WEB_ACCESS_FORM_CONFIG}
                                                                 initialValues={this.state.employee!}
                                                                 saveCommand={this.saveEmployeeRecordCommand}
                                                                 onSave={this.handleOnSaveRecord}
                                                                 onCancel={this.handleOnFormikCancel}/>
                    }

                </Row>
            </Spin>
        );
    }
}
