import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";
import ReportNameOptionsProvider from "./ReportNameOptionsProvider";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";
import {DICTIONARY_REPORT_TYPE} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {UniqReportValidator} from '../../../../../common/widgets/modalForm/validators/sync/UniqReportValidator';

export const REPORT_FORM_CONFIG: ModalFormConfig<ReportRecord> = {
    title: 'report.title.name',
    tableName: 'report',
    getNewRecord: () => {
        return {
            name: '',
            report_type: 1,
            source: "",
        } as ReportRecord;
    },
    fieldConfig: [
        {
            label: 'report.field.report_type',
            field_name: 'report_type',
            renderType: FormFieldRender.RADIO,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_REPORT_TYPE),
                inputProps: {
                    optionType: 'button'
                }
            }
        },
        {
            label: 'equipment.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new ReportNameOptionsProvider()
            }
        },
        {
            label: 'report.field.source',
            field_name: 'source',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator()],
        },
    ],
    syncValidators: [new UniqReportValidator()]
};
