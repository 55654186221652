import {DataRecord} from '../../common/DataRecord';
import {CEDAvatarFields} from "../../common/CEDAvatarFields";
import {TreeFields} from '../../common/TreeFields';
import {TagFields} from '../../common/TagFields';
import {NameIconColorFields} from '../../common/NameIconColorFields';

export interface ReportRecord extends DataRecord, NameIconColorFields, TreeFields, TagFields, CEDAvatarFields {
    report_type: number; // ReportType
    source: string;
}

export function reportHash(rec: ReportRecord): string {
    return rec.name.trim().toLowerCase();
}
