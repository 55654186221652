import React from 'react';
import {Result, Spin} from "antd";
import {URLRouteProps} from "../../../../routing/URLRouteProps";
import {formatMessage} from "../../../../common/locale/IntlProvider";
import ConfirmEmployeeEmailCommand from "./commands/ConfirmEmployeeEmailCommand";
import Button from "antd/lib/button";
import {goToPage} from "../../../../routing/AppRouting";
import {EmployeeEmailConfirmationURLData} from "fm-shared-data/src/types/api/web/EmployeeEmailConfirmationURLData";
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

interface State {
    emailConfirmed: boolean;
    confirmationInProgress: boolean;
    errorKeyResponse?: string;
}

export default class EmployeeConfirmationPage extends React.Component<URLRouteProps, State> {
    state: State = {confirmationInProgress: true, emailConfirmed: false};

    componentDidMount() {
        this.confirmEmail();
    }

    confirmEmail = () => {
        const urlData: EmployeeEmailConfirmationURLData | undefined = decryptObject<EmployeeEmailConfirmationURLData>(this.props.match.params.data);
        if (urlData) {
            new ConfirmEmployeeEmailCommand().execute(urlData).then(() => {
                this.setState({confirmationInProgress: false, emailConfirmed: true});
            }, (error: AxiosErrorResponseData) => {
                this.setState({confirmationInProgress: false, errorKeyResponse: error.message});
            });
        }
    }

    tryAgain = () => {
        this.setState({confirmationInProgress: true, errorKeyResponse: undefined});
        this.confirmEmail();
    }

    gotoHome = () => {
        goToPage(AppRoutes.ROOT, {}, true);
    };

    render() {
        const urlData: EmployeeEmailConfirmationURLData | undefined = decryptObject<EmployeeEmailConfirmationURLData>(this.props.match.params.data);
        return (
            <>
                {this.state.confirmationInProgress && <Result status="info"
                                                              title={formatMessage('employee_email.field.confirmed_on')}
                                                              subTitle={<Spin/>}/>
                }
                {this.state.errorKeyResponse && <Result status="warning"
                                                        title={formatMessage(this.state.errorKeyResponse)}
                                                        extra={[
                                                            <Button key="try_again" onClick={this.tryAgain}>
                                                                Try again
                                                            </Button>,
                                                            <Button type="primary" key="goto" onClick={this.gotoHome}>Back Home</Button>
                                                        ]}/>
                }
                {this.state.emailConfirmed && <Result status="success"
                                                      title={formatMessage('employee_email.field.confirmed_yes')}
                                                      subTitle={urlData?.email}
                                                      extra={
                                                          <Button type="primary" key="goto" onClick={this.gotoHome}>
                                                              Back Home
                                                          </Button>
                                                      }/>
                }
            </>
        );
    }
}
