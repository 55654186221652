import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";

// work_payment_type_id integer   NOT NULL, -- 1 - сдельная оплата, 2 - повременная оплата
export enum WORK_PAYMENT_TYPE {
    PIECEWORK = 1,
    TIME_BASED = 2
}

export interface WorkRecord extends DataRecord, TreeFields, TagFields, AvatarFields {
    name: string;
    unit_id: number;
    work_payment_type_id: number; // WORK_PAYMENT_TYPE
    price: number;

    norm?: number;
    price_above_norm?: number;
    weight_in_kg?: number;

    work_unit_name: string;
    payment_type_name: string;
}

export function offlineWorkRecord(rec: WorkRecord): Partial<WorkRecord> {
    return {
        id: rec.id,
        full_name: `${rec.name} - deprecated. use name`,    // TODO remove from mobile. use name
        short_name: `${rec.name} - deprecated. use name`,   // TODO remove from mobile. use name
        name: rec.name,
        description: 'deprecated',
        work_unit_name: rec.work_unit_name,         // TODO remove work_done_unit_name
        work_done_unit_name: rec.work_unit_name,    // TODO use on mobile work_unit_name
        work_payment_type_id: rec.work_payment_type_id,
        payment_type_name: rec.payment_type_name,
        payment_type_full_name: `${rec.payment_type_name} - deprecated. use payment_type_name`,
        payment_type_short_name: `${rec.payment_type_name} - deprecated. use payment_type_name`,
        price: rec.price,
        // norm: rec.norm,
        // price_above_norm: rec.price_above_norm,
        // weight_in_kg: rec.weight_in_kg,
        // TODO: replace avatar with data to avatar_file_name with file name
        avatar: 'deprecated'
    }
}

export function workHash(rec: WorkRecord): string {
    return rec.name.trim().toLowerCase();
}
