import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";
import isEmpty from "lodash/isEmpty";

export interface NonEmptyValidatorConfig {
    invalidMessageKey: string;
}

export class NonEmptyValidator extends SyncValidator<string | any[] | Date | undefined, NonEmptyValidatorConfig> {

    constructor(config?: NonEmptyValidatorConfig) {
        super(config || {invalidMessageKey: 'modalForm.validation.required'});
    }

    validate(value: string | any[] | Date | undefined): FieldValidatorResult {
        if (value instanceof Date) {
            return null;
        }
        if (typeof value === "object") {
            return isEmpty(value) ? formatMessage(this.config.invalidMessageKey) : null;
        }
        if (typeof value === "number") {
            return value >= 0 ? null : formatMessage(this.config.invalidMessageKey);
        }
        return !value || isEmpty(value.trim()) ? formatMessage(this.config.invalidMessageKey) : null;
    }

}
