import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {stores} from "../../../../app/mobx/Stores";
import DataProvider from "../../modalForm/type/DataProvider";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";

export class DataStoreEquipmentProvider implements DataProvider<DictionaryRawRecord<EquipmentRecord>[]> {

    get(): DictionaryRawRecord<EquipmentRecord>[] {
        return stores.dataStore.equipment.map((field: EquipmentRecord) => {
            return {
                id: field.id,
                name: field.name,
                record: field
            };
        }).sort((a: DictionaryRawRecord<EquipmentRecord>, b: DictionaryRawRecord<EquipmentRecord>): number => a.name.localeCompare(b.name));
    }
}
