import DataProvider from '../modalForm/type/DataProvider';
import {AutoCompleteOptionType} from '../modalForm/formItems/AutoCompleteOptionType';
import {stores} from '../../../app/mobx/Stores';

export default class FileTypeAutoCompleteProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        return dataStore.fileTypes.map((value: string) => {
            return {value};
        });
    }
}
