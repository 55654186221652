import React from 'react';
import {Col, Divider, Row} from 'antd';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {EmployeeViewStore} from '../../../../mobx/EmployeeViewStore';
import UpdateTreeIdsCommand from '../../../../../commands/tree/UpdateTreeIdsCommand';
import ManageTreeWidget from './widgets/ManageTreeWidget';
import BarCode from '../../../../../../../common/widgets/barcode/BarCode';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import ManageTagWidget from './widgets/ManageTagWidget';
import UpdateTagsCommand from '../../../../../commands/UpdateTagsCommand';
import ManageAvatarWidget from './widgets/ManageAvatarWidget';
import {Point} from 'react-easy-crop/types';
import UpdateAvatarCommand from '../../../../../commands/UpdateAvatarCommand';
import {getEmployeeAvatar} from '../../../../EmployeeUtils';
import CEDAvatarsController from '../../../../../../../common/widgets/CEDAvatars/CEDAvatarsController';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import EmployeeTabWebAccess from "./webAccess/EmployeeTabWebAccess";
import {formatMessage} from "../../../../../../../common/locale/IntlProvider";
import EmployeeTagAutoCompleteProvider from "./EmployeeTagAutoCompleteProvider";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    employeeViewStore: EmployeeViewStore;
};

type Props = StoreProps;

@inject('employeeViewStore', 'dataStore', 'coreStore')
@observer
export default class EmployeeTabGeneral extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    updateTree = (updateDataRecord: UpdateDataRecord<TreeFields>): Promise<boolean> => {
        return new UpdateTreeIdsCommand<EmployeeRecord>('employee', updateDataRecord).execute().then((employeeRecord: EmployeeRecord) => {
            const {dataStore, employeeViewStore} = this.props;
            dataStore.updateEmployee(employeeRecord);
            employeeViewStore.applyFiltersToView(updateDataRecord.id);
            return Promise.resolve(true);
        });
    };

    updateTag = (updateDataRecord: UpdateDataRecord<TagFields>): Promise<boolean> => {
        return new UpdateTagsCommand<EmployeeRecord>('employee', updateDataRecord).execute().then((employeeRecord: EmployeeRecord) => {
            const {dataStore, employeeViewStore} = this.props;
            dataStore.updateEmployee(employeeRecord);
            return employeeViewStore.refreshEmployeeTags(updateDataRecord.id).then(() => {
                return Promise.resolve(true);
            });
        });
    };

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        return new UpdateAvatarCommand<EmployeeRecord>('employee', updateDataRecord).execute().then((employeeRecord: EmployeeRecord) => {
            const {dataStore, employeeViewStore} = this.props;
            dataStore.updateEmployee(employeeRecord);
            employeeViewStore.applyFiltersToView(employeeRecord.id);
            return Promise.resolve(true);
        });
    };

    handleOnCropChange = (location: Point) => {
    };

    render() {
        const {employeeViewStore, coreStore} = this.props;
        const {employeeTreeData, selectedEntity} = employeeViewStore;
        const employeeBarcodeData: string = fmBarcode(coreStore.user.schema, BarcodeEntityId.EMPLOYEE, selectedEntity.id);

        return (
            <>
                <Row gutter={[10, 30]}>
                    <Col span={16}>
                        <Row gutter={[10, 30]}>
                            <Col span={24}>
                                <Divider orientation="left">Structure</Divider>
                                <ManageTreeWidget selectedEntity={selectedEntity} treeData={employeeTreeData} updateCommand={this.updateTree}/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">{formatMessage('employee.details.webAccess.title')}</Divider>
                                <EmployeeTabWebAccess/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">Tags</Divider>
                                <ManageTagWidget selectedEntity={selectedEntity} treeData={employeeTreeData} updateCommand={this.updateTag}
                                                 isAdminUser={coreStore.isAdminUser}
                                                 autoCompleteDataProvider={new EmployeeTagAutoCompleteProvider()}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={7}>
                        <ManageAvatarWidget selectedEntity={selectedEntity} updateCommand={this.updateAvatar}
                                            emptyAvatar={getEmployeeAvatar(selectedEntity)}/>
                        <BarCode data={employeeBarcodeData} fontSizePx={52}/>
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <CEDAvatarsController table={'employee'} record={selectedEntity}/>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
