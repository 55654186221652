import {AppStore} from './AppStore';
import {CoreStore} from '../../pages/core/mobx/CoreStore';
import {EmployeeViewStore} from '../../pages/account/employee/mobx/EmployeeViewStore';
import {DataStore} from './DataStore';
import {FieldViewStore} from "../../pages/account/field/mobx/FieldViewStore";
import {CommentStore} from "../../common/widgets/comments/mobx/CommentStore";
import {EquipmentViewStore} from "../../pages/account/equipment/mobx/EquipmentViewStore";
import {RichTextStore} from "../../common/widgets/richText/mobx/RichTextStore";
import {WorkViewStore} from "../../pages/account/work/mobx/WorkViewStore";
import {CalendarViewStore} from "../../pages/account/calendar/mobx/CalendarViewStore";
import {LogTypeViewStore} from "../../pages/account/justlog/mobx/LogTypeViewStore";
import {DictionaryStore} from "../../pages/core/mobx/DictionaryStore";
import {LogDataViewStore} from "../../pages/account/justlog/components/tabs/items/logData/mobx/LogDataViewStore";
import {ScannerViewStore} from '../../pages/account/scanner/mobx/ScannerViewStore';
import {ReportViewStore} from '../../pages/account/report/mobx/ReportViewStore';

const appStore: AppStore = new AppStore();
const dataStore: DataStore = new DataStore();

export const stores = {
    appStore: appStore,
    coreStore: new CoreStore(appStore),
    dataStore: dataStore,
    commentStore: new CommentStore(),
    richTextStore: new RichTextStore(),
    dictionaryStore: new DictionaryStore(),
    logDataViewStore: new LogDataViewStore(),

    employeeViewStore: new EmployeeViewStore(appStore, dataStore),
    fieldViewStore: new FieldViewStore(appStore, dataStore),
    equipmentViewStore: new EquipmentViewStore(appStore, dataStore),
    workViewStore: new WorkViewStore(appStore, dataStore),
    calendarViewStore: new CalendarViewStore(appStore, dataStore),
    logTypeViewStore: new LogTypeViewStore(appStore, dataStore),
    scannerViewStore: new ScannerViewStore(appStore, dataStore),
    reportViewStore: new ReportViewStore(appStore, dataStore),
};
