import {Md5} from 'ts-md5/dist/md5';
import UIConfig from '../../config/UIConfig';

export function getHashedPassword(rawPassword: string, email: string): string {
    const md5: Md5 = new Md5();
    return md5
        .start()
        .appendStr(UIConfig.passwordSalt)
        .appendStr(rawPassword)
        .appendStr(email.trim().toLowerCase())
        .end()
        .toString();
}
