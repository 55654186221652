import React from 'react';
import {Button, Col, Descriptions, Row, Select, Space, Spin} from 'antd';
import {inject, observer} from 'mobx-react';
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import {CoreStore} from "../../../mobx/CoreStore";
import {DataStore} from "../../../../../app/mobx/DataStore";
import './style/style.scss';

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    commandInProgress: boolean;
    record?: AccountRecord;
}

@inject('dataStore', 'coreStore')
@observer
export default class AccountTabDevice extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Props) {
        super(props);
        this.state = {
            modalFormVisible: false,
            commandInProgress: false,
            record: undefined,
        };
    }

    render() {
        const {coreStore} = this.props;
        const {isAdminUser} = coreStore;

        return (
            <Row gutter={[10, 30]} className={'account-container'}>
                <Col span={10}>
                    <Spin spinning={this.state.commandInProgress}>
                        <Descriptions title='Тарифы'></Descriptions>

                        {isAdminUser && (
                            <Space>
                                <Select style={{width: 200}} />
                                <Button>Change</Button>
                            </Space>
                        )}
                    </Spin>
                </Col>
            </Row>
        );
    }
}
