import React, {ReactNode} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {ReCaptchaValidator} from './ReCaptchaValidator';
import UIConfig from '../../../config/UIConfig';

export interface ReCaptchaProps {
    label: string;
    icon?: ReactNode;
    action: string;
    onClick: () => void;
    onValid: () => void;
    onInvalid: () => void;
    loading?: boolean;
    disabled?: boolean;
}

export class ReCaptchaButton extends React.Component<ReCaptchaProps> {
    render() {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={UIConfig.reCaptchaSITEKEY}>
                <ReCaptchaValidator {...this.props} />
            </GoogleReCaptchaProvider>
        );
    }
}
