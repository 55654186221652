import React from "react";
import {findFormFieldRender, LogTypeField, LogTypeFieldType} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {Field} from "formik";
import {NonEmptyValidator} from "../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator";
import {validateSync} from "../../../../../../../common/widgets/modalForm/validators/sync/SyncValidatorUtils";
import {getFormFieldRender} from "../../../../../../../common/widgets/modalForm/FormikUtils";
import {getRealFieldName} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import {getOptions} from "./LogDataFieldItemUtils";

interface Props {
    logTypeField: LogTypeField;
    disabled?: boolean;
}

export class LogDataFieldItem extends React.Component<Props> {

    componentDidMount() {
        // use DictionaryStore and move from here
        // this.unitDictionary.load();
    }

    getFormItem = (logTypeField: LogTypeField): React.ComponentType<any> => {
        return getFormFieldRender(findFormFieldRender(logTypeField.field_type));
    };

    // getOptions = (logTypeField: LogTypeField): FMOptionType[] => {
    //     const options: FMOptionType[] = [];
    //     switch (logTypeField.field_type) {
    //         case LogTypeFieldType.FM_ENTITY: {
    //             switch (logTypeField.field_dict_id as FMEntity) {
    //                 case FMEntity.EMPLOYEE:
    //                     return new DictionaryDecorator(DICTIONARY_ENTITY_EMPLOYEE).selectOptionsProvider;
    //                 case FMEntity.FIELD:
    //                     return new DictionaryDecorator(DICTIONARY_ENTITY_FIELD).selectOptionsProvider;
    //                 case FMEntity.WORK:
    //                     return new DictionaryDecorator(DICTIONARY_ENTITY_WORK).selectOptionsProvider;
    //                 case FMEntity.EQUIPMENT:
    //                     return new DictionaryDecorator(DICTIONARY_ENTITY_EQUIPMENT).selectOptionsProvider;
    //             }
    //             break;
    //         }
    //         case LogTypeFieldType.FM_DICTIONARY: {
    //             switch (logTypeField.field_dict_id as FMDictionary) {
    //                 case FMDictionary.ROLE:
    //                     return new DictionaryDecorator(DICTIONARY_ROLE).selectOptionsProvider;
    //                 case FMDictionary.GENDER:
    //                     return new DictionaryDecorator(DICTIONARY_GENDER).selectOptionsProvider;
    //                 case FMDictionary.WORK_PAYMENT_TYPE:
    //                     return new DictionaryDecorator(DICTIONARY_WORK_PAYMENT_TYPE).selectOptionsProvider;
    //             }
    //             break;
    //         }
    //         case LogTypeFieldType.STRING_LOOKUP: {
    //             // read from DB, because on client not all set of data
    //             return [{key: "1", label: `value 1 from field_id ${logTypeField.field_id}`, value: "value1"} as LabeledValue, {
    //                 key: "2",
    //                 label: `value 2 from field_id ${logTypeField.field_id}`,
    //                 value: "value2"
    //             } as LabeledValue];
    //         }
    //     }
    //     return options;
    // }

    render() {
        const {logTypeField, disabled} = this.props;
        return <Field component={this.getFormItem(logTypeField)}
                      label={logTypeField.field_title}
                      validate={validateSync(logTypeField.field_required && logTypeField.field_type !== LogTypeFieldType.BOOLEAN
                          ? [new NonEmptyValidator()]
                          : [])}
                      key={logTypeField.field_id}
                      name={getRealFieldName(logTypeField)}
                      required={logTypeField.field_required}
                      formItemTooltip={logTypeField.field_description}
                      options={getOptions(logTypeField)}
                      disabled={disabled}
        />;
    }
}