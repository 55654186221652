import {DataRecord} from '../../common/DataRecord';
import {EmployeeGender} from "./EmployeeGender";
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";
import {StringDate} from "../../core/StringDate";

export interface EmployeeRecord extends DataRecord, TreeFields, TagFields, AvatarFields {
    first_name: string;
    last_name: string;
    middle_name: string; // optional?
    gender: EmployeeGender;
    birthday: StringDate;

    position: string;

    is_third_party_employee?: boolean;
    third_party_employee_cost?: number;

    can_login: boolean;
    email?: string;// login email

    roles: string[];
}

export function cleanEmployeeRecord(rec: EmployeeRecord): EmployeeRecord {
    // if (!rec) {
    //     return undefined;
    // }
    const clean: EmployeeRecord = {
        ...rec
    };
    delete clean.password_hash;
    return clean;
}

export function offlineEmployeeRecord(rec: EmployeeRecord): Partial<EmployeeRecord> {
    return {
        id: rec.id,
        first_name: rec.first_name,
        last_name: rec.last_name,
        middle_name: rec.middle_name,
        email: rec.email,
        phone1: 'deprecated',
        phone2: 'deprecated',
        roles: rec.roles,
        avatar_file: 'deprecated',
        avatar: 'deprecated'
    }
}

export function employeeHash(rec: EmployeeRecord): string {
    return [rec.first_name?.trim().toLowerCase(), rec.last_name?.trim().toLowerCase()].sort().join('');
}
