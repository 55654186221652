export type AllLocaleFileType = Record<string, Record<string, string>>;

// split all.json for web
export function loadLocale(locale: string): Promise<Record<string, string>> {
    return import(`../locale/web/${locale}.json`).then((jsonLocale: any) => {
        return jsonLocale;
    });
}

// 1) load all locales needs to use on server-side: depends on user locale need to get localized value
// 2) dist/server needs only all.json
export function loadAllLocales(): Promise<AllLocaleFileType> {
    return import('../locale/server/all.json').then((jsonLocale: any) => {
        return jsonLocale;
    });
}
