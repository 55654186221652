import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";

export default class LoadEmployeeCommand extends Command<EmployeeRecord[]> {
    execute(): Promise<EmployeeRecord[]> {
        return axiosGetInstance().post<EmployeeRecord[]>('/employee/all')
            .then((response: AxiosResponse<EmployeeRecord[]>) => response.data);
    }
}
