import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {stores} from "../../../../app/mobx/Stores";
import DataProvider from "../../modalForm/type/DataProvider";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";

export class DataStoreWorkProvider implements DataProvider<DictionaryRawRecord<WorkRecord>[]> {

    get(): DictionaryRawRecord<WorkRecord>[] {
        return stores.dataStore.work.map((field: WorkRecord) => {
            return {
                id: field.id,
                name: field.name,
                record: field
            };
        }).sort((a: DictionaryRawRecord<WorkRecord>, b: DictionaryRawRecord<WorkRecord>): number => a.name.localeCompare(b.name));
    }
}
