import React from 'react';
import './style/style.scss';
import {Avatar, Image, Tooltip} from 'antd';
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import {RenderFunction} from "antd/lib/tooltip";

export interface AvatarWidgetProps {
    tooltip?: React.ReactNode | RenderFunction;
    size?: AvatarSize;
    hidePreview?: boolean;
    shape?: 'circle' | 'square';
}

interface Props extends AvatarWidgetProps {
    src: string;
    className?: string;
}

export default class AvatarWidget extends React.PureComponent<Props> {

    getSrc = (): React.ReactNode => {
        const {src, hidePreview = false, tooltip = ''} = this.props
        const image: React.ReactNode = <Image preview={!hidePreview} src={src}/>;
        if (tooltip) {
            return <Tooltip title={tooltip} placement="top">
                {image}
            </Tooltip>;
        }
        return image;
    }

    render() {
        const {size = 'default', className = '', shape} = this.props;
        return (
            <Avatar size={size} shape={shape} src={this.getSrc()} className={`avatar-widget ${className}`}/>
        );
    }
}
