import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";

export default class AddEquipmentCommand extends UpdateDataCommand<EquipmentRecord> {

    constructor(private readonly upsertValue: UpsertValue<EquipmentRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<EquipmentRecord>> {
        return axiosGetInstance().post<UpsertResponse<EquipmentRecord>>(`/equipment/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<EquipmentRecord>>) => response.data);
    }

}
