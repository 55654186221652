import DataProvider from '../../../../../common/widgets/modalForm/type/DataProvider';
import {stores} from '../../../../../app/mobx/Stores';
import {AutoCompleteOptionType} from '../../../../../common/widgets/modalForm/formItems/AutoCompleteOptionType';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {toCamelCaseSingle} from "fm-shared-utils/src/utils/SimpleUtils";

export default class EmployeeMiddleNameOptionsProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        const uniqNames: Set<string> = new Set<string>();
        dataStore.employee.forEach((item: EmployeeRecord) => {
            if (item.middle_name) {
                uniqNames.add(toCamelCaseSingle(item.middle_name))
            }
        });
        return Array.from(uniqNames).map((value: string) => {
            return {value};
        });
    }
}
