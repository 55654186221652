export function anyToString(value: any | any[], useNewLine = true): string {
    let result = '';
    if (value === undefined) {
        result = 'undefined';
    } else if (value === null) {
        result = 'null';
    } else if (typeof value === "string") {
        result = value;
    } else if (Array.isArray(value)) {
        result += '\n[';
        result += value.map((item: any) => anyToString(item, false)).join(',');
        result += ']';
    } else if (typeof value === "object") {
        result += useNewLine ? '\n' : '';
        result += JSON.stringify(value);
    } else {
        result = value.toString();
    }
    return result;
}

export function toCamelCaseSingle(value: string): string {
    const rest: string = value.toLowerCase().substr(1);
    return value[0].toUpperCase() + rest;
}

export function toCamelCase(value: string): string {
    return value.split(' ').map((word: string) => toCamelCaseSingle(word)).join(' ');
}
