import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";
import {fieldHash, FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import {stores} from "../../../../../app/mobx/Stores";

export interface UniqFieldValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqFieldValidator extends SyncValidator<FieldRecord, UniqFieldValidatorConfig> {

    constructor(config?: UniqFieldValidatorConfig) {
        super(config || {});
    }

    validate(value: FieldRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = fieldHash(value);
            const isRecordExists: boolean = stores.dataStore.field
                .some((rec: FieldRecord) => value.id !== rec.id && valueHash === fieldHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.field.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
