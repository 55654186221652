import React from 'react';
import DataProvider from '../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {Typography} from 'antd';

const {Text} = Typography;

export default class EmployeeAddressItemRenderer extends DataProvider<React.ReactNode, EmployeeAddressRecord> {
    get(address: EmployeeAddressRecord): React.ReactNode {
        return <Text disabled={!!address.deleted_by} type={address.confirmed ? 'success' : undefined}>{address.address}</Text>;
    }
}
