import React from "react";
import {stores} from "../../../app/mobx/Stores";
import {CheckboxOptionType} from "antd/lib/checkbox/Group";
import {LabeledValue} from "antd/lib/select";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {DictionaryConfig} from "./DictionaryConfig";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {getDictionaryAvatarLabelRenderer, getDictionaryIconLabelRenderer} from "./DictionaryLabelRenderer";

export class DictionaryDecorator<T extends DataRecord = DataRecord> {
    constructor(private readonly dictionaryConfig: DictionaryConfig<T>) {
    }

    get dictionaryKey(): string {
        return this.dictionaryConfig.dictionaryKey;
    }

    get isStatic(): boolean {
        return !this.dictionaryConfig.loadRawRecordsCommand;
    }

    get isDictionaryLoaded(): boolean {
        const {dictionaryKey} = this.dictionaryConfig;
        return !!stores.dictionaryStore.getDictionary(dictionaryKey);
    }

    load = async () => {
        const {dictionaryKey, loadRawRecordsCommand} = this.dictionaryConfig;
        if (loadRawRecordsCommand) {
            return stores.dictionaryStore.loadDictionary(dictionaryKey, loadRawRecordsCommand);
        } else {
            return Promise.reject(`DictionaryDecorator: incorrect dictionaryConfig! No loadRawRecordsCommand: ${JSON.stringify(this.dictionaryConfig)}`);
        }
    };

    getLabelRenderer = (dictionaryRawRecord: DictionaryRawRecord<T>): React.ReactNode => {
        if (this.dictionaryConfig.labelRendererFunction) {
            return this.dictionaryConfig.labelRendererFunction(dictionaryRawRecord)
        }
        if (dictionaryRawRecord.avatar) {
            return getDictionaryAvatarLabelRenderer(dictionaryRawRecord);
        }
        if (dictionaryRawRecord.icon || dictionaryRawRecord.color) {
            return getDictionaryIconLabelRenderer(dictionaryRawRecord);
        }
        return dictionaryRawRecord.name;
    }

    get checkOptionsProvider(): CheckboxOptionType[] {
        return this.dictionaryConfig.rawRecordsProvider.get().map((dictionaryRawRecord: DictionaryRawRecord<T>) => {
            const {id} = dictionaryRawRecord;
            return {
                value: id,
                label: this.getLabelRenderer(dictionaryRawRecord),
            };
        });
    }

    get selectOptionsProvider(): LabeledValue[] {
        return this.dictionaryConfig.rawRecordsProvider.get().map((dictionaryRawRecord: DictionaryRawRecord<T>) => {
            const {id} = dictionaryRawRecord;
            return {
                key: id.toString(),
                value: id,
                label: this.getLabelRenderer(dictionaryRawRecord)
            };
        });
    }
}