import {IntlShape} from 'react-intl';
import {DEFAULT_LOCALE} from "fm-shared-locale/src/common/SupportedLocales";
import {loadLocaleFile} from './LoadLocaleFileActionCreator';
import {LocalStorage, LocalStorageKey} from "../utils/LocalStorageUtils";

export class IntlProvider {

    private static intlProvider: IntlProvider;

    static instance(): IntlProvider {
        if (!this.intlProvider) {
            this.intlProvider = new IntlProvider();
        }
        return this.intlProvider;
    }

    private intlShape: IntlShape | undefined;

    get intl(): IntlShape {
        return this.intlShape!;
    }

    private cache: Map<string, IntlShape> = new Map<string, IntlShape>();

    async loadLocale(locale: string): Promise<IntlShape> {
        let intl: IntlShape | undefined = this.cache.get(locale);
        if (!intl) {
            intl = await loadLocaleFile(locale).then((value: IntlShape) => {
                this.cache.set(locale, value);
                return value;
            });
        }
        this.intlShape = intl;
        LocalStorage.setItem(LocalStorageKey.LOCALE, locale);
        return Promise.resolve(intl!);
    }

}

export const intl = (): IntlShape => IntlProvider.instance().intl;
export const formatMessage = (id: string, values?: Record<string, string | number>): string => intl().formatMessage({id}, values);

export const initialLocale: string = LocalStorage.getItem(LocalStorageKey.LOCALE) || DEFAULT_LOCALE.code;
