import React from 'react';
import {inject, observer} from "mobx-react";
import {CoreStore} from "../mobx/CoreStore";

type StoreProps = {
    coreStore: CoreStore;
};

type Props = StoreProps;

@inject('coreStore')
@observer
export default class UserProfileController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    render() {
        return (
            <div>
                <h1>UserProfile page</h1>
                <h2>Дашборд пользователя (работы, начисления, штрафы/бонусы и тд), упрощенный профайл, действия</h2>
                <p>{JSON.stringify(this.props.coreStore.user)}</p>
                <p>isAdminUser: {this.props.coreStore.isAdminUser.toString()}</p>
            </div>
        );
    }
}
