import {AxiosResponse} from "axios";
import Command from '../../../../common/types/Command';
import {axiosGetInstance} from '../../../../common/utils/AxiosUtils';
import {SyncSearchParams} from 'fm-shared-data/src/types/db/account/scanner/SyncSearchParams';
import {SyncRecord} from 'fm-shared-data/src/types/db/account/sync/SyncRecord';

export default class LoadSyncRecordsCommand extends Command<SyncRecord[], SyncSearchParams> {
    execute(searchParams: SyncSearchParams): Promise<SyncRecord[]> {
        return axiosGetInstance().post<SyncRecord[]>('/sync/records/search', searchParams)
            .then((response: AxiosResponse<SyncRecord[]>) => response.data);
    }
}
