import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../../../common/types/Command";
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";

export default class DeleteLogDataCommand extends Command<LogDataRecord[], LogDataRecord> {
    execute(logDataRecord: LogDataRecord): Promise<LogDataRecord[]> {
        return axiosGetInstance().post<LogDataRecord[]>(`/justlog/data/delete`, logDataRecord)
            .then((response: AxiosResponse<LogDataRecord[]>) => response.data);
    }
}
