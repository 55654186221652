import React from 'react';
import {Button, Col, Row, Space, Spin} from 'antd';
import {inject, observer} from "mobx-react";
import './style/style.scss'
import {CoreStore} from "../../../pages/core/mobx/CoreStore";
import {RichTextStore} from "./mobx/RichTextStore";
import JoditEditor from "jodit-react";
import {RichTextRecord} from "fm-shared-data/src/types/db/common/RichTextRecord";
import CEDAvatarsController from "../CEDAvatars/CEDAvatarsController";
import {formatMessage} from "../../locale/IntlProvider";

type StoreProps = {
    coreStore: CoreStore;
    richTextStore: RichTextStore;
};

interface Props extends StoreProps {
    barcode_entity_id: number;
    entity_id: number;
    isRecordCreator: boolean;
}

interface State {
    commandInProgress: boolean;
    draft: string | undefined;
    isEditMode: boolean;
}

@inject('coreStore', 'richTextStore')
@observer
export default class RichTextWidget extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    state: State = {
        commandInProgress: false,
        draft: undefined,
        isEditMode: false,
    };

    editorConfig: any = {
        statusbar: false,
        // spellcheck: false,
        // extraButtons: [{name: 'save', tooltip: 'Save'}],
        readonly: true,
        toolbar: false
        // tabIndex: 1,
    };

    initEditor = () => {
        const {richTextStore, barcode_entity_id, entity_id} = this.props;
        const storeRecord: RichTextRecord | undefined = richTextStore.getRichText(barcode_entity_id, entity_id);
        if (!storeRecord) {
            richTextStore.loadRichText(barcode_entity_id, entity_id);
        } else {
            if (this.state.draft === undefined) {
                this.setState({draft: storeRecord.value, commandInProgress: false});
            }
        }
    }

    componentDidMount() {
        this.initEditor();
    }

    componentDidUpdate(prevProps: Props): void {
        const {barcode_entity_id, entity_id} = this.props;
        if (barcode_entity_id !== prevProps.barcode_entity_id || entity_id !== prevProps.entity_id) {
            this.setState({draft: undefined, commandInProgress: true});
            return;
        }
        this.initEditor();
    }

    handleChange = (value: string) => {
        this.setState({draft: value});
    }

    handleAddComment = () => async () => {
        const {richTextStore, barcode_entity_id, entity_id} = this.props;
        const {draft} = this.state;
        this.setState({commandInProgress: true});

        const newRichTextRecord: Partial<RichTextRecord> =
            {
                barcode_entity_id,
                entity_id,
                draft
            };

        richTextStore.upsertRichText(barcode_entity_id, entity_id, newRichTextRecord).then(() => {
            this.setState({commandInProgress: false});
        });
    };

    handleOnCancel = () => {
        const {richTextStore, barcode_entity_id, entity_id} = this.props;
        const storeRecord: RichTextRecord | undefined = richTextStore.getRichText(barcode_entity_id, entity_id);
        this.setState({draft: storeRecord?.value}, () => this.handleOnEdit());
    };

    handleOnSave = () => {
        const {richTextStore, barcode_entity_id, entity_id} = this.props;
        const {draft} = this.state;
        this.setState({commandInProgress: true});
        richTextStore.upsertRichText(barcode_entity_id, entity_id, {value: draft}).then(() => {
            this.setState({commandInProgress: false}, () => this.handleOnEdit());
        });
    };

    handleOnEdit = () => {
        const {isEditMode} = this.state;
        if (isEditMode) {
            this.editModeOFF();
        } else {
            this.editModeON();
        }
        this.setState({isEditMode: !isEditMode});
    };

    editModeON = () => {
        this.editorConfig = {
            ...this.editorConfig,
            readonly: false,
            toolbar: true,
            statusbar: true
        };
    };

    editModeOFF = () => {
        this.editorConfig = {
            ...this.editorConfig,
            readonly: true,
            toolbar: false,
            statusbar: false
        };
    };

    render() {
        const {richTextStore, barcode_entity_id, entity_id, coreStore, isRecordCreator} = this.props;
        const {draft, commandInProgress, isEditMode} = this.state;
        const {isAdminUser, user} = coreStore;

        const richTextRecord: RichTextRecord | undefined = richTextStore.getRichText(barcode_entity_id, entity_id);
        if (richTextRecord === undefined) {
            return <Spin/>;
        }
        const hasTextChanged: boolean = richTextRecord.value !== draft;
        const canEdit: boolean = isAdminUser || isRecordCreator || !richTextRecord.created_by || richTextRecord.created_by === user.user_id;

        return (
            <Spin spinning={commandInProgress} wrapperClassName={'rich-text-container maxWidth'}>
                <JoditEditor
                    // ref={this.ref}
                    config={this.editorConfig}
                    value={draft!}
                    onChange={this.handleChange}
                    // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                />
                <br/>
                <Row>
                    <Col span={18}>
                        {canEdit &&
                        <Space>
                            {!isEditMode && <Button onClick={this.handleOnEdit}>
                                {formatMessage('common.edit')}
                            </Button>}
                            {isEditMode && <>
                                <Button disabled={!hasTextChanged || commandInProgress || draft === undefined} onClick={this.handleOnSave} type="primary" loading={commandInProgress}>
                                    {formatMessage('common.save')}
                                </Button>
                                <Button disabled={commandInProgress} onClick={this.handleOnCancel}>
                                    {formatMessage('common.cancel')}
                                </Button>
                            </>}
                        </Space>
                        }
                    </Col>
                    <Col span={4}>
                        {richTextRecord.id !== -1 && <CEDAvatarsController<RichTextRecord> record={richTextRecord} table="rich_text"/>}
                    </Col>
                </Row>
            </Spin>);
    }
}
