import {DataRecord} from '../../common/DataRecord';
import {TreeFields} from "../../common/TreeFields";
import {TagFields} from "../../common/TagFields";
import {AvatarFields} from "../../common/AvatarFields";
import {NameIconColorFields} from "../../common/NameIconColorFields";

export const LOGTYPE_MAX_FIELDS: number = 7;

export interface LogTypeRecord extends DataRecord, TreeFields, TagFields, AvatarFields, NameIconColorFields {
    field_index1?: number;
    field_enabled1?: boolean;
    field_title1?: string;
    field_type1?: string;
    field_required1?: boolean;
    field_prev1?: boolean;
    field_tab1?: boolean;
    field_dict_id1?: string;
    field_description1?: string;

    field_index2?: number;
    field_enabled2?: boolean;
    field_title2?: string;
    field_type2?: string;
    field_required2?: boolean;
    field_prev2?: boolean;
    field_tab2?: boolean;
    field_dict_id2?: string;
    field_description2?: string;

    field_index3?: number;
    field_enabled3?: boolean;
    field_title3?: string;
    field_type3?: string;
    field_required3?: boolean;
    field_prev3?: boolean;
    field_tab3?: boolean;
    field_dict_id3?: string;
    field_description3?: string;

    field_index4?: number;
    field_enabled4?: boolean;
    field_title4?: string;
    field_type4?: string;
    field_required4?: boolean;
    field_prev4?: boolean;
    field_tab4?: boolean;
    field_dict_id4?: string;
    field_description4?: string;

    field_index5?: number;
    field_enabled5?: boolean;
    field_title5?: string;
    field_type5?: string;
    field_required5?: boolean;
    field_prev5?: boolean;
    field_tab5?: boolean;
    field_dict_id5?: string;
    field_description5?: string;

    field_index6?: number;
    field_enabled6?: boolean;
    field_title6?: string;
    field_type6?: string;
    field_required6?: boolean;
    field_prev6?: boolean;
    field_tab6?: boolean;
    field_dict_id6?: string;
    field_description6?: string;

    field_index7?: number;
    field_enabled7?: boolean;
    field_title7?: string;
    field_type7?: string;
    field_required7?: boolean;
    field_prev7?: boolean;
    field_tab7?: boolean;
    field_dict_id7?: string;
    field_description7?: string;
}

export function offlineCalendarRecord(rec: LogTypeRecord): Partial<LogTypeRecord> {
    return {
        id: rec.id,
        name: rec.name,
        icon: rec.icon,
        color: rec.color,
        avatar: 'deprecated'
    }
}

export function logTypeHash(rec: LogTypeRecord): string {
    return rec.name.trim().toLowerCase();
}
