import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import DataProvider from "../../modalForm/type/DataProvider";
import {formatMessage} from "../../../locale/IntlProvider";
import {FMKeyType} from "fm-shared-data/src/types/dictionary/FMKeyType";

export class DictionaryStaticDataProvider implements DataProvider<DictionaryRawRecord[]> {

    constructor(private readonly items: FMKeyType[], private readonly localeKey?: string) {
    }

    get(): DictionaryRawRecord[] {
        return this.items.map((key: FMKeyType) => {
            return {
                id: key,
                name: this.localeKey ? formatMessage(`dictionary.${this.localeKey}.${key}`) : key.toString()
            };
        });
    }
}