// LogTypeFieldType
export enum FormFieldRender {
    INPUT = "INPUT",
    DATE = "DATE",
    RADIO = "RADIO",
    CHECKBOX = "CHECKBOX",
    SINGLE_CHECKBOX = "SINGLE_CHECKBOX",
    SWITCH = "SWITCH",
    RATE = "RATE",
    SINGLE_SELECT = "SINGLE_SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    AUTO_COMPLETE = "AUTO_COMPLETE",
    TEXT = "TEXT",
    MENTIONS = "MENTIONS",
    COLOR_PICKER = "COLOR_PICKER",

    DATETIME = "DATETIME",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    FILE = "FILE",
    FILES = "FILES",
    PHOTO = "PHOTO",
    BARCODE = "BARCODE",
    ICON = "ICON",

    CUSTOM_RENDERER = "CUSTOM_RENDERER"
}
