import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";

export default class LoadLogTypeCommand extends Command<LogTypeRecord[]> {
    execute(): Promise<LogTypeRecord[]> {
        return axiosGetInstance().post<LogTypeRecord[]>('/justlog/type/all')
            .then((response: AxiosResponse<LogTypeRecord[]>) => response.data);
    }
}
