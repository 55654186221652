import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import ScannerNameOptionsProvider from "./ScannerNameOptionsProvider";
import {UniqScannerValidator} from '../../../../../common/widgets/modalForm/validators/sync/UniqScannerValidator';
import ScannerDeviceKeyReadonlyProvider from './ScannerDeviceKeyReadonlyProvider';

export const SCANNER_FORM_CONFIG: ModalFormConfig<ScannerRecord> = {
    title: 'scanner.form.title',
    tableName: 'scanner',
    getNewRecord: () => {
        return {
            name: "",
            device_key: "",
            enabled: false
        } as ScannerRecord;
    },
    fieldConfig: [
        {
            label: 'tree.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new ScannerNameOptionsProvider()
            }
        },
        {
            label: 'scanner.field.device_key',
            field_name: 'device_key',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            readonly: new ScannerDeviceKeyReadonlyProvider()
        },
        {
            label: 'scanner.field.enabled',
            field_name: 'enabled',
            renderType: FormFieldRender.SWITCH,
            syncValidators: [],
        },
    ],
    syncValidators: [new UniqScannerValidator()]
};
