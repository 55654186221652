import {AxiosResponse} from "axios";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";
import {UniqFileId} from "fm-shared-data/src/types/db/common/UniqFileId";

export default class DeleteFilesCommand extends Command<string[], UniqFileId[]> {
    execute(uniqFileIds: UniqFileId[]): Promise<string[]> {
        return axiosGetInstance().post<string[]>(`/file/delete`, uniqFileIds)
            .then((response: AxiosResponse<string[]>) => response.data);
    }
}
