import React from 'react';
import {Route, Switch} from 'react-router';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {CoreStore} from './mobx/CoreStore';
import {inject, observer} from 'mobx-react';
import {URLRouteProps} from '../../routing/URLRouteProps';
import FMLoader from '../../common/widgets/fmLoader/FMLoader';
import BarcodeGeneratorController from '../../common/widgets/barcodeGenerator/BarcodeGeneratorController';
import UserProfileController from './userProfile/UserProfileController';
import AccountProfileController from './accountProfile/AccountProfileController';
import RootPageController from './rootPage/RootPageController';
import AccountRouter from '../account/AccountRouter';
import FMHeaderView from "../account/fmHeader/FMHeaderView";

type StoreProps = {
    coreStore: CoreStore;
};

interface Props extends URLRouteProps, StoreProps {
    test?: string;
}

@inject('coreStore')
@observer
export default class CoreRouter extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    componentDidMount(): void {
        this.props.coreStore.loadCoreData();
    }

    render() {
        if (!this.props.coreStore.isCoreDataLoaded) {
            return <FMLoader text='Loading core data....'/>;
        }

        return (
            <>
                <FMHeaderView/>
                <Switch>
                    <Route path={AppRoutes.ROOT} exact={true} component={RootPageController}/>
                    <Route path={AppRoutes.BARCODE} exact={true} component={BarcodeGeneratorController}/>
                    <Route path={AppRoutes.USER_PROFILE} exact={true} component={UserProfileController}/>
                    <Route path={AppRoutes.ACCOUNT_PROFILE} exact={true} component={AccountProfileController}/>

                    <Route component={AccountRouter}/>
                </Switch>
            </>
        );
    }
}
