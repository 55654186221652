export const ICOFONT_WEB_ICONS: string[] = [
    "icofont-addons",
    "icofont-address-book",
    "icofont-adjust",
    "icofont-alarm",
    "icofont-anchor",
    "icofont-archive",
    "icofont-at",
    "icofont-attachment",
    "icofont-audio",
    "icofont-automation",
    "icofont-badge",
    "icofont-bag-alt",
    "icofont-bag",
    "icofont-ban",
    "icofont-bar-code",
    "icofont-bars",
    "icofont-basket",
    "icofont-battery-empty",
    "icofont-battery-full",
    "icofont-battery-half",
    "icofont-battery-low",
    "icofont-beaker",
    "icofont-beard",
    "icofont-bed",
    "icofont-bell",
    "icofont-beverage",
    "icofont-bill",
    "icofont-bin",
    "icofont-binary",
    "icofont-binoculars",
    "icofont-bluetooth",
    "icofont-bomb",
    "icofont-book-mark",
    "icofont-box",
    "icofont-briefcase",
    "icofont-broken",
    "icofont-bucket",
    "icofont-bucket1",
    "icofont-bucket2",
    "icofont-bug",
    "icofont-building",
    "icofont-bulb-alt",
    "icofont-bullet",
    "icofont-bullhorn",
    "icofont-bullseye",
    "icofont-calendar",
    "icofont-camera-alt",
    "icofont-camera",
    "icofont-card",
    "icofont-cart-alt",
    "icofont-cart",
    "icofont-cc",
    "icofont-charging",
    "icofont-chat",
    "icofont-check-alt",
    "icofont-check-circled",
    "icofont-check",
    "icofont-checked",
    "icofont-children-care",
    "icofont-clip",
    "icofont-clock-time",
    "icofont-close-circled",
    "icofont-close-line-circled",
    "icofont-close-line-squared-alt",
    "icofont-close-line-squared",
    "icofont-close-line",
    "icofont-close-squared-alt",
    "icofont-close-squared",
    "icofont-close",
    "icofont-cloud-download",
    "icofont-cloud-refresh",
    "icofont-cloud-upload",
    "icofont-cloud",
    "icofont-code-not-allowed",
    "icofont-code",
    "icofont-comment",
    "icofont-compass-alt",
    "icofont-compass",
    "icofont-computer",
    "icofont-connection",
    "icofont-console",
    "icofont-contacts",
    "icofont-contrast",
    "icofont-copyright",
    "icofont-credit-card",
    "icofont-crop",
    "icofont-crown",
    "icofont-cube",
    "icofont-cubes",
    "icofont-dashboard-web",
    "icofont-dashboard",
    "icofont-data",
    "icofont-database-add",
    "icofont-database-locked",
    "icofont-database-remove",
    "icofont-database",
    "icofont-delete",
    "icofont-diamond",
    "icofont-dice-multiple",
    "icofont-dice",
    "icofont-disc",
    "icofont-diskette",
    "icofont-document-folder",
    "icofont-download-alt",
    "icofont-download",
    "icofont-downloaded",
    "icofont-drag",
    "icofont-drag1",
    "icofont-drag2",
    "icofont-drag3",
    "icofont-earth",
    "icofont-ebook",
    "icofont-edit",
    "icofont-eject",
    "icofont-email",
    "icofont-envelope-open",
    "icofont-envelope",
    "icofont-eraser",
    "icofont-error",
    "icofont-excavator",
    "icofont-exchange",
    "icofont-exclamation-circle",
    "icofont-exclamation-square",
    "icofont-exclamation-tringle",
    "icofont-exclamation",
    "icofont-exit",
    "icofont-expand",
    "icofont-external-link",
    "icofont-external",
    "icofont-eye-alt",
    "icofont-eye-blocked",
    "icofont-eye-dropper",
    "icofont-eye",
    "icofont-favourite",
    "icofont-fax",
    "icofont-file-fill",
    "icofont-film",
    "icofont-filter",
    "icofont-fire-alt",
    "icofont-fire-burn",
    "icofont-fire",
    "icofont-flag-alt-1",
    "icofont-flag-alt-2",
    "icofont-flag",
    "icofont-flame-torch",
    "icofont-flash-light",
    "icofont-flash",
    "icofont-flask",
    "icofont-focus",
    "icofont-folder-open",
    "icofont-folder",
    "icofont-foot-print",
    "icofont-garbage",
    "icofont-gear-alt",
    "icofont-gear",
    "icofont-gears",
    "icofont-gift",
    "icofont-glass",
    "icofont-globe",
    "icofont-graffiti",
    "icofont-grocery",
    "icofont-hand",
    "icofont-hanger",
    "icofont-hard-disk",
    "icofont-heart-alt",
    "icofont-heart",
    "icofont-history",
    "icofont-home",
    "icofont-horn",
    "icofont-hour-glass",
    "icofont-id",
    "icofont-image",
    "icofont-inbox",
    "icofont-infinite",
    "icofont-info-circle",
    "icofont-info-square",
    "icofont-info",
    "icofont-institution",
    "icofont-interface",
    "icofont-invisible",
    "icofont-jacket",
    "icofont-jar",
    "icofont-jewlery",
    "icofont-karate",
    "icofont-key-hole",
    "icofont-key",
    "icofont-label",
    "icofont-lamp",
    "icofont-layers",
    "icofont-layout",
    "icofont-leaf",
    "icofont-leaflet",
    "icofont-learn",
    "icofont-lego",
    "icofont-lens",
    "icofont-letter",
    "icofont-letterbox",
    "icofont-library",
    "icofont-license",
    "icofont-life-bouy",
    "icofont-life-buoy",
    "icofont-life-jacket",
    "icofont-life-ring",
    "icofont-light-bulb",
    "icofont-lighter",
    "icofont-lightning-ray",
    "icofont-like",
    "icofont-line-height",
    "icofont-link-alt",
    "icofont-link",
    "icofont-list",
    "icofont-listening",
    "icofont-listine-dots",
    "icofont-listing-box",
    "icofont-listing-number",
    "icofont-live-support",
    "icofont-location-arrow",
    "icofont-location-pin",
    "icofont-lock",
    "icofont-login",
    "icofont-logout",
    "icofont-lollipop",
    "icofont-long-drive",
    "icofont-look",
    "icofont-loop",
    "icofont-luggage",
    "icofont-lunch",
    "icofont-lungs",
    "icofont-magic-alt",
    "icofont-magic",
    "icofont-magnet",
    "icofont-mail-box",
    "icofont-mail",
    "icofont-male",
    "icofont-map-pins",
    "icofont-map",
    "icofont-maximize",
    "icofont-measure",
    "icofont-medicine",
    "icofont-mega-phone",
    "icofont-megaphone-alt",
    "icofont-megaphone",
    "icofont-memorial",
    "icofont-memory-card",
    "icofont-mic-mute",
    "icofont-mic",
    "icofont-military",
    "icofont-mill",
    "icofont-minus-circle",
    "icofont-minus-square",
    "icofont-minus",
    "icofont-mobile-phone",
    "icofont-molecule",
    "icofont-money",
    "icofont-moon",
    "icofont-mop",
    "icofont-muffin",
    "icofont-mustache",
    "icofont-navigation-menu",
    "icofont-navigation",
    "icofont-network-tower",
    "icofont-network",
    "icofont-news",
    "icofont-newspaper",
    "icofont-no-smoking",
    "icofont-not-allowed",
    "icofont-notebook",
    "icofont-notepad",
    "icofont-notification",
    "icofont-numbered",
    "icofont-opposite",
    "icofont-optic",
    "icofont-options",
    "icofont-package",
    "icofont-page",
    "icofont-paint",
    "icofont-paper-plane",
    "icofont-paperclip",
    "icofont-papers",
    "icofont-pay",
    "icofont-penguin-linux",
    "icofont-pestle",
    "icofont-phone-circle",
    "icofont-phone",
    "icofont-picture",
    "icofont-pine",
    "icofont-pixels",
    "icofont-plugin",
    "icofont-plus-circle",
    "icofont-plus-square",
    "icofont-plus",
    "icofont-polygonal",
    "icofont-power",
    "icofont-price",
    "icofont-print",
    "icofont-puzzle",
    "icofont-qr-code",
    "icofont-queen",
    "icofont-question-circle",
    "icofont-question-square",
    "icofont-question",
    "icofont-quote-left",
    "icofont-quote-right",
    "icofont-random",
    "icofont-recycle",
    "icofont-refresh",
    "icofont-repair",
    "icofont-reply-all",
    "icofont-reply",
    "icofont-resize",
    "icofont-responsive",
    "icofont-retweet",
    "icofont-road",
    "icofont-robot",
    "icofont-royal",
    "icofont-rss-feed",
    "icofont-safety",
    "icofont-sale-discount",
    "icofont-satellite",
    "icofont-send-mail",
    "icofont-server",
    "icofont-settings-alt",
    "icofont-settings",
    "icofont-share-alt",
    "icofont-share-boxed",
    "icofont-share",
    "icofont-shield",
    "icofont-shopping-cart",
    "icofont-sign-in",
    "icofont-sign-out",
    "icofont-signal",
    "icofont-site-map",
    "icofont-smart-phone",
    "icofont-soccer",
    "icofont-sort-alt",
    "icofont-sort",
    "icofont-space",
    "icofont-spanner",
    "icofont-speech-comments",
    "icofont-speed-meter",
    "icofont-spinner-alt-1",
    "icofont-spinner-alt-2",
    "icofont-spinner-alt-3",
    "icofont-spinner-alt-4",
    "icofont-spinner-alt-5",
    "icofont-spinner-alt-6",
    "icofont-spinner",
    "icofont-spreadsheet",
    "icofont-square",
    "icofont-ssl-security",
    "icofont-star-alt-1",
    "icofont-star-alt-2",
    "icofont-star",
    "icofont-street-view",
    "icofont-support-faq",
    "icofont-tack-pin",
    "icofont-tag",
    "icofont-tags",
    "icofont-tasks-alt",
    "icofont-tasks",
    "icofont-telephone",
    "icofont-telescope",
    "icofont-terminal",
    "icofont-thumbs-down",
    "icofont-thumbs-up",
    "icofont-tick-boxed",
    "icofont-tick-mark",
    "icofont-ticket",
    "icofont-tie",
    "icofont-toggle-off",
    "icofont-toggle-on",
    "icofont-tools-alt-2",
    "icofont-tools",
    "icofont-touch",
    "icofont-traffic-light",
    "icofont-transparent",
    "icofont-tree",
    "icofont-unique-idea",
    "icofont-unlock",
    "icofont-unlocked",
    "icofont-upload-alt",
    "icofont-upload",
    "icofont-usb-drive",
    "icofont-usb",
    "icofont-vector-path",
    "icofont-verification-check",
    "icofont-wall-clock",
    "icofont-wall",
    "icofont-wallet",
    "icofont-warning-alt",
    "icofont-warning",
    "icofont-water-drop",
    "icofont-web",
    "icofont-wheelchair",
    "icofont-wifi-alt",
    "icofont-wifi",
    "icofont-world",
    "icofont-zigzag",
    "icofont-zipped"
]

export const ICOFONT_BRANDS: string[] = [
    "icofont-brand-acer",
    "icofont-brand-adidas",
    "icofont-brand-adobe",
    "icofont-brand-air-new-zealand",
    "icofont-brand-airbnb",
    "icofont-brand-aircell",
    "icofont-brand-airtel",
    "icofont-brand-alcatel",
    "icofont-brand-alibaba",
    "icofont-brand-aliexpress",
    "icofont-brand-alipay",
    "icofont-brand-amazon",
    "icofont-brand-amd",
    "icofont-brand-american-airlines",
    "icofont-brand-android-robot",
    "icofont-brand-android",
    "icofont-brand-aol",
    "icofont-brand-apple",
    "icofont-brand-appstore",
    "icofont-brand-asus",
    "icofont-brand-ati",
    "icofont-brand-att",
    "icofont-brand-audi",
    "icofont-brand-axiata",
    "icofont-brand-bada",
    "icofont-brand-bbc",
    "icofont-brand-bing",
    "icofont-brand-blackberry",
    "icofont-brand-bmw",
    "icofont-brand-box",
    "icofont-brand-burger-king",
    "icofont-brand-business-insider",
    "icofont-brand-buzzfeed",
    "icofont-brand-cannon",
    "icofont-brand-casio",
    "icofont-brand-china-mobile",
    "icofont-brand-china-telecom",
    "icofont-brand-china-unicom",
    "icofont-brand-cisco",
    "icofont-brand-citibank",
    "icofont-brand-cnet",
    "icofont-brand-cnn",
    "icofont-brand-cocal-cola",
    "icofont-brand-compaq",
    "icofont-brand-debian",
    "icofont-brand-delicious",
    "icofont-brand-dell",
    "icofont-brand-designbump",
    "icofont-brand-designfloat",
    "icofont-brand-disney",
    "icofont-brand-dodge",
    "icofont-brand-dove",
    "icofont-brand-drupal",
    "icofont-brand-ebay",
    "icofont-brand-eleven",
    "icofont-brand-emirates",
    "icofont-brand-espn",
    "icofont-brand-etihad-airways",
    "icofont-brand-etisalat",
    "icofont-brand-etsy",
    "icofont-brand-fastrack",
    "icofont-brand-fedex",
    "icofont-brand-ferrari",
    "icofont-brand-fitbit",
    "icofont-brand-flikr",
    "icofont-brand-forbes",
    "icofont-brand-foursquare",
    "icofont-brand-foxconn",
    "icofont-brand-fujitsu",
    "icofont-brand-general-electric",
    "icofont-brand-gillette",
    "icofont-brand-gizmodo",
    "icofont-brand-gnome",
    "icofont-brand-google",
    "icofont-brand-gopro",
    "icofont-brand-gucci",
    "icofont-brand-hallmark",
    "icofont-brand-hi5",
    "icofont-brand-honda",
    "icofont-brand-hp",
    "icofont-brand-hsbc",
    "icofont-brand-htc",
    "icofont-brand-huawei",
    "icofont-brand-hulu",
    "icofont-brand-hyundai",
    "icofont-brand-ibm",
    "icofont-brand-icofont",
    "icofont-brand-icq",
    "icofont-brand-ikea",
    "icofont-brand-imdb",
    "icofont-brand-indiegogo",
    "icofont-brand-intel",
    "icofont-brand-ipair",
    "icofont-brand-jaguar",
    "icofont-brand-java",
    "icofont-brand-joomla",
    "icofont-brand-kickstarter",
    "icofont-brand-kik",
    "icofont-brand-lastfm",
    "icofont-brand-lego",
    "icofont-brand-lenovo",
    "icofont-brand-levis",
    "icofont-brand-lexus",
    "icofont-brand-lg",
    "icofont-brand-life-hacker",
    "icofont-brand-linux-mint",
    "icofont-brand-linux",
    "icofont-brand-lionix",
    "icofont-brand-loreal",
    "icofont-brand-louis-vuitton",
    "icofont-brand-mac-os",
    "icofont-brand-marvel-app",
    "icofont-brand-mashable",
    "icofont-brand-mazda",
    "icofont-brand-mcdonals",
    "icofont-brand-mercedes",
    "icofont-brand-micromax",
    "icofont-brand-microsoft",
    "icofont-brand-mobileme",
    "icofont-brand-mobily",
    "icofont-brand-motorola",
    "icofont-brand-msi",
    "icofont-brand-mts",
    "icofont-brand-myspace",
    "icofont-brand-mytv",
    "icofont-brand-nasa",
    "icofont-brand-natgeo",
    "icofont-brand-nbc",
    "icofont-brand-nescafe",
    "icofont-brand-nestle",
    "icofont-brand-netflix",
    "icofont-brand-nexus",
    "icofont-brand-nike",
    "icofont-brand-nokia",
    "icofont-brand-nvidia",
    "icofont-brand-omega",
    "icofont-brand-opensuse",
    "icofont-brand-oracle",
    "icofont-brand-panasonic",
    "icofont-brand-paypal",
    "icofont-brand-pepsi",
    "icofont-brand-philips",
    "icofont-brand-pizza-hut",
    "icofont-brand-playstation",
    "icofont-brand-puma",
    "icofont-brand-qatar-air",
    "icofont-brand-qvc",
    "icofont-brand-readernaut",
    "icofont-brand-redbull",
    "icofont-brand-reebok",
    "icofont-brand-reuters",
    "icofont-brand-samsung",
    "icofont-brand-sap",
    "icofont-brand-saudia-airlines",
    "icofont-brand-scribd",
    "icofont-brand-shell",
    "icofont-brand-siemens",
    "icofont-brand-sk-telecom",
    "icofont-brand-slideshare",
    "icofont-brand-smashing-magazine",
    "icofont-brand-snapchat",
    "icofont-brand-sony-ericsson",
    "icofont-brand-sony",
    "icofont-brand-soundcloud",
    "icofont-brand-sprint",
    "icofont-brand-squidoo",
    "icofont-brand-starbucks",
    "icofont-brand-stc",
    "icofont-brand-steam",
    "icofont-brand-suzuki",
    "icofont-brand-symbian",
    "icofont-brand-t-mobile",
    "icofont-brand-tango",
    "icofont-brand-target",
    "icofont-brand-tata-indicom",
    "icofont-brand-techcrunch",
    "icofont-brand-telenor",
    "icofont-brand-teliasonera",
    "icofont-brand-tesla",
    "icofont-brand-the-verge",
    "icofont-brand-thenextweb",
    "icofont-brand-toshiba",
    "icofont-brand-toyota",
    "icofont-brand-tribenet",
    "icofont-brand-ubuntu",
    "icofont-brand-unilever",
    "icofont-brand-vaio",
    "icofont-brand-verizon",
    "icofont-brand-viber",
    "icofont-brand-vodafone",
    "icofont-brand-volkswagen",
    "icofont-brand-walmart",
    "icofont-brand-warnerbros",
    "icofont-brand-whatsapp",
    "icofont-brand-wikipedia",
    "icofont-brand-windows",
    "icofont-brand-wire",
    "icofont-brand-wordpress",
    "icofont-brand-xiaomi",
    "icofont-brand-yahoobuzz",
    "icofont-brand-yamaha",
    "icofont-brand-youtube",
    "icofont-brand-zain"
];

export const ICOFONT_DIRECTIONAL: string[] = [
    "icofont-arrow-down",
    "icofont-arrow-left",
    "icofont-arrow-right",
    "icofont-arrow-up",
    "icofont-block-down",
    "icofont-block-left",
    "icofont-block-right",
    "icofont-block-up",
    "icofont-bubble-down",
    "icofont-bubble-left",
    "icofont-bubble-right",
    "icofont-bubble-up",
    "icofont-caret-down",
    "icofont-caret-left",
    "icofont-caret-right",
    "icofont-caret-up",
    "icofont-circled-down",
    "icofont-circled-left",
    "icofont-circled-right",
    "icofont-circled-up",
    "icofont-collapse",
    "icofont-cursor-drag",
    "icofont-curved-double-left",
    "icofont-curved-double-right",
    "icofont-curved-down",
    "icofont-curved-left",
    "icofont-curved-right",
    "icofont-curved-up",
    "icofont-dotted-down",
    "icofont-dotted-left",
    "icofont-dotted-right",
    "icofont-dotted-up",
    "icofont-double-left",
    "icofont-double-right",
    "icofont-expand-alt",
    "icofont-hand-down",
    "icofont-hand-drag",
    "icofont-hand-drag1",
    "icofont-hand-drag2",
    "icofont-hand-drawn-alt-down",
    "icofont-hand-drawn-alt-left",
    "icofont-hand-drawn-alt-right",
    "icofont-hand-drawn-alt-up",
    "icofont-hand-drawn-down",
    "icofont-hand-drawn-left",
    "icofont-hand-drawn-right",
    "icofont-hand-drawn-up",
    "icofont-hand-grippers",
    "icofont-hand-left",
    "icofont-hand-right",
    "icofont-hand-up",
    "icofont-line-block-down",
    "icofont-line-block-left",
    "icofont-line-block-right",
    "icofont-line-block-up",
    "icofont-long-arrow-down",
    "icofont-long-arrow-left",
    "icofont-long-arrow-right",
    "icofont-long-arrow-up",
    "icofont-rounded-collapse",
    "icofont-rounded-double-left",
    "icofont-rounded-double-right",
    "icofont-rounded-down",
    "icofont-rounded-expand",
    "icofont-rounded-left-down",
    "icofont-rounded-left-up",
    "icofont-rounded-left",
    "icofont-rounded-right-down",
    "icofont-rounded-right-up",
    "icofont-rounded-right",
    "icofont-rounded-up",
    "icofont-scroll-bubble-down",
    "icofont-scroll-bubble-left",
    "icofont-scroll-bubble-right",
    "icofont-scroll-bubble-up",
    "icofont-scroll-double-down",
    "icofont-scroll-double-left",
    "icofont-scroll-double-right",
    "icofont-scroll-double-up",
    "icofont-scroll-down",
    "icofont-scroll-left",
    "icofont-scroll-long-down",
    "icofont-scroll-long-left",
    "icofont-scroll-long-right",
    "icofont-scroll-long-up",
    "icofont-scroll-right",
    "icofont-scroll-up",
    "icofont-simple-down",
    "icofont-simple-left-down",
    "icofont-simple-left-up",
    "icofont-simple-left",
    "icofont-simple-right-down",
    "icofont-simple-right-up",
    "icofont-simple-right",
    "icofont-simple-up",
    "icofont-square-down",
    "icofont-square-left",
    "icofont-square-right",
    "icofont-square-up",
    "icofont-stylish-down",
    "icofont-stylish-left",
    "icofont-stylish-right",
    "icofont-stylish-up",
    "icofont-swoosh-down",
    "icofont-swoosh-left",
    "icofont-swoosh-right",
    "icofont-swoosh-up",
    "icofont-thin-double-left",
    "icofont-thin-double-right",
    "icofont-thin-down",
    "icofont-thin-left",
    "icofont-thin-right",
    "icofont-thin-up"
];

export const ICOFONT_SOCIAL: string[] = [
    "icofont-500px",
    "icofont-aim",
    "icofont-badoo",
    "icofont-baidu-tieba",
    "icofont-bbm-messenger",
    "icofont-bebo",
    "icofont-behance",
    "icofont-blogger",
    "icofont-bootstrap",
    "icofont-brightkite",
    "icofont-cloudapp",
    "icofont-concrete5",
    "icofont-delicious",
    "icofont-designbump",
    "icofont-designfloat",
    "icofont-deviantart",
    "icofont-digg",
    "icofont-dotcms",
    "icofont-dribbble",
    "icofont-dribble",
    "icofont-dropbox",
    "icofont-ebuddy",
    "icofont-ello",
    "icofont-ember",
    "icofont-envato",
    "icofont-evernote",
    "icofont-facebook-messenger",
    "icofont-facebook",
    "icofont-feedburner",
    "icofont-flikr",
    "icofont-folkd",
    "icofont-foursquare",
    "icofont-friendfeed",
    "icofont-ghost",
    "icofont-github",
    "icofont-gnome",
    "icofont-google-buzz",
    "icofont-google-hangouts",
    "icofont-google-map",
    "icofont-google-plus",
    "icofont-google-talk",
    "icofont-hype-machine",
    "icofont-instagram",
    "icofont-kakaotalk",
    "icofont-kickstarter",
    "icofont-kik",
    "icofont-kiwibox",
    "icofont-line-messenger",
    "icofont-line",
    "icofont-linkedin",
    "icofont-linux-mint",
    "icofont-live-messenger",
    "icofont-livejournal",
    "icofont-magento",
    "icofont-meetme",
    "icofont-meetup",
    "icofont-mixx",
    "icofont-newsvine",
    "icofont-nimbuss",
    "icofont-odnoklassniki",
    "icofont-opencart",
    "icofont-oscommerce",
    "icofont-pandora",
    "icofont-photobucket",
    "icofont-picasa",
    "icofont-pinterest",
    "icofont-prestashop",
    "icofont-qik",
    "icofont-qq",
    "icofont-readernaut",
    "icofont-reddit",
    "icofont-renren",
    "icofont-rss",
    "icofont-shopify",
    "icofont-silverstripe",
    "icofont-skype",
    "icofont-slack",
    "icofont-slashdot",
    "icofont-slidshare",
    "icofont-smugmug",
    "icofont-snapchat",
    "icofont-soundcloud",
    "icofont-spotify",
    "icofont-stack-exchange",
    "icofont-stack-overflow",
    "icofont-steam",
    "icofont-stumbleupon",
    "icofont-tagged",
    "icofont-technorati",
    "icofont-telegram",
    "icofont-tinder",
    "icofont-trello",
    "icofont-tumblr",
    "icofont-twitch",
    "icofont-twitter",
    "icofont-typo3",
    "icofont-ubercart",
    "icofont-viber",
    "icofont-viddler",
    "icofont-vimeo",
    "icofont-vine",
    "icofont-virb",
    "icofont-virtuemart",
    "icofont-vk",
    "icofont-wechat",
    "icofont-weibo",
    "icofont-whatsapp",
    "icofont-xing",
    "icofont-yahoo",
    "icofont-yelp",
    "icofont-youku",
    "icofont-youtube",
    "icofont-zencart"
];

export const ICOFONT_ANIMAL: string[] = [
    "icofont-bat",
    "icofont-bear-face",
    "icofont-bear-tracks",
    "icofont-bear",
    "icofont-bird-alt",
    "icofont-bird-flying",
    "icofont-bird",
    "icofont-birds",
    "icofont-bone",
    "icofont-bull",
    "icofont-butterfly-alt",
    "icofont-butterfly",
    "icofont-camel-alt",
    "icofont-camel-head",
    "icofont-camel",
    "icofont-cat-alt-1",
    "icofont-cat-alt-2",
    "icofont-cat-alt-3",
    "icofont-cat-dog",
    "icofont-cat-face",
    "icofont-cat",
    "icofont-cow-head",
    "icofont-cow",
    "icofont-crab",
    "icofont-crocodile",
    "icofont-deer-head",
    "icofont-dog-alt",
    "icofont-dog-barking",
    "icofont-dog",
    "icofont-dolphin",
    "icofont-duck-tracks",
    "icofont-eagle-head",
    "icofont-eaten-fish",
    "icofont-elephant-alt",
    "icofont-elephant-head-alt",
    "icofont-elephant-head",
    "icofont-elephant",
    "icofont-elk",
    "icofont-fish-1",
    "icofont-fish-2",
    "icofont-fish-3",
    "icofont-fish-4",
    "icofont-fish-5",
    "icofont-fish",
    "icofont-fox-alt",
    "icofont-fox",
    "icofont-frog-tracks",
    "icofont-frog",
    "icofont-froggy",
    "icofont-giraffe-head-1",
    "icofont-giraffe-head-2",
    "icofont-giraffe-head",
    "icofont-giraffe",
    "icofont-goat-head",
    "icofont-gorilla",
    "icofont-hen-tracks",
    "icofont-horse-head-1",
    "icofont-horse-head-2",
    "icofont-horse-head",
    "icofont-horse-tracks",
    "icofont-jellyfish",
    "icofont-kangaroo",
    "icofont-lemur",
    "icofont-lion-head-1",
    "icofont-lion-head-2",
    "icofont-lion-head",
    "icofont-lion",
    "icofont-monkey-2",
    "icofont-monkey-3",
    "icofont-monkey-face",
    "icofont-monkey",
    "icofont-octopus-alt",
    "icofont-octopus",
    "icofont-owl",
    "icofont-panda-face",
    "icofont-panda",
    "icofont-panther",
    "icofont-parrot-lip",
    "icofont-parrot",
    "icofont-paw",
    "icofont-pelican",
    "icofont-penguin",
    "icofont-pig-face",
    "icofont-pig",
    "icofont-pigeon-1",
    "icofont-pigeon-2",
    "icofont-pigeon",
    "icofont-rabbit",
    "icofont-rat",
    "icofont-rhino-head",
    "icofont-rhino",
    "icofont-rooster",
    "icofont-seahorse",
    "icofont-seal",
    "icofont-shrimp-alt",
    "icofont-shrimp",
    "icofont-snail-1",
    "icofont-snail-2",
    "icofont-snail-3",
    "icofont-snail",
    "icofont-snake",
    "icofont-squid",
    "icofont-squirrel",
    "icofont-tiger-face",
    "icofont-tiger",
    "icofont-turtle",
    "icofont-whale",
    "icofont-woodpecker",
    "icofont-zebra"
];

export const ICOFONT_CURRENCY: string[] = [
    "icofont-afghani-false",
    "icofont-afghani-minus",
    "icofont-afghani-plus",
    "icofont-afghani-true",
    "icofont-afghani",
    "icofont-baht-false",
    "icofont-baht-minus",
    "icofont-baht-plus",
    "icofont-baht-true",
    "icofont-baht",
    "icofont-bitcoin-false",
    "icofont-bitcoin-minus",
    "icofont-bitcoin-plus",
    "icofont-bitcoin-true",
    "icofont-bitcoin",
    "icofont-dollar-flase",
    "icofont-dollar-minus",
    "icofont-dollar-plus",
    "icofont-dollar-true",
    "icofont-dollar",
    "icofont-dong-false",
    "icofont-dong-minus",
    "icofont-dong-plus",
    "icofont-dong-true",
    "icofont-dong",
    "icofont-euro-false",
    "icofont-euro-minus",
    "icofont-euro-plus",
    "icofont-euro-true",
    "icofont-euro",
    "icofont-frank-false",
    "icofont-frank-minus",
    "icofont-frank-plus",
    "icofont-frank-true",
    "icofont-frank",
    "icofont-hryvnia-false",
    "icofont-hryvnia-minus",
    "icofont-hryvnia-plus",
    "icofont-hryvnia-true",
    "icofont-hryvnia",
    "icofont-lira-false",
    "icofont-lira-minus",
    "icofont-lira-plus",
    "icofont-lira-true",
    "icofont-lira",
    "icofont-peseta-false",
    "icofont-peseta-minus",
    "icofont-peseta-plus",
    "icofont-peseta-true",
    "icofont-peseta",
    "icofont-peso-false",
    "icofont-peso-minus",
    "icofont-peso-plus",
    "icofont-peso-true",
    "icofont-peso",
    "icofont-pound-false",
    "icofont-pound-minus",
    "icofont-pound-plus",
    "icofont-pound-true",
    "icofont-pound",
    "icofont-renminbi-false",
    "icofont-renminbi-minus",
    "icofont-renminbi-plus",
    "icofont-renminbi-true",
    "icofont-renminbi",
    "icofont-riyal-false",
    "icofont-riyal-minus",
    "icofont-riyal-plus",
    "icofont-riyal-true",
    "icofont-riyal",
    "icofont-rouble-false",
    "icofont-rouble-minus",
    "icofont-rouble-plus",
    "icofont-rouble-true",
    "icofont-rouble",
    "icofont-rupee-false",
    "icofont-rupee-minus",
    "icofont-rupee-plus",
    "icofont-rupee-true",
    "icofont-rupee",
    "icofont-taka-false",
    "icofont-taka-minus",
    "icofont-taka-plus",
    "icofont-taka-true",
    "icofont-taka",
    "icofont-turkish-lira-false",
    "icofont-turkish-lira-minus",
    "icofont-turkish-lira-plus",
    "icofont-turkish-lira-true",
    "icofont-turkish-lira",
    "icofont-won-false",
    "icofont-won-minus",
    "icofont-won-plus",
    "icofont-won-true",
    "icofont-won",
    "icofont-yen-false",
    "icofont-yen-minus",
    "icofont-yen-plus",
    "icofont-yen-true",
    "icofont-yen"
];

export const ICOFONT_FOOD: string[] = [
    "icofont-apple",
    "icofont-arabian-coffee",
    "icofont-artichoke",
    "icofont-asparagus",
    "icofont-avocado",
    "icofont-baby-food",
    "icofont-banana",
    "icofont-bbq",
    "icofont-beans",
    "icofont-beer",
    "icofont-bell-pepper-capsicum",
    "icofont-birthday-cake",
    "icofont-bread",
    "icofont-broccoli",
    "icofont-burger",
    "icofont-cabbage",
    "icofont-carrot",
    "icofont-cauli-flower",
    "icofont-cheese",
    "icofont-chef",
    "icofont-cherry",
    "icofont-chicken-fry",
    "icofont-chicken",
    "icofont-cocktail",
    "icofont-coconut-water",
    "icofont-coconut",
    "icofont-coffee-alt",
    "icofont-coffee-cup",
    "icofont-coffee-mug",
    "icofont-coffee-pot",
    "icofont-cola",
    "icofont-corn",
    "icofont-croissant",
    "icofont-crop-plant",
    "icofont-cucumber",
    "icofont-culinary",
    "icofont-cup-cake",
    "icofont-dining-table",
    "icofont-donut",
    "icofont-egg-plant",
    "icofont-egg-poached",
    "icofont-farmer-alt",
    "icofont-farmer",
    "icofont-fast-food",
    "icofont-food-basket",
    "icofont-food-cart",
    "icofont-fork-and-knife",
    "icofont-french-fries",
    "icofont-fruits",
    "icofont-grapes",
    "icofont-honey",
    "icofont-hot-dog",
    "icofont-ice-cream-alt",
    "icofont-ice-cream",
    "icofont-juice",
    "icofont-ketchup",
    "icofont-kiwi",
    "icofont-layered-cake",
    "icofont-lemon-alt",
    "icofont-lemon",
    "icofont-lobster",
    "icofont-mango",
    "icofont-milk",
    "icofont-mushroom",
    "icofont-noodles",
    "icofont-onion",
    "icofont-orange",
    "icofont-pear",
    "icofont-peas",
    "icofont-pepper",
    "icofont-pie-alt",
    "icofont-pie",
    "icofont-pineapple",
    "icofont-pizza-slice",
    "icofont-pizza",
    "icofont-plant",
    "icofont-popcorn",
    "icofont-potato",
    "icofont-pumpkin",
    "icofont-raddish",
    "icofont-restaurant-menu",
    "icofont-restaurant",
    "icofont-salt-and-pepper",
    "icofont-sandwich",
    "icofont-sausage",
    "icofont-soft-drinks",
    "icofont-soup-bowl",
    "icofont-spoon-and-fork",
    "icofont-steak",
    "icofont-strawberry",
    "icofont-sub-sandwich",
    "icofont-sushi",
    "icofont-taco",
    "icofont-tea-pot",
    "icofont-tea",
    "icofont-tomato",
    "icofont-watermelon",
    "icofont-wheat"
];

export const ICOFONT_MOBILE_UI: string[] = [
    "icofont-ui-add",
    "icofont-ui-alarm",
    "icofont-ui-battery",
    "icofont-ui-block",
    "icofont-ui-bluetooth",
    "icofont-ui-brightness",
    "icofont-ui-browser",
    "icofont-ui-calendar",
    "icofont-ui-call",
    "icofont-ui-camera",
    "icofont-ui-cart",
    "icofont-ui-cell-phone",
    "icofont-ui-chat",
    "icofont-ui-check",
    "icofont-ui-clip-board",
    "icofont-ui-clip",
    "icofont-ui-clock",
    "icofont-ui-close",
    "icofont-ui-contact-list",
    "icofont-ui-copy",
    "icofont-ui-cut",
    "icofont-ui-delete",
    "icofont-ui-dial-phone",
    "icofont-ui-edit",
    "icofont-ui-email",
    "icofont-ui-file",
    "icofont-ui-fire-wall",
    "icofont-ui-flash-light",
    "icofont-ui-flight",
    "icofont-ui-folder",
    "icofont-ui-game",
    "icofont-ui-handicapped",
    "icofont-ui-home",
    "icofont-ui-image",
    "icofont-ui-laoding",
    "icofont-ui-lock",
    "icofont-ui-love-add",
    "icofont-ui-love-broken",
    "icofont-ui-love-remove",
    "icofont-ui-love",
    "icofont-ui-map",
    "icofont-ui-message",
    "icofont-ui-messaging",
    "icofont-ui-movie",
    "icofont-ui-music-player",
    "icofont-ui-music",
    "icofont-ui-mute",
    "icofont-ui-network",
    "icofont-ui-next",
    "icofont-ui-note",
    "icofont-ui-office",
    "icofont-ui-password",
    "icofont-ui-pause",
    "icofont-ui-play-stop",
    "icofont-ui-play",
    "icofont-ui-pointer",
    "icofont-ui-power",
    "icofont-ui-press",
    "icofont-ui-previous",
    "icofont-ui-rate-add",
    "icofont-ui-rate-blank",
    "icofont-ui-rate-remove",
    "icofont-ui-rating",
    "icofont-ui-record",
    "icofont-ui-remove",
    "icofont-ui-reply",
    "icofont-ui-rotation",
    "icofont-ui-rss",
    "icofont-ui-search",
    "icofont-ui-settings",
    "icofont-ui-social-link",
    "icofont-ui-tag",
    "icofont-ui-text-chat",
    "icofont-ui-text-loading",
    "icofont-ui-theme",
    "icofont-ui-timer",
    "icofont-ui-touch-phone",
    "icofont-ui-travel",
    "icofont-ui-unlock",
    "icofont-ui-user-group",
    "icofont-ui-user",
    "icofont-ui-v-card",
    "icofont-ui-video-chat",
    "icofont-ui-video-message",
    "icofont-ui-video-play",
    "icofont-ui-video",
    "icofont-ui-volume",
    "icofont-ui-weather",
    "icofont-ui-wifi",
    "icofont-ui-zoom-in",
    "icofont-ui-zoom-out"
];

export const ICOFONT_SPORT: string[] = [
    "icofont-badminton-birdie",
    "icofont-baseball",
    "icofont-baseballer",
    "icofont-basketball-hoop",
    "icofont-basketball",
    "icofont-billiard-ball",
    "icofont-boot-alt-1",
    "icofont-boot-alt-2",
    "icofont-boot",
    "icofont-bowling-alt",
    "icofont-bowling",
    "icofont-canoe",
    "icofont-cheer-leader",
    "icofont-climbing",
    "icofont-corner",
    "icofont-field-alt",
    "icofont-field",
    "icofont-football-alt",
    "icofont-football-american",
    "icofont-football",
    "icofont-foul",
    "icofont-goal-keeper",
    "icofont-goal",
    "icofont-golf-alt",
    "icofont-golf-bag",
    "icofont-golf-cart",
    "icofont-golf-field",
    "icofont-golf",
    "icofont-golfer",
    "icofont-helmet",
    "icofont-hockey-alt",
    "icofont-hockey",
    "icofont-ice-skate",
    "icofont-jersey-alt",
    "icofont-jersey",
    "icofont-jumping",
    "icofont-kick",
    "icofont-leg",
    "icofont-match-review",
    "icofont-medal-sport",
    "icofont-offside",
    "icofont-olympic-logo",
    "icofont-olympic",
    "icofont-padding",
    "icofont-penalty-card",
    "icofont-racer",
    "icofont-racing-car",
    "icofont-racing-flag-alt",
    "icofont-racing-flag",
    "icofont-racings-wheel",
    "icofont-referee",
    "icofont-refree-jersey",
    "icofont-result-sport",
    "icofont-rugby-ball",
    "icofont-rugby-player",
    "icofont-rugby",
    "icofont-runner-alt-1",
    "icofont-runner-alt-2",
    "icofont-runner",
    "icofont-score-board",
    "icofont-skiing-man",
    "icofont-skydiving-goggles",
    "icofont-snow-mobile",
    "icofont-steering",
    "icofont-stopwatch",
    "icofont-substitute",
    "icofont-swimmer",
    "icofont-table-tennis",
    "icofont-team-alt",
    "icofont-team",
    "icofont-tennis-player",
    "icofont-tennis",
    "icofont-tracking",
    "icofont-trophy-alt",
    "icofont-trophy",
    "icofont-volleyball-alt",
    "icofont-volleyball-fire",
    "icofont-volleyball",
    "icofont-water-bottle",
    "icofont-whistle-alt",
    "icofont-whistle",
    "icofont-win-trophy"
];

export const ICOFONT_WEATHER: string[] = [
    "icofont-breakdown",
    "icofont-celsius",
    "icofont-clouds",
    "icofont-cloudy",
    "icofont-dust",
    "icofont-eclipse",
    "icofont-fahrenheit",
    "icofont-forest-fire",
    "icofont-full-night",
    "icofont-full-sunny",
    "icofont-hail-night",
    "icofont-hail-rainy-night",
    "icofont-hail-rainy-sunny",
    "icofont-hail-rainy",
    "icofont-hail-sunny",
    "icofont-hail-thunder-night",
    "icofont-hail-thunder-sunny",
    "icofont-hail-thunder",
    "icofont-hail",
    "icofont-hill-night",
    "icofont-hill-sunny",
    "icofont-hurricane",
    "icofont-meteor",
    "icofont-night",
    "icofont-rainy-night",
    "icofont-rainy-sunny",
    "icofont-rainy-thunder",
    "icofont-rainy",
    "icofont-snow-alt",
    "icofont-snow-flake",
    "icofont-snow-temp",
    "icofont-snow",
    "icofont-snowy-hail",
    "icofont-snowy-night-hail",
    "icofont-snowy-night-rainy",
    "icofont-snowy-night",
    "icofont-snowy-rainy",
    "icofont-snowy-sunny-hail",
    "icofont-snowy-sunny-rainy",
    "icofont-snowy-sunny",
    "icofont-snowy-thunder-night",
    "icofont-snowy-thunder-sunny",
    "icofont-snowy-thunder",
    "icofont-snowy-windy-night",
    "icofont-snowy-windy-sunny",
    "icofont-snowy-windy",
    "icofont-snowy",
    "icofont-sun-alt",
    "icofont-sun-rise",
    "icofont-sun-set",
    "icofont-sun",
    "icofont-sunny-day-temp",
    "icofont-sunny",
    "icofont-thunder-light",
    "icofont-tornado",
    "icofont-umbrella-alt",
    "icofont-umbrella",
    "icofont-volcano",
    "icofont-wave",
    "icofont-wind-scale-0",
    "icofont-wind-scale-1",
    "icofont-wind-scale-10",
    "icofont-wind-scale-11",
    "icofont-wind-scale-12",
    "icofont-wind-scale-2",
    "icofont-wind-scale-3",
    "icofont-wind-scale-4",
    "icofont-wind-scale-5",
    "icofont-wind-scale-6",
    "icofont-wind-scale-7",
    "icofont-wind-scale-8",
    "icofont-wind-scale-9",
    "icofont-wind-waves",
    "icofont-wind",
    "icofont-windy-hail",
    "icofont-windy-night",
    "icofont-windy-raining",
    "icofont-windy-sunny",
    "icofont-windy-thunder-raining",
    "icofont-windy-thunder",
    "icofont-windy"
];

export const ICOFONT_CONSTRUCTION: string[] = [
    "icofont-architecture-alt",
    "icofont-architecture",
    "icofont-barricade",
    "icofont-bolt",
    "icofont-bricks",
    "icofont-building-alt",
    "icofont-bull-dozer",
    "icofont-calculations",
    "icofont-cement-mix",
    "icofont-cement-mixer",
    "icofont-concrete-mixer",
    "icofont-danger-zone",
    "icofont-drill",
    "icofont-eco-energy",
    "icofont-eco-environmen",
    "icofont-energy-air",
    "icofont-energy-oil",
    "icofont-energy-savings",
    "icofont-energy-solar",
    "icofont-energy-water",
    "icofont-engineer",
    "icofont-fire-extinguisher-alt",
    "icofont-fire-extinguisher",
    "icofont-fix-tools",
    "icofont-fork-lift",
    "icofont-glue-oil",
    "icofont-hammer-alt",
    "icofont-hammer",
    "icofont-help-robot",
    "icofont-industries-1",
    "icofont-industries-2",
    "icofont-industries-3",
    "icofont-industries-4",
    "icofont-industries-5",
    "icofont-industries",
    "icofont-labour",
    "icofont-mining",
    "icofont-paint-brush",
    "icofont-pollution",
    "icofont-power-zone",
    "icofont-radio-active",
    "icofont-recycle-alt",
    "icofont-recycling-man",
    "icofont-safety-hat-light",
    "icofont-safety-hat",
    "icofont-saw",
    "icofont-screw-driver",
    "icofont-tools-1",
    "icofont-tools-bag",
    "icofont-tow-truck",
    "icofont-trolley",
    "icofont-trowel",
    "icofont-under-construction-alt",
    "icofont-under-construction",
    "icofont-vehicle-cement",
    "icofont-vehicle-crane",
    "icofont-vehicle-delivery-van",
    "icofont-vehicle-dozer",
    "icofont-vehicle-excavator",
    "icofont-vehicle-trucktor",
    "icofont-vehicle-wrecking",
    "icofont-worker",
    "icofont-workers-group",
    "icofont-wrench"
];

export const ICOFONT_MEDICAL: string[] = [
    "icofont-aids",
    "icofont-ambulance-crescent",
    "icofont-ambulance-cross",
    "icofont-ambulance",
    "icofont-autism",
    "icofont-bandage",
    "icofont-blind",
    "icofont-blood-drop",
    "icofont-blood-test",
    "icofont-blood",
    "icofont-brain-alt",
    "icofont-brain",
    "icofont-capsule",
    "icofont-crutch",
    "icofont-disabled",
    "icofont-dna-alt-1",
    "icofont-dna-alt-2",
    "icofont-dna",
    "icofont-doctor-alt",
    "icofont-doctor",
    "icofont-drug-pack",
    "icofont-drug",
    "icofont-first-aid-alt",
    "icofont-first-aid",
    "icofont-heart-beat-alt",
    "icofont-heart-beat",
    "icofont-heartbeat",
    "icofont-herbal",
    "icofont-hospital",
    "icofont-icu",
    "icofont-injection-syringe",
    "icofont-laboratory",
    "icofont-medical-sign-alt",
    "icofont-medical-sign",
    "icofont-nurse-alt",
    "icofont-nurse",
    "icofont-nursing-home",
    "icofont-operation-theater",
    "icofont-paralysis-disability",
    "icofont-patient-bed",
    "icofont-patient-file",
    "icofont-pills",
    "icofont-prescription",
    "icofont-pulse",
    "icofont-stethoscope-alt",
    "icofont-stethoscope",
    "icofont-stretcher",
    "icofont-surgeon-alt",
    "icofont-surgeon",
    "icofont-tablets",
    "icofont-test-bottle",
    "icofont-test-tube",
    "icofont-thermometer-alt",
    "icofont-thermometer",
    "icofont-tooth",
    "icofont-xray"
];

export const ICOFONT_TRANSPORT: string[] = [
    "icofont-air-balloon",
    "icofont-airplane-alt",
    "icofont-airplane",
    "icofont-articulated-truck",
    "icofont-auto-mobile",
    "icofont-auto-rickshaw",
    "icofont-bicycle-alt-1",
    "icofont-bicycle-alt-2",
    "icofont-bicycle",
    "icofont-bus-alt-1",
    "icofont-bus-alt-2",
    "icofont-bus-alt-3",
    "icofont-bus",
    "icofont-cab",
    "icofont-cable-car",
    "icofont-car-alt-1",
    "icofont-car-alt-2",
    "icofont-car-alt-3",
    "icofont-car-alt-4",
    "icofont-car",
    "icofont-delivery-time",
    "icofont-fast-delivery",
    "icofont-fire-truck-alt",
    "icofont-fire-truck",
    "icofont-free-delivery",
    "icofont-helicopter",
    "icofont-motor-bike-alt",
    "icofont-motor-bike",
    "icofont-motor-biker",
    "icofont-oil-truck",
    "icofont-rickshaw",
    "icofont-rocket-alt-1",
    "icofont-rocket-alt-2",
    "icofont-rocket",
    "icofont-sail-boat-alt-1",
    "icofont-sail-boat-alt-2",
    "icofont-sail-boat",
    "icofont-scooter",
    "icofont-sea-plane",
    "icofont-ship-alt",
    "icofont-ship",
    "icofont-speed-boat",
    "icofont-taxi",
    "icofont-tractor",
    "icofont-train-line",
    "icofont-train-steam",
    "icofont-tram",
    "icofont-truck-alt",
    "icofont-truck-loaded",
    "icofont-truck",
    "icofont-van-alt",
    "icofont-van",
    "icofont-yacht"
];

export const ICOFONT_DEVICE: string[] = [
    "icofont-android-nexus",
    "icofont-android-tablet",
    "icofont-apple-watch",
    "icofont-drawing-tablet",
    "icofont-earphone",
    "icofont-flash-drive",
    "icofont-game-console",
    "icofont-game-controller",
    "icofont-game-pad",
    "icofont-game",
    "icofont-headphone-alt-1",
    "icofont-headphone-alt-2",
    "icofont-headphone-alt-3",
    "icofont-headphone-alt",
    "icofont-headphone",
    "icofont-htc-one",
    "icofont-imac",
    "icofont-ipad",
    "icofont-iphone",
    "icofont-ipod-nano",
    "icofont-ipod-touch",
    "icofont-keyboard-alt",
    "icofont-keyboard-wireless",
    "icofont-keyboard",
    "icofont-laptop-alt",
    "icofont-laptop",
    "icofont-macbook",
    "icofont-magic-mouse",
    "icofont-micro-chip",
    "icofont-microphone-alt",
    "icofont-microphone",
    "icofont-monitor",
    "icofont-mouse",
    "icofont-mp3-player",
    "icofont-nintendo",
    "icofont-playstation-alt",
    "icofont-psvita",
    "icofont-radio-mic",
    "icofont-radio",
    "icofont-refrigerator",
    "icofont-samsung-galaxy",
    "icofont-surface-tablet",
    "icofont-ui-head-phone",
    "icofont-ui-keyboard",
    "icofont-washing-machine",
    "icofont-wifi-router",
    "icofont-wii-u",
    "icofont-windows-lumia",
    "icofont-wireless-mouse",
    "icofont-xbox-360"
];

export const ICOFONT_PAYMENT: string[] = [
    "icofont-2checkout-alt",
    "icofont-2checkout",
    "icofont-amazon-alt",
    "icofont-amazon",
    "icofont-american-express-alt",
    "icofont-american-express",
    "icofont-apple-pay-alt",
    "icofont-apple-pay",
    "icofont-bank-transfer-alt",
    "icofont-bank-transfer",
    "icofont-braintree-alt",
    "icofont-braintree",
    "icofont-cash-on-delivery-alt",
    "icofont-cash-on-delivery",
    "icofont-diners-club-alt-1",
    "icofont-diners-club-alt-2",
    "icofont-diners-club-alt-3",
    "icofont-diners-club",
    "icofont-discover-alt",
    "icofont-discover",
    "icofont-eway-alt",
    "icofont-eway",
    "icofont-google-wallet-alt-1",
    "icofont-google-wallet-alt-2",
    "icofont-google-wallet-alt-3",
    "icofont-google-wallet",
    "icofont-jcb-alt",
    "icofont-jcb",
    "icofont-maestro-alt",
    "icofont-maestro",
    "icofont-mastercard-alt",
    "icofont-mastercard",
    "icofont-payoneer-alt",
    "icofont-payoneer",
    "icofont-paypal-alt",
    "icofont-paypal",
    "icofont-sage-alt",
    "icofont-sage",
    "icofont-skrill-alt",
    "icofont-skrill",
    "icofont-stripe-alt",
    "icofont-stripe",
    "icofont-visa-alt",
    "icofont-visa-electron",
    "icofont-visa",
    "icofont-western-union-alt",
    "icofont-western-union"
];

export const ICOFONT_MATHMETICAL: string[] = [
    "icofont-abacus-alt",
    "icofont-abacus",
    "icofont-angle-180",
    "icofont-angle-45",
    "icofont-angle-90",
    "icofont-angle",
    "icofont-calculator-alt-1",
    "icofont-calculator-alt-2",
    "icofont-calculator",
    "icofont-circle-ruler-alt",
    "icofont-circle-ruler",
    "icofont-compass-alt-1",
    "icofont-compass-alt-2",
    "icofont-compass-alt-3",
    "icofont-compass-alt-4",
    "icofont-golden-ratio",
    "icofont-marker-alt-1",
    "icofont-marker-alt-2",
    "icofont-marker-alt-3",
    "icofont-marker",
    "icofont-math",
    "icofont-mathematical-alt-1",
    "icofont-mathematical-alt-2",
    "icofont-mathematical",
    "icofont-pen-alt-1",
    "icofont-pen-alt-2",
    "icofont-pen-alt-3",
    "icofont-pen-holder-alt-1",
    "icofont-pen-holder",
    "icofont-pen",
    "icofont-pencil-alt-1",
    "icofont-pencil-alt-2",
    "icofont-pencil-alt-3",
    "icofont-pencil-alt-4",
    "icofont-pencil",
    "icofont-ruler-alt-1",
    "icofont-ruler-alt-2",
    "icofont-ruler-compass-alt",
    "icofont-ruler-compass",
    "icofont-ruler-pencil-alt-1",
    "icofont-ruler-pencil-alt-2",
    "icofont-ruler-pencil",
    "icofont-ruler",
    "icofont-rulers-alt",
    "icofont-rulers",
    "icofont-square-root",
    "icofont-ui-calculator"
];

export const ICOFONT_TEXT_EDITOR: string[] = [
    "icofont-align-center",
    "icofont-align-left",
    "icofont-align-right",
    "icofont-all-caps",
    "icofont-bold",
    "icofont-brush",
    "icofont-clip-board",
    "icofont-code-alt",
    "icofont-color-bucket",
    "icofont-color-picker",
    "icofont-copy-invert",
    "icofont-copy",
    "icofont-cut",
    "icofont-delete-alt",
    "icofont-edit-alt",
    "icofont-eraser-alt",
    "icofont-font",
    "icofont-heading",
    "icofont-indent",
    "icofont-italic-alt",
    "icofont-italic",
    "icofont-justify-all",
    "icofont-justify-center",
    "icofont-justify-left",
    "icofont-justify-right",
    "icofont-link-broken",
    "icofont-outdent",
    "icofont-paper-clip",
    "icofont-paragraph",
    "icofont-pin",
    "icofont-printer",
    "icofont-redo",
    "icofont-rotation",
    "icofont-save",
    "icofont-small-cap",
    "icofont-strike-through",
    "icofont-sub-listing",
    "icofont-subscript",
    "icofont-superscript",
    "icofont-table",
    "icofont-text-height",
    "icofont-text-width",
    "icofont-trash",
    "icofont-underline",
    "icofont-undo"
];

export const ICOFONT_ABSTRACT: string[] = [
    "icofont-angry-monster",
    "icofont-bathtub",
    "icofont-bird-wings",
    "icofont-bow",
    "icofont-castle",
    "icofont-circuit",
    "icofont-crown-king",
    "icofont-crown-queen",
    "icofont-dart",
    "icofont-disability-race",
    "icofont-diving-goggle",
    "icofont-eye-open",
    "icofont-flora-flower",
    "icofont-flora",
    "icofont-gift-box",
    "icofont-halloween-pumpkin",
    "icofont-hand-power",
    "icofont-hand-thunder",
    "icofont-king-monster",
    "icofont-love",
    "icofont-magician-hat",
    "icofont-native-american",
    "icofont-owl-look",
    "icofont-phoenix",
    "icofont-robot-face",
    "icofont-sand-clock",
    "icofont-shield-alt",
    "icofont-ship-wheel",
    "icofont-skull-danger",
    "icofont-skull-face",
    "icofont-snowmobile",
    "icofont-space-shuttle",
    "icofont-star-shape",
    "icofont-swirl",
    "icofont-tattoo-wing",
    "icofont-throne",
    "icofont-tree-alt",
    "icofont-triangle",
    "icofont-unity-hand",
    "icofont-weed",
    "icofont-woman-bird"
];

export const ICOFONT_FILE_TYPE: string[] = [
    "icofont-file-alt",
    "icofont-file-audio",
    "icofont-file-avi-mp4",
    "icofont-file-bmp",
    "icofont-file-code",
    "icofont-file-css",
    "icofont-file-document",
    "icofont-file-eps",
    "icofont-file-excel",
    "icofont-file-exe",
    "icofont-file-file",
    "icofont-file-flv",
    "icofont-file-gif",
    "icofont-file-html5",
    "icofont-file-image",
    "icofont-file-iso",
    "icofont-file-java",
    "icofont-file-javascript",
    "icofont-file-jpg",
    "icofont-file-midi",
    "icofont-file-mov",
    "icofont-file-mp3",
    "icofont-file-pdf",
    "icofont-file-php",
    "icofont-file-png",
    "icofont-file-powerpoint",
    "icofont-file-presentation",
    "icofont-file-psb",
    "icofont-file-psd",
    "icofont-file-python",
    "icofont-file-ruby",
    "icofont-file-spreadsheet",
    "icofont-file-sql",
    "icofont-file-svg",
    "icofont-file-text",
    "icofont-file-tiff",
    "icofont-file-video",
    "icofont-file-wave",
    "icofont-file-wmv",
    "icofont-file-word",
    "icofont-file-zip"
];

export const ICOFONT_EDUCATION: string[] = [
    "icofont-abc",
    "icofont-atom",
    "icofont-award",
    "icofont-bell-alt",
    "icofont-black-board",
    "icofont-book-alt",
    "icofont-book",
    "icofont-brainstorming",
    "icofont-certificate-alt-1",
    "icofont-certificate-alt-2",
    "icofont-certificate",
    "icofont-education",
    "icofont-electron",
    "icofont-fountain-pen",
    "icofont-globe-alt",
    "icofont-graduate-alt",
    "icofont-graduate",
    "icofont-group-students",
    "icofont-hat-alt",
    "icofont-hat",
    "icofont-instrument",
    "icofont-lamp-light",
    "icofont-medal",
    "icofont-microscope-alt",
    "icofont-microscope",
    "icofont-paper",
    "icofont-pen-alt-4",
    "icofont-pen-nib",
    "icofont-pencil-alt-5",
    "icofont-quill-pen",
    "icofont-read-book-alt",
    "icofont-read-book",
    "icofont-school-bag",
    "icofont-school-bus",
    "icofont-student-alt",
    "icofont-student",
    "icofont-teacher",
    "icofont-test-bulb",
    "icofont-test-tube-alt",
    "icofont-university"
];

export const ICOFONT_LAW: string[] = [
    "icofont-burglar",
    "icofont-cannon-firing",
    "icofont-cc-camera",
    "icofont-cop-badge",
    "icofont-cop",
    "icofont-court-hammer",
    "icofont-court",
    "icofont-finger-print",
    "icofont-gavel",
    "icofont-handcuff-alt",
    "icofont-handcuff",
    "icofont-investigation",
    "icofont-investigator",
    "icofont-jail",
    "icofont-judge",
    "icofont-law-alt-1",
    "icofont-law-alt-2",
    "icofont-law-alt-3",
    "icofont-law-book",
    "icofont-law-document",
    "icofont-law-order",
    "icofont-law-protect",
    "icofont-law-scales",
    "icofont-law",
    "icofont-lawyer-alt-1",
    "icofont-lawyer-alt-2",
    "icofont-lawyer",
    "icofont-legal",
    "icofont-pistol",
    "icofont-police-badge",
    "icofont-police-cap",
    "icofont-police-car-alt-1",
    "icofont-police-car-alt-2",
    "icofont-police-car",
    "icofont-police-hat",
    "icofont-police-van",
    "icofont-police",
    "icofont-thief-alt",
    "icofont-thief"
];

export const ICOFONT_PERSON: string[] = [
    "icofont-boy",
    "icofont-business-man-alt-1",
    "icofont-business-man-alt-2",
    "icofont-business-man-alt-3",
    "icofont-business-man",
    "icofont-female",
    "icofont-funky-man",
    "icofont-girl-alt",
    "icofont-girl",
    "icofont-group",
    "icofont-hotel-boy-alt",
    "icofont-hotel-boy",
    "icofont-kid",
    "icofont-man-in-glasses",
    "icofont-people",
    "icofont-support",
    "icofont-user-alt-1",
    "icofont-user-alt-2",
    "icofont-user-alt-3",
    "icofont-user-alt-4",
    "icofont-user-alt-5",
    "icofont-user-alt-6",
    "icofont-user-alt-7",
    "icofont-user-female",
    "icofont-user-male",
    "icofont-user-suited",
    "icofont-user",
    "icofont-users-alt-1",
    "icofont-users-alt-2",
    "icofont-users-alt-3",
    "icofont-users-alt-4",
    "icofont-users-alt-5",
    "icofont-users-alt-6",
    "icofont-users-social",
    "icofont-users",
    "icofont-waiter-alt",
    "icofont-waiter",
    "icofont-woman-in-glasses"
];

export const ICOFONT_MULTIMEDIA: string[] = [
    "icofont-cassette-player",
    "icofont-cassette",
    "icofont-forward",
    "icofont-guiter",
    "icofont-movie",
    "icofont-multimedia",
    "icofont-music-alt",
    "icofont-music-disk",
    "icofont-music-note",
    "icofont-music-notes",
    "icofont-music",
    "icofont-mute-volume",
    "icofont-pause",
    "icofont-play-alt-1",
    "icofont-play-alt-2",
    "icofont-play-alt-3",
    "icofont-play-pause",
    "icofont-play",
    "icofont-record",
    "icofont-retro-music-disk",
    "icofont-rewind",
    "icofont-song-notes",
    "icofont-sound-wave-alt",
    "icofont-sound-wave",
    "icofont-stop",
    "icofont-video-alt",
    "icofont-video-cam",
    "icofont-video-clapper",
    "icofont-video",
    "icofont-volume-bar",
    "icofont-volume-down",
    "icofont-volume-mute",
    "icofont-volume-off",
    "icofont-volume-up",
    "icofont-youtube-play"
];

export const ICOFONT_BUSINESS: string[] = [
    "icofont-bank-alt",
    "icofont-bank",
    "icofont-barcode",
    "icofont-bill-alt",
    "icofont-billboard",
    "icofont-briefcase-1",
    "icofont-briefcase-2",
    "icofont-businessman",
    "icofont-businesswoman",
    "icofont-chair",
    "icofont-coins",
    "icofont-company",
    "icofont-contact-add",
    "icofont-files-stack",
    "icofont-handshake-deal",
    "icofont-id-card",
    "icofont-meeting-add",
    "icofont-money-bag",
    "icofont-pie-chart",
    "icofont-presentation-alt",
    "icofont-presentation",
    "icofont-stamp",
    "icofont-stock-mobile"
];

export const ICOFONT_EMOTICON: string[] = [
    "icofont-angry",
    "icofont-astonished",
    "icofont-confounded",
    "icofont-confused",
    "icofont-crying",
    "icofont-dizzy",
    "icofont-expressionless",
    "icofont-heart-eyes",
    "icofont-laughing",
    "icofont-nerd-smile",
    "icofont-open-mouth",
    "icofont-rage",
    "icofont-rolling-eyes",
    "icofont-sad",
    "icofont-simple-smile",
    "icofont-slightly-smile",
    "icofont-smirk",
    "icofont-stuck-out-tongue",
    "icofont-wink-smile",
    "icofont-worried"
];

export const ICOFONT_KIDS: string[] = [
    "icofont-baby-backpack",
    "icofont-baby-cloth",
    "icofont-baby-milk-bottle",
    "icofont-baby-trolley",
    "icofont-baby",
    "icofont-candy",
    "icofont-holding-hands",
    "icofont-infant-nipple",
    "icofont-kids-scooter",
    "icofont-safety-pin",
    "icofont-teddy-bear",
    "icofont-toy-ball",
    "icofont-toy-cat",
    "icofont-toy-duck",
    "icofont-toy-elephant",
    "icofont-toy-hand",
    "icofont-toy-horse",
    "icofont-toy-lattu",
    "icofont-toy-train"
];

export const ICOFONT_TRAVEL: string[] = [
    "icofont-5-star-hotel",
    "icofont-air-ticket",
    "icofont-beach-bed",
    "icofont-beach",
    "icofont-camping-vest",
    "icofont-direction-sign",
    "icofont-hill-side",
    "icofont-hill",
    "icofont-hotel",
    "icofont-island-alt",
    "icofont-island",
    "icofont-sandals-female",
    "icofont-sandals-male",
    "icofont-travelling"
];

export const ICOFONT_CHART: string[] = [
    "icofont-chart-arrows-axis",
    "icofont-chart-bar-graph",
    "icofont-chart-flow-1",
    "icofont-chart-flow-2",
    "icofont-chart-flow",
    "icofont-chart-growth",
    "icofont-chart-histogram-alt",
    "icofont-chart-histogram",
    "icofont-chart-line-alt",
    "icofont-chart-line",
    "icofont-chart-pie-alt",
    "icofont-chart-pie",
    "icofont-chart-radar-graph"
];

export const ICOFONT_SEARCH: string[] = [
    "icofont-search-1",
    "icofont-search-2",
    "icofont-search-document",
    "icofont-search-folder",
    "icofont-search-job",
    "icofont-search-map",
    "icofont-search-property",
    "icofont-search-restaurant",
    "icofont-search-stock",
    "icofont-search-user",
    "icofont-search"
];

export const ICOFONT_FITNESS: string[] = [
    "icofont-cycling-alt",
    "icofont-cycling",
    "icofont-dumbbell",
    "icofont-dumbbells",
    "icofont-gym-alt-1",
    "icofont-gym-alt-2",
    "icofont-gym-alt-3",
    "icofont-gym",
    "icofont-muscle-weight",
    "icofont-muscle"
];

export const ICOFONT_ALL: string[] = [
    ...ICOFONT_WEB_ICONS,
    ...ICOFONT_BRANDS,
    ...ICOFONT_DIRECTIONAL,
    ...ICOFONT_SOCIAL,
    ...ICOFONT_ANIMAL,
    ...ICOFONT_CURRENCY,
    ...ICOFONT_FOOD,
    ...ICOFONT_MOBILE_UI,
    ...ICOFONT_SPORT,
    ...ICOFONT_WEATHER,
    ...ICOFONT_CONSTRUCTION,
    ...ICOFONT_MEDICAL,
    ...ICOFONT_TRANSPORT,
    ...ICOFONT_DEVICE,
    ...ICOFONT_PAYMENT,
    ...ICOFONT_MATHMETICAL,
    ...ICOFONT_TEXT_EDITOR,
    ...ICOFONT_ABSTRACT,
    ...ICOFONT_FILE_TYPE,
    ...ICOFONT_EDUCATION,
    ...ICOFONT_LAW,
    ...ICOFONT_PERSON,
    ...ICOFONT_MULTIMEDIA,
    ...ICOFONT_BUSINESS,
    ...ICOFONT_EMOTICON,
    ...ICOFONT_KIDS,
    ...ICOFONT_TRAVEL,
    ...ICOFONT_CHART,
    ...ICOFONT_SEARCH,
    ...ICOFONT_FITNESS
];

export function getIconName(className: string): string {
    return className
        .replace('icofont-', '')
        .split('-')
        .map((word: string) => {
            const [firstLatter, ...rest] = word.split('');
            return [firstLatter.toUpperCase(), ...rest].join('');
        })
        .join(' ');
}