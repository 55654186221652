import React from 'react';
import logo from '../../../assets/svg/logo.svg';
import './style/fmLogo.scss';
import classNames from 'classnames';

interface Props {
    spin?: boolean;
}

export default class FMLogo extends React.Component<Props> {
    render() {
        return (
            <div className="fm-logo">
                <img src={logo} className={classNames('fm-logo__item', {
                    'spin-item': !!this.props.spin,
                })} alt="FieldMaster"/> FieldMaster
            </div>
        );
    }
}
