import React from 'react';
import SignUpPageView from './SignUpPageView';
import './../style/loginPage.scss';
import {URLRouteProps} from '../../../../routing/URLRouteProps';

export default class SignUpPageController extends React.Component<URLRouteProps> {

    render() {
        return (
            <div className="login-container">
                <div className="login-container__form">
                    <SignUpPageView/>
                </div>
            </div>
        );
    }
}
