import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import CalendarTabInfoView from './CalendarTabInfoView';
import {CalendarViewStore} from '../../mobx/CalendarViewStore';
import {inject, observer} from 'mobx-react';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import CalendarTabGeneral from "./items/general/CalendarTabGeneral";
import {withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import './style/style.scss'
import {TabItemContent} from "../../../../../common/widgets/tab/TabItemContent";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";

type StoreProps = {
    coreStore: CoreStore;
    calendarViewStore: CalendarViewStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('calendarViewStore', 'coreStore')
@observer
export default class CalendarTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    readonly tabItems: TabItemContent<CalendarRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: CalendarTabGeneral
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('CalendarTabController: constructor');
        this.state = {selectedTab: 'tab1'};
    }

    componentDidMount(): void {
        console.log('CalendarTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('CalendarTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {calendarViewStore} = this.props;
        const {length} = calendarViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'equipment.card.title'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'equipment.card.title.many'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'calendar.form.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {calendarViewStore} = this.props;
        const {length} = calendarViewStore.selectedEntities;
        if (length === 1) {
            return 'Actions for one';
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<CalendarRecord>[] => {
        const {calendarViewStore, coreStore} = this.props;
        const {length} = calendarViewStore.selectedEntities;
        if (length === 1) {
            return this.tabItems.map((tabItem: TabItemContent<CalendarRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {calendarViewStore, coreStore} = this.props;
        const {selectedEntities, selectedEntity} = calendarViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<CalendarRecord> = this.tabItems.find((item: TabItemContent<CalendarRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const isRecordCreator: boolean = selectedEntity.created_by === coreStore.user.user_id;
                const MyComponentWithRecord: any = withRecord<CalendarRecord>(MyComponentType, {record: selectedEntity, entity_id: selectedEntity.id, isRecordCreator});
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <i>{intl().formatMessage({id: 'msg1'})} for {selectedEntities.length} record(s)</i>;
        } else {
            return <CalendarTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
