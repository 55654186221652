import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {FieldViewStore} from './mobx/FieldViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import FieldPageView from "./FieldPageView";
import {FieldURLData} from "./FieldURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    fieldViewStore: FieldViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'fieldViewStore')
@observer
export default class FieldPageController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('FieldPageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('FieldPageController: componentDidMount', performance.now());
        const {fieldViewStore} = this.props;
        if (!fieldViewStore.isDataLoaded) {
            await fieldViewStore.loadData();
        } else {
            const urlData: FieldURLData | undefined = decryptObject<FieldURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.fieldBarCode)) {
                fieldViewStore.universalFilter = urlData!.fieldBarCode!;
                fieldViewStore.clearFilters();
                goToPage(AppRoutes.FM_FIELD);
            } else if (fieldViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                fieldViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {fieldViewStore} = this.props;
        const urlData: FieldURLData | undefined = decryptObject<FieldURLData>(this.props.match.params.data);
        console.log('FieldPageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', fieldViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.fieldBarCode)) {
            fieldViewStore.universalFilter = urlData!.fieldBarCode!;
            fieldViewStore.clearFilters();
            goToPage(AppRoutes.FM_FIELD);
        } else if (isEmpty(urlData?.fieldBarCode) && !fieldViewStore.isViewReady) {
            fieldViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'FieldPageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('FieldPageController: componentDidCatch', performance.now());
    }

    render() {
        const {fieldViewStore} = this.props;
        const {isViewReady, isDataLoaded} = fieldViewStore;

        if (!isDataLoaded) {
            return <p>Load field data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating field view....</p>;
        }

        return (
            <FieldPageView/>
        );
    }
}
