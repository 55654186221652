import {FMKeyType} from "fm-shared-data/src/types/dictionary/FMKeyType";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";

export interface Code128CItem {
    font: string;
    calc: number;
}

// The 128 code
//Version 2.00 in 2008
// https://grandzebu.net/informatique/codbar-en/code128.htm
export const CODE128C: Record<string, Code128CItem> = {
    '00': {font: '212', calc: 0},
    '01': {font: '!', calc: 1},
    '02': {font: '"', calc: 2},
    '03': {font: '#', calc: 3},
    '04': {font: '$', calc: 4},
    '05': {font: '%', calc: 5},
    '06': {font: '&', calc: 6},
    '07': {font: '\'', calc: 7},
    '08': {font: '(', calc: 8},
    '09': {font: ')', calc: 9},
    '10': {font: '*', calc: 10},
    '11': {font: '+', calc: 11},
    '12': {font: ',', calc: 12},
    '13': {font: '-', calc: 13},
    '14': {font: '.', calc: 14},
    '15': {font: '/', calc: 15},
    '16': {font: '0', calc: 16},
    '17': {font: '1', calc: 17},
    '18': {font: '2', calc: 18},
    '19': {font: '3', calc: 19},
    '20': {font: '4', calc: 20},
    '21': {font: '5', calc: 21},
    '22': {font: '6', calc: 22},
    '23': {font: '7', calc: 23},
    '24': {font: '8', calc: 24},
    '25': {font: '9', calc: 25},
    '26': {font: ':', calc: 26},
    '27': {font: ';', calc: 27},
    '28': {font: '<', calc: 28},
    '29': {font: '=', calc: 29},
    '30': {font: '>', calc: 30},
    '31': {font: '?', calc: 31},
    '32': {font: '@', calc: 32},
    '33': {font: 'A', calc: 33},
    '34': {font: 'B', calc: 34},
    '35': {font: 'C', calc: 35},
    '36': {font: 'D', calc: 36},
    '37': {font: 'E', calc: 37},
    '38': {font: 'F', calc: 38},
    '39': {font: 'G', calc: 39},
    '40': {font: 'H', calc: 40},
    '41': {font: 'I', calc: 41},
    '42': {font: 'J', calc: 42},
    '43': {font: 'K', calc: 43},
    '44': {font: 'L', calc: 44},
    '45': {font: 'M', calc: 45},
    '46': {font: 'N', calc: 46},
    '47': {font: 'O', calc: 47},
    '48': {font: 'P', calc: 48},
    '49': {font: 'Q', calc: 49},
    '50': {font: 'R', calc: 50},
    '51': {font: 'S', calc: 51},
    '52': {font: 'T', calc: 52},
    '53': {font: 'U', calc: 53},
    '54': {font: 'V', calc: 54},
    '55': {font: 'W', calc: 55},
    '56': {font: 'X', calc: 56},
    '57': {font: 'Y', calc: 57},
    '58': {font: 'Z', calc: 58},
    '59': {font: '[', calc: 59},
    '60': {font: '\\', calc: 60},
    '61': {font: ']', calc: 61},
    '62': {font: '^', calc: 62},
    '63': {font: '_', calc: 63},
    '64': {font: '`', calc: 64},
    '65': {font: 'a', calc: 65},
    '66': {font: 'b', calc: 66},
    '67': {font: 'c', calc: 67},
    '68': {font: 'd', calc: 68},
    '69': {font: 'e', calc: 69},
    '70': {font: 'f', calc: 70},
    '71': {font: 'g', calc: 71},
    '72': {font: 'h', calc: 72},
    '73': {font: 'i', calc: 73},
    '74': {font: 'j', calc: 74},
    '75': {font: 'k', calc: 75},
    '76': {font: 'l', calc: 76},
    '77': {font: 'm', calc: 77},
    '78': {font: 'n', calc: 78},
    '79': {font: 'o', calc: 79},
    '80': {font: 'p', calc: 80},
    '81': {font: 'q', calc: 81},
    '82': {font: 'r', calc: 82},
    '83': {font: 's', calc: 83},
    '84': {font: 't', calc: 84},
    '85': {font: 'u', calc: 85},
    '86': {font: 'v', calc: 86},
    '87': {font: 'w', calc: 87},
    '88': {font: 'x', calc: 88},
    '89': {font: 'y', calc: 89},
    '90': {font: 'z', calc: 90},
    '91': {font: '{', calc: 91},
    '92': {font: '|', calc: 92},
    '93': {font: '}', calc: 93},
    '94': {font: '~', calc: 94},
    '95': {font: '200', calc: 95},
    '96': {font: '201', calc: 96},
    '97': {font: '202', calc: 97},
    '98': {font: '203', calc: 98},
    '99': {font: '204', calc: 99},
    '100': {font: '205', calc: 0},
    '101': {font: '206', calc: 0},
    '102': {font: '207', calc: 0},
    '103': {font: '208', calc: 0}, // Start A
    '104': {font: '209', calc: 0}, // Start B
    '105': {font: '210', calc: 0}, // Start C
    '106': {font: '211', calc: 0}, // Stop
};

function getFontChar(value: string): string {
    const code: Code128CItem = CODE128C[value]!;
    return code.font.length === 1 ? code.font : String.fromCharCode(Number.parseInt(code.font));
}

function calculateFontValue(data: string): string {
    const arr2: string[] = data.split('').reduce((prev: string[], current: string, indx: number) => {
        if (indx % 2 === 0) {
            const p: string = data.substr(indx, 2);
            prev.push(p);
        }
        return prev;
    }, []);

    const font: string[] = [];
    const c1: number = arr2.reduce((prev: number, val: string, indx: number) => {
        const code: Code128CItem = CODE128C[val]!;
        font.push(getFontChar(val));
        return prev + (code.calc * (indx + 1));
    }, 105); // Start C

    const r: number = c1 % 103; // 103 const
    const m: string = r > 9 ? r.toString() : `0${r}`;
    const check: string = getFontChar(m);
    return font.join('') + check;
}

export function getBarCodeFontValue(data: string): string {
    console.info(calculateFontValue(data));
    return String.fromCharCode(210) + calculateFontValue(data) + String.fromCharCode(211);
}

export function correctCode128Data(data: string): string {
    return data.length % 2 === 0 ? data : `0${data}`;
}

const ACTION_ENTITY_ID = '1';

export function isActionBarcode(barcode_entity_id: FMKeyType | BarcodeEntityId): boolean {
    return barcode_entity_id.toString() === ACTION_ENTITY_ID;
}

export function fmBarcode(account_or_schema: string, barcode_entity_id: FMKeyType | BarcodeEntityId, entity_id: FMKeyType): string {
    const accountId: string = account_or_schema.replace('account_', '');
    return `${accountId.padStart(3, '0')}${barcode_entity_id.toString().padStart(2, '0')}${entity_id.toString().padStart(7, '0')}`;
}

export function isBarCode(value: string): boolean {
    return !!value.match(/^\d{12}$/); // TODO improve it
}
