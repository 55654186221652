import {FileRecord} from "fm-shared-data/src/types/db/common/FileRecord";

export const FILE_UPLOAD_FIELDNAME: string = 'file';
export const FILES_UPLOAD_FIELDNAME: string = 'files[]';

export const MAX_FILE_SIZE_MB: number = 10;
export const MAX_FILES: number = 10;

export const MAX_IMAGE_SIZE_MB: number = 5;

export function sizeBToKb(b: number): number {
    return b / 1024;
}

export function sizeKbToMb(kb: number): number {
    return kb / 1024;
}

export function sizeKbToB(kb: number): number {
    return kb * 1024;
}

export function sizeMbToKb(mb: number): number {
    return mb * 1024;
}

export function sizeBToMb(b: number): number {
    return sizeKbToMb(sizeBToKb(b));
}

export function sizeMbToB(mb: number): number {
    return sizeMbToKb(sizeKbToB(mb));
}

export function sizeBToLabel(b: number): string {
    if (b < 1024) {
        return `${b} b`;
    }
    if (b < 1024 * 1024) {
        return `${sizeBToKb(b).toFixed(2)} Kb`;
    }
    return `${sizeBToMb(b).toFixed(2)} Mb`;
}

export function getFileDownloadURL(fileRecord: FileRecord): string {
    const {id, rnd} = fileRecord;
    return `/file/download/${id}/${rnd}`;
}

