import {Form, Typography} from 'antd';
import Checkbox, {CheckboxChangeEvent} from 'antd/lib/checkbox';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import React from 'react';
import {intl} from '../../../../common/locale/IntlProvider';
import {ReCaptchaButton} from '../../../../common/widgets/reCaptcha/ReCaptchaButton';
import {getHashedPassword} from '../../../../common/crypto/LoginUtils';
import {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {AuthLocalBody} from '../types/AuthLocalBody';
import {goToPage, goToURL} from '../../../../routing/AppRouting';
import LinkTo from '../../../../routing/LinkTo';
import {axiosGetInstance, axiosUpdateAuthToken} from '../../../../common/utils/AxiosUtils';
import {UnlockOutlined} from '@ant-design/icons/lib';
import {LocalStorage, LocalStorageKey} from "../../../../common/utils/LocalStorageUtils";
import {SigninURLData} from "fm-shared-data/src/types/auth/SigninURLData";
import {ResponseError} from "fm-shared-data/src/types/api/ResponseError";
import {JWTToken} from "fm-shared-data/src/types/auth/JWTToken";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {ResponseErrorCode} from "fm-shared-data/src/types/api/ResponseErrorCode";
import {ResetPasswordURLData} from "fm-shared-data/src/types/auth/ResetPasswordURLData";

const {Paragraph} = Typography;

interface Props {
    data?: SigninURLData;
}

interface State {
    userName: string;
    password: string;
    rememberMe: boolean;
    lockButton: boolean;
    responseError: ResponseError | undefined;
}

export default class SignInPageView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userName: props.data?.email || LocalStorage.getItem(LocalStorageKey.SIGNIN_EMAIL) || '',
            password: '',
            rememberMe: !!LocalStorage.getItem(LocalStorageKey.SIGNIN_EMAIL),
            lockButton: false,
            responseError: undefined,
        };
    }

    handleStartLogin = () => {
        this.setState({lockButton: true, responseError: undefined});
    };

    handleLogin = async () => {
        const email: string = this.state.userName.trim().toLowerCase();
        const hashedPassword: string = getHashedPassword(this.state.password, email);
        // console.info('new hashedPassword', hashedPassword);
        const axios: AxiosInstance = axiosGetInstance();
        const body: AuthLocalBody = {
            username: email,
            password: hashedPassword
        };

        await axios.post<JWTToken>('/auth/login', body)
            .then((response: AxiosResponse<JWTToken>) => {
                const accessToken: string = response.data.access_token;
                axiosUpdateAuthToken(accessToken);

                if (this.state.rememberMe) {
                    LocalStorage.setItem(LocalStorageKey.SIGNIN_EMAIL, email);
                    LocalStorage.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
                } else {
                    LocalStorage.removeItem(LocalStorageKey.SIGNIN_EMAIL);
                    LocalStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
                }

                if (this.props.data?.redirectURL) {
                    goToURL(this.props.data.redirectURL, true);
                } else {
                    // if (this.props.data?.redirectPage) {
                    //     console.info(parseAppPage(this.props.data?.redirectPage));
                    // }
                    // const appRoutes: AppRoutes = AppRoutes.ROOT;
                    // goToPage(appRoutes, this.props.data?.redirectData);
                    goToPage(AppRoutes.ROOT);
                }

                return {} as AxiosResponse<JWTToken>;
            })
            .catch((error: AxiosError<AxiosErrorResponseData>) => {
                const errorCode: string = error.response?.data.message || error.message;
                const responseError: ResponseError = {
                    errorCode: errorCode as ResponseErrorCode,
                    errorMessage: `Error (${errorCode})`
                };
                this.onErrorLogin(responseError);
                return {} as AxiosResponse<JWTToken>;
            });
    };

    handleReCaptchaInvalid = () => {
        this.onErrorLogin({
            errorCode: ResponseErrorCode.RECAPTCHA_ERROR,
            errorMessage: 'ReCaptcha validation error'
        });
    };

    onErrorLogin = (responseError: ResponseError) => {
        console.error('onErrorLogin ', JSON.stringify(responseError));
        this.setState({lockButton: false, responseError});
    };

    onChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({userName: value});
    };

    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({password: value});
    };

    onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            // TODO: handle Enter key as action
            console.info('manual click to login button');
        }
    };

    onChangeRememberMe = (e: CheckboxChangeEvent) => {
        const {checked} = e.target;
        this.setState({rememberMe: checked});
    };

    isFormValid = (): boolean => {
        return !!this.state.userName && !!this.state.password.trim();
    };

    render() {
        const email: string = this.state.userName.trim().toLowerCase();
        const resetPasswordURLParams: ResetPasswordURLData = {email};
        return (
            <div>
                <h1>{intl().formatMessage({id: 'signin.title'})}</h1>
                <hr/>
                <Form layout={'vertical'} labelAlign={'left'}>
                    <FormItem label="Email">
                        <Input
                            name="email"
                            type="email"
                            value={this.state.userName}
                            placeholder="email"
                            disabled={this.state.lockButton}
                            onChange={this.onChangeUserName}
                        />
                    </FormItem>
                    <FormItem label="Password">
                        <Input
                            name="password"
                            type="password"
                            value={this.state.password}
                            placeholder="password"
                            disabled={this.state.lockButton}
                            onChange={this.onChangePassword}
                            onKeyUp={this.onKeyUp}
                        />
                    </FormItem>
                    <FormItem>
                        <div className={'twoColumnFormItem'}>
                            <Checkbox
                                checked={this.state.rememberMe}
                                disabled={this.state.lockButton}
                                onChange={this.onChangeRememberMe}
                            >
                                Remember me
                            </Checkbox>
                            <LinkTo to={AppRoutes.RESET_PASSWORD_DATA} params={resetPasswordURLParams}>Reset password</LinkTo>
                        </div>
                    </FormItem>
                    <FormItem>
                        <ReCaptchaButton
                            disabled={!this.isFormValid()}
                            label={intl().formatMessage({id: 'signin.title'})}
                            icon={<UnlockOutlined/>}
                            action="login"
                            loading={this.state.lockButton}
                            onClick={this.handleStartLogin}
                            onValid={this.handleLogin}
                            onInvalid={this.handleReCaptchaInvalid}
                        />
                    </FormItem>
                </Form>
                {this.state.responseError && (
                    <Paragraph copyable={true} type={'danger'}>{this.state.responseError.errorMessage}</Paragraph>
                )}
            </div>
        );
    }
}
