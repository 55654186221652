import {action, makeObservable, observable, runInAction} from 'mobx';
import {computedFn} from "mobx-utils";
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";
import {LogDataSearchParams} from "fm-shared-data/src/types/db/account/justlog/LogDataSearchParams";
import LoadLogDataCommand from "../commands/LoadLogDataCommand";
import {Moment} from 'moment';
import {DateRange} from '../../../../../../../../common/utils/DateRangeUtils';

export type LogDataSearchParamsUI = LogDataSearchParams<Moment | null>;

export class LogDataViewStore {
    logTypeMap: Map<number, DateRange> = new Map<number, DateRange>();
    dataMap: Map<string, LogDataRecord[]> = new Map<string, LogDataRecord[]>();

    constructor() {
        makeObservable(this, {
            logTypeMap: observable,
            dataMap: observable,

            changePeriod: action,

            loadData: action,
            setData: action,
            addData: action,
            deleteData: action,
        });
    }

    private getKey(logDataSearchParams: LogDataSearchParamsUI): string {
        const {log_type, entity_id, start_date, end_date} = logDataSearchParams;
        // return `${log_type}.${entity_id}`;
        return `${log_type}.${entity_id}.${start_date ? start_date.toString() : ""}.${end_date ? end_date.toString() : ""}`;
    }

    getData = computedFn((logDataSearchParams: LogDataSearchParamsUI): LogDataRecord[] | undefined => {
        return this.dataMap.get(this.getKey(logDataSearchParams));
    });

    getLogTypeRange = computedFn((log_type: number): DateRange | undefined => {
        return this.logTypeMap.get(log_type);
    });

    async changePeriod(logDataSearchParams: LogDataSearchParamsUI) {
        await this.loadData(logDataSearchParams);
    }

    getSearchParams = (logDataSearchParams: LogDataSearchParamsUI): LogDataSearchParamsUI => {
        return {
            ...logDataSearchParams
            // ...getLogDataSearchParams(logDataSearchParams.log_type, this.dateStart, this.dateEnd),
        }
    };

    async loadData(logDataSearchParams: LogDataSearchParamsUI) {
        console.warn(`logDataSearchParams: ${JSON.stringify(logDataSearchParams)}`);
        this.logTypeMap.set(logDataSearchParams.log_type, {
            dateStart: logDataSearchParams.start_date,
            dateEnd: logDataSearchParams.end_date
        });
        this.dataMap.delete(this.getKey(logDataSearchParams));
        const dataRecords: LogDataRecord[] = await new LoadLogDataCommand(this.getSearchParams(logDataSearchParams)).execute();
        // dataRecords.push({...dataRecords[0]});
        runInAction(() => {
            this.setData(logDataSearchParams, dataRecords);
        });
    }

    async setData(logDataSearchParams: LogDataSearchParamsUI, dataRecords: LogDataRecord[]) {
        this.dataMap.set(this.getKey(logDataSearchParams), dataRecords);
    }

    async addData(searchParams: LogDataSearchParams, record: Partial<LogDataRecord>) {
        // const data: InsertResponse<LogDataRecord> = await new AddLogDataCommand(searchParams).execute(record);
        // runInAction(() => {
        //     this.setData(searchParams, data.list);
        // });
    }

    async deleteData(searchParams: LogDataSearchParams, id: number) {
        // const data: LogDataRecord[] = await new DeleteLogDataCommand(searchParams).execute(id);
        // runInAction(() => {
        //     this.setData(searchParams, data);
        // });
    }

}
