import React from "react";
import {LogTypeField, LogTypeFieldType} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {DictionaryDecorator} from "../../../../../../../common/widgets/dictionary/DictionaryDecorator";
import {DICTIONARY_GENDER, DICTIONARY_ROLE, DICTIONARY_WORK_PAYMENT_TYPE} from "../../../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {FMOptionType} from "../../../../../../../common/widgets/modalForm/type/FormFieldConfig";
import {FMEntity} from "fm-shared-data/src/types/db/core/FMEntity";
import {
    DICTIONARY_ENTITY_EMPLOYEE,
    DICTIONARY_ENTITY_EQUIPMENT,
    DICTIONARY_ENTITY_FIELD,
    DICTIONARY_ENTITY_WORK
} from "../../../../../../../common/widgets/dictionary/EntityDictionatryConfig";
import {FMDictionary} from "fm-shared-data/src/types/db/core/FMDictionary";
import {LabeledValue} from "antd/lib/select";

export function getOptions(logTypeField: LogTypeField): FMOptionType[] {
    const options: FMOptionType[] = [];
    switch (logTypeField.field_type) {
        case LogTypeFieldType.FM_ENTITY: {
            switch (logTypeField.field_dict_id as FMEntity) {
                case FMEntity.EMPLOYEE:
                    return new DictionaryDecorator(DICTIONARY_ENTITY_EMPLOYEE).selectOptionsProvider;
                case FMEntity.FIELD:
                    return new DictionaryDecorator(DICTIONARY_ENTITY_FIELD).selectOptionsProvider;
                case FMEntity.WORK:
                    return new DictionaryDecorator(DICTIONARY_ENTITY_WORK).selectOptionsProvider;
                case FMEntity.EQUIPMENT:
                    return new DictionaryDecorator(DICTIONARY_ENTITY_EQUIPMENT).selectOptionsProvider;
            }
            break;
        }
        case LogTypeFieldType.FM_DICTIONARY: {
            switch (logTypeField.field_dict_id as FMDictionary) {
                case FMDictionary.ROLE:
                    return new DictionaryDecorator(DICTIONARY_ROLE).selectOptionsProvider;
                case FMDictionary.GENDER:
                    return new DictionaryDecorator(DICTIONARY_GENDER).selectOptionsProvider;
                case FMDictionary.WORK_PAYMENT_TYPE:
                    return new DictionaryDecorator(DICTIONARY_WORK_PAYMENT_TYPE).selectOptionsProvider;
            }
            break;
        }
        case LogTypeFieldType.STRING_LOOKUP: {
            // read from DB, because on client not all set of data
            return [{key: "1", label: `value 1 from field_id ${logTypeField.field_id}`, value: "value1"} as LabeledValue, {
                key: "2",
                label: `value 2 from field_id ${logTypeField.field_id}`,
                value: "value2"
            } as LabeledValue];
        }
    }
    return options;
}