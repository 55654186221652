import React from 'react';
import './style/sectionHeader.scss';
import {Popconfirm, Space} from 'antd';
import classNames from 'classnames';
import {DeleteOutlined, EditOutlined, PlusOutlined, SyncOutlined} from '@ant-design/icons/lib';
import {formatMessage} from '../../locale/IntlProvider';
import FilterControl from "../filterControl/FilterControl";

export interface SCRUDProps {
    onFilter?: (value: string) => void;
    onAdd?: () => void;
    onEdit?: (id?: any) => void;
    onDelete?: (id?: any) => void;
    onRefresh?: () => void;

    isFilterEnabled?: boolean;
    isAddEnabled?: boolean;
    isEditEnabled?: boolean;// | string | null; // TODO: rename to isEditDisabled
    isDeleteEnabled?: boolean;
    isRefreshEnabled?: boolean;

    filterValue?: string;
    className?: string;
}

interface State {
    filterValue: string
}

export default class SCRUDActions extends React.Component<SCRUDProps, State> {

    constructor(props: SCRUDProps) {
        super(props);
        this.state = {
            filterValue: props.filterValue || ""
        };
    }

    handleAddClick = () => {
        if (this.props.isAddEnabled && this.props.onAdd) {
            this.props.onAdd();
        }
    };

    handleEditClick = () => {
        if (this.props.isEditEnabled && this.props.onEdit) {
            this.props.onEdit();
        }
    };

    handleRefreshClick = () => {
        if (this.props.isRefreshEnabled && this.props.onRefresh) {
            this.props.onRefresh();
        }
    };

    handleClick = (event: any) => {
        event.stopPropagation(); // TODO: is it required in react 17?
    };

    handleOnFilter = (filterValue: string) => {
        this.setState({filterValue});
        if (this.props.onFilter) {
            this.props.onFilter(filterValue); // debounce this!
        }
    };

    render() {
        const {className = ''} = this.props;
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div className={`scrud-container ${className}`} onClick={this.handleClick}>
                <Space className="scrud-container-icons2">
                    {this.props.children}
                    {this.props.onFilter && (
                        <FilterControl
                            onFilter={this.handleOnFilter}
                            isFilterEnabled={this.props.isFilterEnabled}
                            value={this.props.filterValue}
                        />
                    )}
                    {this.props.onAdd && (
                        <PlusOutlined
                            onClick={this.handleAddClick}
                            className={classNames('scrud-container__icon', {
                                'scrud-container__icon--disabled': !this.props.isAddEnabled,
                            })}
                        />
                    )}
                    {this.props.onEdit && (
                        <EditOutlined
                            onClick={this.handleEditClick}
                            className={classNames('scrud-container__icon', {
                                'scrud-container__icon--disabled': !this.props.isEditEnabled,
                            })}
                        />
                    )}
                    {this.props.onDelete && (
                        <Popconfirm
                            disabled={!this.props.isDeleteEnabled}
                            placement={'right'}
                            okType={'primary'}
                            title={formatMessage('SCRUDActions.delete.confirmation')}
                            okText={formatMessage('common.yes')}
                            cancelText={formatMessage('common.no')}
                            onConfirm={this.props.onDelete}
                        >
                            <DeleteOutlined
                                className={classNames('scrud-container__icon', {
                                    'scrud-container__icon--disabled': !this.props.isDeleteEnabled,
                                })}
                            />
                        </Popconfirm>
                    )}
                    {this.props.onRefresh && (
                        <SyncOutlined
                            onClick={this.handleRefreshClick}
                            className={classNames('scrud-container__icon', {
                                'scrud-container__icon--disabled': !this.props.isRefreshEnabled,
                            })}
                        />
                    )}
                </Space>
            </div>
        );
    }
}
