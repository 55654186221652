import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {FieldPivotOverviewRecord} from 'fm-shared-data/src/types/db/account/data/FieldPivotOverviewRecord';
import {ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';

export default class LoadFieldPivotOverviewCommand extends Command<FieldPivotOverviewRecord[], ReportFilterParams> {
    execute(reportFilterParams: ReportFilterParams): Promise<FieldPivotOverviewRecord[]> {
        return axiosGetInstance().post<FieldPivotOverviewRecord[]>('/data/field/pivot/overview', reportFilterParams)
            .then((response: AxiosResponse<FieldPivotOverviewRecord[]>) => response.data);
    }
}
