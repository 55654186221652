import React from 'react';
import {Route} from 'react-router';
import {HashRouter, Router} from 'react-router-dom';
import {axiosInit} from '../common/utils/AxiosUtils';
import {Provider} from 'mobx-react'
import {configure} from 'mobx'
import {stores} from './mobx/Stores';
import {hashHistory} from '../routing/AppRouting';
import AppRouter from './AppRouter';
import CoreHeaderView from '../pages/core/coreHeader/CoreHeaderView';
import './style/layout.scss';
import './style/splitter-layout.scss';
import './style/ag-grid.scss';

interface Props {
    test?: string;
}

export default class App extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        configure({
            enforceActions: "always",
            useProxies: "never",
            // useProxies: "ifavailable",
            // computedRequiresReaction: true,
            // reactionRequiresObservable: true,
            // observableRequiresReaction: true,
            // disableErrorBoundaries: false
            // reactionScheduler: (f) => {
            //     setTimeout(f, 1);
            // },
        });
        axiosInit();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('App: componentDidCatch', performance.now(), error, errorInfo);
    }

    render() {
        return (
            <Provider {...stores}>
                <Router history={hashHistory}>
                        <HashRouter>
                            <div className="fx-main">
                                <div className="fx-menu">
                                    <CoreHeaderView/>
                                </div>
                                <div className="fx-body">
                                    <Route component={AppRouter}/>
                                </div>
                            </div>
                        </HashRouter>
                </Router>
            </Provider>
        );
    }
}
