import React from "react";
import {DataNode} from "rc-tree/lib/interface";
import TreeNodeRenderer from "./TreeNodeRenderer";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import Icofont from "react-icofont";
import {getIconName} from "../icofont/IcofontUtils";

export function getTreeNodes<T extends TreeRecord>(treeData: TreeData<T>[], showCount: boolean = true, idToString: boolean = false, rootSelectable: boolean = true): DataNode[] {
    const treeDataToDataNode = (treeData: TreeData<T>): DataNode => {
        return {
            title: TreeNodeRenderer(treeData, !!treeData.children.length, showCount),
            key: idToString ? treeData.id.toString() : treeData.id,
            selectable: rootSelectable ? true : treeData.id !== 1
        };
    };
    const loop = (data: TreeData<T>[]): DataNode[] =>
        data.map((item: TreeData<T>) => {
            if (item.children && item.children.length) {
                const node: DataNode = treeDataToDataNode(item);
                node.children = loop(item.children);
                return node;
            }
            return treeDataToDataNode(item);
        });
    return loop(treeData);
}

export const IcofontLabelRendererFunction = (dictionaryRawRecord: DictionaryRawRecord): React.ReactNode => {
    const {name} = dictionaryRawRecord;
    return <div className={"displayFlex"}><Icofont icon={name} size={"2"}/>&nbsp;{getIconName(name)}</div>;
};
