import React from 'react';
import {Checkbox} from 'antd';
import {CheckboxOptionType, CheckboxValueType} from 'antd/lib/checkbox/Group';
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {formatMessage} from "../../locale/IntlProvider";
import PredefinedFilterItem from './PredefinedFilterItem';

interface Props<T extends DataRecord> {
    filters: PredefinedFilterItem<T>[];
    selectedFilters: PredefinedFilterItem<T>[];
    onChange: (selectedFilters: PredefinedFilterItem<T>[]) => void;
}

export default class PredefinedDataFilters<T extends DataRecord> extends React.Component<Props<T>> {

    handleOnChange = (checkedValue: CheckboxValueType[]) => {
        const {filters, onChange} = this.props;
        onChange(filters.filter((item: PredefinedFilterItem<T>) => checkedValue.includes(item.key)));
    };

    render() {
        const {filters, selectedFilters} = this.props;
        const options: CheckboxOptionType[] = filters.map((predefinedFilter: PredefinedFilterItem<T>) => {
            return {
                label: formatMessage(predefinedFilter.titleKey),
                value: predefinedFilter.key
            };
        });
        const defaultValues: string[] = selectedFilters.map((item: PredefinedFilterItem<T>) => item.key);

        return (
            <Checkbox.Group
                options={options}
                defaultValue={defaultValues}
                onChange={this.handleOnChange}/>
        );
    }
}
