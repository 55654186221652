import React from 'react';
import {Card} from 'antd';
import {formatMessage, intl} from '../../../../../common/locale/IntlProvider';
import ReportTabInfoView from './ReportTabInfoView';
import {ReportViewStore} from '../../mobx/ReportViewStore';
import {inject, observer} from 'mobx-react';
import {CoreStore} from "../../../../core/mobx/CoreStore";
import ReportTabGeneral from "./items/general/ReportTabGeneral";
import {CustomRendererProps, withRecord} from "../../../../../common/widgets/tab/CustomRenderer";
import './style/style.scss'
import {TabItemContent} from "../../../../../common/widgets/tab/TabItemContent";
import {ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";
import CommentsWidget from "../../../../../common/widgets/comments/CommentsWidget";
import ManageFileWidget from "../../../../../common/widgets/fileWidget/ManageFileWidget";
import RichTextWidget from "../../../../../common/widgets/richText/RichTextWidget";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {getLogTypeTabItems} from "../../../../../common/widgets/tab/TabItemUtil";
import {DataStore} from "../../../../../app/mobx/DataStore";
import Icofont from 'react-icofont';
import OpenReportController from '../../../../../common/widgets/reports/preview/OpenReportController';

type StoreProps = {
    coreStore: CoreStore;
    reportViewStore: ReportViewStore;
    dataStore: DataStore;
};

type Props = StoreProps;

interface State {
    selectedTab: string;
}

@inject('reportViewStore', 'coreStore', 'dataStore')
@observer
export default class ReportTabController extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private readonly barcode_entity_id: BarcodeEntityId = BarcodeEntityId.REPORT;

    private readonly staticTabItems: TabItemContent<ReportRecord>[] = [
        {
            key: 'tab1',
            tab: formatMessage('employee.details.general.title'),
            componentType: ReportTabGeneral
        },
        {
            key: 'tab2',
            tab: formatMessage('employee_email.field.description'),
            disabled: false,
            withRecords: true,
            componentType: RichTextWidget,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<ReportRecord>
        },
        {
            key: 'tab3',
            tab: formatMessage('employee.details.files.title'),
            componentType: ManageFileWidget,
            withRecords: true,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<ReportRecord>
        },
        {
            key: 'tab4',
            tab: formatMessage('employee.details.comments.title'),
            disabled: false,
            withRecords: true,
            componentType: CommentsWidget,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<ReportRecord>
        },
        {
            key: 'tab5',
            tab:<><Icofont icon="icofont-play-alt-2"/> {formatMessage('report.title.name')}</>,
            disabled: false,
            withRecords: true,
            componentType: OpenReportController,
            componentProps: {
                barcode_entity_id: this.barcode_entity_id
            } as CustomRendererProps<ReportRecord>
        },
    ];

    constructor(props: Props) {
        super(props);
        console.info('WorkTabController: constructor');
        this.state = {selectedTab: 'tab1'};
    }

    getTabItems = (): TabItemContent<ReportRecord>[] => {
        const logTypeRecordTabs: LogTypeRecord[] = this.props.dataStore.getLogTypeRecordTabs(this.barcode_entity_id);
        const logTypeTabItems: TabItemContent<ReportRecord>[] = getLogTypeTabItems<ReportRecord>(logTypeRecordTabs, this.barcode_entity_id, this.staticTabItems.length);
        return [...this.staticTabItems, ...logTypeTabItems];
    }

    componentDidMount(): void {
        console.log('WorkTabController: componentDidMount', performance.now());
    }

    componentDidUpdate(): void {
        console.log('WorkTabController: componentDidUpdate', performance.now());
    }

    onTabChange = (key: string) => {
        this.setState({selectedTab: key});
    };

    getTitle = (): string => {
        const {reportViewStore} = this.props;
        const {length} = reportViewStore.selectedEntities;
        if (length === 1) {
            return intl().formatMessage({id: 'report.title.name'});
        } else if (length > 1) {
            return intl().formatMessage(
                {id: 'report.title.names'},
                {count: length},
            );
        } else {
            return intl().formatMessage({id: 'work.card.help.title'});
        }
    };

    getExtra = (): React.ReactNode => {
        const {reportViewStore} = this.props;
        const {length} = reportViewStore.selectedEntities;
        if (length === 1) {
            return null;
        } else if (length > 1) {
            return 'Actions for many';
        } else {
            return 'i';
        }
    };

    getTabList = (): TabItemContent<ReportRecord>[] => {
        const {reportViewStore, coreStore} = this.props;
        const {length} = reportViewStore.selectedEntities;
        if (length === 1) {
            return this.getTabItems().map((tabItem: TabItemContent<ReportRecord>) => {
                tabItem.disabled = tabItem.adminOnly ? !coreStore.isAdminUser : false;
                return tabItem;
            });
        } else if (length > 1) {
            return [];
        } else {
            return [];
        }
    };

    getContent = (): React.ReactNode => {
        const {reportViewStore, coreStore} = this.props;
        const {selectedEntities, selectedEntity} = reportViewStore;
        if (selectedEntities.length === 1) {
            const tabItem: TabItemContent<ReportRecord> = this.getTabItems().find((item: TabItemContent<ReportRecord>) => item.key === this.state.selectedTab)!;
            const {componentProps = {}} = tabItem;
            const {componentType: MyComponentType} = tabItem;
            if (tabItem.withRecords) {
                const isRecordCreator: boolean = selectedEntity.created_by === coreStore.user.user_id;
                const MyComponentWithRecord: any = withRecord<ReportRecord>(MyComponentType, {
                    record: selectedEntity,
                    entity_id: selectedEntity.id,
                    isRecordCreator
                });
                return <MyComponentWithRecord {...componentProps}/>;
                //     return <MyComponentType {...componentProps} record={selectedEntity}/>
            }
            return <MyComponentType {...componentProps}/>
        } else if (selectedEntities.length > 1) {
            return <i>{intl().formatMessage({id: 'msg1'})} for {selectedEntities.length} record(s)</i>;
        } else {
            return <ReportTabInfoView/>;
        }
    };

    render() {
        return (
            <Card activeTabKey={this.state.selectedTab}
                  size="small"
                  className="fm-card"
                  bordered={false}
                  title={this.getTitle()}
                  extra={this.getExtra()}
                  tabList={this.getTabList()}
                  onTabChange={this.onTabChange}>
                {this.getContent()}
            </Card>
        );
    }
}
