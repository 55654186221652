import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";

export default class UpdateLogDataCommand extends UpdateDataCommand<LogDataRecord> {
    constructor(private readonly upsertValue: UpsertValue<LogDataRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<LogDataRecord>> {
        return axiosGetInstance().post<UpsertResponse<LogDataRecord>>(`/justlog/data/update`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<LogDataRecord>>) => response.data);
    }
}
