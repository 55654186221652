import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";
import CalendarNameOptionsProvider from "./CalendarNameOptionsProvider";
import {UniqCalendarValidator} from "../../../../../common/widgets/modalForm/validators/sync/UniqCalendarValidator";

export const CALENDAR_FORM_CONFIG: ModalFormConfig<CalendarRecord> = {
    title: 'calendar.form.title',
    tableName: 'calendar',
    getNewRecord: () => {
        return {
            name: '',
        } as CalendarRecord;
    },
    fieldConfig: [
        {
            label: 'calendar.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new CalendarNameOptionsProvider()
            }
        },
    ],
    syncValidators: [new UniqCalendarValidator()]
};
