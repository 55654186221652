import {CryptoKeys} from './CryptoKeys';
import {SecureBody} from "fm-shared-data/src/types/auth/SecureBody";

export function btoa(str: string) {
    return Buffer.from(str).toString('base64');
}

export function atob(b64Encoded: string) {
    return Buffer.from(b64Encoded, 'base64').toString();
}

export function encryptString(value: string, key: string = CryptoKeys.DEFAULT): string {
    return encodeURIComponent(`${btoa(encodeURIComponent(key))}${btoa(encodeURIComponent(value))}`);
}

export function decryptString(value: string, key: string = CryptoKeys.DEFAULT): string {
    const encoded64: string = decodeURIComponent(value).slice(btoa(decodeURIComponent(key)).length);
    return decodeURIComponent(atob(encoded64));
}

export function encryptObject(obj: object = {}, key: string = CryptoKeys.DEFAULT): string {
    return encryptString(JSON.stringify(obj), key);
}

export function decryptObject<T = object>(value: string | undefined, key: string = CryptoKeys.DEFAULT): T | undefined {
    if (!value || !isValidURLData(value)) {
        return undefined;
    }
    const json: string = decryptString(value, key);
    if (json.length === 0 || json.substr(0, 1) !== '{') {
        console.error('ERROR in decryptObject(). Is not a json object:', json, 'value:', value);
        return undefined;
    }
    return JSON.parse(json) as T;
}

export function secureBody(value: object): SecureBody {
    return {data: encryptObject(value)};
}

export function isValidURLData(rawData: string | undefined): boolean {
    if (!rawData) {
        return true;
    }
    if (rawData.includes('[') || rawData.includes(']')) {
        return false;
    }
    return true;
}
