import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {FieldWorklogInfoRecord} from "fm-shared-data/src/types/db/account/sync/FieldWorklogRequest";

export default class LoadFieldWorklogRecordsCommand extends Command<FieldWorklogInfoRecord[], number> {
    execute(sync_process_id: number): Promise<FieldWorklogInfoRecord[]> {
        return axiosGetInstance().post<FieldWorklogInfoRecord[]>(`/sync/worklog/field/${sync_process_id}`)
            .then((response: AxiosResponse<FieldWorklogInfoRecord[]>) => response.data);
    }
}
