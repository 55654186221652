import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";
import WorkNameOptionsProvider from "./WorkNameOptionsProvider";
import {UniqWorkValidator} from "../../../../../common/widgets/modalForm/validators/sync/UniqWorkValidator";
import {DICTIONARY_UNIT_CONFIG} from "../../../../../common/widgets/dictionary/configs/DictionaryUnitConfig";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";
import {DICTIONARY_WORK_PAYMENT_TYPE} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import UserReadonlyProvider from "./UserReadonlyProvider";

export const WORK_FORM_CONFIG: ModalFormConfig<WorkRecord> = {
    title: 'work.field.work_name',
    tableName: 'work',
    getNewRecord: () => {
        return {
            name: '',
            // unit_id: 0,
            payment_type_name: '',
            // price: 0,
            // work_done_unit_name: '',
            // work_payment_type_id: 0,
            work_unit_name: '',
        } as WorkRecord;
    },
    fieldConfig: [
        {
            label: 'work.field.work_payment_type_id',
            field_name: 'work_payment_type_id',
            readonly: new UserReadonlyProvider(),
            renderType: FormFieldRender.RADIO,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_WORK_PAYMENT_TYPE),
                inputProps: {
                    optionType: 'button'
                }
            }
        },
        {
            label: 'equipment.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new WorkNameOptionsProvider()
            }
        },
        {
            label: 'work.field.unit_id',
            field_name: 'unit_id',
            renderType: FormFieldRender.SINGLE_SELECT,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_UNIT_CONFIG),
            }
        },
        {
            label: 'work.field.price',
            field_name: 'price',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [new NonEmptyValidator()],
        },
        {
            label: 'work.field.norm',
            field_name: 'norm',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [new NonEmptyValidator()],
        },
        {
            label: 'work.field.price_above_norm',
            field_name: 'price_above_norm',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [new NonEmptyValidator()],
        },
        {
            label: 'work.field.weight_in_kg',
            field_name: 'weight_in_kg',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [new NonEmptyValidator()],
        },
    ],
    syncValidators: [new UniqWorkValidator()]
};
