import DataProvider from '../../../../../common/widgets/modalForm/type/DataProvider';
import {FieldValidatorResult} from '../../../../../common/widgets/modalForm/validators/FieldValidatorTypes';
import {formatMessage} from '../../../../../common/locale/IntlProvider';
import {stores} from "../../../../../app/mobx/Stores";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";

export default class UserReadonlyProvider extends DataProvider<FieldValidatorResult, DataRecord> {
    get(record: DataRecord): FieldValidatorResult {
        return stores.coreStore.isAdminUser || !record.id ? null : formatMessage('modalForm.validation.admin.can.edit');
    }
}
