class UIGlobalConfig {
    get isProductionMode(): boolean {
        return process.env.NODE_ENV === 'production';
    }

    // TODO: ref it. it's visible in result js!
    get reCaptchaSITEKEY(): string {
        return '6Ldqqc4UAAAAAHjWIMGSNsDulu983DAJT1PBdv9N';
    }

    get passwordSalt(): string {
        return 'FieldMaster2020!';
    }
}

const UIConfig: UIGlobalConfig = new UIGlobalConfig();

export default UIConfig;
