import React from 'react';
import {Checkbox, Space, Spin} from 'antd';
import {DataRecord} from 'fm-shared-data/src/types/db/common/DataRecord';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, ColGroupDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {CellDoubleClickedEvent} from 'ag-grid-community/dist/lib/events';
import {copyToClipboard} from '../../../../../../../../common/widgets/clipboard/ClipboardUtils';
import SCRUDActions from '../../../../../../../../common/widgets/scrudActions/SCRUDActions';
import {RowClassParams} from 'ag-grid-community/dist/lib/entities/gridOptions';
import { getRowClassCommon } from '../../../../../../../../common/utils/GridUtils';

interface Props<R extends DataRecord> {
    records: R[] | undefined;
    columnDefs: (ColDef | ColGroupDef)[];
    getRowClass?: (params: RowClassParams) => string | string[] | undefined;

}

export default class BaseDetailsTabView<R extends DataRecord> extends React.Component<Props<R>> {

    handleCellDoubleClicked = (event: CellDoubleClickedEvent) => {
        copyToClipboard(event.value);
    }

    myComp = (props: any) => {
        return <Checkbox disabled={true} checked={props.value}/>
    }

    // menu = (
    //     <Menu
    //         onClick={onMenuClick}
    //         items={[
    //             {
    //                 key: '1',
    //                 label: '1st item',
    //             },
    //             {
    //                 key: '2',
    //                 label: '2nd item',
    //             },
    //             {
    //                 key: '3',
    //                 label: '3rd item',
    //             },
    //         ]}
    //     />
    // );
// <Dropdown.Button overlay={menu}>{props.id}</Dropdown.Button>

    handleOnEditLogDataRecord = (data: R) => async () => {
        console.log("handleOnEditLogDataRecord");
        // await this.checkAndLoadDictionaries(() => {
        // this.setState({modalFormVisible: true, modalFormData: data});
        // });
    }

    handleOnDeleteLogDataRecord = (data: R) => async () => {
        console.log("handleOnDeleteLogDataRecord");
        // const LogTypeRecords: LogTypeRecord[] = await new DeleteLogDataCommand().execute(deleteEntity);
        // await new DeleteLogDataCommand().execute(data);
        // this.loadData(true);
    }

    frameworkComponents = {
        'actionsCellRenderer': (props: any) => <Space className={"align-right"}>
            <SCRUDActions onEdit={this.handleOnEditLogDataRecord(props.data as R)}
                          isEditEnabled={props.data?.process_state === 3}/>
        </Space>,
    }

    actionsColDef: ColDef = {
        headerName: "actions2",
        cellRenderer: "actionsCellRenderer"
    }

    render() {
        const {records, columnDefs} = this.props;
        return (
            <Spin spinning={!records} wrapperClassName={"spin-flex maxContent"}>
                <AgGridReact className={"ag-theme-alpine"}
                             immutableData={true}
                             suppressLoadingOverlay={true}
                             suppressNoRowsOverlay={true}
                             suppressCellSelection={true}
                             rowData={records}
                             getRowNodeId={(rec: R) => rec.id.toString()}
                             columnDefs={columnDefs}
                             // columnDefs={columnDefs.concat(this.actionsColDef)}
                             gridOptions={
                                 {
                                     getRowClass: this.props.getRowClass || getRowClassCommon
                                 }
                             }
                             rowSelection="single"
                             rowHeight={30}
                             onCellDoubleClicked={this.handleCellDoubleClicked}
                             frameworkComponents={this.frameworkComponents}
                             defaultColDef={{
                                 sortable: true,
                                 resizable: true,
                                 filter: true,
                                 flex: 1,
                             }}
                >
                </AgGridReact>
            </Spin>
        );
    }
}
