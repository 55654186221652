import React from 'react';
import {Spin} from 'antd';
import './style/style.scss';
import GetCEDAvatarsCommand from "./commands/GetCEDAvatarsCommand";
import CEDAvatars from "./CEDAvatars";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {CEDAvatarFields} from "fm-shared-data/src/types/db/common/CEDAvatarFields";

interface Props<T extends DataRecord> {
    record: T;
    table: string;
    size?: 'large' | 'small' | 'default' | number;
    showSpin?: boolean;
}

interface State {
    cedAvatarFields: CEDAvatarFields | undefined;
}

export default class CEDAvatarsController<T extends DataRecord> extends React.Component<Props<T>, State> {
    state: State = {
        cedAvatarFields: undefined
    };

    componentDidMount() {
        console.info('CEDAvatarsController: componentDidMount ');
        this.loadCED();
    }

    componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<State>, snapshot?: any) {
        console.info('CEDAvatarsController: componentDidUpdate ');
        // BUG: did not update when user stay on this DataRecord (id's did not change) BUT record has been updated
        if (prevProps.record.id !== this.props.record.id) {
            // this.setState({cedAvatarFields: undefined});
            this.loadCED();
        }
    }

    loadCED = () => {
        const {table, record} = this.props;
        new GetCEDAvatarsCommand(table).execute(record).then((cedAvatarFields: CEDAvatarFields) => {
            this.setState({cedAvatarFields});
        });
    }

    render() {
        const {size = 'default', record, showSpin} = this.props;
        const {cedAvatarFields} = this.state;
        const detailedRecord: T & CEDAvatarFields = Object.assign({}, record, cedAvatarFields);
        return (
            <Spin spinning={!cedAvatarFields && !!showSpin} wrapperClassName={'ced-avatar-container'}>
                {cedAvatarFields && (<CEDAvatars size={size} record={detailedRecord} className={'ced-avatar-item'} />)}
            </Spin>
        );
    }
}
