import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {calendarHash, CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqEquipmentValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqCalendarValidator extends SyncValidator<CalendarRecord, UniqEquipmentValidatorConfig> {

    constructor(config?: UniqEquipmentValidatorConfig) {
        super(config || {});
    }

    validate(value: CalendarRecord): FieldValidatorResult {
        if (value.short_name && value.short_name.toLowerCase().trim()) {
            const valueHash: string = calendarHash(value);
            const isRecordExists: boolean = stores.dataStore.calendar
                .some((rec: CalendarRecord) => value.id !== rec.id && valueHash === calendarHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.calendar.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
