import {action, makeObservable, observable, runInAction} from 'mobx';
import {CommonCommentDetailed} from "fm-shared-data/src/types/db/common/CommonCommentDetailed";
import {computedFn} from "mobx-utils";
import DeleteCommentCommand from "../commands/DeleteCommentCommand";
import {CommonCommentRecord} from "fm-shared-data/src/types/db/common/CommonCommentRecord";
import AddCommentCommand from "../commands/AddCommentCommand";
import {InsertResponse} from "fm-shared-data/src/types/db/common/InsertResponse";
import {ParentTreeData} from "fm-shared-data/src/types/db/common/ParentTreeData";
import {convertParentDataRecordsToParentData} from "fm-shared-utils/src/utils/tree/ParentUtils";
import LoadCommentsCommand from "../commands/LoadCommentsCommand";

export class CommentStore {
    commentsMap: Map<string, ParentTreeData<CommonCommentDetailed>[]> = new Map<string, ParentTreeData<CommonCommentDetailed>[]>();

    constructor() {
        makeObservable(this, {
            commentsMap: observable,

            loadComments: action,
            setComments: action,
            addComment: action,
            deleteComment: action,
        });
    }

    private getKey(barcode_entity_id: number, entity_id: number): string {
        return `${barcode_entity_id}.${entity_id}`;
    }

    getComments = computedFn((barcode_entity_id: number, entity_id: number): ParentTreeData<CommonCommentDetailed>[] | undefined => {
        return this.commentsMap.get(this.getKey(barcode_entity_id, entity_id));
    });

    async loadComments(barcode_entity_id: number, entity_id: number) {
        this.commentsMap.delete(this.getKey(barcode_entity_id, entity_id));
        const comments: CommonCommentDetailed[] = await new LoadCommentsCommand(barcode_entity_id, entity_id).execute();
        runInAction(() => {
            this.setComments(barcode_entity_id, entity_id, convertParentDataRecordsToParentData<CommonCommentDetailed>(comments));
        });
    }

    async setComments(barcode_entity_id: number, entity_id: number, comments: ParentTreeData<CommonCommentDetailed>[]) {
        this.commentsMap.set(this.getKey(barcode_entity_id, entity_id), comments);
    }

    async addComment(barcode_entity_id: number, entity_id: number, comment: Partial<CommonCommentRecord>) {
        const comments: InsertResponse<CommonCommentDetailed> = await new AddCommentCommand(barcode_entity_id, entity_id).execute(comment);
        runInAction(() => {
            this.setComments(barcode_entity_id, entity_id, convertParentDataRecordsToParentData<CommonCommentDetailed>(comments.list));
        });
    }

    async deleteComment(barcode_entity_id: number, entity_id: number, comment_id: number) {
        const comments: CommonCommentDetailed[] = await new DeleteCommentCommand(barcode_entity_id, entity_id).execute(comment_id);
        runInAction(() => {
            this.setComments(barcode_entity_id, entity_id, convertParentDataRecordsToParentData<CommonCommentDetailed>(comments));
        });
    }

}
