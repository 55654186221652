import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {logTypeHash, LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqLogTypeValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqLogTypeValidator extends SyncValidator<LogTypeRecord, UniqLogTypeValidatorConfig> {

    constructor(config?: UniqLogTypeValidatorConfig) {
        super(config || {});
    }

    validate(value: LogTypeRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = logTypeHash(value);
            const isRecordExists: boolean = stores.dataStore.calendar
                .some((rec: LogTypeRecord) => value.id !== rec.id && valueHash === logTypeHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.logType.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
