import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";

export default class LoadEmployeeAddressCommand extends Command<EmployeeAddressRecord[]> {
    execute(): Promise<EmployeeAddressRecord[]> {
        return axiosGetInstance().post<EmployeeAddressRecord[]>('/employee/address')
            .then((response: AxiosResponse<EmployeeAddressRecord[]>) => response.data);
    }
}
