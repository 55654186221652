import DataProvider from '../../../../../common/widgets/modalForm/type/DataProvider';
import {FieldValidatorResult} from '../../../../../common/widgets/modalForm/validators/FieldValidatorTypes';
import {formatMessage} from '../../../../../common/locale/IntlProvider';
import {ScannerRecord} from 'fm-shared-data/src/types/db/account/scanner/ScannerRecord';

export default class ScannerDeviceKeyReadonlyProvider extends DataProvider<FieldValidatorResult, ScannerRecord> {
    get(record: ScannerRecord): FieldValidatorResult {
        return record?.id ? formatMessage('employee_email.field.email_confirmed_readonly') : null;
    }
}
