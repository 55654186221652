import {AxiosResponse} from "axios";
import UpdateDataCommand from "../../../types/UpdateDataCommand";
import {axiosGetInstance} from "../../../utils/AxiosUtils";
import {FileDetailed} from "fm-shared-data/src/types/db/common/FileDetailed";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";

export default class UpdateFileCommand extends UpdateDataCommand<FileDetailed> {
    execute(upsertValue: UpsertValue<FileDetailed>): Promise<UpsertResponse<FileDetailed>> {
        return axiosGetInstance().post<UpsertResponse<FileDetailed>>(`/file/update`, upsertValue)
            .then((response: AxiosResponse<UpsertResponse<FileDetailed>>) => response.data);
    }

}
