export async function delay(timeout: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        console.info(`dealy start! for ${timeout / 1000} sec...`);
        setTimeout(() => {
            console.info(
                `dealy finished! Continue loading nest app (${timeout / 1000} sec)...`,
            );
            resolve(`OK in ${timeout / 1000} sec!!`);
        }, timeout);
    });
}
