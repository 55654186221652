import React from 'react';
import {Badge, Collapse, Select, Tag} from 'antd';
import TreeStructure from '../../../../../common/widgets/tree/TreeStructure';
import {formatMessage} from '../../../../../common/locale/IntlProvider';
import {ApartmentOutlined, FilterOutlined, TagOutlined} from '@ant-design/icons/lib';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {inject, observer} from 'mobx-react';
import {LogTypeViewStore} from '../../mobx/LogTypeViewStore';
import PredefinedFilterItem from '../../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../../common/utils/LocalStorageUtils';
import {PREDEFINED_LOGTYPE_FILTERS} from './PredefinedLogTypeFilters';
import PredefinedDataFilters from '../../../../../common/widgets/predefinedFilters/PredefinedDataFilters';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import {CustomTagProps} from "rc-select/lib/interface/generator";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {getTagColor, getTagLabel} from "../../../employee/components/tabs/items/general/widgets/TagUtils";
import UpdateTreeCommand from "../../../commands/tree/UpdateTreeCommand";
import AddTreeCommand from "../../../commands/tree/AddTreeCommand";
import DeleteTreeCommand from "../../../commands/tree/DeleteTreeCommand";
import MoveTreeCommand from "../../../commands/tree/MoveTreeCommand";
import {LOGTYPE_TREE_STRUCTURE_FORM_CONFIG} from "./LogTypeTreeStructureFormConfig";
import {TreeRecord} from 'fm-shared-data/src/types/db/common/TreeRecord';

const {Option} = Select;
const {Panel} = Collapse;

type StoreProps = {
    logTypeViewStore: LogTypeViewStore;
};

type Props = StoreProps;

interface State {
    activeKeys: string[];
    modalFormVisible: boolean;
    modalFormData: TreeRecord;
    // modalFormData: Partial<LogTypeTreeRecord>;
}

@inject('logTypeViewStore')
@observer
export default class LogTypeFiltersComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    private openPanels: string[] = (LocalStorage.getItem(LocalStorageKey.LOGTYPE_FILTER_OPEN_PANELS) || '0').split(',');

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('LogTypeFiltersComponent: constructor', new Date());
        this.state = {
            activeKeys: this.openPanels,
            modalFormVisible: false,
            modalFormData: {} as TreeRecord
        };
    }

    onSearch = (value: string) => {
        console.log(`Search: ${value}`);
    };

    onTreeAdd = async () => {
        const {selectedLogTypeTreeRecord} = this.props.logTypeViewStore;
        this.setState({modalFormVisible: true, modalFormData: LOGTYPE_TREE_STRUCTURE_FORM_CONFIG.getNewRecord(selectedLogTypeTreeRecord)});
    };

    onTreeEdit = () => {
        const {logTypeViewStore} = this.props;
        const {treeSelectedNode} = logTypeViewStore;
        this.setState({modalFormVisible: true, modalFormData: treeSelectedNode.record});
    };

    onTreeDelete = async () => {
        const {selectedLogTypeTreeRecord} = this.props.logTypeViewStore;
        const result: TreeRecord[] = await new DeleteTreeCommand<TreeRecord>('log_type_tree').execute(selectedLogTypeTreeRecord);
        await this.props.logTypeViewStore.updateLogTypeTreeRecord(result);
        const selectedTreeNode: TreeData<TreeRecord> | undefined = findNodeById(this.props.logTypeViewStore.logTypeTreeData, selectedLogTypeTreeRecord.parent_id);
        this.handleTreeSelect(selectedTreeNode!);
    };

    onTreeDrag = async (dragId: number, id: number, dropPosition: number): Promise<any> => {
        const updateResponse: UpsertResponse<TreeRecord> = await new MoveTreeCommand<TreeRecord>('log_type_tree', dragId, id, dropPosition).execute();
        return this.props.logTypeViewStore.updateLogTypeTreeRecord(updateResponse.list);
    };

    componentDidMount(): void {
        console.log('LogTypeFiltersComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('LogTypeFiltersComponent: componentDidUpdate', new Date());
    }

    collapseOnChange = (key: string | string[]) => {
        console.log('collapseOnChange', key);
        const keys: string[] = typeof key === 'string' ? [key] : key;
        this.setState({activeKeys: keys});
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_FILTER_OPEN_PANELS, keys.join(','));
    };

    saveTreeRecordCommand = async (upsertValue: UpsertValue<TreeRecord>): Promise<UpsertResponse<TreeRecord>> => {
        const {logTypeViewStore} = this.props;
        const {treeSelectedNode} = logTypeViewStore;
        const isUpdate: boolean = !!upsertValue.initialValue.id;
        if (upsertValue.updatedValue.color === "#000000") {
            upsertValue.updatedValue.color = undefined;
        }
        const upsertResponse: UpsertResponse<TreeRecord> = isUpdate
            ? await new UpdateTreeCommand<TreeRecord>('log_type_tree').execute(upsertValue)
            : await new AddTreeCommand<TreeRecord>('log_type_tree', treeSelectedNode.id).execute(upsertValue);
        logTypeViewStore.updateLogTypeTreeRecord(upsertResponse.list);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveTreeRecord = async (upsertValue: UpsertValue<TreeRecord>) => {
        this.handleOnCancelTreeModalForm();
    };

    handleOnCancelTreeModalForm = () => {
        this.setState({modalFormVisible: false});
    };

    handleTreeSelect = (selectedNode: TreeData<TreeRecord>) => {
        const {logTypeViewStore} = this.props;
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_TREE_ID, selectedNode.id.toString());
        logTypeViewStore.setTreeSelectedNode(selectedNode);
    };

    handleOnExpand = (expandedKeys: number[]) => {
        const {logTypeViewStore} = this.props;
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_TREE_NODES, expandedKeys.join(','));
        logTypeViewStore.expandedKeys = expandedKeys;
    };

    handleOnPredefinedFilterChange = (selectedFilters: PredefinedFilterItem<LogTypeRecord>[]) => {
        const {logTypeViewStore} = this.props;
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_PREDEFINED_FILTERS, selectedFilters.map((selectedFilter: PredefinedFilterItem<LogTypeRecord>) => selectedFilter.key).join(','));
        logTypeViewStore.setPredefinedFilters(selectedFilters);
    };

    handleOnTagsChange = (tags: string[]) => {
        const {logTypeViewStore} = this.props;
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_TAGS_FILTERS, tags.join(','));
        logTypeViewStore.setSelectedLogTypeTags(tags);
    };

    tagRender = (props: CustomTagProps): React.ReactElement => {
        const {value, closable, onClose} = props;
        return (
            <Tag color={getTagColor(value.toString())}
                 closable={closable}
                 onClose={onClose}
                 className={'tag-text'}>{getTagLabel(value.toString(), 40)}</Tag>
        );
    };

    render() {
        const {logTypeViewStore} = this.props;
        const {logTypeTreeData, treeSelectedNode, isTreeRootNodeSelected} = logTypeViewStore;

        const isTreePanelExpanded: boolean = this.state.activeKeys.includes('0');
        let treePanelTitle: string = formatMessage('employee.filters.tree.title');
        if (!isTreePanelExpanded) {
            treePanelTitle += `: ${logTypeViewStore.treeSelectedNode.name}`;
        }

        return (
            <Collapse bordered={false}
                      defaultActiveKey={this.openPanels}
                      onChange={this.collapseOnChange}>
                <Panel header={treePanelTitle} key="0"
                       extra={isTreePanelExpanded
                           ? <SCRUDActions onAdd={this.onTreeAdd}
                                           onDelete={this.onTreeDelete}
                                           onEdit={this.onTreeEdit}
                                           isAddEnabled={true}
                                           isEditEnabled={!isTreeRootNodeSelected}
                                           isDeleteEnabled={!isTreeRootNodeSelected && treeSelectedNode.count === 0}/>
                           : <ApartmentOutlined/>}>
                    <TreeStructure treeData={logTypeTreeData}
                                   selectedNode={treeSelectedNode}
                                   expandedKeys={logTypeViewStore.expandedKeys}
                                   onExpand={this.handleOnExpand}
                                   onSelect={this.handleTreeSelect}
                                   onDrag={this.onTreeDrag}
                                   onDoubleClick={!isTreeRootNodeSelected ? this.onTreeEdit : undefined}
                    />
                    {this.state.modalFormVisible && <ModalFormik config={LOGTYPE_TREE_STRUCTURE_FORM_CONFIG}
                                                                 initialValues={this.state.modalFormData}
                                                                 saveCommand={this.saveTreeRecordCommand}
                                                                 onSave={this.handleOnSaveTreeRecord}
                                                                 onCancel={this.handleOnCancelTreeModalForm}/>
                    }
                </Panel>
                <Panel header={formatMessage('employee.filters.predefined.title')} key="1"
                       extra={<Badge count={logTypeViewStore.selectedPredefinedFilters.length} size={'small'} offset={[3, -3]}><FilterOutlined/></Badge>}>
                    <PredefinedDataFilters
                        filters={PREDEFINED_LOGTYPE_FILTERS}
                        selectedFilters={logTypeViewStore.selectedPredefinedFilters}
                        onChange={this.handleOnPredefinedFilterChange}/>
                </Panel>
                <Panel header={formatMessage('employee.filters.tags.title')} key="2"
                       extra={<Badge count={logTypeViewStore.selectedLogTypeTags.length} size={'small'} offset={[3, -3]}><TagOutlined/></Badge>}>
                    <Select mode="multiple"
                            tagRender={this.tagRender}
                            allowClear={true}
                            style={{width: '100%'}}
                            placeholder="Please select tags"
                            value={logTypeViewStore.selectedLogTypeTags.map((tag: string) => tag)}
                            disabled={!logTypeViewStore.isLogTypeTagsLoaded}
                            loading={logTypeViewStore.isLogTypeTagsLoading}
                            onChange={this.handleOnTagsChange}>
                        {logTypeViewStore.logTypeTags.map((tag: string, index: number) => <Option key={`${index}`} value={tag}>
                            <Tag className={'tag-text'} color={getTagColor(tag)} closable={false}>{getTagLabel(tag, 40)}</Tag>
                        </Option>)}
                    </Select>
                </Panel>
            </Collapse>
        );
    }
}
