import DataProvider from '../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {AutoCompleteOptionType} from '../../../../../../../../common/widgets/modalForm/formItems/AutoCompleteOptionType';
import {stores} from '../../../../../../../../app/mobx/Stores';
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";

export default class PhoneTypeAutoCompleteProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        const uniqTypes: Set<string> = new Set<string>();
        uniqTypes.add('Mobile');
        uniqTypes.add('Desk');
        uniqTypes.add('Home');
        dataStore.employeePhone.forEach((item: EmployeePhoneRecord) => uniqTypes.add(item.phone_type));
        return Array.from(uniqTypes).map((value: string) => {
            return {value};
        });
    }
}
