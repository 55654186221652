import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../common/utils/AxiosUtils";
import Command from "../../../common/types/Command";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";

export default class UpdateTagsCommand<T extends DataRecord = DataRecord> extends Command<T, UpdateDataRecord<TagFields>> {

    constructor(private readonly table: string, private readonly updateDataRecord: UpdateDataRecord<TagFields>) {
        super();
    }

    async execute(): Promise<T> {
        return axiosGetInstance().post<T>(`/tag/update/${this.table}`, this.updateDataRecord)
            .then((response: AxiosResponse<T>) => response.data);
    }

}
