import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {ReportRecord} from 'fm-shared-data/src/types/db/account/report/ReportRecord';

export default class LoadReportCommand extends Command<ReportRecord[]> {
    execute(): Promise<ReportRecord[]> {
        return axiosGetInstance().post<ReportRecord[]>('/report/all')
            .then((response: AxiosResponse<ReportRecord[]>) => response.data);
    }
}
