import * as React from 'react';
import {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import Button from 'antd/lib/button';
import {ReCaptchaProps} from './ReCaptchaButton';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import ReCaptchaResponse from './ReCaptchaResponse';
import ReCaptchaRequest from './ReCaptchaRequest';

// Docs: https://developers.google.com/recaptcha/docs/verify/

export const ReCaptchaValidator: React.FunctionComponent<ReCaptchaProps> = (props: ReCaptchaProps) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [tokenError, setTokenError] = useState(false);

    const validateToken = async (token: string): Promise<boolean> => {
        const body: ReCaptchaRequest = {token};
        const axios: AxiosInstance = Axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const response: AxiosResponse<ReCaptchaResponse> = await axios.post<ReCaptchaResponse>('/recaptcha/validate', body)
            .catch((error: any) => {
                console.info('FM ReCaptcha validation error:', error);
                return Promise.resolve({data: {success: false}} as AxiosResponse<ReCaptchaResponse>);
            });
        console.info('FM ReCaptcha validation response:', response);
        return Promise.resolve(response.data.success);
    };

    const clickHandler = async () => {
        if (!executeRecaptcha) {
            return;
        }
        props.onClick();
        setTokenError(false);
        const token: string = await executeRecaptcha(props.action);
        const isValidToken: boolean = await validateToken(token);
        if (isValidToken) {
            props.onValid();
        } else {
            setTokenError(true);
            props.onInvalid();
        }
    };

    return (
        <div>
            <Button
                icon={props.icon}
                onClick={clickHandler}
                disabled={props.disabled}
                loading={props.loading}
                type="primary"
            >
                {props.label}
            </Button>
            <br/>
            <small>
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy</a>{' '}and{' '}
                <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                    Terms of Service</a>{' '} apply.
            </small>
            {tokenError && (
                <div>
                    Google ReCaptcha reject this action. Please try again.
                </div>
            )}
        </div>
    );
};
