import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import {restoreEquipmentPredefinedFilter} from "../components/filters/PredefinedEquipmentFilters";
import LoadTagsCommand from "../../commands/LoadTagsCommand";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export class EquipmentViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<EquipmentRecord>[] = [];

    visibleEntities: EquipmentRecord[] = [];
    selectedEntities: EquipmentRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    equipmentTags: string[] = [];
    selectedEquipmentTags: string[] = [];
    isEquipmentTagsLoaded: Date | undefined;
    isEquipmentTagsLoading: boolean = true;

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            equipmentTags: observable,
            selectedEquipmentTags: observable,
            isEquipmentTagsLoaded: observable,
            isEquipmentTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateEquipmentTreeRecord: action,
            setSelectedEquipmentTags: action,
            refreshEquipmentTags: action,

            selectedEntityIds: computed,
            visibleEquipmentCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            equipmentTreeData: computed,
            selectedEquipmentTreeRecord: computed,

            setEquipment: action,
        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleEquipmentCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isEquipmentLoaded && !!this.dataStore.isEquipmentTreeLoaded && !!this.isEquipmentTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleEquipmentCount > 0 && this.visibleEquipmentCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.equipmentTreeRootId;
    }

    get equipmentTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.equipmentTreeData;
    }

    get selectedEquipmentTreeRecord(): TreeRecord {
        return this.dataStore.equipmentTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isEquipmentLoaded) {
            loaders.push(this.dataStore.loadEquipment());
        }
        if (!this.dataStore.isEquipmentTreeLoaded) {
            loaders.push(this.dataStore.loadEquipmentTree());
        }
        if (!this.isEquipmentTagsLoaded) {
            loaders.push(this.loadEquipmentTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadEquipmentTags() {
        this.isEquipmentTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('equipment').execute();
        runInAction(() => {
            this.equipmentTags = tags;
            this.isEquipmentTagsLoading = false;
            this.isEquipmentTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.equipmentTreeData, this.dataStore.equipmentTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.EQUIPMENT_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.EQUIPMENT_PREDEFINED_FILTERS);

        //tags:
        this.selectedEquipmentTags = [];
        LocalStorage.removeItem(LocalStorageKey.EQUIPMENT_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (equipment: EquipmentRecord) => (equipment.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: EquipmentRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedEquipmentTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (equipment: EquipmentRecord) => this.selectedEquipmentTags.filter(Boolean).every((eTag: string) => equipment.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.equipmentTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<EquipmentRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedEquipmentTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.equipment
            : this.dataStore.equipment.filter((equipmentRecord: EquipmentRecord) => {
                return filters.every((fun: any) => fun(equipmentRecord));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: EquipmentRecord | undefined = this.visibleEntities.find((equipment: EquipmentRecord) => equipment.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateEquipmentTreeRecord(equipmentTreeRecords: TreeRecord[]) {
        this.dataStore.setEquipmentTree(equipmentTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.equipmentTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.equipmentTreeData, this.dataStore.equipmentTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedEquipmentTags(tags: string[]) {
        this.selectedEquipmentTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshEquipmentTags(trySelectId?: number) {
        await this.loadEquipmentTags();
        runInAction(() => {
            this.selectedEquipmentTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<EquipmentRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setEquipment(data: EquipmentRecord[], trySelectId?: number) {
        this.dataStore.setEquipment(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedEquipments: EquipmentRecord[]) {
        this.selectedEntities = selectedEquipments;
    }

    get selectedEntity(): EquipmentRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.equipmentTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.EQUIPMENT_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.equipmentTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.equipmentTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.EQUIPMENT_TREE_NODES) || this.dataStore.equipmentTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreEquipmentPredefinedFilter();

        // tags:
        this.selectedEquipmentTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.EQUIPMENT_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.equipmentTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.EQUIPMENT_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }

        if (isBarCode(this.universalFilter)) {
            const entityId: number = Number.parseInt(this.universalFilter.substr(-7));
            this.visibleEntities = this.visibleEntities.filter((equipment: EquipmentRecord) => entityId === equipment.id);
        } else { // full text search
            this.visibleEntities = this.visibleEntities.filter((equipment: EquipmentRecord) => {
                const searchString: string = equipment.name.toLowerCase();
                return searchString.includes(this.universalFilter.toLowerCase());
            });
        }
        // this.selectedEntities = []; // clear any selections
    }

}
