import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";

export default class AddReportCommand extends UpdateDataCommand<ReportRecord> {

    constructor(private readonly upsertValue: UpsertValue<ReportRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<ReportRecord>> {
        return axiosGetInstance().post<UpsertResponse<ReportRecord>>(`/report/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<ReportRecord>>) => response.data);
    }

}
