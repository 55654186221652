import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {stores} from "../../../../app/mobx/Stores";
import DataProvider from "../../modalForm/type/DataProvider";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";

export class DataStoreFieldProvider implements DataProvider<DictionaryRawRecord<FieldRecord>[]> {

    get(): DictionaryRawRecord<FieldRecord>[] {
        return stores.dataStore.field.map((field: FieldRecord) => {
            return {
                id: field.id,
                name: field.name,
                record: field
            };
        }).sort((a: DictionaryRawRecord<FieldRecord>, b: DictionaryRawRecord<FieldRecord>): number => a.name.localeCompare(b.name));
    }
}