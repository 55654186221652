export enum ResponseErrorCode {
    LOGIN_USER_NOT_FOUND = "USER_NOT_FOUND",
    LOGIN_WRONG_PASSWORD = "WRONG_PASSWORD",
    LOGIN_USER_NOT_ACTIVATED = "USER_NOT_ACTIVATED", // account has been created but user did not set new password (not activate)

    SIGNUP_USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
    SIGNUP_CANT_CREATE_NEW_ACCOUNT = "CANT_CREATE_NEW_ACCOUNT",

    SET_PASSWORD_ALREADY_SET = "PASSWORD_ALREADY_SET",
    SET_PASSWORD_LINK_EXPIRED_OR_USED = "LINK_EXPIRED_OR_USED",

    FORGOT_PASSWORD_USER_NOT_FOUND = "USER_NOT_FOUND",

    RECAPTCHA_ERROR = "RECAPTCHA_ERROR",

    SERVER_IS_BUSY_TRY_LATER = "SERVER_IS_BUSY_TRY_LATER",
}
