import {ACCESS_TOKEN_NAME, getCookie} from "fm-shared-utils/src/utils/CookieUtils";

export function setBrowserCookie(name: string, value: string, days: number) {
    let expires: string = '';
    if (days) {
        const date: Date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getBrowserCookie(name: string): string | undefined {
    return getCookie(document.cookie, name);
}

export function deleteBrowserCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export function setBrowserAuthCookie(accessTokenValue: string) {
    return setBrowserCookie(ACCESS_TOKEN_NAME, accessTokenValue, 32);
}

export function deleteBrowserAuthCookie() {
    deleteBrowserCookie(ACCESS_TOKEN_NAME);
}
