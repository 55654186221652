import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";

export default class LoadEquipmentCommand extends Command<EquipmentRecord[]> {
    execute(): Promise<EquipmentRecord[]> {
        return axiosGetInstance().post<EquipmentRecord[]>('/equipment/all')
            .then((response: AxiosResponse<EquipmentRecord[]>) => response.data);
    }
}
