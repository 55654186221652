import {action, makeObservable, observable, runInAction} from 'mobx';
import {AppURL} from '../../routing/AppURL';
import {initialLocale, IntlProvider} from '../../common/locale/IntlProvider';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

export class AppStore<T = any> {
    appURL: AppURL<T> = {url: AppRoutes.ROOT, page: AppRoutes.ROOT};
    locale: string = initialLocale;
    isLocaleLoaded: Date | undefined;
    isLocaleExists: boolean = false;

    currentPage: AppRoutes = AppRoutes.ROOT;

    constructor() {
        makeObservable(this, {
            appURL: observable,
            locale: observable,
            isLocaleLoaded: observable,
            isLocaleExists: observable,
            currentPage: observable,

            loadLocale: action,
            updateAppURL: action,

        });
    }

    async loadLocale(newLocale: string) {
        console.info(`lets load locale ${newLocale} ... `);
        this.isLocaleLoaded = undefined;
        // localStorage.setItem('locale', newLocale);// TODO fix it. it's hotfix
        await IntlProvider.instance().loadLocale(newLocale);
        runInAction(() => {
            this.locale = newLocale;
            this.isLocaleLoaded = new Date();
            this.isLocaleExists = true;
        });
    }

    updateAppURL(appURL: AppURL<T>) {
        console.info('AppStore: updateAppURL to ', JSON.stringify(appURL));
        this.appURL = appURL;
    }
}
