import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {restoreLogTypePredefinedFilter} from "../components/filters/PredefinedLogTypeFilters";
import LoadTagsCommand from "../../commands/LoadTagsCommand";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export class LogTypeViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<LogTypeRecord>[] = [];

    visibleEntities: LogTypeRecord[] = [];
    selectedEntities: LogTypeRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    logTypeTags: string[] = [];
    selectedLogTypeTags: string[] = [];
    isLogTypeTagsLoaded: Date | undefined;
    isLogTypeTagsLoading: boolean = true;

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            logTypeTags: observable,
            selectedLogTypeTags: observable,
            isLogTypeTagsLoaded: observable,
            isLogTypeTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateLogTypeTreeRecord: action,
            setSelectedLogTypeTags: action,
            refreshLogTypeTags: action,

            selectedEntityIds: computed,
            visibleLogTypeCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            logTypeTreeData: computed,
            selectedLogTypeTreeRecord: computed,

            setLogType: action,
        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleLogTypeCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isLogTypeLoaded && !!this.dataStore.isLogTypeTreeLoaded && !!this.isLogTypeTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleLogTypeCount > 0 && this.visibleLogTypeCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.logTypeTreeRootId;
    }

    get logTypeTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.logTypeTreeData;
    }

    get selectedLogTypeTreeRecord(): TreeRecord {
        return this.dataStore.logTypeTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isLogTypeTreeLoaded) {
            loaders.push(this.dataStore.loadLogTypeTree());
        }
        if (!this.isLogTypeTagsLoaded) {
            loaders.push(this.loadLogTypeTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadLogTypeTags() {
        this.isLogTypeTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('log_type').execute();
        runInAction(() => {
            this.logTypeTags = tags;
            this.isLogTypeTagsLoading = false;
            this.isLogTypeTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.logTypeTreeData, this.dataStore.logTypeTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.LOGTYPE_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.LOGTYPE_PREDEFINED_FILTERS);

        //tags:
        this.selectedLogTypeTags = [];
        LocalStorage.removeItem(LocalStorageKey.LOGTYPE_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (logType: LogTypeRecord) => (logType.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: LogTypeRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedLogTypeTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (logType: LogTypeRecord) => this.selectedLogTypeTags.filter(Boolean).every((eTag: string) => logType.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.logTypeTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<LogTypeRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedLogTypeTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.logType
            : this.dataStore.logType.filter((LogTypeRecord: LogTypeRecord) => {
                return filters.every((fun: any) => fun(LogTypeRecord));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: LogTypeRecord | undefined = this.visibleEntities.find((logType: LogTypeRecord) => logType.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateLogTypeTreeRecord(logTypeTreeRecords: TreeRecord[]) {
        this.dataStore.setLogTypeTree(logTypeTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.logTypeTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.logTypeTreeData, this.dataStore.logTypeTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedLogTypeTags(tags: string[]) {
        this.selectedLogTypeTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshLogTypeTags(trySelectId?: number) {
        await this.loadLogTypeTags();
        runInAction(() => {
            this.selectedLogTypeTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<LogTypeRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setLogType(data: LogTypeRecord[], trySelectId?: number) {
        this.dataStore.setLogType(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedLogTypes: LogTypeRecord[]) {
        this.selectedEntities = selectedLogTypes;
    }

    get selectedEntity(): LogTypeRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.logTypeTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.LOGTYPE_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.logTypeTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.logTypeTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.LOGTYPE_TREE_NODES) || this.dataStore.logTypeTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreLogTypePredefinedFilter();

        // tags:
        this.selectedLogTypeTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.LOGTYPE_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.logTypeTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.LOGTYPE_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }

        if (isBarCode(this.universalFilter)) {
            const entityId: number = Number.parseInt(this.universalFilter.substr(-7));
            this.visibleEntities = this.visibleEntities.filter((logType: LogTypeRecord) => entityId === logType.id);
        } else { // full text search
            this.visibleEntities = this.visibleEntities.filter((logType: LogTypeRecord) => {
                const searchString: string = logType.name.toLowerCase();
                return searchString.includes(this.universalFilter.toLowerCase());
            });
        }
        // this.selectedEntities = []; // clear any selections
    }

}
