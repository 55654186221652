import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";

export default class AddLogTypeCommand extends UpdateDataCommand<LogTypeRecord> {

    constructor(private readonly upsertValue: UpsertValue<LogTypeRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<LogTypeRecord>> {
        return axiosGetInstance().post<UpsertResponse<LogTypeRecord>>(`/justlog/type/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<LogTypeRecord>>) => response.data);
    }

}
