import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {ModalFormConfig} from '../../../../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {FormFieldRender} from '../../../../../../../../common/widgets/modalForm/type/FormFieldRender';
import {NonEmptyValidator} from '../../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {DICTIONARY_DYNAMIC_EMPLOYEE_CONFIRMED_EMAILS} from './DynamicDictionaryEmployeeEmails';
import {DICTIONARY_ROLE} from "../../../../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {DictionaryDecorator} from "../../../../../../../../common/widgets/dictionary/DictionaryDecorator";

export const EMPLOYEE_WEB_ACCESS_FORM_CONFIG: ModalFormConfig<EmployeeRecord> = {
    title: 'employee_web_access.modalForm.title',
    tableName: 'employee',
    getNewRecord: (): EmployeeRecord => {
        return {} as EmployeeRecord;
    },
    fieldConfig: [
        {
            label: 'employee_web_access.field.email',
            field_name: 'email',
            renderType: FormFieldRender.SINGLE_SELECT,
            syncValidators: [new NonEmptyValidator({invalidMessageKey: 'employee_web_access.field.email_invalid'})],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_DYNAMIC_EMPLOYEE_CONFIRMED_EMAILS),
                inputProps: {
                    placeholder: 'Login email'
                }
            },
        },
        {
            label: 'employee_web_access.field.roles',
            field_name: 'roles',
            renderType: FormFieldRender.MULTI_SELECT,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_ROLE),
                inputProps: {
                    placeholder: 'Please select roles'
                }
            }
        }
    ]
};
