import React from 'react';
import './style/filterControl.scss';
import {Input} from 'antd';
import classNames from 'classnames';
import {FilterOutlined} from '@ant-design/icons/lib';
import {formatMessage} from '../../locale/IntlProvider';

interface Props {
    onFilter?: (value: string) => void;
    isFilterEnabled?: boolean;
    value?: string;
}

interface State {
    isFilterVisible: boolean;
}

export default class FilterControl extends React.Component<Props, State> {
    ref = React.createRef<Input>();

    constructor(props: Props) {
        super(props);
        this.state = {
            isFilterVisible: !!props?.value
        };
    }

    onSearchClick = () => {
        if (this.props.isFilterEnabled) {
            this.setState({isFilterVisible: !this.state.isFilterVisible}, () => {
                if (this.state.isFilterVisible && this.ref.current) {
                    this.ref.current.focus();
                }
            });
        }
    };

    // handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (e.charCode === 13) {
    //         this.props.onFilter!((e.target as HTMLInputElement).value);
    //         // (e.target as HTMLInputElement).value = '';
    //     }
    // };

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.onFilter) {
            return;
        }
        if (e.type === 'click') {
            // clear icon is clicked
            this.props.onFilter('');
        } else {
            this.props.onFilter((e.target as HTMLInputElement).value);
        }
    };

    render() {
        const iconFilter = (
            <FilterOutlined
                title={!this.state.isFilterVisible ? formatMessage('common.filter') : formatMessage('common.close')}
                onClick={this.onSearchClick}
                className={classNames('section-header__icon', {
                    'section-header__icon--disabled': !this.props.isFilterEnabled,
                })}
            />
        );

        if (!this.props.onFilter) {
            return null;
        }

        return (
            <div className={classNames(
                'filter-control',
                {'filter-control--open': this.state.isFilterVisible},
            )}
            >
                {!this.state.isFilterVisible && iconFilter}
                {this.state.isFilterVisible && (
                    <Input onChange={this.handleOnChange}
                        // onKeyPress={this.handleKeyPress}
                           ref={this.ref}
                           suffix={iconFilter}
                           size="small"
                           placeholder={`${formatMessage('common.filter')} ...`}
                           allowClear={true}
                           value={this.props.value || ""}
                    />
                )}
            </div>
        );
    }
}
