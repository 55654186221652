import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {reportHash, ReportRecord} from "fm-shared-data/src/types/db/account/report/ReportRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqEquipmentValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqReportValidator extends SyncValidator<ReportRecord, UniqEquipmentValidatorConfig> {

    constructor(config?: UniqEquipmentValidatorConfig) {
        super(config || {});
    }

    validate(value: ReportRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = reportHash(value);
            const isRecordExists: boolean = stores.dataStore.report
                .some((rec: ReportRecord) => value.id !== rec.id && valueHash === reportHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.work.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
