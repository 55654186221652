import React from 'react';
import {Spin} from 'antd';
import {EMPLOYEE_PHONE_FORM_CONFIG} from './phone/EmployeePhoneFormConfig';
import {inject, observer} from 'mobx-react';
import {EmployeeViewStore} from '../../../../mobx/EmployeeViewStore';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import './style/employeePhoneTab.scss';
import Icofont from 'react-icofont';
import UpdateEmployeePhoneCommand from './phone/commands/UpdateEmployeePhoneCommand';
import AddEmployeePhoneCommand from './phone/commands/AddEmployeePhoneCommand';
import DeleteEmployeePhoneCommand from './phone/commands/DeleteEmployeePhoneCommand';
import RecordList from '../../../../../../../common/widgets/recordList/RecordList';
import {EMPLOYEE_EMAIL_FORM_CONFIG} from './email/form/EmployeeEmailFormConfig';
import EmployeePhoneItemRenderer from './phone/EmployeePhoneItemRenderer';
import EmployeeEmailItemRenderer from './email/form/EmployeeEmailItemRenderer';
import UpdateEmployeeEmailCommand from './email/commands/UpdateEmployeeEmailCommand';
import AddEmployeeEmailCommand from './email/commands/AddEmployeeEmailCommand';
import DeleteEmployeeEmailCommand from './email/commands/DeleteEmployeeEmailCommand';
import {formatMessage} from '../../../../../../../common/locale/IntlProvider';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {EMPLOYEE_ADDRESS_FORM_CONFIG} from "./address/EmployeeAddressFormConfig";
import EmployeeAddressItemRenderer from "./address/EmployeeAddressItemRenderer";
import UpdateEmployeeAddressCommand from "./address/commands/UpdateEmployeeAddressCommand";
import AddEmployeeAddressCommand from "./address/commands/AddEmployeeAddressCommand";
import DeleteEmployeeAddressCommand from "./address/commands/DeleteEmployeeAddressCommand";
import {CustomRendererProps} from "../../../../../../../common/widgets/tab/CustomRenderer";

type StoreProps = {
    dataStore: DataStore;
    employeeViewStore: EmployeeViewStore;
};

type Props = CustomRendererProps<EmployeeRecord> & StoreProps;

interface State {
    isDataLoaded: boolean;
}

@inject('employeeViewStore', 'dataStore')
@observer
export default class EmployeeContactsTab extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    state = {
        isDataLoaded: false
    };

    componentDidMount() {
        const {dataStore} = this.props;
        if (!dataStore.isEmployeePhoneLoaded) {
            dataStore.loadEmployeePhone();
        }
        if (!dataStore.isEmployeeEmailLoaded) {
            dataStore.loadEmployeeEmail();
        }
        if (!dataStore.isEmployeeAddressLoaded) {
            dataStore.loadEmployeeAddress();
        }
    }

    savePhoneRecordCommand = async (upsertValue: UpsertValue<EmployeePhoneRecord>): Promise<UpsertResponse<EmployeePhoneRecord>> => {
        const {dataStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<EmployeePhoneRecord> = isUpdate
            ? await new UpdateEmployeePhoneCommand(upsertValue).execute()
            : await new AddEmployeePhoneCommand(upsertValue).execute();
        dataStore.setEmployeePhone(upsertResponse.list);
        return Promise.resolve(upsertResponse);
    };

    deletePhoneRecordCommand = async (record: EmployeePhoneRecord): Promise<UpsertResponse<EmployeePhoneRecord>> => {
        const {dataStore} = this.props;
        const employeePhoneRecords: EmployeePhoneRecord[] = await new DeleteEmployeePhoneCommand().execute(record);
        const upsertResponse: UpsertResponse<EmployeePhoneRecord> = {
            value: record,
            list: employeePhoneRecords
        };
        dataStore.setEmployeePhone(employeePhoneRecords);
        return Promise.resolve(upsertResponse);
    };

    saveEmailRecordCommand = async (upsertValue: UpsertValue<EmployeeEmailRecord>): Promise<UpsertResponse<EmployeeEmailRecord>> => {
        const {dataStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<EmployeeEmailRecord> = isUpdate
            ? await new UpdateEmployeeEmailCommand(upsertValue).execute()
            : await new AddEmployeeEmailCommand(upsertValue).execute();
        dataStore.setEmployeeEmail(upsertResponse.list);
        return Promise.resolve(upsertResponse);
    };

    deleteEmailRecordCommand = async (record: EmployeeEmailRecord): Promise<UpsertResponse<EmployeeEmailRecord>> => {
        const {dataStore} = this.props;
        const employeeEmailRecords: EmployeeEmailRecord[] = await new DeleteEmployeeEmailCommand().execute(record);
        const upsertResponse: UpsertResponse<EmployeeEmailRecord> = {
            value: record,
            list: employeeEmailRecords
        };
        dataStore.setEmployeeEmail(employeeEmailRecords);
        return Promise.resolve(upsertResponse);
    };

    refreshEmailRecordCommand = async (): Promise<EmployeeEmailRecord[]> => {
        const {dataStore, employeeViewStore} = this.props;
        await dataStore.loadEmployeeEmail();
        return Promise.resolve(employeeViewStore.selectedEmployeeEmail);
    };

    saveAddressRecordCommand = async (upsertValue: UpsertValue<EmployeeAddressRecord>): Promise<UpsertResponse<EmployeeAddressRecord>> => {
        const {dataStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<EmployeeAddressRecord> = isUpdate
            ? await new UpdateEmployeeAddressCommand(upsertValue).execute()
            : await new AddEmployeeAddressCommand(upsertValue).execute();
        dataStore.setEmployeeAddress(upsertResponse.list);
        return Promise.resolve(upsertResponse);
    };

    deleteAddressRecordCommand = async (record: EmployeeAddressRecord): Promise<UpsertResponse<EmployeeAddressRecord>> => {
        const {dataStore} = this.props;
        const employeeAddressRecords: EmployeeAddressRecord[] = await new DeleteEmployeeAddressCommand().execute(record);
        const upsertResponse: UpsertResponse<EmployeeAddressRecord> = {
            value: record,
            list: employeeAddressRecords
        };
        dataStore.setEmployeeAddress(employeeAddressRecords);
        return Promise.resolve(upsertResponse);
    };

    render() {
        // TODO ref to show spin inside RecordList
        if (!this.props.dataStore.isEmployeePhoneLoaded
            || !this.props.dataStore.isEmployeeEmailLoaded
            || !this.props.dataStore.isEmployeeAddressLoaded) {
            return <Spin/>;
        }
        const {employeeViewStore} = this.props;

        return (
            <div className="fullDivColumn">
                <div className="topRow">
                    <div className="fullDivRow">
                        <div className="leftDiv">
                            <RecordList<EmployeePhoneRecord, EmployeeRecord> modalFormConfig={EMPLOYEE_PHONE_FORM_CONFIG}
                                                                             title={<><Icofont
                                                                                 icon={'icofont-ui-dial-phone'}/> {formatMessage('employee.details.contacts.phone.title')}</>}
                                                                             dataSource={employeeViewStore.selectedEmployeePhone}
                                                                             parentRecord={employeeViewStore.selectedEntity}
                                                                             itemRenderer={new EmployeePhoneItemRenderer()}
                                                                             saveCommand={this.savePhoneRecordCommand}
                                                                             deleteCommand={this.deletePhoneRecordCommand}/>
                        </div>
                        <div className="rightDiv">
                            <RecordList<EmployeeEmailRecord, EmployeeRecord> modalFormConfig={EMPLOYEE_EMAIL_FORM_CONFIG}
                                                                             title={<><Icofont
                                                                                 icon={'icofont-ui-email'}/> {formatMessage('employee.details.contacts.email.title')}</>}
                                                                             dataSource={employeeViewStore.selectedEmployeeEmail}
                                                                             parentRecord={employeeViewStore.selectedEntity}
                                                                             itemRenderer={new EmployeeEmailItemRenderer()}
                                                                             saveCommand={this.saveEmailRecordCommand}
                                                                             deleteCommand={this.deleteEmailRecordCommand}
                                                                             refreshCommand={this.refreshEmailRecordCommand}/>
                        </div>
                    </div>
                </div>
                <div className="bottomRow">
                    <RecordList<EmployeeAddressRecord, EmployeeRecord> modalFormConfig={EMPLOYEE_ADDRESS_FORM_CONFIG}
                                                                       title={<><Icofont
                                                                           icon={'icofont-mail-box'}/> {formatMessage('employee.details.contacts.address.title')}</>}
                                                                       dataSource={employeeViewStore.selectedEmployeeAddress}
                                                                       parentRecord={employeeViewStore.selectedEntity}
                                                                       itemRenderer={new EmployeeAddressItemRenderer()}
                                                                       saveCommand={this.saveAddressRecordCommand}
                                                                       deleteCommand={this.deleteAddressRecordCommand}/>
                </div>
            </div>
        );
    }
}
