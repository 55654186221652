import React from 'react';
import {Card, Checkbox, List} from 'antd';
import {intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {SCANNER_FORM_CONFIG} from './ScannerFormConfig';
import {inject, observer} from 'mobx-react';
import {ScannerViewStore} from '../../mobx/ScannerViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddScannerCommand from './commands/AddScannerCommand';
import UpdateScannerCommand from './commands/UpdateScannerCommand';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import AvatarWidget from "../../../../../common/widgets/avatarWidget/AvatarWidget";
import {getAvatar} from "../../../employee/EmployeeUtils";

type StoreProps = {
    scannerViewStore: ScannerViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: ScannerRecord;
}

@inject('scannerViewStore')
@observer
export default class ScannerListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('ScannerListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as ScannerRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {scannerViewStore} = this.props;
        scannerViewStore.universalFilter = value;
        scannerViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedScannerTreeRecord} = this.props.scannerViewStore;
        const newScannerRecord: ScannerRecord = SCANNER_FORM_CONFIG.getNewRecord();
        newScannerRecord.tree_ids = [selectedScannerTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newScannerRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {scannerViewStore} = this.props;
        const {selectedEntity} = scannerViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    saveScannerRecordCommand = async (upsertValue: UpsertValue<ScannerRecord>): Promise<UpsertResponse<ScannerRecord>> => {
        const {scannerViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<ScannerRecord> = isUpdate
            ? await new UpdateScannerCommand(upsertValue).execute()
            : await new AddScannerCommand(upsertValue).execute();
        scannerViewStore.setScanner(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveScannerRecord = async (upsertValue: UpsertValue<ScannerRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('ScannerListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('ScannerListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedLogTypes: ScannerRecord[]) => {
        const {scannerViewStore} = this.props;
        scannerViewStore.setSelectedEntities(selectedLogTypes);
    };

    onSelectAll = () => {
        const {scannerViewStore} = this.props;
        if (scannerViewStore.selectedEntities.length === 0) {
            scannerViewStore.selectedEntities = scannerViewStore.visibleEntities;
        } else {
            scannerViewStore.selectedEntities = [];
        }
    };

    render() {
        const {scannerViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleScannerCount, selectedEntities} = scannerViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const isEmptyList: boolean = visibleScannerCount === 0;

        return (
            <div className="scanner-container">
                <Card size="small"
                      className="fm-card"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : scannerViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'scanner.list.title'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={scannerViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}/>
                      }>
                    <div className="list-container">
                        <CheckedList selectedRecords={scannerViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: ScannerRecord) => (
                                         <List.Item.Meta avatar={<AvatarWidget src={getAvatar(item)} size={50} hidePreview={true}/>}
                                                         title={item.name}
                                                         description={item.device_key}
                                         />)}/>
                        {this.state.modalFormVisible && <ModalFormik config={SCANNER_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveScannerRecordCommand}
                                                                     onSave={this.handleOnSaveScannerRecord}
                                                                     onCancel={this.handleOnModalFormClosed}/>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
