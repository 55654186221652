import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";

export default class AddTreeCommand<T extends TreeRecord> extends UpdateDataCommand<T> {

    constructor(private readonly table: string, private readonly treeSelectedNodeId: number) {
        super();
    }

    execute(upsertValue: UpsertValue<T>): Promise<UpsertResponse<T>> {
        return axiosGetInstance().post<UpsertResponse<T>>(`/tree/add/${this.treeSelectedNodeId}/${this.table}`, upsertValue)
            .then((response: AxiosResponse<UpsertResponse<T>>) => response.data);
    }
}
