import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import {restoreScannerPredefinedFilter} from "../components/filters/PredefinedScannerFilters";
import LoadTagsCommand from "../../commands/LoadTagsCommand";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import moment from 'moment';
import {DateRange, RangeViewType} from '../../../../common/utils/DateRangeUtils';
import {SyncRecord} from 'fm-shared-data/src/types/db/account/sync/SyncRecord';
import {computedFn} from 'mobx-utils';
import LoadSyncRecordsCommand from '../commands/LoadSyncDataCommand';
import {getSyncSearchParams, SyncSearchParams} from 'fm-shared-data/src/types/db/account/scanner/SyncSearchParams';
import {FieldWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/FieldWorklogRequest';
import LoadFieldWorklogRecordsCommand from '../commands/LoadFieldWorklogRecordsCommand';
import {EquipmentWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/EquipmentWorklogRequest';
import LoadEquipmentWorklogRecordsCommand from '../commands/LoadEqupmentWorklogRecordsCommand';
import {HarvestWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/HarvestWorklogRequest';
import LoadHarvestWorklogInfoRecordsCommand from '../commands/LoadHarvestWorklogRecordsCommand';
import {IncidentWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/IncidentWorklogRequest';
import LoadIncidentWorklogInfoRecordsCommand from '../commands/LoadIncidentWorklogRecordsCommand';
import LoadFineBonusWorklogInfoRecordsCommand from '../commands/LoadFineBonusWorklogRecordsCommand';
import {FineBonusWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/FineBonusWorklogRequest';
import LoadEventWorklogInfoRecordsCommand from '../commands/LoadEventWorklogRecordsCommand';
import {EventWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/EventWorklogRequest';

export enum SyncDetailTab {
    FIELD = "FIELD",
    HARVEST = "HARVEST",
    EQUIPMENT = "EQUIPMENT",
    INCIDENT = "INCIDENT",
    FINE_BONUS = "FINE_BONUS",
    EVENT = "EVENT",
}

export class ScannerViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<ScannerRecord>[] = [];

    visibleEntities: ScannerRecord[] = [];
    selectedEntities: ScannerRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    scannerTags: string[] = [];
    selectedScannerTags: string[] = [];
    isScannerTagsLoaded: Date | undefined;
    isScannerTagsLoading: boolean = true;

    // sync records by range

    view: RangeViewType = RangeViewType.GRID;
    device_key: string = "";
    dateRange: DateRange = {dateStart: moment().startOf('day'), dateEnd: moment().endOf('day')};

    syncRecordsMap: Map<string, SyncRecord[]> = new Map<string, SyncRecord[]>();

    syncId: number = 0;
    activeSyncDetailTab: SyncDetailTab = SyncDetailTab.FIELD;

    fieldRecordsMap: Map<string, FieldWorklogInfoRecord[]> = new Map<string, FieldWorklogInfoRecord[]>();
    equipmentRecordsMap: Map<string, EquipmentWorklogInfoRecord[]> = new Map<string, EquipmentWorklogInfoRecord[]>();
    harvestRecordsMap: Map<string, HarvestWorklogInfoRecord[]> = new Map<string, HarvestWorklogInfoRecord[]>();
    incidentRecordsMap: Map<string, IncidentWorklogInfoRecord[]> = new Map<string, IncidentWorklogInfoRecord[]>();
    fineBonusRecordsMap: Map<string, FineBonusWorklogInfoRecord[]> = new Map<string, FineBonusWorklogInfoRecord[]>();
    eventRecordsMap: Map<string, EventWorklogInfoRecord[]> = new Map<string, EventWorklogInfoRecord[]>();

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            scannerTags: observable,
            selectedScannerTags: observable,
            isScannerTagsLoaded: observable,
            isScannerTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateScannerTreeRecord: action,
            setSelectedScannerTags: action,
            refreshScannerTags: action,

            selectedEntityIds: computed,
            visibleScannerCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            scannerTreeData: computed,
            selectedScannerTreeRecord: computed,

            setScanner: action,

            // sync records by range

            device_key: observable,
            view: observable,
            dateRange: observable,
            syncRecordsMap: observable,

            setDeviceKey: action,
            setView: action,
            setDateRange: action,

            loadSyncRecords: action,
            setSyncRecords: action,

            syncId: observable,
            setSyncId: action,

            activeSyncDetailTab: observable,
            setActiveSyncDetailTab: action,

            fieldRecordsMap: observable,
            equipmentRecordsMap: observable,
            harvestRecordsMap: observable,
            incidentRecordsMap: observable,
            fineBonusRecordsMap: observable,
            eventRecordsMap: observable,

        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleScannerCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isScannerLoaded && !!this.dataStore.isScannerTreeLoaded && !!this.isScannerTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleScannerCount > 0 && this.visibleScannerCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.scannerTreeRootId;
    }

    get scannerTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.scannerTreeData;
    }

    get selectedScannerTreeRecord(): TreeRecord {
        return this.dataStore.scannerTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isScannerLoaded) {
            loaders.push(this.dataStore.loadScanner());
        }
        if (!this.dataStore.isScannerTreeLoaded) {
            loaders.push(this.dataStore.loadScannerTree());
        }
        if (!this.isScannerTagsLoaded) {
            loaders.push(this.loadScannerTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadScannerTags() {
        this.isScannerTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('scanner').execute();
        runInAction(() => {
            this.scannerTags = tags;
            this.isScannerTagsLoading = false;
            this.isScannerTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.scannerTreeData, this.dataStore.scannerTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.SCANNER_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.SCANNER_PREDEFINED_FILTERS);

        //tags:
        this.selectedScannerTags = [];
        LocalStorage.removeItem(LocalStorageKey.SCANNER_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (scanner: ScannerRecord) => (scanner.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: ScannerRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedScannerTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (scanner: ScannerRecord) => this.selectedScannerTags.filter(Boolean).every((eTag: string) => scanner.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.scannerTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<ScannerRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedScannerTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.scanner
            : this.dataStore.scanner.filter((ScannerRecord: ScannerRecord) => {
                return filters.every((fun: any) => fun(ScannerRecord));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: ScannerRecord | undefined = this.visibleEntities.find((scannerRecord: ScannerRecord) => scannerRecord.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateScannerTreeRecord(scannerTreeRecords: TreeRecord[]) {
        this.dataStore.setScannerTree(scannerTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.scannerTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.scannerTreeData, this.dataStore.scannerTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedScannerTags(tags: string[]) {
        this.selectedScannerTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshScannerTags(trySelectId?: number) {
        await this.loadScannerTags();
        runInAction(() => {
            this.selectedScannerTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<ScannerRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setScanner(data: ScannerRecord[], trySelectId?: number) {
        this.dataStore.setScanner(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedScanners: ScannerRecord[]) {
        this.selectedEntities = selectedScanners;
    }

    get selectedEntity(): ScannerRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.scannerTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.SCANNER_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.scannerTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.scannerTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.SCANNER_TREE_NODES) || this.dataStore.scannerTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreScannerPredefinedFilter();

        // tags:
        this.selectedScannerTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.SCANNER_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.scannerTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.SCANNER_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }
        // full text search
        this.visibleEntities = this.visibleEntities.filter((scanner: ScannerRecord) => {
            const searchString: string = scanner.name.toLowerCase();
            return searchString.includes(this.universalFilter.toLowerCase());
        });
        // this.selectedEntities = []; // clear any selections
    }

    getKey(useSyncId: boolean = false): string {
        const {dateStart, dateEnd} = this.dateRange;
        return `${this.device_key}.${useSyncId ? this.syncId : ""}.${dateStart ? dateStart.toString() : ""}.${dateEnd ? dateEnd.toString() : ""}`;
    }

    setView(newView: RangeViewType) {
        this.view = newView;
    }

    setDeviceKey(device_key: string) {
        this.device_key = device_key;
        this.loadSyncRecords();
    }

    setDateRange(dateRange: DateRange) {
        this.dateRange = dateRange;
        this.loadSyncRecords();
    }

    async loadSyncRecords(forceLoad: boolean = false) {
        this.setSyncId(0);
        if (forceLoad || !this.syncRecordsMap.has(this.getKey())) {
            // await delay(2000);
            const syncSearchParams: SyncSearchParams = getSyncSearchParams(this.device_key, this.dateRange.dateStart, this.dateRange.dateEnd);
            const syncRecords: SyncRecord[] = await new LoadSyncRecordsCommand().execute(syncSearchParams);
            runInAction(() => {
                this.setSyncRecords(syncRecords);
            });
        }
    }

    setSyncRecords(syncRecords: SyncRecord[]) {
        this.syncRecordsMap.set(this.getKey(), syncRecords);
    }

    getSyncRecords = computedFn((): SyncRecord[] | undefined => {
        return this.syncRecordsMap.get(this.getKey());
    });

    // details

    setSyncId(syncId: number) {
        this.syncId = syncId;
        // if (this.syncId) {
        this.setActiveSyncDetailTab(this.activeSyncDetailTab);
        // }
    }

    setActiveSyncDetailTab(activeSyncDetailTab: SyncDetailTab, forceLoad: boolean = false) {
        this.activeSyncDetailTab = activeSyncDetailTab;
        switch (this.activeSyncDetailTab) {
            case SyncDetailTab.FIELD:
                this.loadFieldRecords(forceLoad);
                break;
            case SyncDetailTab.EQUIPMENT:
                this.loadEquipmentRecords(forceLoad);
                break;
            case SyncDetailTab.HARVEST:
                this.loadHarvestRecords(forceLoad);
                break;
            case SyncDetailTab.INCIDENT:
                this.loadIncidentRecords(forceLoad);
                break;
            case SyncDetailTab.FINE_BONUS:
                this.loadFineBonusRecords(forceLoad);
                break;
            case SyncDetailTab.EVENT:
                this.loadEventRecords(forceLoad);
                break;
        }
    }

    // FieldWorklogRecord

    async loadFieldRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.fieldRecordsMap.has(this.getKey(true))) {
            const fieldRecords: FieldWorklogInfoRecord[] = await new LoadFieldWorklogRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setFieldRecords(fieldRecords);
            });
        }
    }

    setFieldRecords(fieldsRecords: FieldWorklogInfoRecord[]) {
        this.fieldRecordsMap.set(this.getKey(true), fieldsRecords);
    }

    getFieldRecords = computedFn((): FieldWorklogInfoRecord[] | undefined => {
        return this.fieldRecordsMap.get(this.getKey(true));
    });

    // EquipmentWorklogRecord

    async loadEquipmentRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.equipmentRecordsMap.has(this.getKey(true))) {
            const equipmentRecords: EquipmentWorklogInfoRecord[] = await new LoadEquipmentWorklogRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setEquipmentRecords(equipmentRecords);
            });
        }
    }

    setEquipmentRecords(equipmentsRecords: EquipmentWorklogInfoRecord[]) {
        this.equipmentRecordsMap.set(this.getKey(true), equipmentsRecords);
    }

    getEquipmentRecords = computedFn((): EquipmentWorklogInfoRecord[] | undefined => {
        return this.equipmentRecordsMap.get(this.getKey(true));
    });

    // HarvestWorklogInfoRecord

    async loadHarvestRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.harvestRecordsMap.has(this.getKey(true))) {
            const harvestRecords: HarvestWorklogInfoRecord[] = await new LoadHarvestWorklogInfoRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setHarvestRecords(harvestRecords);
            });
        }
    }

    setHarvestRecords(harvestRecords: HarvestWorklogInfoRecord[]) {
        this.harvestRecordsMap.set(this.getKey(true), harvestRecords);
    }

    getHarvestRecords = computedFn((): HarvestWorklogInfoRecord[] | undefined => {
        return this.harvestRecordsMap.get(this.getKey(true));
    });

    // IncidentWorklogInfoRecord

    async loadIncidentRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.incidentRecordsMap.has(this.getKey(true))) {
            const incidentRecords: IncidentWorklogInfoRecord[] = await new LoadIncidentWorklogInfoRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setIncidentRecords(incidentRecords);
            });
        }
    }

    setIncidentRecords(incidentRecords: IncidentWorklogInfoRecord[]) {
        this.incidentRecordsMap.set(this.getKey(true), incidentRecords);
    }

    getIncidentRecords = computedFn((): IncidentWorklogInfoRecord[] | undefined => {
        return this.incidentRecordsMap.get(this.getKey(true));
    });

    // FineBonusWorklogInfoRecord

    async loadFineBonusRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.fineBonusRecordsMap.has(this.getKey(true))) {
            const fineBonusRecords: FineBonusWorklogInfoRecord[] = await new LoadFineBonusWorklogInfoRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setFineBonusRecords(fineBonusRecords);
            });
        }
    }

    setFineBonusRecords(fineBonusRecords: FineBonusWorklogInfoRecord[]) {
        this.fineBonusRecordsMap.set(this.getKey(true), fineBonusRecords);
    }

    getFineBonusRecords = computedFn((): FineBonusWorklogInfoRecord[] | undefined => {
        return this.fineBonusRecordsMap.get(this.getKey(true));
    });

    // EventWorklogInfoRecord

    async loadEventRecords(forceLoad: boolean = false) {
        if (forceLoad || !this.eventRecordsMap.has(this.getKey(true))) {
            const eventRecords: EventWorklogInfoRecord[] = await new LoadEventWorklogInfoRecordsCommand().execute(this.syncId);
            runInAction(() => {
                this.setEventRecords(eventRecords);
            });
        }
    }

    setEventRecords(eventRecords: EventWorklogInfoRecord[]) {
        this.eventRecordsMap.set(this.getKey(true), eventRecords);
    }

    getEventRecords = computedFn((): EventWorklogInfoRecord[] | undefined => {
        return this.eventRecordsMap.get(this.getKey(true));
    });

}
