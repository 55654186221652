import {LogTypeField, LogTypeFieldType} from "./LogTypeField";
import {LOGTYPE_MAX_FIELDS, LogTypeRecord} from "./LogTypeRecord";

export function readLogTypeField(record: LogTypeRecord, field_id: number): LogTypeField {
    return {
        field_id: `${field_id}`,
        field_index: record[`field_index${field_id}`] ?? LOGTYPE_MAX_FIELDS - 1,
        field_enabled: record[`field_enabled${field_id}`] ?? false,
        field_title: record[`field_title${field_id}`],
        field_type: (record[`field_type${field_id}`] || LogTypeFieldType.NUMBER) as LogTypeFieldType,
        field_unit: record[`field_unit${field_id}`],
        field_required: record[`field_required${field_id}`] ?? true,
        field_prev: record[`field_prev${field_id}`] ?? false,
        field_tab: record[`field_tab${field_id}`] ?? false,
        field_dict_id: record[`field_dict_id${field_id}`] || undefined,
        field_description: record[`field_description${field_id}`]
    };
}

export function readLogTypeFields(record: LogTypeRecord): LogTypeField[] {
    const logTypeFields: LogTypeField[] = [];
    for (let field_id: number = 1; field_id <= LOGTYPE_MAX_FIELDS; field_id++) {
        logTypeFields.push(readLogTypeField(record, field_id));
    }
    return logTypeFields.sort((a: LogTypeField, b: LogTypeField) => {
        const {field_index: f1 = 0} = a;
        const {field_index: f2 = 0} = b;
        return f1 > f2
            ? 1
            : (f1 < f2 ? -1 : 0)
    });
}

export function validateLogTypeField(logTypeField: LogTypeField): string | null {
    if (!logTypeField.field_enabled) {
        return null;
    }
    if (!logTypeField.field_title?.trim()) {
        return "Title can't be empty";
    }
    if (!logTypeField.field_type) {
        return `Please select type of ${logTypeField.field_title} filed`;
    }

    return null;
}

export function validateLogTypeFields(logTypeFields: LogTypeField[]): string | null {
    if (logTypeFields.some((logTypeField: LogTypeField) => validateLogTypeField(logTypeField) !== null)) {
        return 'Please validate log items';
    } else if (!logTypeFields.some((logTypeField: LogTypeField) => logTypeField.field_enabled)) {
        return 'Please add at list one field';
    }
    return null;
}


export function getRealFieldName(logTypeField: LogTypeField): string {
    switch (logTypeField.field_type) {
        case LogTypeFieldType.NUMBER:
        case LogTypeFieldType.PERCENT:
        case LogTypeFieldType.RATE:
        case LogTypeFieldType.FM_ENTITY:
        case LogTypeFieldType.FM_DICTIONARY:
            return `field${logTypeField.field_id}_number`;
        case LogTypeFieldType.BOOLEAN:
            return `field${logTypeField.field_id}_boolean`;
        case LogTypeFieldType.DATE:
        case LogTypeFieldType.DATETIME:
        case LogTypeFieldType.TIME:
            return `field${logTypeField.field_id}_date`;
        default:
            return `field${logTypeField.field_id}_string`;
    }
}