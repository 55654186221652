import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";

export default class LoadEmployeePhoneCommand extends Command<EmployeePhoneRecord[]> {
    execute(): Promise<EmployeePhoneRecord[]> {
        return axiosGetInstance().post<EmployeePhoneRecord[]>('/employee/phone')
            .then((response: AxiosResponse<EmployeePhoneRecord[]>) => response.data);
    }
}
