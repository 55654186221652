import React from 'react';
import {Col, message, Modal, Row, Slider, Spin, Tooltip} from "antd";
import {ReloadOutlined, ZoomInOutlined} from "@ant-design/icons";
import Cropper from "react-easy-crop";
import {Area, Point} from "react-easy-crop/types";
import './style/style.scss';
import {formatMessage} from "../../../../../../../../../common/locale/IntlProvider";
import {getCroppedImg} from "./ImageUtils";

interface Props {
    titleKey?: string;
    imageBase64: string;
    onCrop: (cropBase64: string) => void;
    onCancel: () => void;
}

interface State {
    isProgress: boolean;
    file?: File;
    base64?: string;
    croppedImage?: string;

    zoom: number;
    rotation: number;
    crop: Point;

    croppedArea?: Area;
    croppedAreaPixels?: Area
}

export default class CropImageWidget extends React.Component<Props, State> {

    private readonly maxZoom: number = 5;

    constructor(props: Props) {
        super(props);
        this.state = {
            isProgress: false,
            zoom: 1,
            rotation: 0,
            crop: {x: 0, y: 0}
        };
    }

    handleOk = async () => {
        if (this.state.isProgress) {
            return;
        }
        try {
            this.setState({isProgress: true});
            const croppedImage: string = await getCroppedImg(this.props.imageBase64, this.state.croppedAreaPixels!, this.state.rotation);
            this.setState({isProgress: false});
            this.props.onCrop(croppedImage);
        } catch (e: any) {
            this.setState({isProgress: false});
            message.error({content: formatMessage('cropImage.error.large'), duration: 3});
            console.error(e);
        }
    }

    handleCancel = () => {
        if (this.state.isProgress) {
            return;
        }
        this.props.onCancel();
    }

    handleOnCropChange = (crop: Point) => {
        this.setState({crop});
    };

    handleOnRotationChange = (rotation: number) => {
        this.setState({rotation});
    };

    handleOnCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        this.setState({croppedArea, croppedAreaPixels})
        console.log(croppedArea, croppedAreaPixels);
    };

    handleOnZoomChange = (zoom: number) => {
        this.setState({zoom});
    };

    getCroppedImage = () => () => {
        console.log(this.state.zoom);
    };

    render() {
        return (
            <Modal title={formatMessage(this.props.titleKey || 'cropImage.default.title')}
                   centered={true}
                   visible={true}
                   onOk={this.handleOk}
                   okButtonProps={{loading: this.state.isProgress}}
                   okText={formatMessage('common.save')}
                   onCancel={this.handleCancel}
                   cancelText={formatMessage('common.cancel')}
                   closable={!this.state.isProgress}
                   maskClosable={false}
                   destroyOnClose={true}>
                <Spin spinning={this.state.isProgress}>
                    <Row gutter={[20, 20]} align="middle">
                        <Col span={24}>
                            <div className={'crop-image-container'}>
                                <Cropper cropShape={"round"}
                                         restrictPosition={false}
                                         maxZoom={this.maxZoom}
                                         image={this.props.imageBase64}
                                         crop={this.state.crop}
                                         rotation={this.state.rotation}
                                         zoom={this.state.zoom}
                                         aspect={1}
                                         onCropChange={this.handleOnCropChange}
                                         onRotationChange={this.handleOnRotationChange}
                                         onCropComplete={this.handleOnCropComplete}
                                         onZoomChange={this.handleOnZoomChange}
                                />
                            </div>
                        </Col>
                        <Col span={1}>
                            <Tooltip title={formatMessage('cropImage.zoom.title')}><ZoomInOutlined/></Tooltip>
                        </Col>
                        <Col span={11}>
                            <Slider value={this.state.zoom}
                                    min={.1}
                                    max={this.maxZoom}
                                    step={0.1}
                                    aria-labelledby={formatMessage('cropImage.zoom.title')}
                                    onChange={(zoom: number) => this.setState({zoom})}
                            />
                        </Col>
                        <Col span={1}>
                            <Tooltip title={formatMessage('cropImage.rotation.title')}><ReloadOutlined/></Tooltip>
                        </Col>
                        <Col span={11}>
                            <Slider value={this.state.rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby={formatMessage('cropImage.rotation.title')}
                                    onChange={(rotation: number) => this.setState({rotation})}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        );
    }
}
