import {action, makeObservable, observable, runInAction} from 'mobx';
import {computedFn} from "mobx-utils";
import Command from "../../../common/types/Command";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";

export class DictionaryStore {
    dictionaryMap: Map<string, DictionaryRawRecord[]> = new Map<string, DictionaryRawRecord[]>();

    constructor() {
        makeObservable(this, {
            dictionaryMap: observable,

            loadDictionary: action,
            setDictionary: action,
        });
    }

    getDictionary = computedFn((dictionaryKey: string): DictionaryRawRecord[] | undefined => {
        return this.dictionaryMap.get(dictionaryKey);
    });

    async loadDictionary(dictionaryKey: string, cmd: Command<DictionaryRawRecord[], string>) {
        this.dictionaryMap.delete(dictionaryKey);
        const values: DictionaryRawRecord[] = await cmd.execute(dictionaryKey);
        runInAction(() => {
            this.setDictionary(dictionaryKey, values);
        });
    }

    async setDictionary(dictionaryKey: string, values: DictionaryRawRecord[]) {
        this.dictionaryMap.set(dictionaryKey, values);
    }
}
