import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";
import {employeeHash, EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {stores} from "../../../../../app/mobx/Stores";

export interface UniqEmployeeValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqEmployeeValidator extends SyncValidator<EmployeeRecord, UniqEmployeeValidatorConfig> {

    constructor(config?: UniqEmployeeValidatorConfig) {
        super(config || {});
    }

    validate(value: EmployeeRecord): FieldValidatorResult {
        if (value.last_name?.trim() && value.first_name?.trim()) {
            const valueHash: string = employeeHash(value);
            const name: string = [value.first_name, value.last_name].join(' ');
            const isRecordExists: boolean = stores.dataStore.employee
                .some((rec: EmployeeRecord) => value.id !== rec.id && valueHash === employeeHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.employee.uniq', {name}) : null;
        }
        return null;
    }
}