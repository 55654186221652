import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {axiosLogout} from '../../../common/utils/AxiosUtils';
import {AppStore} from '../../../app/mobx/AppStore';
import {fetchCurrentAccount, fetchCurrentEmployee, fetchCurrentUser} from './LoadDataActionCreator';
import {UserRecord} from "fm-shared-data/src/types/db/core/UserRecord";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import {Role} from "fm-shared-data/src/types/db/core/Role";

export class CoreStore {
    isCoreDataLoaded: Date | undefined = undefined;

    // core data used after it loaded
    user: UserRecord = {} as UserRecord;
    employeeRecord: EmployeeRecord = {} as EmployeeRecord;
    account = {} as AccountRecord;

    constructor(private readonly appStore: AppStore) {
        makeObservable(this, {
            isCoreDataLoaded: observable,

            user: observable,
            employeeRecord: observable,
            account: observable,

            loadAccount: action,
            setAccount: action,

            loadCoreData: action,
            logout: action,

            isLocaleExists: computed,
            isDataLoaded: computed,
            locale: computed,
            isAdminUser: computed
        });
    }

    async loadCoreData() {
        console.info(`async loadCoreData()!`);
        const userRecord: UserRecord = await fetchCurrentUser();
        const employeeRecord: EmployeeRecord = await fetchCurrentEmployee();
        await this.loadAccount();
        runInAction(() => {
            this.user = userRecord;
            this.employeeRecord = employeeRecord;
            this.isCoreDataLoaded = new Date();
        });
    }

    logout() {
        axiosLogout();
        this.isCoreDataLoaded = undefined;
    }

    get isLocaleExists(): boolean {
        return this.appStore.isLocaleExists;
    }

    get isDataLoaded(): boolean {
        return this.isLocaleExists && !!this.isCoreDataLoaded;
    }

    get locale(): string {
        return this.appStore.locale;
    }

    get isAdminUser(): boolean {
        const {roles = []} = this.user;
        return roles.includes(Role.ADMIN);
    }

    async loadAccount() {
        const account: AccountRecord = await fetchCurrentAccount();
        runInAction(() => {
            this.setAccount(account);
        });
    }

    setAccount(account: AccountRecord) {
        this.account = account;
    }

}
