import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {stores} from "../../../../app/mobx/Stores";
import DataProvider from "../../modalForm/type/DataProvider";

export class DictionaryStoreDataProvider implements DataProvider<DictionaryRawRecord[]> {

    constructor(private readonly dictionaryKey: string) {
    }

    get(): DictionaryRawRecord[] {
        return stores.dictionaryStore.getDictionary(this.dictionaryKey) || [];
    }
}