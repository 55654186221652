import {AxiosResponse} from "axios";
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import {axiosGetInstance} from "../../../../../common/utils/AxiosUtils";
import Command from "../../../../../common/types/Command";

export default class UpdateAccountCommand extends Command<AccountRecord, AccountRecord> {
    async execute(updateDataRecord: AccountRecord): Promise<AccountRecord> {
        return axiosGetInstance().post<AccountRecord>(`/account/update`, updateDataRecord)
            .then((response: AxiosResponse<AccountRecord>) => response.data);
    }

}
