import React from 'react';
import {Badge, Button, Col, Descriptions, Row, Space, Spin} from 'antd';
import {inject, observer} from 'mobx-react';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {getAvatar} from "../../../../account/employee/EmployeeUtils";
import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import {CoreStore} from "../../../mobx/CoreStore";
import {DataStore} from "../../../../../app/mobx/DataStore";
import UpdateAvatarCommand from "../../../../account/commands/UpdateAvatarCommand";
import ManageAvatarWidget from "../../../../account/employee/components/tabs/items/general/widgets/ManageAvatarWidget";
import SCRUDActions from "../../../../../common/widgets/scrudActions/SCRUDActions";
import './style/style.scss';
import ModalFormik from "../../../../../common/widgets/modalForm/ModalFormik";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {ACCOUNT_FORM_CONFIG} from "./AccountFormConfig";
import UpdateAccountCommand from "./UpdateAccountCommand";
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    record?: AccountRecord;

    commandInProgress: boolean;
    resetPasswordSent: boolean;
}

@inject('dataStore', 'coreStore')
@observer
export default class AccountTabGeneral extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Props) {
        super(props);
        this.state = {
            modalFormVisible: false,
            record: undefined,
            commandInProgress: false,
            resetPasswordSent: false
        };
    }

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        return new UpdateAvatarCommand<AccountRecord>('account', updateDataRecord).execute().then((accountRecord: AccountRecord) => {
            const {coreStore} = this.props;
            coreStore.setAccount(accountRecord);
            return Promise.resolve(true);
        });
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {coreStore} = this.props;
        const {account} = coreStore;
        this.setState({modalFormVisible: true, record: account});
    };

    handleOnSaveRecord = (upsertValue: UpsertValue<AccountRecord>) => {
        this.setState({modalFormVisible: false, record: undefined});
    };

    handleOnFormikCancel = () => {
        this.setState({modalFormVisible: false, record: undefined});
    };

    saveRecordCommand = async (upsertValue: UpsertValue<AccountRecord>): Promise<UpsertResponse<AccountRecord>> => {
        const {coreStore} = this.props;
        const updatedValue: AccountRecord = await new UpdateAccountCommand().execute(upsertValue.updatedValue);
        coreStore.setAccount(updatedValue);
        return Promise.resolve({value: updatedValue, list: [updatedValue]});
    };

    render() {
        const {coreStore} = this.props;
        const {account, isAdminUser} = coreStore;

        return (
            <>
                <Row gutter={[10, 30]} className={'maxWidth'}>
                    <Col span={17}>
                        <Spin spinning={this.state.commandInProgress}>
                            <Descriptions title="Account Info" bordered column={2}
                                          extra={<SCRUDActions onEdit={this.onEdit} isEditEnabled={isAdminUser}/>}>
                                <Descriptions.Item label="Название компании">{account.name}</Descriptions.Item>
                                <Descriptions.Item label="Код">{account.id}</Descriptions.Item>
                                <Descriptions.Item label="Код страны">{account.country_code}</Descriptions.Item>
                                <Descriptions.Item label="Код валюты">{account.currency_code}</Descriptions.Item>
                                <Descriptions.Item label="Default locale">{account.default_locale}</Descriptions.Item>
                                <Descriptions.Item label="Created on">{MomentUtils.clientDateToLabel(account.created_on, false)}</Descriptions.Item>
                            </Descriptions>
                        </Spin>
                        <br/>
                        <Spin spinning={this.state.commandInProgress}>
                            <Descriptions title="Tariff Info" bordered column={3}>
                                <Descriptions.Item label="Тираф"><a href="/">Pro</a></Descriptions.Item>
                                <Descriptions.Item label="Статус">
                                    <Badge status={"success"} text="Active"/>
                                </Descriptions.Item>
                                <Descriptions.Item label="Оплачен до">10 августа 2022</Descriptions.Item>
                            </Descriptions>
                            <br/>
                            <div className="alignRight">
                                <Space>
                                    <Button disabled={!isAdminUser}>Изменить тариф...</Button>
                                    <Button disabled={!isAdminUser}>История платежей...</Button>
                                    <Button>Оплатить</Button>
                                </Space>
                            </div>
                        </Spin>

                    </Col>
                    <Col span={1}></Col>
                    <Col span={6}>
                        <Descriptions title="Logo"></Descriptions>
                        <ManageAvatarWidget selectedEntity={account} updateCommand={this.updateAvatar} emptyAvatar={getAvatar(account)}/>
                    </Col>
                </Row>
                {this.state.modalFormVisible && <ModalFormik config={ACCOUNT_FORM_CONFIG}
                                                             initialValues={this.state.record!}
                                                             saveCommand={this.saveRecordCommand}
                                                             onSave={this.handleOnSaveRecord}
                                                             onCancel={this.handleOnFormikCancel}/>
                }
            </>
        );
    }
}
