import React from 'react';
import {Card} from 'antd';
import SplitterLayout from 'react-splitter-layout';
import {loadValue, onSplitterResize,} from '../../../common/utils/SplitterUtils';
import {intl} from '../../../common/locale/IntlProvider';
import EmployeeFiltersComponent from "./components/filters/EmployeeFiltersComponent";
import EmployeeListComponent from "./components/list/EmployeeListComponent";
import EmployeeTabController from "./components/tabs/EmployeeTabController";
import './style/employeeView.scss';

export default class EmployeePageView extends React.Component {
    render() {
        return (
            <SplitterLayout
                primaryIndex={1}
                primaryMinSize={200}
                secondaryMinSize={220}
                secondaryInitialSize={loadValue('employee-sp-1', 250)}
                onSecondaryPaneSizeChange={onSplitterResize('employee-sp-1')}>
                <Card size="small" title={intl().formatMessage({id: 'employee.filters.title'})} bodyStyle={{padding: 0}} bordered={false}>
                    <EmployeeFiltersComponent/>
                </Card>
                <SplitterLayout
                    primaryIndex={1}
                    primaryMinSize={400}
                    secondaryMinSize={250}
                    secondaryInitialSize={loadValue('employee-sp-2', 350)}
                    onSecondaryPaneSizeChange={onSplitterResize('employee-sp-2')}>
                    <div className="flexh100">
                        <EmployeeListComponent/>
                    </div>
                    <EmployeeTabController/>
                </SplitterLayout>
            </SplitterLayout>
        );
    }
}
