import React from 'react';
import {Form, Input, Typography} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import {intl} from '../../../../common/locale/IntlProvider';
import {ReCaptchaButton} from '../../../../common/widgets/reCaptcha/ReCaptchaButton';
import {AxiosError, AxiosResponse} from 'axios';
import {MailOutlined} from '@ant-design/icons/lib';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import ResetPasswordCommand from './ResetPasswordCommand';
import {ResetPasswordURLData} from "fm-shared-data/src/types/auth/ResetPasswordURLData";
import {ResponseError} from "fm-shared-data/src/types/api/ResponseError";
import {SignupBody} from "fm-shared-data/src/types/auth/SignupBody";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {ResponseErrorCode} from "fm-shared-data/src/types/api/ResponseErrorCode";
import {JWTToken} from "fm-shared-data/src/types/auth/JWTToken";
import {DEFAULT_LOCALE} from "fm-shared-locale/src/common/SupportedLocales";

const {Paragraph} = Typography;

interface State {
    email: string;
    lockButton: boolean;
    lockForm: boolean;
    responseError: ResponseError | undefined;
    okMessage: string;
}

interface StateProps {
    data?: ResetPasswordURLData;
}

type Props = StateProps;

export default class ResetPasswordPageView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: this.props.data?.email || '',
            lockButton: false,
            lockForm: false,
            responseError: undefined,
            okMessage: '',
        };
    }

    onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({email: value});
    };

    handleStartLogin = () => {
        this.setState({lockButton: true, responseError: undefined, okMessage: ''});
    };

    handleLogin = async () => {
        const email: string = this.state.email.trim().toLowerCase();
        const locale: string = LocalStorage.getItem(LocalStorageKey.LOCALE) || DEFAULT_LOCALE.code;
        const body: SignupBody = {
            email,
            locale
        };

        await new ResetPasswordCommand().execute(body)
            .then((response: JWTToken) => {
                this.setState({
                    lockButton: false,
                    lockForm: true,
                    okMessage: `Please check the email box. Feel free to contact us at support@fieldmaster.com.ua`
                });
                return response;
            })
            .catch((error: AxiosError<AxiosErrorResponseData>) => {
                const errorCode: string = error.response?.data.message || error.message;
                const responseError: ResponseError = {
                    errorCode: errorCode as ResponseErrorCode,
                    errorMessage: `Cannot reset password (${errorCode}). Please contact support@fieldmaster.com.ua`
                };
                this.onErrorLogin(responseError);
                return {} as AxiosResponse<JWTToken>;
            });
    };

    handleReCaptchaInvalid = () => {
        this.onErrorLogin({
            errorCode: ResponseErrorCode.RECAPTCHA_ERROR,
            errorMessage: 'ReCaptcha validation error'
        });
    };

    onErrorLogin = (responseError: ResponseError) => {
        console.error('onErrorLogin ', JSON.stringify(responseError));
        this.setState({lockButton: false, responseError});
    };

    isFormValid = (): boolean => {
        return !!this.state.email.trim();
    };

    render() {
        const email: string = this.state.email.trim();
        const userEmailDomain: string = email.split('@')[1];
        const emailWWW: string = `www.${userEmailDomain}`.toLowerCase();

        return (
            <div>
                <h1>{intl().formatMessage({id: 'resetpassword.title'})}</h1>
                <hr/>
                <Form layout={'vertical'} labelAlign={'left'}>
                    <FormItem label="User email">
                        <Input
                            name="email"
                            type="email"
                            value={this.state.email}
                            placeholder="email"
                            disabled={this.state.lockButton || this.state.lockForm}
                            onChange={this.onChangeEmail}
                        />
                    </FormItem>
                    <FormItem>
                        <ReCaptchaButton
                            disabled={!this.isFormValid() || this.state.lockForm}
                            label="Reset password"
                            icon={<MailOutlined/>}
                            action="ResetPassword"
                            loading={this.state.lockButton}
                            onClick={this.handleStartLogin}
                            onValid={this.handleLogin}
                            onInvalid={this.handleReCaptchaInvalid}
                        />
                    </FormItem>
                </Form>
                {this.state.responseError && (
                    <Paragraph copyable={true} type={'danger'}>{this.state.responseError.errorMessage}</Paragraph>
                )}
                {this.state.okMessage && (
                    <>
                        <Paragraph strong={true}>{this.state.okMessage}</Paragraph>
                        <Paragraph type={'secondary'}>Visit <a href={`http://${emailWWW}`} target="_blank" rel="noopener noreferrer">{emailWWW}</a></Paragraph>
                    </>
                )}
            </div>
        );
    }
}
