import {AxiosResponse} from "axios";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {CEDAvatarFields} from "fm-shared-data/src/types/db/common/CEDAvatarFields";

export default class GetCEDAvatarsCommand<T extends DataRecord> extends Command<CEDAvatarFields, T> {
    constructor(private readonly table: string) {
        super();
    }

    execute(record: T): Promise<CEDAvatarFields> {
        return axiosGetInstance().post<CEDAvatarFields>(`/avatar/ced/${this.table}/${record.id}`, record)
            .then((response: AxiosResponse<CEDAvatarFields>) => response.data);
    }
}
