import {AxiosResponse} from "axios";
import {InsertResponse} from "fm-shared-data/src/types/db/common/InsertResponse";
import {RichTextRecord} from "fm-shared-data/src/types/db/common/RichTextRecord";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";

export default class UpsertRichTextCommand extends Command<InsertResponse<RichTextRecord>, Partial<RichTextRecord>> {

    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }

    execute(payload: Partial<RichTextRecord>): Promise<InsertResponse<RichTextRecord>> {
        return axiosGetInstance().post<InsertResponse<RichTextRecord>>(`/richtext/upsert/${this.barcode_entity_id}/${this.entity_id}`, payload)
            .then((response: AxiosResponse<InsertResponse<RichTextRecord>>) => response.data);
    }

}
