import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {TotalPivotPaymentRecord} from 'fm-shared-data/src/types/db/account/data/TotalPivotPaymentRecord';
import {ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';

export default class LoadTotalPivotPaymentCommand extends Command<TotalPivotPaymentRecord[], ReportFilterParams> {
    execute(reportFilterParams: ReportFilterParams): Promise<TotalPivotPaymentRecord[]> {
        return axiosGetInstance().post<TotalPivotPaymentRecord[]>('/data/total/pivot/payment', reportFilterParams)
            .then((response: AxiosResponse<TotalPivotPaymentRecord[]>) => response.data);
    }
}
