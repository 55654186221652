import {AxiosResponse} from "axios";
import UpdateDataCommand from "../../../../../../../../../common/types/UpdateDataCommand";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";

export default class AddEmployeePhoneCommand extends UpdateDataCommand<EmployeePhoneRecord> {

    constructor(private readonly upsertValue: UpsertValue<EmployeePhoneRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<EmployeePhoneRecord>> {
        return axiosGetInstance().post<UpsertResponse<EmployeePhoneRecord>>(`/employee/phone/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<EmployeePhoneRecord>>) => response.data);
    }

}
