import React from 'react';
import {Col, Divider, Row} from 'antd';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {EquipmentViewStore} from '../../../../mobx/EquipmentViewStore';
import BarCode from '../../../../../../../common/widgets/barcode/BarCode';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import {Point} from 'react-easy-crop/types';
import CEDAvatarsController from '../../../../../../../common/widgets/CEDAvatars/CEDAvatarsController';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import ManageTreeWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTreeWidget";
import ManageTagWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTagWidget";
import {getAvatar} from "../../../../../employee/EmployeeUtils";
import ManageAvatarWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageAvatarWidget";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import UpdateTreeIdsCommand from "../../../../../commands/tree/UpdateTreeIdsCommand";
import UpdateTagsCommand from "../../../../../commands/UpdateTagsCommand";
import UpdateAvatarCommand from "../../../../../commands/UpdateAvatarCommand";
import EquipmentTagAutoCompleteProvider from "./EquipmentTagAutoCompleteProvider";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    equipmentViewStore: EquipmentViewStore;
};

type Props = StoreProps;

@inject('equipmentViewStore', 'dataStore', 'coreStore')
@observer
export default class EquipmentTabGeneral extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    updateTree = (updateDataRecord: UpdateDataRecord<TreeFields>): Promise<boolean> => {
        return new UpdateTreeIdsCommand<EquipmentRecord>('equipment', updateDataRecord).execute().then((equipmentRecord: EquipmentRecord) => {
            const {dataStore, equipmentViewStore} = this.props;
            dataStore.updateEquipment(equipmentRecord);
            equipmentViewStore.applyFiltersToView(updateDataRecord.id);
            return Promise.resolve(true);
        });
    };

    updateTag = (updateDataRecord: UpdateDataRecord<TagFields>): Promise<boolean> => {
        return new UpdateTagsCommand<EquipmentRecord>('equipment', updateDataRecord).execute().then((equipmentRecord: EquipmentRecord) => {
            const {dataStore, equipmentViewStore} = this.props;
            dataStore.updateEquipment(equipmentRecord);
            return equipmentViewStore.refreshEquipmentTags(updateDataRecord.id).then(() => {
                return Promise.resolve(true);
            });
        });
    };

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        console.info(updateDataRecord);
        return new UpdateAvatarCommand<EquipmentRecord>('equipment', updateDataRecord).execute().then((equipmentRecord: EquipmentRecord) => {
            const {dataStore, equipmentViewStore} = this.props;
            dataStore.updateEquipment(equipmentRecord);
            equipmentViewStore.applyFiltersToView(equipmentRecord.id);
            return Promise.resolve(true);
        });
    };

    handleOnCropChange = (location: Point) => {
    };

    render() {
        const {equipmentViewStore, coreStore} = this.props;
        const {equipmentTreeData, selectedEntity} = equipmentViewStore;
        const equipmentBarcodeData: string = fmBarcode(coreStore.user.schema, BarcodeEntityId.EQUIPMENT, selectedEntity.id);

        return (
            <span className={'maxWidth'}>
                <Row gutter={[10, 30]}>
                    <Col span={16}>
                        <Row gutter={[10, 30]}>
                            <Col span={24}>
                                <Divider orientation="left">Structure</Divider>
                                <ManageTreeWidget selectedEntity={selectedEntity} treeData={equipmentTreeData} updateCommand={this.updateTree}/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">Tags</Divider>
                                <ManageTagWidget selectedEntity={selectedEntity} treeData={equipmentTreeData} updateCommand={this.updateTag}
                                                 isAdminUser={coreStore.isAdminUser}
                                                 autoCompleteDataProvider={new EquipmentTagAutoCompleteProvider()}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={7}>
                        <ManageAvatarWidget selectedEntity={selectedEntity} updateCommand={this.updateAvatar}
                                            emptyAvatar={getAvatar(selectedEntity)}/>
                        <BarCode data={equipmentBarcodeData} fontSizePx={52}/>
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <CEDAvatarsController table={'equipment'} record={selectedEntity}/>
                        </div>
                    </Col>
                </Row>
            </span>
        );
    }
}
