import {action, makeObservable, observable, runInAction} from 'mobx';
import {RichTextRecord} from "fm-shared-data/src/types/db/common/RichTextRecord";
import {computedFn} from "mobx-utils";
import UpsertRichTextCommand from "../commands/UpsertRichTextCommand";
import {InsertResponse} from "fm-shared-data/src/types/db/common/InsertResponse";
import LoadRichTextCommand from "../commands/LoadRichTextCommand";

export class RichTextStore {
    richTextMap: Map<string, RichTextRecord> = new Map<string, RichTextRecord>();

    constructor() {
        makeObservable(this, {
            richTextMap: observable,

            loadRichText: action,
            setRichText: action,
            upsertRichText: action,
            // deleteRichText: action,
        });
    }

    private getKey(barcode_entity_id: number, entity_id: number): string {
        return `${barcode_entity_id}.${entity_id}`;
    }

    getRichText = computedFn((barcode_entity_id: number, entity_id: number): RichTextRecord | undefined => {
        return this.richTextMap.get(this.getKey(barcode_entity_id, entity_id));
    });

    async loadRichText(barcode_entity_id: number, entity_id: number) {
        this.richTextMap.delete(this.getKey(barcode_entity_id, entity_id));
        const richText: RichTextRecord | undefined = await new LoadRichTextCommand(barcode_entity_id, entity_id).execute();
        runInAction(() => {
            this.setRichText(barcode_entity_id, entity_id, richText);
        });
    }

    async setRichText(barcode_entity_id: number, entity_id: number, value?: RichTextRecord) {
        this.richTextMap.set(this.getKey(barcode_entity_id, entity_id), value || {id: -1, value: '', barcode_entity_id, entity_id} as RichTextRecord);
    }

    async upsertRichText(barcode_entity_id: number, entity_id: number, value: Partial<RichTextRecord>) {
        const richText: InsertResponse<RichTextRecord> = await new UpsertRichTextCommand(barcode_entity_id, entity_id).execute(value);
        runInAction(() => {
            this.setRichText(barcode_entity_id, entity_id, richText.list[0]);
        });
    }

    // async deleteRichText(barcode_entity_id: number, entity_id: number, id: number) {
    //     const richText: RichTextRecord = await new DeleteRichTextCommand(barcode_entity_id, entity_id).execute(id);
    //     runInAction(() => {
    //         this.setRichText(barcode_entity_id, entity_id, richText);
    //     });
    // }

}
