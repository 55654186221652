import React from "react";
import {LogTypeField, LogTypeFieldType} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {Button, Checkbox, Input, Select} from "antd";
import {LOGTYPE_MAX_FIELDS} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";
import {
    DICTIONARY_FM_DICTIONARY_CONFIG,
    DICTIONARY_FM_ENTITY_CONFIG,
    DICTIONARY_LOGTYPE_FIELD_TYPE
} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {validateLogTypeField} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {DICTIONARY_UNIT_CONFIG} from "../../../../../common/widgets/dictionary/configs/DictionaryUnitConfig";
import TextArea from "antd/lib/input/TextArea";

interface Props {
    logTypeField: LogTypeField;
    onChange: (newValue: any, field_name: string) => void;
}

export class LogTypeFieldItem extends React.Component<Props> {

    private unitDictionary: DictionaryDecorator = new DictionaryDecorator(DICTIONARY_UNIT_CONFIG);
    private fmEntityDictionary: DictionaryDecorator = new DictionaryDecorator(DICTIONARY_FM_ENTITY_CONFIG);
    private fmDictionary: DictionaryDecorator = new DictionaryDecorator(DICTIONARY_FM_DICTIONARY_CONFIG);

    componentDidMount() {
        // use DictionaryStore and move from here
        this.unitDictionary.load();
    }

    field_id = (): string => {
        return this.props.logTypeField.field_id;
    }

    field_index = (): number => {
        return this.props.logTypeField.field_index;
    }

    dbFieldName = (field_name: string): string => {
        return `${field_name}${this.field_id()}`;
    }

    handleOnChangeEnable = (e: any) => {
        this.props.onChange(e.target.checked, this.dbFieldName("field_enabled"));
    }

    handleOnChangeRequired = (e: any) => {
        this.props.onChange(e.target.checked, this.dbFieldName("field_required"));
    }

    handleOnChangePrev = (e: any) => {
        this.props.onChange(e.target.checked, this.dbFieldName("field_prev"));
    }

    handleOnChangeTab = (e: any) => {
        this.props.onChange(e.target.checked, this.dbFieldName("field_tab"));
    }

    handleOnChangeIndexUp = (e: any) => {
        this.props.onChange(this.field_index() - 1, this.dbFieldName("field_index"));
    }

    handleOnChangeIndexDown = (e: any) => {
        this.props.onChange(this.field_index() + 1, this.dbFieldName("field_index"));
    }

    handleOnChangeTitle = (target: any) => {
        const {logTypeField, onChange} = this.props;
        const dbFieldName: string = `field_title${logTypeField.field_id}`;
        onChange(target.target.value, dbFieldName);
    }

    handleOnChangeType = (value: LogTypeFieldType) => {
        this.props.onChange(value, this.dbFieldName("field_type"));
    }

    handleOnChangeUnit = (value: string | undefined) => {
        this.props.onChange(value, this.dbFieldName("field_unit"));
    }

    handleOnChangeDictId = (value: string | undefined) => {
        this.props.onChange(value, this.dbFieldName("field_dict_id"));
    }

    handleOnChangeDescription = (value: any) => {
        this.props.onChange(value.target.value, this.dbFieldName("field_description"));
    }

    render() {
        const {logTypeField} = this.props;
        const isValid: string | null = validateLogTypeField(logTypeField);
        const bg: string = isValid === null ? "transparent" : "#ff000047";
        return <div style={{backgroundColor: bg}} className={'logTypeFieldItem-container'}>
            <strong>{this.field_index()}. &nbsp;</strong>
            <Button onClick={this.handleOnChangeIndexUp}
                    size={"small"}
                    shape={"circle"}
                    disabled={this.field_index() === 1}><CaretUpOutlined/></Button>
            <Button onClick={this.handleOnChangeIndexDown}
                    size={"small"}
                    shape={"circle"}
                    disabled={this.field_index() === LOGTYPE_MAX_FIELDS}><CaretDownOutlined/></Button>
            &nbsp;
            <Checkbox checked={logTypeField.field_enabled}
                      onChange={this.handleOnChangeEnable}/>
            &nbsp;
            <Input value={logTypeField.field_title}
                   placeholder="Название поля"
                   disabled={!logTypeField.field_enabled}
                   onChange={this.handleOnChangeTitle}
                   allowClear={true}
                   style={{width: 150}}/>
            &nbsp;
            <Checkbox checked={logTypeField.field_required}
                      onChange={this.handleOnChangeRequired}
                      style={{color: "red"}}
                      disabled={!logTypeField.field_enabled}>*</Checkbox>
            <Checkbox checked={logTypeField.field_prev}
                      onChange={this.handleOnChangePrev}
                      disabled={!logTypeField.field_enabled}>Default prev</Checkbox>
            <Select onChange={this.handleOnChangeType}
                    value={logTypeField.field_type}
                    options={new DictionaryDecorator(DICTIONARY_LOGTYPE_FIELD_TYPE).selectOptionsProvider}
                    disabled={!logTypeField.field_enabled}
                    style={{width: 150}}
            />
            &nbsp;
            {logTypeField.field_type === LogTypeFieldType.NUMBER && <Select onChange={this.handleOnChangeUnit}
                                                                            value={logTypeField.field_unit}
                                                                            options={this.unitDictionary.selectOptionsProvider}
                                                                            disabled={!logTypeField.field_enabled}
                                                                            style={{width: 80}}
            />}
            {logTypeField.field_type === LogTypeFieldType.FM_ENTITY && <Select onChange={this.handleOnChangeDictId}
                                                                               value={logTypeField.field_dict_id}
                                                                               options={this.fmEntityDictionary.selectOptionsProvider}
                                                                               disabled={!logTypeField.field_enabled}
                                                                               style={{width: 120}}
            />}
            &nbsp;
            {logTypeField.field_type === LogTypeFieldType.FM_ENTITY && <Checkbox checked={logTypeField.field_tab}
                                                                                 onChange={this.handleOnChangeTab}>Show on tab</Checkbox>}
            {logTypeField.field_type === LogTypeFieldType.FM_DICTIONARY && <Select onChange={this.handleOnChangeDictId}
                                                                                   value={logTypeField.field_dict_id}
                                                                                   options={this.fmDictionary.selectOptionsProvider}
                                                                                   disabled={!logTypeField.field_enabled}
                                                                                   style={{width: 120}}
            />}
            <TextArea onChange={this.handleOnChangeDescription}
                      value={logTypeField.field_description}
                      disabled={!logTypeField.field_enabled}
                      placeholder="Описание"
                      rows={1}/>

            {isValid !== null && <p style={{color: "red"}}>{isValid}</p>}
        </div>;
    }
}