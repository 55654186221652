import {AccountRecord} from "fm-shared-data/src/types/db/core/AccountRecord";
import {ModalFormConfig} from "../../../../../common/widgets/modalForm/type/ModalFormConfig";
import {FormFieldRender} from "../../../../../common/widgets/modalForm/type/FormFieldRender";
import {NonEmptyValidator} from "../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";
import {DICTIONARY_LOCALE} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";

export const ACCOUNT_FORM_CONFIG: ModalFormConfig<AccountRecord> = {
    title: 'account.modalForm.title',
    tableName: 'account',
    getNewRecord: (): AccountRecord => {
        return {} as AccountRecord;
    },
    fieldConfig: [
        {
            label: 'account.field.name',
            field_name: 'name',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator({invalidMessageKey: 'account.field.name_invalid'})],
            config: {
                inputProps: {
                    placeholder: 'Account name'
                }
            },
        },
        {
            label: 'account.field.country_code',
            field_name: 'country_code',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator({invalidMessageKey: 'account.field.name_invalid'})],
            config: {
                inputProps: {
                    placeholder: 'country code'
                }
            },
        },
        {
            label: 'account.field.currency_code',
            field_name: 'currency_code',
            renderType: FormFieldRender.INPUT,
            syncValidators: [new NonEmptyValidator({invalidMessageKey: 'account.field.name_invalid'})],
            config: {
                inputProps: {
                    placeholder: 'currency code'
                }
            },
        },
        {
            label: 'account.field.default_locale',
            field_name: 'default_locale',
            renderType: FormFieldRender.SINGLE_SELECT,
            syncValidators: [new NonEmptyValidator()],
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_LOCALE),
                inputProps: {
                    placeholder: 'Select default locale'
                }
            }
        }
    ]
};
