import React from 'react';
import {Button, Modal} from 'antd';
import {formatMessage} from '../../locale/IntlProvider';
import Icofont from 'react-icofont';
import ReportReactController from '../reportReact/ReportReactController';

interface Props {
    title?: string;
}

interface State {
    isSingleBadgeVisible: boolean;
}

export default class PrintBadge extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isSingleBadgeVisible: false
        };
    }

    showBadgeHandler = () => {
        this.setState({isSingleBadgeVisible: true});
    }

    hideBadgeHandler = () => {
        this.setState({isSingleBadgeVisible: false});
    }

    render() {
        return (
            <>
                <Button type='text'
                        onClick={this.showBadgeHandler}
                        title={formatMessage("tooltip.print.badge")}>
                    <Icofont icon="id" size="2" className="blueText"/>
                </Button>
                <Modal width="50%"
                       onOk={this.hideBadgeHandler}
                       onCancel={this.hideBadgeHandler}
                       visible={!!this.state.isSingleBadgeVisible}>
                    <ReportReactController title="Single badge">
                        {this.props.children}
                    </ReportReactController>
                </Modal>
            </>
        );
    }
}
