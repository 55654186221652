import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";

export default class LoadFieldCommand extends Command<FieldRecord[]> {
    execute(): Promise<FieldRecord[]> {
        return axiosGetInstance().post<FieldRecord[]>('/field/all')
            .then((response: AxiosResponse<FieldRecord[]>) => response.data);
    }
}
