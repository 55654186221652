import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface EmailValidatorConfig {
    invalidMessageKey: string;
}

export class EmailValidator extends SyncValidator<string | undefined, EmailValidatorConfig> {

    constructor(config?: EmailValidatorConfig) {
        super(config || {invalidMessageKey: 'modalForm.validation.email'});
    }

    validate(value: string | undefined): FieldValidatorResult {
        if (!value) {
            return null;
        }
        return value.split('@').length !== 2 ? formatMessage(this.config.invalidMessageKey) : null;
    }

}
