import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {stores} from "../../../../../app/mobx/Stores";
import {scannerHash, ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface UniqScannerValidatorConfig {
    invalidMessageKey?: string;
}

export class UniqScannerValidator extends SyncValidator<ScannerRecord, UniqScannerValidatorConfig> {

    constructor(config?: UniqScannerValidatorConfig) {
        super(config || {});
    }

    validate(value: ScannerRecord): FieldValidatorResult {
        if (value.name && value.name.toLowerCase().trim()) {
            const valueHash: string = scannerHash(value);
            const isRecordExists: boolean = stores.dataStore.scanner
                .some((rec: ScannerRecord) => value.id !== rec.id && valueHash === scannerHash(rec));
            return isRecordExists ? formatMessage('modalForm.validation.scanner.device_key.uniq', {name: value.name}) : null;
        }
        return null;
    }
}
