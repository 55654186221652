import React from 'react';
import {Slider, Space} from "antd";
import ReactToPrint from "react-to-print";
import Checkbox from "antd/lib/checkbox";
import './style/style.scss'
import Icofont from "react-icofont";

interface Props {
    title: string;
}

interface State {
    zoom: number;
    pageOrientation: string;
}

export default class ReportReactController extends React.Component<Props, State> {
    private readonly cssA4Style: string = "@page {size: A4;}";

    reportPageRef: React.ReactInstance | null = null;

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            zoom: 100,
            pageOrientation: "portrait"
        };
    }

    handleOnChange = (zoom: number) => {
        this.setState({zoom});
    };

    handleOnCheck = () => {
        this.setState({pageOrientation: this.state.pageOrientation === "portrait" ? "landscape" : "portrait"});
    };

    render() {
        return (
            <div className="page-body">
                <div className="page-body-footer">
                    <Space>
                        <strong>Штрих-код(ы)</strong>
                        <Slider
                            min={50}
                            max={250}
                            onChange={this.handleOnChange}
                            value={this.state.zoom}
                            step={1}
                            style={{width: 400}}
                        />
                        <ReactToPrint
                            trigger={() => {
                                return <Icofont icon="print" size="2"/>;
                            }}
                            content={() => this.reportPageRef}
                        />
                        <Checkbox checked={this.state.pageOrientation === "portrait"} onChange={this.handleOnCheck}>Portrait</Checkbox>
                    </Space>
                </div>
                <div className="page-container">
                    <div className="page" ref={(el) => (this.reportPageRef = el)} contentEditable="false" style={{zoom: this.state.zoom / 100}}>
                        {this.state.pageOrientation === "portrait" && (
                            <style>{this.cssA4Style}</style>
                        )}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
