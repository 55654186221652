import {AxiosResponse} from "axios";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";
import {FILES_UPLOAD_FIELDNAME} from "fm-shared-utils/src/utils/file/FileUtils";
import {EntityKey} from "fm-shared-data/src/types/db/common/EntityKey";

export default class UploadFilesCommand extends Command<string[], File[]> {
    constructor(private readonly fileEntityKey: EntityKey) {
        super();
    }

    execute(files: File[]): Promise<string[]> {
        const formData: FormData = new FormData();
        files.forEach((file: File) => formData.append(FILES_UPLOAD_FIELDNAME, file, file.name));
        const {barcode_entity_id, entity_id} = this.fileEntityKey;
        return axiosGetInstance().post<string[]>(`/file/uploads/${barcode_entity_id}/${entity_id}`, formData)
            .then((response: AxiosResponse<string[]>) => response.data);
    }
}
