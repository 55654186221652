import React from 'react';
import './style/refreshControl.scss';
import classNames from 'classnames';
import {SyncOutlined} from '@ant-design/icons/lib';

interface Props {
    onRefresh: () => Promise<any>;
}

interface State {
    isRefreshProcess: boolean;
}

export default class RefreshControl extends React.Component<Props, State> {
    state = {isRefreshProcess: false};

    onRefreshClick = () => {
        this.setState({isRefreshProcess: !this.state.isRefreshProcess});
        this.props.onRefresh().then(() => this.setState({isRefreshProcess: !this.state.isRefreshProcess}));
    };

    render() {
        return (
            <SyncOutlined className={classNames('refresh-control', {
                'section-header__icon': !this.state.isRefreshProcess,
            })}
                          title={'Обновить данные'}
                          onClick={this.onRefreshClick}
                          spin={this.state.isRefreshProcess}
            />
        );
    }
}
