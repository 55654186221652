import {Format, Hierarchy, Measure, Options, Report} from 'webdatarocks';

export const fmFormats: Format[] = [
    {
        name: "paymentFormat",
        decimalPlaces: 2
    },
    {
        name: "kgFormat",
        decimalPlaces: 1
    }
];

export const fmGlobal: Report = {
    localization: "/pivot-localizations/ru.json"
}

export function getOptions(title?: string): Options {
    return {
        grid: {
            "showGrandTotals": "columns",
            title
        }
    }
}

enum FIELD_NAME {
    user_name = "user_name",
    work_name = "work_name",
    field_name = "field_name",
}

export const SLICE_HIERARCHY: Record<FIELD_NAME, Hierarchy> = {
    [FIELD_NAME.user_name]: {
        uniqueName: "user_name",
        caption: "Работник"
    },
    [FIELD_NAME.work_name]: {
        uniqueName: "work_name",
        caption: "Работа"
    },
    [FIELD_NAME.field_name]: {
        uniqueName: "field_name",
        caption: "Участок"
    },
};

enum UNIQUE_NAME {
    value = "value",
    value_kg = "value_kg",
    payment = "payment",
    value_mins = "value_mins",
    value_hours = "value_hours",
    third_party_employee_cost = "third_party_employee_cost",
    payment_employee_cost = "payment_employee_cost",
}

export const SLICE_MEASURE: Record<UNIQUE_NAME, Measure> = {
    [UNIQUE_NAME.value]: {
        uniqueName: "value",
        aggregation: "sum",
        caption: "Объем, шт",
        grandTotalCaption: "ИТОГО Объем, шт:"
    },
    [UNIQUE_NAME.value_kg]: {
        uniqueName: "value_kg",
        aggregation: "sum",
        caption: "Вес, кг",
        grandTotalCaption: "ИТОГО Вес, кг:"
    },
    [UNIQUE_NAME.payment]: {
        uniqueName: "payment",
        aggregation: "sum",
        caption: "Оплата за работу, грн",
        grandTotalCaption: "ИТОГО Оплата за работу, грн:",
        format: "paymentFormat"
    },
    [UNIQUE_NAME.value_mins]: {
        uniqueName: "value_mins",
        caption: "Минут",
        grandTotalCaption: "ИТОГО Минут:",
        active: false
    },
    [UNIQUE_NAME.value_hours]: {
        uniqueName: "value_hours",
        caption: "Часов",
        grandTotalCaption: "ИТОГО Часов:"
    },
    [UNIQUE_NAME.third_party_employee_cost]: {
        uniqueName: "third_party_employee_cost",
        caption: "Стоимость работника, в грн/час",
        grandTotalCaption: "ИТОГО Стоимость работника, в грн/час:",
        format: "paymentFormat",
        active: false
    },
    [UNIQUE_NAME.payment_employee_cost]: {
        uniqueName: "payment_employee_cost",
        caption: "Оплата за содержание, грн",
        grandTotalCaption: "ИТОГО Оплата за содержание, грн:",
        format: "paymentFormat"
    },
};
