import React from "react";
import Icofont from "react-icofont";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {getAvatar} from "../../../pages/account/employee/EmployeeUtils";
import AvatarWidget from "../avatarWidget/AvatarWidget";
import {Space} from "antd";

export function getDictionaryIconLabelRenderer<T extends DataRecord = DataRecord>(dictionaryRawRecord: DictionaryRawRecord<T>): React.ReactNode {
    const {icon, color, name} = dictionaryRawRecord;
    return <div className="tree-title" style={{color: color}}>
        {icon && <Icofont icon={`${icon}`} size={"2"}/>}
        {name}
    </div>;
}

export function getDictionaryAvatarLabelRenderer<T extends DataRecord = DataRecord>(dictionaryRawRecord: DictionaryRawRecord<T>): React.ReactNode {
    const {avatar, color, name} = dictionaryRawRecord;
    return <Space className="tree-title" style={{color: color}}>
        {avatar && <AvatarWidget src={getAvatar(dictionaryRawRecord)} size={25} hidePreview={true}/>}
        {name}
    </Space>;
}