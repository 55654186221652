import React from "react";
import {LogTypeField} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {LogDataFieldItem} from "./LogDataFieldItem";
import {Form as AntForm} from 'antd';

interface Props {
    enabledLogTypeFields: LogTypeField[];
}

export const LogDataFields = ({enabledLogTypeFields}: Props) => {
    return (
        <div className="logdata-container">
            <AntForm layout={'vertical'}>
                {enabledLogTypeFields.map((logTypeField: LogTypeField) => {
                    return <LogDataFieldItem key={logTypeField.field_id}
                                             disabled={!logTypeField.field_enabled}
                                             logTypeField={logTypeField}/>;
                })}
            </AntForm>
        </div>
    );
};
