import PredefinedFilterItem from '../../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import isEmpty from 'lodash/isEmpty';
import {LocalStorage, LocalStorageKey} from "../../../../../common/utils/LocalStorageUtils";
import {DateUtils} from "fm-shared-utils/src/utils/datatime/DateUtils";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";

export const PREDEFINED_CALENDAR_FILTERS: PredefinedFilterItem<CalendarRecord>[] = [
    {
        key: 'filter_new',
        titleKey: 'employee.filters.predefined.filter_new',
        filterFunction: (record: CalendarRecord) => {
            return DateUtils.diffDayDates(record.created_on) <= 7;
        }
    },
    {
        key: 'filter_recent',
        titleKey: 'employee.filters.predefined.filter_recent',
        filterFunction: (record: CalendarRecord) => {
            const {edited_on} = record;
            return !!edited_on && DateUtils.diffDayDates(edited_on) <= 7;
        }
    },
    {
        key: 'filter_not_in_tree',
        titleKey: 'employee.filters.predefined.filter_not_in_tree',
        filterFunction: (record: CalendarRecord) => {
            return record.tree_ids.length === 0;
        }
    },
    {
        key: 'filter_without_avatar',
        titleKey: 'employee.filters.predefined.filter_without_avatar',
        filterFunction: (record: CalendarRecord) => {
            return !record.avatar;
        }
    },
];

export function restoreCalendarPredefinedFilter(): PredefinedFilterItem<CalendarRecord>[] {
    const availableKeys: string[] = PREDEFINED_CALENDAR_FILTERS.map((item: PredefinedFilterItem<CalendarRecord>) => item.key);
    const validKeys: string[] = (LocalStorage.getItem(LocalStorageKey.CALENDAR_PREDEFINED_FILTERS) || "")
        .split(',')
        .filter((saved: string) => availableKeys.includes(saved));
    if (isEmpty(validKeys)) {
        return [];
    }
    return PREDEFINED_CALENDAR_FILTERS.filter((item: PredefinedFilterItem<CalendarRecord>) => validKeys.includes(item.key));
}
