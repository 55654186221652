import React from 'react';
import {intl} from '../../../../../common/locale/IntlProvider';

export default function ScannerTabInfoView() {
    return (
        <>
            <p>{intl().formatMessage({id: 'employee.card.help1'})}</p>
            <p>{intl().formatMessage({id: 'employee.card.help2'})}</p>
        </>
    );
}
