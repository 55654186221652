import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {EmployeeViewStore} from './mobx/EmployeeViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import EmployeePageView from "./EmployeePageView";
import {EmployeeURLData} from "./EmployeeURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    employeeViewStore: EmployeeViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'employeeViewStore')
@observer
export default class EmployeePageController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('EmployeePageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('EmployeePageController: componentDidMount', performance.now());
        const {employeeViewStore} = this.props;
        if (!employeeViewStore.isDataLoaded) {
            await employeeViewStore.loadData();
        } else {
            const urlData: EmployeeURLData | undefined = decryptObject<EmployeeURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.employeeBarCode)) {
                employeeViewStore.universalFilter = urlData!.employeeBarCode!;
                employeeViewStore.clearFilters();
                goToPage(AppRoutes.FM_EMPLOYEE);
            } else if (employeeViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                employeeViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {employeeViewStore} = this.props;
        const urlData: EmployeeURLData | undefined = decryptObject<EmployeeURLData>(this.props.match.params.data);
        console.log('EmployeePageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', employeeViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.employeeBarCode)) {
            employeeViewStore.universalFilter = urlData!.employeeBarCode!;
            employeeViewStore.clearFilters();
            goToPage(AppRoutes.FM_EMPLOYEE);
        } else if (isEmpty(urlData?.employeeBarCode) && !employeeViewStore.isViewReady) {
            employeeViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'EmployeePageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('EmployeePageController: componentDidCatch', performance.now());
    }

    render() {
        const {employeeViewStore} = this.props;
        const {isViewReady, isDataLoaded} = employeeViewStore;

        if (!isDataLoaded) {
            return <p>Load employee data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating employee view....</p>;
        }

        return (
            <EmployeePageView/>
        );
    }
}
