import React from 'react';
import {Card, Checkbox, List} from 'antd';
import {intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {WORK_FORM_CONFIG} from './WorkFormConfig';
import {inject, observer} from 'mobx-react';
import {WorkViewStore} from '../../mobx/WorkViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddWorkCommand from './commands/AddWorkCommand';
import UpdateWorkCommand from './commands/UpdateWorkCommand';
import DeleteWorkCommand from './commands/DeleteWorkCommand';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";
import AvatarWidget from "../../../../../common/widgets/avatarWidget/AvatarWidget";
import {getAvatar} from "../../../employee/EmployeeUtils";

type StoreProps = {
    workViewStore: WorkViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: WorkRecord;
}

@inject('workViewStore')
@observer
export default class WorkListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('WorkListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as WorkRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {workViewStore} = this.props;
        workViewStore.universalFilter = value;
        workViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedWorkTreeRecord} = this.props.workViewStore;
        const newWorkRecord: WorkRecord = WORK_FORM_CONFIG.getNewRecord();
        newWorkRecord.tree_ids = [selectedWorkTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newWorkRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {workViewStore} = this.props;
        const {selectedEntity} = workViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    onDelete = async () => {
        console.log(`Delete:`);
        const {workViewStore} = this.props;
        const {selectedEntity} = workViewStore;
        const WorkRecords: WorkRecord[] = await new DeleteWorkCommand().execute(selectedEntity);
        //unselect from selectedEntities ???
        workViewStore.setWork(WorkRecords);
    };

    saveWorkRecordCommand = async (upsertValue: UpsertValue<WorkRecord>): Promise<UpsertResponse<WorkRecord>> => {
        const {workViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<WorkRecord> = isUpdate
            ? await new UpdateWorkCommand(upsertValue).execute()
            : await new AddWorkCommand(upsertValue).execute();
        workViewStore.setWork(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveWorkRecord = async (upsertValue: UpsertValue<WorkRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('WorkListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('WorkListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedWorks: WorkRecord[]) => {
        const {workViewStore} = this.props;
        workViewStore.setSelectedEntities(selectedWorks);
    };

    onSelectAll = () => {
        const {workViewStore} = this.props;
        if (workViewStore.selectedEntities.length === 0) {
            workViewStore.selectedEntities = workViewStore.visibleEntities;
        } else {
            workViewStore.selectedEntities = [];
        }
    };

    render() {
        const {workViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleWorkCount, selectedEntities} = workViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const areManyEntitiesSelected: boolean = selectedEntities.length > 1;
        const isEmptyList: boolean = visibleWorkCount === 0;

        return (
            <div className="entity-container">
                <Card size="small"
                      className="fm-card"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : workViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'work.field.works_names'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={workViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              onDelete={this.onDelete}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}
                              isDeleteEnabled={isOneEntitySelected || areManyEntitiesSelected}/>}>
                    <div className="list-container">
                        <CheckedList selectedRecords={workViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: WorkRecord) => (
                                         <List.Item.Meta avatar={<AvatarWidget src={getAvatar(item)} size={50} hidePreview={true}/>}
                                                         title={item.name}
                                                         description={item.payment_type_name}/>)}/>
                        {this.state.modalFormVisible && <ModalFormik config={WORK_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveWorkRecordCommand}
                                                                     onSave={this.handleOnSaveWorkRecord}
                                                                     onCancel={this.handleOnModalFormClosed}/>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
