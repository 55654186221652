import blankAvatar from "../../../assets/image/blank-avatar.png";
import blankAvatarFemale from "../../../assets/image/blank-avatar-female.png";
import blankAvatarMale from "../../../assets/image/blank-avatar-male.png";
import {EmployeeGender} from "fm-shared-data/src/types/db/account/employee/EmployeeGender";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";

export function getEmployeeAvatarByData(gender: string, avatar?: string): string {
    return avatar || (gender === EmployeeGender.FEMALE ? blankAvatarFemale : blankAvatarMale);
}

export function getEmployeeAvatar(employee: EmployeeRecord): string {
    return getEmployeeAvatarByData(employee.gender, employee.avatar);
}

export function getAvatar<T extends AvatarFields = AvatarFields>(avatarFields: T): string {
    return avatarFields.avatar || blankAvatar;
}

export function getEmployeeName(employee: EmployeeRecord): string {
    return `${employee.last_name} ${employee.first_name}`;
}
