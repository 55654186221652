import React from 'react';
import {Card, Checkbox, List, message} from 'antd';
import {intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {LOGTYPE_FORM_CONFIG} from './LogTypeFormConfig';
import {inject, observer} from 'mobx-react';
import {LogTypeViewStore} from '../../mobx/LogTypeViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddLogTypeCommand from './commands/AddLogTypeCommand';
import UpdateLogTypeCommand from './commands/UpdateLogTypeCommand';
import DeleteLogTypeCommand from './commands/DeleteLogTypeCommand';
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import AvatarWidget from "../../../../../common/widgets/avatarWidget/AvatarWidget";
import {getAvatar} from "../../../employee/EmployeeUtils";
import {LogTypeFields} from "./LogTypeFields";
import {CatchError, CatchErrorCode} from "../../../../../common/types/CatchError";
import {LogTypeField} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {readLogTypeFields, validateLogTypeFields} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import NameIconColorRenderer from "../../../../../common/widgets/tree/NameIconColorRenderer";

type StoreProps = {
    logTypeViewStore: LogTypeViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: LogTypeRecord;
}

@inject('logTypeViewStore')
@observer
export default class LogTypeListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('LogTypeListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as LogTypeRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {logTypeViewStore} = this.props;
        logTypeViewStore.universalFilter = value;
        logTypeViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedLogTypeTreeRecord} = this.props.logTypeViewStore;
        const newLogTypeRecord: LogTypeRecord = LOGTYPE_FORM_CONFIG.getNewRecord();
        newLogTypeRecord.tree_ids = [selectedLogTypeTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newLogTypeRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {logTypeViewStore} = this.props;
        const {selectedEntity} = logTypeViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    onDelete = async () => {
        console.log(`Delete:`);
        const {logTypeViewStore} = this.props;
        const {selectedEntity} = logTypeViewStore;
        const LogTypeRecords: LogTypeRecord[] = await new DeleteLogTypeCommand().execute(selectedEntity);
        //unselect from selectedEntities ???
        logTypeViewStore.setLogType(LogTypeRecords);
    };

    saveLogTypeRecordCommand = async (upsertValue: UpsertValue<LogTypeRecord>): Promise<UpsertResponse<LogTypeRecord>> => {
        // custom validation
        const logTypeFields: LogTypeField[] = readLogTypeFields(this.state.modalFormData);
        const validationResult: string | null = validateLogTypeFields(logTypeFields);
        if (validationResult !== null) {
            const catchError: CatchError = {
                errorMessage: validationResult,
                errorCode: CatchErrorCode.CONNECTIVITY
            }
            message.error({content: catchError.errorMessage, duration: 4});
            return Promise.reject(catchError);
        }

        // merge two states: formik (upsertValue) and local (this.state.modalFormData)
        const mergedUpsertValue: UpsertValue<LogTypeRecord> = {
            initialValue: upsertValue.initialValue,
            updatedValue: this.state.modalFormData
        };
        for (const fieldConfig of LOGTYPE_FORM_CONFIG.fieldConfig) {
            mergedUpsertValue.updatedValue[fieldConfig.field_name] = upsertValue.updatedValue[fieldConfig.field_name];
        }

        const {logTypeViewStore} = this.props;
        const isUpdate: boolean = !!mergedUpsertValue.initialValue.id;
        const upsertResponse: UpsertResponse<LogTypeRecord> = isUpdate
            ? await new UpdateLogTypeCommand(mergedUpsertValue).execute()
            : await new AddLogTypeCommand(mergedUpsertValue).execute();
        logTypeViewStore.setLogType(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveLogTypeRecord = async (upsertValue: UpsertValue<LogTypeRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('LogTypeListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('LogTypeListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedLogTypes: LogTypeRecord[]) => {
        const {logTypeViewStore} = this.props;
        logTypeViewStore.setSelectedEntities(selectedLogTypes);
    };

    onSelectAll = () => {
        const {logTypeViewStore} = this.props;
        if (logTypeViewStore.selectedEntities.length === 0) {
            logTypeViewStore.selectedEntities = logTypeViewStore.visibleEntities;
        } else {
            logTypeViewStore.selectedEntities = [];
        }
    };

    onChange = (newValue: any, dbFieldName: string) => {
        console.log(`LogTypeListComponent onChange: ${dbFieldName} set to ${newValue}`);

        // find and update prev value
        const rec: any = {};
        if (dbFieldName.startsWith('field_index')) {
            const diff: number = newValue - this.state.modalFormData[dbFieldName];
            let prevIndex: number = -1
            let i: number = 1;
            while (prevIndex === -1 && i <= 7) {
                if (this.state.modalFormData[`field_index${i}`] === newValue) {
                    prevIndex = i;
                }
                i++;
            }
            rec[`field_index${prevIndex}`] = newValue - diff;
        }

        const updatedRec: LogTypeRecord = {
            ...this.state.modalFormData,
            [dbFieldName]: newValue,
            ...rec
        };

        // cleanup some fields after change the type. Before and/or after change
        const field_id: string = dbFieldName.split('').pop()!;
        if (dbFieldName === `field_type${field_id}`) {
            updatedRec[`field_unit${field_id}`] = undefined;
            updatedRec[`field_dict_id${field_id}`] = undefined;
        }
        this.setState({modalFormData: updatedRec})
    }

    render() {
        const {logTypeViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleLogTypeCount, selectedEntities} = logTypeViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const areManyEntitiesSelected: boolean = selectedEntities.length > 1;
        const isEmptyList: boolean = visibleLogTypeCount === 0;

        return (
            <div className="logtype-container">
                <Card size="small"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : logTypeViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'logType.form.title'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={logTypeViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              onDelete={this.onDelete}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}
                              isDeleteEnabled={isOneEntitySelected || areManyEntitiesSelected}/>}>
                    <div className="list-container">
                        <CheckedList selectedRecords={logTypeViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: LogTypeRecord) => (
                                         <List.Item.Meta avatar={<AvatarWidget src={getAvatar(item)} size={50} hidePreview={true}/>}
                                             // title={item.name}
                                                         title={NameIconColorRenderer(item)}
                                         />)}/>
                        {this.state.modalFormVisible && <ModalFormik config={LOGTYPE_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveLogTypeRecordCommand}
                                                                     onSave={this.handleOnSaveLogTypeRecord}
                                                                     onCancel={this.handleOnModalFormClosed}>
                            <LogTypeFields logTypeRecord={this.state.modalFormData} onChange={this.onChange}/>
                        </ModalFormik>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
