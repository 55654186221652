import React from 'react';
import './style/style.scss'
import BarCode from "../barcode/BarCode";

interface Props {
    title: string;
    subTitle: string;
    barcodeData: string;
}

export default class BadgeCard extends React.Component<Props> {
    render() {
        const {title, subTitle, barcodeData} = this.props;
        return (
            <div className="badge-card-container">
                <span className="badge-card-container__title">{title}<br/>{subTitle}</span>
                <BarCode data={barcodeData} fontSizePx={72}/>
            </div>
        );
    }
}
