import React from 'react';
import {TreeSelect} from 'antd';
import {DataNode} from "rc-tree/lib/interface";
import {getTreeNodes} from "../../../../../../../../common/widgets/tree/TreeStructureUtils";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {FMKeyType} from "fm-shared-data/src/types/dictionary/FMKeyType";

interface Props<T extends DataRecord & TreeFields, R extends TreeRecord> {
    selectedEntity: T;
    treeData: TreeData<R>[];
    updateCommand: (value: UpdateDataRecord<TreeFields>) => Promise<boolean>
}

interface State {
    isTreeUpdating: boolean;
}

export default class ManageTreeWidget<T extends DataRecord & TreeFields, R extends TreeRecord> extends React.Component<Props<T, R>, State> {

    constructor(props: Props<T, R>) {
        super(props);
        this.state = {
            isTreeUpdating: false
        };
    }

    handleOnChangeTree = async (value: string[]) => {
        this.setState({isTreeUpdating: true});
        const {selectedEntity} = this.props;
        const {edited_on} = selectedEntity;
        const updateDataRecord: UpdateDataRecord<TreeFields> = {
            id: selectedEntity.id,
            edited_on: edited_on,// && clientDateToUTCDate(edited_on),
            data: {tree_ids: value.map((s: string) => Number.parseInt(s))}
        };
        this.props.updateCommand(updateDataRecord).then(() => {
            this.setState({isTreeUpdating: false});
        });
    };

    render() {
        const dataNode: DataNode[] = getTreeNodes(this.props.treeData, false, true, false);
        return (
            <TreeSelect treeData={dataNode}
                        value={this.props.selectedEntity.tree_ids.map((id: FMKeyType) => id.toString())}
                        onChange={this.handleOnChangeTree}
                        loading={this.state.isTreeUpdating}
                        placeholder="Please select"
                        style={{width: '100%'}}
                        listHeight={300}
                        treeDefaultExpandAll={true}
                        allowClear={true}
                        showSearch={false}
                        multiple={true}/>
        );
    }
}
