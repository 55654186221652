import {generatePath} from 'react-router-dom';
import {createHashHistory} from 'history';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {AppURL} from "./AppURL";
import {encryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";

export const hashHistory = createHashHistory();

export function goToPage<T extends object = any>(page: AppRoutes, data?: T, replace?: boolean) {
    const url: string = genURLToPage(page, data);

    if (hashHistory.location.pathname === decodeURIComponent(url)) {
        // console.info('PREVENT NOT OPEN CURRENT SAME URL :)', hashHistory.location.pathname, decodeURIComponent(url));
        return;
    }

    if (replace) {
        hashHistory.replace(url);
    } else {
        hashHistory.push(url);
    }
    window.scrollTo(0, 0);
}

export function goToAppURL<T extends object = any>(appURL: AppURL<T>, replace?: boolean) {
    goToPage<T>(appURL.page, appURL.data, replace);
}

export function genURLToPage<T extends object = any>(page: AppRoutes, data?: T): string {
    let url = page.toString();
    if (data && page.includes('/:data')) {
        url = generatePath(page, {
            data: encryptObject(data),
        });
    }
    return url;
}

export function goToURL(url: string, replace?: boolean): void {
    // if (replace) {
    //     history.replace(url);
    // } else {
    //     history.push(url);
    // }
    // window.scrollTo(0, 0);
    window.location.href = url;
}

export function goBack() {
    hashHistory.goBack();
}
