import React from 'react';
import {Spin} from 'antd';
import './style/style.scss'
import {RecordTabProps} from '../../tab/RecordTabProps';
import {ReportRecord} from 'fm-shared-data/src/types/db/account/report/ReportRecord';
import * as WebDataRocks from "react-webdatarocks";
import "webdatarocks/webdatarocks.css";
import {HarvestPivotOverviewRecord} from 'fm-shared-data/src/types/db/account/data/HarvestPivotOverviewRecord';
import LoadHarvestPivotOverviewCommand from './LoadHarvestPivotOverviewCommand';
import {getReportFilterParams, ReportFilterParams} from 'fm-shared-data/src/types/db/account/report/ReportFilterParams';
import {Moment} from 'moment';
import {fmFormats, fmGlobal, getOptions, SLICE_HIERARCHY, SLICE_MEASURE} from '../WDRUtils';

interface Props extends RecordTabProps<ReportRecord>, ReportFilterParams<Moment | null> {
    barcode_entity_id: number; // BarcodeEntityId.REPORT (17)
    entity_id: number;

    refreshData?: Date;
}

interface State {
    data?: HarvestPivotOverviewRecord[];
}

export default class HarvestPivotOverviewReport extends React.Component<Props, State> {
    state: State = {};

    ref: React.RefObject<WebDataRocks.Pivot> = React.createRef<WebDataRocks.Pivot>();

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('HarvestPivotOverviewReport: constructor', performance.now());
    }

    componentDidMount() {
        if (this.props.refreshData) {
            this.loadData("componentDidMount");
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        console.log('HarvestPivotOverviewReport: componentDidUpdate', performance.now());
        if (prevProps.refreshData !== this.props.refreshData) {
            this.loadData("componentDidUpdate");
        }
    }

    loadData = (src: string) => {
        console.log('HarvestPivotOverviewReport: loadData:', src);
        const reportFilterParams: ReportFilterParams = getReportFilterParams(this.props);
        console.log("reportFilterParams: ", reportFilterParams);
        this.setState({data: undefined});
        new LoadHarvestPivotOverviewCommand().execute(reportFilterParams).then((data: HarvestPivotOverviewRecord[]) => {
            this.setState({data});
        });
    }

    onReportComplete = () => {
        console.log("onReportComplete");
        // this.setState({});
    }

    onBeforeToolbarCreated = (toolbar: any) => {
        console.log("onBeforetoolbarcreated");
        const tabs: any = toolbar.getTabs();
        toolbar.getTabs = function () {
            delete tabs[0]; // delete Connect tab
            delete tabs[1]; // delete Open tab
            // delete tabs[2]; // delete Save tab
            // tabs.unshift({
            //         id: "wdr-tab-lightblue",
            //         title: "Refresh",
            //         handler: () => {
            //             console.log("Refresh click");
            //         },
            //         icon: this.icons.connect
            //     },
            //     {
            //         id: "wdr-tab-default",
            //         title: "Print",
            //         handler: () => {
            //             console.log("Print click");
            //         },
            //         icon: this.icons.open
            //     },
            //     {
            //         id: "wdr-tab-lightblue",
            //         title: "Test",
            //         handler: () => {
            //             console.log("Test click");
            //         },
            //         icon: this.icons.format
            //     });
            return tabs;
        }
    }
    onExport = (ops?: any) => {
        // ref.exportTo();
        console.log("onExport", ops);
        return {
            footer: "footer!!!",
            header: "header!!!!?!;"
        };
    }

    render() {
        const {refreshData} = this.props;
        const {data} = this.state;
        const isDataLoading: boolean = !data || !refreshData;

        return (
            <Spin spinning={isDataLoading} wrapperClassName={'rich-text-container rich-text-container__scroll maxWidth'}>
                {/*<p>dateRange: {JSON.stringify(this.props.dateRange)}</p>*/}
                {/*<p>employee_id: {this.props.employee_id}</p>*/}
                {/*<p>work_id: {this.props.work_id}</p>*/}
                {/*<p>field_id: {this.props.field_id}</p>*/}
                {!isDataLoading &&
                    <WebDataRocks.Pivot ref={this.ref}
                                        toolbar={true}
                                        width="100%"
                        // exportstart={this.onExport}
                                        reportcomplete={this.onReportComplete}
                                        beforetoolbarcreated={this.onBeforeToolbarCreated}
                                        global={fmGlobal}
                                        report={
                                            {
                                                formats: fmFormats,
                                                dataSource: {
                                                    data: data!
                                                },
                                                slice: {
                                                    rows: [
                                                        SLICE_HIERARCHY.user_name,
                                                        SLICE_HIERARCHY.work_name
                                                    ],
                                                    columns: [
                                                        {
                                                            uniqueName: "Measures"
                                                        },
                                                        SLICE_HIERARCHY.field_name
                                                    ],
                                                    measures: [
                                                        SLICE_MEASURE.value,
                                                        SLICE_MEASURE.value_kg
                                                    ]
                                                },
                                                options: getOptions()
                                            }
                                        }
                    />
                }
            </Spin>);
    }
}
