import moment, {Moment} from 'moment';
import {formatMessage} from '../../locale/IntlProvider';

interface Range {
    [x: string]: [Moment, Moment]
}

export const getDefaultRanges = (): Range => {
    return {
        [formatMessage('common.filter.prev_month')]: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
        // [formatMessage('common.filter.prev_week')]: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        [formatMessage('common.filter.yesterday')]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        [formatMessage('common.filter.today')]: [moment(), moment()],
        // [formatMessage('common.filter.this_week')]: [moment().startOf('week'), moment().endOf('week')],
        [formatMessage('common.filter.this_month')]: [moment().startOf('month'), moment().endOf('month')],
    };
}
