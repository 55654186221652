export interface CatchError {
    errorCode: CatchErrorCode;
    errorMessage: string;
    data?: any
}

export enum CatchErrorCode {
    OPTIMISTIC_LOCK = "OPTIMISTIC_LOCK",
    CONNECTIVITY = "CONNECTIVITY"
}
