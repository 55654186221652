import React from 'react';
import {Button, Col, Comment, Popconfirm, Row, Space, Spin} from 'antd';
import {inject, observer} from "mobx-react";
import TextArea from "antd/lib/input/TextArea";
import {DeleteOutlined} from "@ant-design/icons";
import classNames from "classnames";
import './style/style.scss'
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";
import {CommonCommentDetailed} from "fm-shared-data/src/types/db/common/CommonCommentDetailed";
import {ParentTreeData} from "fm-shared-data/src/types/db/common/ParentTreeData";
import {CommonCommentRecord} from "fm-shared-data/src/types/db/common/CommonCommentRecord";
import {CoreStore} from "../../../pages/core/mobx/CoreStore";
import {DataStore} from "../../../app/mobx/DataStore";
import {formatMessage} from "../../locale/IntlProvider";
import AvatarWidget from "../avatarWidget/AvatarWidget";
import {getEmployeeAvatar, getEmployeeAvatarByData} from "../../../pages/account/employee/EmployeeUtils";
import {CommentStore} from "./mobx/CommentStore";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    commentStore: CommentStore;
};

interface Props extends StoreProps {
    barcode_entity_id: number;
    entity_id: number;
}

interface State {
    mapComment: Map<number, string>;
    visibleComment: Set<number>;
    commandInProgress: boolean;
}

@inject('coreStore', 'dataStore', 'commentStore')
@observer
export default class CommentsWidget extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    state: State = {
        mapComment: new Map<number, string>(),
        visibleComment: new Set<number>(),
        commandInProgress: false
    };

    componentDidMount() {
        const {commentStore, barcode_entity_id, entity_id} = this.props;
        if (!commentStore.getComments(barcode_entity_id, entity_id)) {
            commentStore.loadComments(barcode_entity_id, entity_id);
        }
    }

    toggleReply = (id: number) => () => {
        const {visibleComment} = this.state;
        if (visibleComment.has(id)) {
            visibleComment.delete(id);
        } else {
            visibleComment.add(id);
        }
        const {mapComment} = this.state;
        if (id === 0) {
            mapComment.delete(id);
        }
        this.setState({visibleComment, mapComment});
    };

    handleOnDelete = (id: number) => () => {
        this.setState({commandInProgress: true});
        const {commentStore, barcode_entity_id, entity_id} = this.props;
        commentStore.deleteComment(barcode_entity_id, entity_id, id).then(() => {
            const {mapComment, visibleComment} = this.state;
            mapComment.delete(id);
            visibleComment.delete(id);
            this.setState({commandInProgress: false, mapComment, visibleComment});
        })
    };

    getCommentRenderer = (commentTreeData: ParentTreeData<CommonCommentDetailed>): React.ReactNode => {
        const {record, children} = commentTreeData;
        const {
            id,
            comment,
            comment_by_employee_name,
            comment_by_employee_avatar,
            comment_by_employee_gender,
            created_on
        } = record;
        const actions: React.ReactNode[] = [<Button type={"link"} size={"small"} key={`reply_${id}`}
                                                    onClick={this.toggleReply(id)}>{this.state.visibleComment.has(id) ? 'Cancel' : 'Reply'}</Button>];
        if (this.props.coreStore.isAdminUser) {
            actions.push(<Popconfirm
                key={`delete_${id}`}
                disabled={children.length > 0}
                placement={'right'}
                okType={'primary'}
                title={
                    <>
                        <Row gutter={[10, 20]}>
                            <Col span={24}>
                                {formatMessage('SCRUDActions.delete.comment.confirmation')}
                            </Col>
                            <Col>
                                <AvatarWidget src={getEmployeeAvatarByData(comment_by_employee_gender, comment_by_employee_avatar)}/>
                            </Col>
                            <Col>
                                <Space
                                    className={'ant-comment-content-author-name'}><strong>{comment_by_employee_name}</strong> {MomentUtils.clientDateToLabel(created_on)}
                                </Space>
                            </Col>
                        </Row>
                    </>}
                okText={formatMessage('common.yes')}
                cancelText={formatMessage('common.no')}
                onConfirm={this.handleOnDelete(id)}>
                <DeleteOutlined className={classNames('scrud-container__icon')}/>
            </Popconfirm>);
        }
        return (
            <Comment actions={actions}
                     author={<strong>{comment_by_employee_name}</strong>}
                     datetime={MomentUtils.clientDateToLabel(created_on)}
                     avatar={<AvatarWidget src={getEmployeeAvatarByData(comment_by_employee_gender, comment_by_employee_avatar)}/>}
                     content={
                         <p style={{userSelect: "unset"}}>
                             {comment}
                         </p>
                     }>
                {this.state.visibleComment.has(id) && (this.getAddCommentRenderer(id))}
                {children.length > 0 && (this.getTreeNodes(children))}
            </Comment>
        );
    }

    getTreeNodes = (treeData: ParentTreeData<CommonCommentDetailed>[]): React.ReactNode[] => {
        return treeData.map((tree: ParentTreeData<CommonCommentDetailed>) => {
            return this.getCommentRenderer(tree);
        });
    }

    getAddCommentRenderer = (id: number = 0): React.ReactNode => {
        const {coreStore} = this.props;
        return (
            <Comment avatar={
                <AvatarWidget hidePreview={true} src={getEmployeeAvatar(coreStore.employeeRecord)}/>}
                     content={<>
                         <Row>
                             <Col span={24}>
                                 <TextArea maxLength={5000} showCount={true} rows={3}
                                           value={this.state.mapComment.get(id) || ''}
                                           onChange={this.handleCommentChange(id)}/>
                             </Col>
                             <Col>
                                 <Button htmlType="submit" type="primary"
                                         onClick={this.handleAddComment(id)}
                                         disabled={this.isButtonDisabled(id)}>
                                     Add Comment
                                 </Button>
                             </Col>
                             <Col>
                                 <Button type={"default"} key={`reply_${id}`} onClick={this.toggleReply(id)}>
                                     {id === 0 ? 'Clear' : 'Cancel'}
                                 </Button>
                             </Col>
                         </Row>
                     </>}
            />
        );
    }

    handleCommentChange = (parent_id: number) => (e: any) => {
        const {mapComment} = this.state;
        mapComment.set(parent_id, e.target.value);
        this.setState({mapComment});
    };

    handleAddComment = (id: number) => async () => {
        const comment: string = this.state.mapComment.get(id) || '';
        this.setState({commandInProgress: true});

        const {commentStore, barcode_entity_id, entity_id} = this.props;
        const newEmployeeCommentRecord: Partial<CommonCommentRecord> =
            {
                parent_id: id === 0 ? undefined : id,
                comment
            };

        commentStore.addComment(barcode_entity_id, entity_id, newEmployeeCommentRecord).then(() => {
            const {mapComment, visibleComment} = this.state;
            mapComment.delete(id);
            if (id !== 0) {
                visibleComment.delete(id);
            }
            this.setState({commandInProgress: false, mapComment, visibleComment});
        });
    };

    isButtonDisabled = (parent_id: number): boolean => {
        const comment: string = this.state.mapComment.get(parent_id) || '';
        return comment.trim().length < 2;
    };

    render() {
        const {commentStore, barcode_entity_id, entity_id} = this.props;
        if (!commentStore.getComments(barcode_entity_id, entity_id)) {
            return <Spin/>;
        }

        return (
            <Spin spinning={this.state.commandInProgress} wrapperClassName={'rich-text-container rich-text-container__scroll maxWidth'}>
                {this.getTreeNodes(commentStore.getComments(barcode_entity_id, entity_id) || [])}
                {/*{comments.length > 0 && (<hr/>)}*/}
                {this.getAddCommentRenderer()}
            </Spin>);
    }
}
