import React, {RefObject} from 'react';
import {Col, Divider, Input, message, Row, Select, Spin, Tag, Tooltip} from 'antd';
import {InfoCircleTwoTone, PlusOutlined} from "@ant-design/icons";
import {getTagColor, getTagLabel, hasSystemPrefix, hidePrefix, TAG_PREFIX} from "./TagUtils";
import './style/style.scss';
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import DataProvider from "../../../../../../../../common/widgets/modalForm/type/DataProvider";
import {CustomTagProps} from "rc-select/lib/interface/generator";
import {isEmpty} from "lodash";

const {Option} = Select;

interface Props<T extends DataRecord & TagFields, R extends TreeRecord> {
    selectedEntity: T;
    treeData: TreeData<R>[];
    updateCommand: (value: UpdateDataRecord<TagFields>) => Promise<boolean>;
    isAdminUser: boolean;
    autoCompleteDataProvider: DataProvider<string[]>;
}

interface State {
    isTagUpdating: boolean;
    inputVisible: boolean;
    inputValue: string;
    editInputValue: string;
}

export default class ManageTagWidget<T extends DataRecord & TagFields, R extends TreeRecord> extends React.Component<Props<T, R>, State> {

    saveInputRef: RefObject<Input>;
    saveEditInputRef: RefObject<Input>;

    constructor(props: Props<T, R>) {
        super(props);
        this.state = {
            isTagUpdating: false,
            inputVisible: false,
            inputValue: '',
            editInputValue: ''
        };
        this.saveInputRef = React.createRef();
        this.saveEditInputRef = React.createRef();
    }

    handleOnChangeTag = async (tags: string[]) => {
        this.setState({isTagUpdating: true});
        const {selectedEntity} = this.props;
        const {edited_on} = selectedEntity;
        const updateDataRecord: UpdateDataRecord<TagFields> = {
            id: selectedEntity.id,
            edited_on: edited_on,// && clientDateToUTCDate(edited_on),
            data: {tags}
        };
        this.props.updateCommand(updateDataRecord).then(() => {
            this.setState({isTagUpdating: false});
        });
    };

    handleClose = (removedTag: string) => {
        const tags: string[] = this.props.selectedEntity.tags.filter((tag: string) => tag !== removedTag);
        this.handleOnChangeTag(tags);
    };

    showInput = () => {
        this.setState({inputVisible: true}, () => this.saveInputRef.current?.focus());
    };

    handleInputChange = (value: any) => {
        this.setState({inputValue: value});
    };

    handleOnSelect = (inputValue: any) => {
        let newTag: string = inputValue;
        if (this.isAdded(newTag)) {
            message.warning({content: `Tag "${hidePrefix(newTag)}" already added.`, duration: 6});
            newTag = '';
        } else {
            newTag = this.lookupTag(newTag);
        }

        if (!this.props.isAdminUser && hasSystemPrefix(newTag)) {
            message.error({content: `You can't add a system tag "${hidePrefix(newTag)}"`, duration: 7});
            newTag = '';
        }
        this.setState({inputValue: newTag}, () => this.saveInputRef.current?.blur());
    };

    // handleOnKeyDown = (e: any) => {
    //     if (e.keyCode === 13) {
    //         this.handleOnSelect(e.target.value);
    //     }
    // };

    isAdded(newTag: string): boolean {
        const {selectedEntity} = this.props;
        const {tags} = selectedEntity;
        return tags.some((tag: string) => hidePrefix(tag).toLowerCase() == hidePrefix(newTag).toLowerCase());
    }

    lookupTag(newTag: string): string {
        const {autoCompleteDataProvider} = this.props;
        return autoCompleteDataProvider.get().find((tag: string) => hidePrefix(tag).toLowerCase() == hidePrefix(newTag).toLowerCase())
            || newTag;
    }

    handleOnFilter = (inputValue: string, option: any) => {
        return hidePrefix(option.value).toLowerCase().indexOf(hidePrefix(inputValue).toLowerCase()) !== -1;
    };

    handleInputConfirm = () => {
        const {inputValue} = this.state;
        if (inputValue.trim()) {
            this.handleOnChangeTag([...this.props.selectedEntity.tags, this.validateTag(inputValue.trim())]);
        }
        this.setState({
            inputVisible: false,
            inputValue: '',
        });
    };

    validateTag = (tag: string): string => {
        if (!this.props.isAdminUser && hasSystemPrefix(tag)) {
            return tag.substring(1);
        }
        return tag;
    }

    canEdit = (tag: string): boolean => {
        return this.props.isAdminUser ? true : !hasSystemPrefix(tag);
    };

    getOptions = (): React.ReactNode[] => {
        const {isAdminUser, selectedEntity} = this.props;
        const {tags} = selectedEntity;
        return this.props.autoCompleteDataProvider.get().reduce((prev: React.ReactNode[], tag: string, index: number) => {
            const hasAccess: boolean = isAdminUser || (!isAdminUser && !hasSystemPrefix(tag));
            if (hasAccess && !tags.includes(tag)) {
                prev.push(<Option key={`${index}`} value={tag}>
                    <Tag className={'tag-text'} color={getTagColor(tag)} closable={false}>{getTagLabel(tag, 40)}</Tag>
                </Option>);
            }
            return prev;
        }, []);
    }

    // common
    tagRender = (props: CustomTagProps): React.ReactElement => {
        const {value, closable, onClose} = props;
        return (
            <Tag color={getTagColor(value.toString())}
                 closable={closable}
                 onClose={onClose}
                 className={'tag-text'}>{getTagLabel(value.toString(), 40)}</Tag>
        );
    };

    render() {
        const {inputVisible, inputValue} = this.state;
        const {tags} = this.props.selectedEntity;

        const c1: number = 5;
        const c2: number = 10;
        const c3: number = 9;

        const tagLegend = (
            <div style={{color: 'black', width: 320}}>
                <Divider orientation="left">Tag Prefix Usage</Divider>
                <div style={{paddingTop: 1, paddingLeft: 15, paddingBottom: 5}}>
                    <Row gutter={[8, 16]} style={{color: 'gray'}}>
                        <Col span={c1}>
                            Prefix
                        </Col>
                        <Col span={c2}>
                            Tag
                        </Col>
                        <Col span={c3}>
                            Result
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]}>
                        <Col span={c1}>
                            {TAG_PREFIX.SUCCESS}
                        </Col>
                        <Col span={c2}>
                            <b>{TAG_PREFIX.SUCCESS}</b>success
                        </Col>
                        <Col span={c3}>
                            <Tag closable={false} color={"success"}>success</Tag>
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]}>
                        <Col span={c1}>
                            {TAG_PREFIX.PROCESSING}
                        </Col>
                        <Col span={c2}>
                            <b>{TAG_PREFIX.PROCESSING}</b>processing
                        </Col>
                        <Col span={c3}>
                            <Tag closable={false} color={"processing"}>processing</Tag>
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]}>
                        <Col span={c1}>
                            {TAG_PREFIX.WARNING}
                        </Col>
                        <Col span={c2}>
                            <b>{TAG_PREFIX.WARNING}</b>warning
                        </Col>
                        <Col span={c3}>
                            <Tag closable={false} color={"warning"}>warning</Tag>
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]}>
                        <Col span={c1}>
                            {TAG_PREFIX.ERROR}
                        </Col>
                        <Col span={c2}>
                            <b>{TAG_PREFIX.ERROR}</b>error
                        </Col>
                        <Col span={c3}>
                            <Tag closable={false} color={"error"}>error</Tag>
                        </Col>
                    </Row>
                </div>
            </div>
        );

        return (
            <Spin wrapperClassName={'manage-tags'} spinning={this.state.isTagUpdating}>
                {tags.map((tag: string, index: number) => {
                    const tagElem = (
                        <Tag key={`${tag}_${index}_tag`}
                             color={getTagColor(tag)}
                             closable={this.canEdit(tag)}
                             onClose={() => this.handleClose(tag)}>
                              <span>
                                {getTagLabel(tag)}
                              </span>
                        </Tag>
                    );
                    return tagElem;
                })}
                {inputVisible && (
                    <Select mode="tags"
                            ref={this.saveInputRef}
                            key={'add_tag_input'}
                            size="small"
                            className="input-tag"
                            value={isEmpty(inputValue) ? undefined : inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onSelect={this.handleOnSelect}
                            filterOption={this.handleOnFilter}

                            tagRender={this.tagRender}
                            allowClear={true}
                            notFoundContent={null}
                        //     placeholder="Please select or crate a tag"
                        //     placeholder="+ tag"
                        //     onKeyDown={this.handleOnKeyDown}
                    >
                        {this.getOptions()}
                    </Select>
                )}
                {!inputVisible && (
                    <Tag className="add-tag" key={'add_tag'} onClick={this.showInput}>
                        <PlusOutlined/>&nbsp;&nbsp;New Tag
                        <Tooltip color={'#fffbca'}
                                 overlayClassName={'legendTooltip'}
                                 title={tagLegend}>
                            <InfoCircleTwoTone twoToneColor={'steelblue'}/>
                        </Tooltip>
                    </Tag>
                )}
            </Spin>
        );
    }
}
