import React from 'react';
import {Button, Result, Typography} from 'antd';
import {goToPage} from '../../../routing/AppRouting';
import {XHRErrorURLData} from "fm-shared-data/src/types/api/XHRErrorURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

const {Paragraph} = Typography;

interface Props {
    data: XHRErrorURLData;
}

export default class ErrorPageView extends React.Component<Props> {
    gotoHome = () => {
        goToPage(AppRoutes.ROOT, {}, true);
    };

    render() {
        return (
            <Result status="500"
                    title={`Error on page: ${this.props.data.url}`}
                    subTitle={
                        <Paragraph strong={true} type={'danger'} copyable={true}>{this.props.data.error}</Paragraph>
                    }
                    extra={[
                        <a href={`mailto:support@fieldmaster.com.ua?subject=Error%20from%20web&body=${this.props.data.error}`} key="email">
                            <Button>
                                Email to FieldMaster
                            </Button>
                        </a>,
                        <Button type="primary" key="home" onClick={this.gotoHome}>Back Home</Button>,
                    ]}
            />
        );
    }
}
