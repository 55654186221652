import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {RichTextRecord} from "fm-shared-data/src/types/db/common/RichTextRecord";

export default class LoadRichTextCommand extends Command<RichTextRecord | undefined> {
    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }

    execute(): Promise<RichTextRecord | undefined> {
        return axiosGetInstance().get<RichTextRecord>(`/richtext/get/${this.barcode_entity_id}/${this.entity_id}`)
            .then((response: AxiosResponse<RichTextRecord>) => response.data);
    }
}
