import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import FieldNameOptionsProvider from './FieldNameOptionsProvider';
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import {UniqFieldValidator} from "../../../../../common/widgets/modalForm/validators/sync/UniqFieldValidator";
import {PositiveNumberValidator} from '../../../../../common/widgets/modalForm/validators/sync/PositiveNumberValidator';

export const FIELD_FORM_CONFIG: ModalFormConfig<FieldRecord> = {
    title: 'field.form.title',
    tableName: 'field',
    getNewRecord: () => {
        return {
            name: '',
            area: 0
        } as FieldRecord;
    },
    fieldConfig: [
        {
            label: 'field.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new FieldNameOptionsProvider()
            }
        },
        {
            label: 'field.field.area',
            field_name: 'area',
            renderType: FormFieldRender.NUMBER,
            syncValidators: [new NonEmptyValidator(), new PositiveNumberValidator()],
        },
    ],
    syncValidators: [new UniqFieldValidator()]
};
