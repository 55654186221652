import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {CommonCommentDetailed} from "fm-shared-data/src/types/db/common/CommonCommentDetailed";

export default class LoadCommentsCommand extends Command<CommonCommentDetailed[]> {
    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }
    execute(): Promise<CommonCommentDetailed[]> {
        return axiosGetInstance().get<CommonCommentDetailed[]>(`/comment/get/${this.barcode_entity_id}/${this.entity_id}`)
            .then((response: AxiosResponse<CommonCommentDetailed[]>) => response.data);
    }
}
