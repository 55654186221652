import React from 'react';
import Icofont from 'react-icofont';
import {AxiosInstance, AxiosResponse} from 'axios';
import {Button} from 'antd';
import {axiosGetInstance} from '../../../common/utils/AxiosUtils';
import {goToPage} from '../../../routing/AppRouting';
import {Link} from 'react-router-dom';
import {XHRErrorURLData} from "fm-shared-data/src/types/api/XHRErrorURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";

export default class RootPageController extends React.Component {

    raiseError = () => {
        const params: XHRErrorURLData = {url: 'An URL', error: 'Error on root page!'};
        goToPage(AppRoutes.ERROR_DATA, params);
    };

    //TODO: handle this and test other
    raiseException = () => {
        let arg: string[] | undefined;
        const url: string = arg!.join('');
        const params: XHRErrorURLData = {url: url, error: 'Error on root page!'};
        goToPage(AppRoutes.ERROR_DATA, params);
    };

    callProtectedService = async (): Promise<EmployeeRecord> => {
        const axios: AxiosInstance = axiosGetInstance();
        const response: AxiosResponse<EmployeeRecord> = await axios.post<EmployeeRecord>('/user/logged');
        const employee: EmployeeRecord = response.data;
        return Promise.resolve(employee);
    };

    render() {
        return (
            <div className="user-menu">
                <h1>Главная</h1>
                <Icofont icon="compass" spin={true} size="2"/>
                <br/><br/>
                {/*<p><Button type={'primary'} onClick={this.callProtectedService}>call server: /user/logged</Button></p>*/}
                <p><Button onClick={this.raiseError}>Raise an error</Button></p>
                <p><Link to={'/incorrect/page'}>Go to incorrect page</Link></p>
                {/*<p><Button onClick={this.raiseException}>raise an exception</Button></p>*/}
                {/*{intl().formatMessage({id: 'employee.list.title'})}*/}
            </div>
        );
    }
}
