import React from 'react';
import DataProvider from "../../../../../../../../../common/widgets/modalForm/type/DataProvider";
import {Tooltip, Typography} from 'antd';
import {CheckCircleTwoTone, QuestionCircleTwoTone} from "@ant-design/icons";
import {formatMessage} from "../../../../../../../../../common/locale/IntlProvider";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

const {Text} = Typography;

export default class EmployeeEmailItemRenderer extends DataProvider<React.ReactNode, EmployeeEmailRecord> {
    get(email: EmployeeEmailRecord): React.ReactNode {
        const confirmedEmail: boolean = !!email?.confirmed_on;
        return <Text disabled={!!email.deleted_by} type={email.confirmed_on ? 'success' : 'warning'}>
            {!confirmedEmail && <Tooltip title={formatMessage('employee_email.field.confirmed_no')}><QuestionCircleTwoTone twoToneColor="orange"/></Tooltip>}
            {confirmedEmail && <Tooltip title={formatMessage('employee_email.field.confirmed_yes')}><CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>}
            &nbsp;&nbsp;
            {email.email}
        </Text>;

    }
}
