import {AxiosResponse} from "axios";
import {CommonCommentDetailed} from "fm-shared-data/src/types/db/common/CommonCommentDetailed";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";

export default class DeleteCommentCommand extends Command<CommonCommentDetailed[], number> {

    constructor(private readonly barcode_entity_id: number, private readonly entity_id: number) {
        super();
    }

    execute(comment_id: number): Promise<CommonCommentDetailed[]> {
        return axiosGetInstance().post<CommonCommentDetailed[]>(`/comment/delete/${this.barcode_entity_id}/${this.entity_id}/${comment_id}`)
            .then((response: AxiosResponse<CommonCommentDetailed[]>) => response.data);
    }

}
