import React from 'react';
import {Space} from 'antd';
import LanguageController from '../../../common/widgets/locale/LanguageController';
import './style/coreHeaderView.scss';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {inject, observer} from 'mobx-react'
import {CoreStore} from '../mobx/CoreStore';
import FMLogo from '../../../common/widgets/fmLogo/FMLogo';
import FMLogout from '../../../common/widgets/fmLogout/FMLogout';
import {goToPage} from '../../../routing/AppRouting';
import FMUserProfile from '../../../common/widgets/fmUserProfile/FMUserProfile';
import FMCoreMenu from '../../../common/widgets/fmCoreMenu/FMCoreMenu';

type StoreProps = {
    coreStore: CoreStore;
};

interface Props extends StoreProps {
    test?: string;
}

@inject('coreStore')
@observer
export default class CoreHeaderView extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    handleOnLogout = () => {
        this.props.coreStore.logout();
        goToPage(AppRoutes.LOGOUT);
    };

    render() {

        const {coreStore} = this.props;
        const {isDataLoaded, isAdminUser} = coreStore;

        return (
            <div className="core-menu">
                <FMLogo />
                <div className="core-menu__menu-items">
                    <FMCoreMenu visible={isDataLoaded} isAdminUser={isAdminUser} />
                </div>
                <div className="core-menu__client">
                    <Space>
                        <FMUserProfile visible={isDataLoaded} />
                        <LanguageController/>
                        <FMLogout visible={isDataLoaded} onLogout={this.handleOnLogout} />
                    </Space>
                </div>
            </div>
        );
    }
}
