import {Moment} from 'moment';

export type DateRange = {
    dateStart: Moment | null,
    dateEnd: Moment | null
};

export enum RangeViewType {
    GRID = 'grid',
    CHART = 'chart'
}
