import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";

export default class LoadCalendarCommand extends Command<CalendarRecord[]> {
    execute(): Promise<CalendarRecord[]> {
        return axiosGetInstance().post<CalendarRecord[]>('/calendar/all')
            .then((response: AxiosResponse<CalendarRecord[]>) => response.data);
    }
}
