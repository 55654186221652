import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {TabItemContent} from "./TabItemContent";
import LogDataTab, {LogDataTabProps} from "../../../pages/account/justlog/components/tabs/items/logData/LogDataTab";
import {CustomRendererProps} from "./CustomRenderer";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import NameIconColorRenderer from "../tree/NameIconColorRenderer";

export function getLogTypeTabItems<T extends DataRecord>(logTypeRecordTabs: LogTypeRecord[], barcode_entity_id: BarcodeEntityId, startIndex: number): TabItemContent<T>[] {
    return logTypeRecordTabs.map((logTypeRecord: LogTypeRecord, index: number) => {
        return {
            key: `tab${startIndex + index + 1}`,
            // tab: logTypeRecord.name,
            tab: NameIconColorRenderer(logTypeRecord),
            disabled: false,
            withRecords: true,
            componentType: LogDataTab,
            componentProps: {
                barcode_entity_id,
                log_type: logTypeRecord.id
            } as CustomRendererProps<T, LogDataTabProps>
        }
    });
}