import React, {Component} from 'react';
import {Card, DatePicker, Radio, Space, Spin} from 'antd';
import {inject, observer} from 'mobx-react';
import './style/style.scss';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import SCRUDActions from '../../../../../../../common/widgets/scrudActions/SCRUDActions';
import {Moment} from 'moment';
import {BarChartOutlined, TableOutlined} from '@ant-design/icons';
import {ScannerViewStore, SyncDetailTab} from '../../../../mobx/ScannerViewStore';
import {CustomRendererProps} from '../../../../../../../common/widgets/tab/CustomRenderer';
import {LogDataTabProps} from '../../../../../justlog/components/tabs/items/logData/LogDataTab';
import {ScannerRecord} from 'fm-shared-data/src/types/db/account/scanner/ScannerRecord';
import {RangeValue} from "rc-picker/lib/interface";
import {RangeViewType} from '../../../../../../../common/utils/DateRangeUtils';
import {SyncRecord} from 'fm-shared-data/src/types/db/account/sync/SyncRecord';
import {loadValue, onSplitterResize} from '../../../../../../../common/utils/SplitterUtils';
import SplitterLayout from 'react-splitter-layout';
import {AgGridReact} from 'ag-grid-react';
import {CellDoubleClickedEvent, SelectionChangedEvent} from 'ag-grid-community/dist/lib/events';
import {DataRecord} from 'fm-shared-data/src/types/db/common/DataRecord';
import BaseDetailsTabView from './detailsTab/BaseDetailsTabView';
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Scatter, Tooltip as RETooltip, XAxis, YAxis} from 'recharts';
import {MomentUtils} from 'fm-shared-utils/src/utils/datatime/MomentUtils';
import {ValueFormatterParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {DateUtils} from 'fm-shared-utils/src/utils/datatime/DateUtils';
import {formatMessage} from '../../../../../../../common/locale/IntlProvider';
import {getDefaultRanges} from '../../../../../../../common/widgets/rangePicker/RangePickerUtils';
import {copyToClipboard} from '../../../../../../../common/widgets/clipboard/ClipboardUtils';
import {getRowClassCommon, getRowClassFieldWorklog} from '../../../../../../../common/utils/GridUtils';

const {RangePicker} = DatePicker;

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    scannerViewStore: ScannerViewStore;
};

interface Props extends StoreProps, CustomRendererProps<ScannerRecord, LogDataTabProps> {
}

interface State {
    modalFormVisible: boolean;
    commandInProgress: boolean;
}

@inject('dataStore', 'coreStore', 'scannerViewStore')
@observer
export default class SyncDataTab extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    gridRef = React.createRef<AgGridReact>();

    constructor(props: Props) {
        super(props);
        this.state = {
            modalFormVisible: false,
            commandInProgress: false,
        };
    }

    componentDidMount(): void {
        const syncRecords: SyncRecord[] | undefined = this.props.scannerViewStore.getSyncRecords();
        console.log(
            'SyncDataTab: componentDidMount',
            performance.now(),
            syncRecords?.length,
            this.props,
            this.props.scannerViewStore.device_key
        );
        const {scannerViewStore, record} = this.props;
        // TODO move it to the list!
        scannerViewStore.setDeviceKey(record.device_key);
    }

    componentDidUpdate(): void {
        const syncRecords: SyncRecord[] | undefined = this.props.scannerViewStore.getSyncRecords();
        console.log(
            'SyncDataTab: componentDidUpdate',
            performance.now(),
            syncRecords?.length,
            this.props,
            this.props.scannerViewStore.device_key
        );
    }

    refreshCommand = async () => {
        const {scannerViewStore} = this.props;
        await scannerViewStore.loadSyncRecords(true);
    }

    refreshDetailsCommand = async () => {
        const {scannerViewStore} = this.props;
        const {activeSyncDetailTab} = scannerViewStore;
        scannerViewStore.setActiveSyncDetailTab(activeSyncDetailTab, true);
    }

    handleOnChangeDateRange = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
        // handle No period here
        const {scannerViewStore} = this.props;
        scannerViewStore.setDateRange({
            dateStart: dates && dates[0] ? dates[0].startOf('day') : null,
            dateEnd: dates && dates[1] ? dates[1].endOf('day') : null
        });
        // TODO: fix UX!
        scannerViewStore.setSyncId(0);
    }

    onViewChange = (e: any) => {
        const {scannerViewStore} = this.props;
        scannerViewStore.setView(e.target.value as RangeViewType);
    }

    onTabChange = (key: string) => {
        const {scannerViewStore} = this.props;
        scannerViewStore.setActiveSyncDetailTab(key as SyncDetailTab);
    };

    onSelectionChangeHandler = (event: SelectionChangedEvent) => {
        const {scannerViewStore} = this.props;
        const selectedRows: SyncRecord[] = event.api.getSelectedRows();
        if (selectedRows.length) {
            const [selectedSyncRecord] = selectedRows;
            scannerViewStore.setSyncId(selectedSyncRecord.id);
        } else {
            scannerViewStore.setSyncId(0);
        }
    };

    handleCellDoubleClicked = (event: CellDoubleClickedEvent) => {
        copyToClipboard(event.value);
    };

    render() {
        const {scannerViewStore} = this.props;
        const {
            device_key,
            view,
            dateRange,
            syncId,
            activeSyncDetailTab
        } = scannerViewStore;

        if (!device_key) {
            return <p>Waiting for device_key....</p>
        }
        const syncRecords: SyncRecord[] | undefined = scannerViewStore.getSyncRecords();

        const lineColors: string[] = ["#82ca9d", "#8884d8", "#bd82ca", "#8884d8", "#82ca9d", "#bd82ca", "#8884d8", "#82ca9d", "#bd82ca"]
        const getXValue = (data: SyncRecord) => {
            return MomentUtils.dateToMoment(data.created_on).format('ll');
        };

        return (
            <Card size='small'
                  className="fm-card maxContent"
                  bordered={false}
                  title={
                      <Space>
                          <span>Данные за период: </span>
                          <RangePicker disabled={!syncRecords}
                                       allowEmpty={[true, true]}
                                       defaultValue={[dateRange.dateStart, dateRange.dateEnd]}
                                       ranges={getDefaultRanges()}
                                       onChange={this.handleOnChangeDateRange}
                          />
                          <Radio.Group value={view}
                                       onChange={this.onViewChange}>
                              <Radio.Button value={RangeViewType.GRID}><TableOutlined/></Radio.Button>
                              <Radio.Button value={RangeViewType.CHART}><BarChartOutlined/></Radio.Button>
                          </Radio.Group>
                      </Space>
                  }
                  extra={<SCRUDActions onRefresh={this.refreshCommand} isRefreshEnabled={!!syncRecords}/>}>
                <SplitterLayout
                    customClassName="maxContent"
                    vertical={true}
                    primaryIndex={1}
                    primaryMinSize={200}
                    secondaryMinSize={140}
                    secondaryInitialSize={loadValue('sync-sp-2', 250)}
                    onSecondaryPaneSizeChange={onSplitterResize('sync-sp-2')}>
                    <Spin spinning={!syncRecords} wrapperClassName={"spin-flex maxContent"}>
                        {view === RangeViewType.GRID &&
                            <AgGridReact className={"ag-theme-alpine maxContent fm-ag-grid"}
                                         ref={this.gridRef}
                                         immutableData={true}
                                         suppressLoadingOverlay={true}
                                         suppressNoRowsOverlay={!syncRecords}
                                         suppressCellSelection={true}
                                         rowData={syncRecords}
                                         getRowNodeId={(rec: DataRecord) => rec.id.toString()}
                                         suppressFocusAfterRefresh={true}
                                         rowHeight={30}
                                         defaultColDef={{
                                             sortable: true,
                                             resizable: true,
                                             filter: true,
                                             flex: 1,
                                             // minWidth: 100,
                                         }}
                                         rowSelection="single"
                                         onSelectionChanged={this.onSelectionChangeHandler}
                                         onCellDoubleClicked={this.handleCellDoubleClicked}
                                         gridOptions={
                                             {
                                                 getRowClass: getRowClassCommon
                                             }
                                         }
                                         columnDefs={[
                                             {field: "id", maxWidth: 90, filter: false},
                                             {
                                                 field: "created_on",
                                                 valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                 headerName: formatMessage("common.field.date_time")
                                             },
                                             {field: "device_instance_id", headerName: formatMessage("scanner.sync.device_instance_id")},
                                             {field: "app_version", headerName: formatMessage("scanner.sync.app_version")},
                                             {
                                                 field: "process_start",
                                                 valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                 headerName: formatMessage("scanner.sync.process_start")
                                             },
                                             // {
                                             //     field: "process_end",
                                             //     valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                             //     tooltipField: "process_end"
                                             // },
                                             {
                                                 field: "process_state",
                                                 valueFormatter: (params: ValueFormatterParams) => {
                                                     const rec: SyncRecord = params.data as SyncRecord;
                                                     if (rec.process_start && rec.process_end) {
                                                         return `${rec.process_state} (${DateUtils.diffSecDates(rec.process_end, rec.process_start)})`;
                                                     }
                                                     return params.value;
                                                 },
                                                 headerName: formatMessage("scanner.sync.process_state")
                                             },
                                             {
                                                 field: "process_result",
                                                 headerName: formatMessage("scanner.sync.process_result")
                                             },
                                             {
                                                 field: "created_by_name",
                                                 headerName: formatMessage("employee.field.user_name")
                                             }
                                         ]}
                            >
                            </AgGridReact>
                        }
                        {view === RangeViewType.CHART &&
                            <div className={"fx-body"}>
                                <ResponsiveContainer>
                                    <ComposedChart data={syncRecords}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey={getXValue}/>
                                        <YAxis/>
                                        <RETooltip isAnimationActive={false}/>
                                        <Legend/>
                                        <Line type="monotone"
                                              name="Battery"
                                              dataKey="device_battery"
                                              stroke={lineColors[1]}
                                              activeDot={{r: 5}}/>
                                        <Bar type="monotone"
                                             name="Process state"
                                             dataKey="process_state"
                                             fill="orange"/>
                                        <Scatter type="monotone"
                                                 name="Power connected"
                                                 dataKey="device_power_connected"
                                                 fill="red"/>

                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        }
                    </Spin>
                    <Card size="small"
                          className="fm-card maxContent"
                          bodyStyle={{padding: 0}}
                          bordered={false}
                          activeTabKey={activeSyncDetailTab}
                          tabList={[
                              {key: SyncDetailTab.FIELD, tab: formatMessage("scanner.worklog.field"), disabled: !syncId},
                              {key: SyncDetailTab.HARVEST, tab: formatMessage("scanner.worklog.harvest"), disabled: !syncId},
                              {key: SyncDetailTab.EQUIPMENT, tab: formatMessage("scanner.worklog.equipment"), disabled: !syncId},
                              {key: SyncDetailTab.INCIDENT, tab: formatMessage("scanner.worklog.incident"), disabled: !syncId},
                              {key: SyncDetailTab.FINE_BONUS, tab: formatMessage("scanner.worklog.fineBonus"), disabled: !syncId},
                              {key: SyncDetailTab.EVENT, tab: formatMessage("scanner.worklog.events"), disabled: !syncId},
                          ]}
                          onTabChange={this.onTabChange}
                          tabProps={{size: 'small'}}
                          extra={<SCRUDActions onRefresh={this.refreshDetailsCommand} isRefreshEnabled={!!syncId}/>}>
                        {activeSyncDetailTab === SyncDetailTab.FIELD &&
                            <BaseDetailsTabView records={scannerViewStore.getFieldRecords()}
                                                getRowClass={getRowClassFieldWorklog}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "created_on",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "work_name", headerName: formatMessage("work.field.work_name")},
                                                    {field: "field_name", headerName: formatMessage("field.form.title")},
                                                    {field: "is_start", headerName: formatMessage("common.field.is_start")},
                                                    {field: "value_mins", headerName: formatMessage("common.field.value_mins")},
                                                    {field: "pair_row_id", headerName: formatMessage("common.field.pair_row_id")},
                                                    {field: "process_state", headerName: formatMessage("scanner.sync.process_state")},
                                                ]}/>}
                        {activeSyncDetailTab === SyncDetailTab.HARVEST &&
                            <BaseDetailsTabView records={scannerViewStore.getHarvestRecords()}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "created_on",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "work_name", headerName: formatMessage("work.field.work_name")},
                                                    {field: "field_name", headerName: formatMessage("field.form.title")},
                                                    {field: "value", headerName: formatMessage("common.field.volume")},
                                                    {field: "is_correction", headerName: formatMessage("common.field.is_correction")},
                                                    {field: "process_state"},
                                                ]}/>
                        }
                        {activeSyncDetailTab === SyncDetailTab.EQUIPMENT &&
                            <BaseDetailsTabView records={scannerViewStore.getEquipmentRecords()}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "created_on",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "work_name", headerName: formatMessage("work.field.work_name")},
                                                    {field: "field_name", headerName: formatMessage("field.form.title")},
                                                    {field: "equipment_names", headerName: formatMessage("equipment.card.title")},
                                                    {field: "is_start", headerName: formatMessage("common.field.is_start")}
                                                ]}/>
                        }
                        {activeSyncDetailTab === SyncDetailTab.INCIDENT &&
                            <BaseDetailsTabView records={scannerViewStore.getIncidentRecords()}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "created_on",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "work_name", headerName: formatMessage("work.field.work_name")},
                                                    {field: "field_name", headerName: formatMessage("field.form.title")},
                                                    {field: "equipment_name", headerName: formatMessage("equipment.card.title")},
                                                    {field: "comment", headerName: formatMessage("file_record.field.comment")}
                                                ]}/>
                        }
                        {activeSyncDetailTab === SyncDetailTab.FINE_BONUS &&
                            <BaseDetailsTabView records={scannerViewStore.getFineBonusRecords()}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "created_on",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "value", headerName: formatMessage("common.field.value")},
                                                    {field: "comment", headerName: formatMessage("file_record.field.comment")}
                                                ]}/>
                        }
                        {activeSyncDetailTab === SyncDetailTab.EVENT &&
                            <BaseDetailsTabView records={scannerViewStore.getEventRecords()}
                                                columnDefs={[
                                                    {field: "row_id", maxWidth: 90, filter: false},
                                                    {field: "id", maxWidth: 90, filter: false},
                                                    {
                                                        field: "event_date",
                                                        valueFormatter: (params: ValueFormatterParams) => MomentUtils.clientDateToLabel(params.value, false),
                                                        headerName: formatMessage("common.field.date_time")
                                                    },
                                                    {field: "user_name", headerName: formatMessage("employee.field.worker_name")},
                                                    {field: "work_name", headerName: formatMessage("work.field.work_name")},
                                                    {field: "field_name", headerName: formatMessage("field.form.title")},
                                                    {field: "equipment_name", headerName: formatMessage("equipment.card.title")},
                                                    {field: "comment", headerName: formatMessage("file_record.field.comment")}
                                                ]}/>
                        }
                    </Card>
                </SplitterLayout>
            </Card>
        );
    }
}
