import Axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {goToPage} from '../../routing/AppRouting';
import {LocalStorage, LocalStorageKey} from "./LocalStorageUtils";
import {deleteBrowserAuthCookie, setBrowserAuthCookie} from "./ClientCookieUtils";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {SigninURLData} from "fm-shared-data/src/types/auth/SigninURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

export function axiosUpdateAuthToken(accessTokenValue: string) {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${accessTokenValue}`;
    setBrowserAuthCookie(accessTokenValue);
}

export function axiosRemoveAuthToken() {
    delete Axios.defaults.headers.common['Authorization'];
}

const requestInterceptorOnFulfilled = (request: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    console.info('requestInterceptorOnFulfilled', request);
    return request;
    // return Promise.resolve(request);
};

const responseInterceptorOnFulfilled = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    console.info('responseInterceptorOnFulfilled', response);
    return Promise.resolve(response);
};

export function isSigninURL(url: string): boolean {
    // TODO: improve this
    return url.includes('#/signin') || url.includes('#/logout');
}

const responseInterceptorOnRejected = (error: AxiosError<AxiosErrorResponseData>): any => {
    const currentUrl: string = window.location.href;
    if (!isSigninURL(currentUrl)) {
        if (error.response?.status === 401) {
            const params: SigninURLData = {
                email: '',
                redirectURL: currentUrl
            };
            goToPage(AppRoutes.SIGNIN_DATA, params);
            // } else if (error.response?.status || 0 >= 500) {
            //     const params: XHRErrorURLData = {
            //         // TODO: improve this
            //         error: `${error.config.url} ${JSON.stringify(error.response?.data)}`,
            //         url: currentUrl
            //     };
            //     goToPage(AppPage.ERROR, params);
        }
    }
    return Promise.reject(error.response?.data || error);
};

// const reviverDate = (key: string, value: any): any => {
//     if (value && DB_TIMESTAMP_FIELDS.includes(key)) {
//         return new Date(value);
//     }
//     return value;
// }

export function axiosInit() {
    console.info('axiosInit');
    const applicationJson = 'application/json';
    Axios.defaults.headers.post['Content-Type'] = applicationJson;
    const accessTokenValue: string | null = LocalStorage.getItem(LocalStorageKey.ACCESS_TOKEN);

    // set global deserializer of all app/json's responses:
    // Axios.defaults.transformResponse = (data: any, headers?: any): any => {
    //     if (headers?.['content-type']?.startsWith(applicationJson)) {
    //         console.info('transformResponse: application/json', typeof data, data);
    //         try {
    //             return JSON.parse(data, reviverDate);
    //         } catch (e: any) {
    //             console.error('Error on Axios transformResponse:');
    //             console.error(e);
    //             return null;
    //         }
    //     }
    //     return data;
    // };

    if (accessTokenValue) {
        axiosUpdateAuthToken(accessTokenValue);
    }
    // Axios.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);
}

export function axiosLogout() {
    console.info('axiosLogout');
    axiosRemoveAuthToken()
    deleteBrowserAuthCookie();
    LocalStorage.removeItem(LocalStorageKey.ACCESS_TOKEN)
}

export function axiosGetInstance(): AxiosInstance {
    const axios: AxiosInstance = Axios.create();

    axios.interceptors.request.use(requestInterceptorOnFulfilled);
    axios.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);
    return axios;
}

