import React from 'react';
import {Col, Divider, Row} from 'antd';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {CalendarViewStore} from '../../../../mobx/CalendarViewStore';
import BarCode from '../../../../../../../common/widgets/barcode/BarCode';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import CEDAvatarsController from '../../../../../../../common/widgets/CEDAvatars/CEDAvatarsController';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import {fmBarcode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import ManageTreeWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTreeWidget";
import ManageTagWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTagWidget";
import {getAvatar} from "../../../../../employee/EmployeeUtils";
import ManageAvatarWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageAvatarWidget";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";
import UpdateTreeIdsCommand from "../../../../../commands/tree/UpdateTreeIdsCommand";
import UpdateTagsCommand from "../../../../../commands/UpdateTagsCommand";
import UpdateAvatarCommand from "../../../../../commands/UpdateAvatarCommand";
import CalendarTagAutoCompleteProvider from "./CalendarTagAutoCompleteProvider";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    calendarViewStore: CalendarViewStore;
};

type Props = StoreProps;

@inject('calendarViewStore', 'dataStore', 'coreStore')
@observer
export default class CalendarTabGeneral extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    updateTree = (updateDataRecord: UpdateDataRecord<TreeFields>): Promise<boolean> => {
        return new UpdateTreeIdsCommand<CalendarRecord>('calendar', updateDataRecord).execute().then((calendarRecord: CalendarRecord) => {
            const {dataStore, calendarViewStore} = this.props;
            dataStore.updateCalendar(calendarRecord);
            calendarViewStore.applyFiltersToView(updateDataRecord.id);
            return Promise.resolve(true);
        });
    };

    updateTag = (updateDataRecord: UpdateDataRecord<TagFields>): Promise<boolean> => {
        return new UpdateTagsCommand<CalendarRecord>('calendar', updateDataRecord).execute().then((calendarRecord: CalendarRecord) => {
            const {dataStore, calendarViewStore} = this.props;
            dataStore.updateCalendar(calendarRecord);
            return calendarViewStore.refreshCalendarTags(updateDataRecord.id).then(() => {
                return Promise.resolve(true);
            });
        });
    };

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        console.info(updateDataRecord);
        return new UpdateAvatarCommand<CalendarRecord>('calendar', updateDataRecord).execute().then((calendarRecord: CalendarRecord) => {
            const {dataStore, calendarViewStore} = this.props;
            dataStore.updateCalendar(calendarRecord);
            calendarViewStore.applyFiltersToView(calendarRecord.id);
            return Promise.resolve(true);
        });
    };

    render() {
        const {calendarViewStore, coreStore} = this.props;
        const {calendarTreeData, selectedEntity} = calendarViewStore;
        const calendarBarcodeData: string = fmBarcode(coreStore.user.schema, 3, selectedEntity.id);

        return (
            <>
                <Row gutter={[10, 30]}>
                    <Col span={16}>
                        <Row gutter={[10, 30]}>
                            <Col span={24}>
                                <Divider orientation="left">Structure</Divider>
                                <ManageTreeWidget selectedEntity={selectedEntity} treeData={calendarTreeData} updateCommand={this.updateTree}/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">Tags</Divider>
                                <ManageTagWidget selectedEntity={selectedEntity} treeData={calendarTreeData} updateCommand={this.updateTag}
                                                 isAdminUser={coreStore.isAdminUser}
                                                 autoCompleteDataProvider={new CalendarTagAutoCompleteProvider()}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={7}>
                        <ManageAvatarWidget selectedEntity={selectedEntity} updateCommand={this.updateAvatar}
                                            emptyAvatar={getAvatar(selectedEntity)}/>
                        <BarCode data={calendarBarcodeData} fontSizePx={52}/>
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <CEDAvatarsController table={'calendar'} record={selectedEntity}/>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
