import moment, {Moment} from 'moment';
import {StringDate} from "fm-shared-data/src/types/db/core/StringDate";
import {DateUtils} from "./DateUtils";

export class MomentUtils {
    public static parseDate(stringDate: string): Moment {
        return moment(stringDate);
    }

    public static dateToMoment(date: StringDate): Moment {
        return moment(DateUtils.toDate(date));
    }

    public static momentToDate(moment: Moment): Date {
        return moment.toDate();
    }

    // TODO: ref fullFormat to format enums
    public static clientDateToLabel(clientDate: StringDate, fullFormat: boolean = true): string {
        // console.info(moment.locale());
        return clientDate ? this.dateToMoment(clientDate).format(fullFormat ? 'LLLL' : 'lll') : ''; // momentjs.com
    }
    
}
