import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {inject, observer} from 'mobx-react';
import {URLRouteProps} from '../../routing/URLRouteProps';
import HomeDashboardController from './homeDashboard/HomeDashboardController';
import EmployeePageController from './employee/EmployeePageController';
import FieldController from './field/FieldPageController';
import EquipmentController from "./equipment/EquipmentController";
import WorkController from "./work/WorkController";
import ReportsController from './reports/ReportsController';
import {ReportsURLData} from './reports/ReportsURLData';
import {genURLToPage} from '../../routing/AppRouting';
import './style/accountRouter.scss';
import CalendarController from "./calendar/CalendarController";
import LogTypeController from "./justlog/LogTypeController";
import FMLoader from "../../common/widgets/fmLoader/FMLoader";
import {DataStore} from "../../app/mobx/DataStore";
import ScannerController from './scanner/ScannerController';
import ReportController from './report/ReportController';

type StoreProps = {
    dataStore: DataStore;
};

type Props = URLRouteProps & StoreProps;

@inject('dataStore')
@observer
export default class AccountRouter extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    componentDidMount(): void {
        this.props.dataStore.loadBaseData();
    }

    render() {
        if (!this.props.dataStore.isBaseDataLoaded) {
            return <FMLoader text='Loading account data....'/>;
        }

        return (
            <>
                {/*<FMHeaderView/>*/}
                <div className="fm-body">
                    <Switch>
                        <Route path={AppRoutes.FM_HOME} exact={true} component={HomeDashboardController}/>

                        <Route path={[AppRoutes.FM_EMPLOYEE, AppRoutes.FM_EMPLOYEE_DATA]} exact={true} component={EmployeePageController}/>
                        <Route path={[AppRoutes.FM_FIELD, AppRoutes.FM_FIELD_DATA]} exact={true} component={FieldController}/>
                        <Route path={[AppRoutes.FM_EQUIPMENT, AppRoutes.FM_EQUIPMENT_DATA]} exact={true} component={EquipmentController}/>
                        <Route path={[AppRoutes.FM_WORK, AppRoutes.FM_WORK_DATA]} exact={true} component={WorkController}/>
                        <Route path={[AppRoutes.FM_LOG_TYPE, AppRoutes.FM_LOG_TYPE_DATA]} exact={true} component={LogTypeController}/>
                        <Route path={[AppRoutes.FM_CALENDAR, AppRoutes.FM_CALENDAR_DATA]} exact={true} component={CalendarController}/>
                        <Route path={[AppRoutes.FM_SCANNER, AppRoutes.FM_SCANNER_DATA]} exact={true} component={ScannerController}/>
                        <Route path={[AppRoutes.FM_REPORT, AppRoutes.FM_REPORT_DATA]} exact={true} component={ReportController}/>

                        <Route path={AppRoutes.FM_REPORTS} exact={true} render={(props: URLRouteProps) => {
                            const url: string = genURLToPage(AppRoutes.FM_REPORTS_DATA, {reportId: 1} as ReportsURLData)
                            return <Redirect to={url}/>;
                        }}/>
                        <Route path={AppRoutes.FM_REPORTS_DATA} exact={true} component={ReportsController}/>

                        <Redirect to={AppRoutes.PAGE404}/>
                    </Switch>
                </div>
            </>
        );
    }
}
