import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";

export default class AddEmployeeCommand extends UpdateDataCommand<EmployeeRecord> {

    constructor(private readonly upsertValue: UpsertValue<EmployeeRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<EmployeeRecord>> {
        return axiosGetInstance().post<UpsertResponse<EmployeeRecord>>(`/employee/add`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<EmployeeRecord>>) => response.data);
    }

}
