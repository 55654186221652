import React from 'react';
import {Button, Form, Typography} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import {intl} from '../../../../common/locale/IntlProvider';
import {ReCaptchaButton} from '../../../../common/widgets/reCaptcha/ReCaptchaButton';
import LinkTo from '../../../../routing/LinkTo';
import {getHashedPassword} from '../../../../common/crypto/LoginUtils';
import Axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {secureBody} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {LockOutlined} from '@ant-design/icons/lib';
import {DEFAULT_LOCALE} from "fm-shared-locale/src/common/SupportedLocales";
import {LocalStorage, LocalStorageKey} from "../../../../common/utils/LocalStorageUtils";
import {ResponseError} from "fm-shared-data/src/types/api/ResponseError";
import {SetPasswordURLData} from "fm-shared-data/src/types/auth/SetPasswordURLData";
import {SetPasswordBody} from "fm-shared-data/src/types/auth/SetPasswordBody";
import {JWTToken} from "fm-shared-data/src/types/auth/JWTToken";
import {AxiosErrorResponseData} from "fm-shared-data/src/types/api/AxiosErrorResponseData";
import {ResponseErrorCode} from "fm-shared-data/src/types/api/ResponseErrorCode";
import {SigninURLData} from "fm-shared-data/src/types/auth/SigninURLData";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

const {Paragraph} = Typography;

interface State {
    password: string;
    passwordConfirmation: string;
    lockButton: boolean;
    lockForm: boolean;
    responseError: ResponseError | undefined;
    okMessage: string;
}

interface StateProps {
    data: SetPasswordURLData;
}

type Props = StateProps;

export default class SetPasswordPageView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirmation: '',
            lockButton: false,
            lockForm: false,
            responseError: undefined,
            okMessage: '',
        };
    }

    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({password: value});
    };

    onChangeConfirmationPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState({passwordConfirmation: value});
    };

    handleStartLogin = () => {
        this.setState({lockButton: true, responseError: undefined, okMessage: ''});
    };

    handleLogin = async () => {
        const {email, schema, isInitialSetup} = this.props.data;
        const {password} = this.state;
        const hashedPassword: string = getHashedPassword(password, email);
        const locale: string = LocalStorage.getItem(LocalStorageKey.LOCALE) || DEFAULT_LOCALE.code;
        const axios: AxiosInstance = Axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const body: SetPasswordBody = {
            email,
            schema,
            isInitialSetup,
            pass: hashedPassword,
            actionIndex: this.props.data.actionIndex,
            locale
        };

        await axios.post<JWTToken>('/auth/setpass', secureBody(body))
            .then((response: AxiosResponse<JWTToken>) => {
                this.setState({lockButton: false, lockForm: true, okMessage: `You are all set!`});
                return response;
            })
            .catch((error: AxiosError<AxiosErrorResponseData>) => {
                const errorCode: string = error.response?.data.message || error.message;
                const responseError: ResponseError = {
                    errorCode: errorCode as ResponseErrorCode,
                    errorMessage: this.isPasswordAlreadySet(errorCode)
                        ? `You already use this link.`
                        : `Cannot set up new password (${errorCode}). Please contact support@fieldmaster.com.ua`
                };
                this.onErrorLogin(responseError);
                return {} as AxiosResponse<JWTToken>;
            });
    };

    handleReCaptchaInvalid = () => {
        this.onErrorLogin({
            errorCode: ResponseErrorCode.RECAPTCHA_ERROR,
            errorMessage: 'ReCaptcha validation error'
        });
    };

    onErrorLogin = (responseError: ResponseError) => {
        console.error('onErrorLogin ', JSON.stringify(responseError));
        this.setState({lockButton: false, responseError});
    };

    isFormValid = (): boolean => {
        const {password, passwordConfirmation} = this.state;
        return !!this.props.data?.email &&
            password.trim().length > 0 &&
            password.length >= 6 &&
            password.localeCompare(passwordConfirmation) === 0;
    };

    isPasswordAlreadySet = (errorCode: ResponseErrorCode | string | undefined): boolean => {
        return [ResponseErrorCode.SET_PASSWORD_ALREADY_SET, ResponseErrorCode.SET_PASSWORD_LINK_EXPIRED_OR_USED].includes(errorCode as ResponseErrorCode);
    };

    render() {
        const {email} = this.props.data;
        const urlParams: SigninURLData = {
            email
        };
        return (
            <div>
                <h1>{intl().formatMessage({id: 'setpassword.title'})}</h1>
                <hr/>
                <Form layout={'vertical'} labelAlign={'left'}>
                    <FormItem label="Email">
                        <Input
                            name="email"
                            type="email"
                            value={email}
                            disabled={true}
                        />
                    </FormItem>
                    <FormItem label="Password">
                        <Input
                            name="password"
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            disabled={this.state.lockButton || this.state.lockForm}
                            onChange={this.onChangePassword}
                        />
                    </FormItem>
                    <FormItem label="Repeat Password">
                        <Input
                            name="password"
                            type="password"
                            value={this.state.passwordConfirmation}
                            placeholder="Repeat password"
                            disabled={this.state.lockButton || this.state.lockForm}
                            onChange={this.onChangeConfirmationPassword}
                        />
                    </FormItem>
                    <FormItem>
                        <ReCaptchaButton
                            disabled={!this.isFormValid() || this.state.lockForm}
                            label="Set password"
                            icon={<LockOutlined />}
                            action="SetPassword"
                            loading={this.state.lockButton}
                            onClick={this.handleStartLogin}
                            onValid={this.handleLogin}
                            onInvalid={this.handleReCaptchaInvalid}
                        />
                    </FormItem>
                </Form>
                {this.state.responseError && (
                    <Paragraph copyable={true} type={'danger'}>{this.state.responseError.errorMessage}</Paragraph>
                )}
                {this.isPasswordAlreadySet(this.state.responseError?.errorCode) && (
                    <Paragraph type={'danger'}>
                        Please request new one via <LinkTo to={AppRoutes.RESET_PASSWORD_DATA} params={urlParams}>Reset password</LinkTo>.
                    </Paragraph>
                )}
                {this.state.okMessage && (
                    <>
                        <Paragraph strong={true}>{this.state.okMessage}</Paragraph>
                        <Button type={'primary'}>
                            <LinkTo to={AppRoutes.SIGNIN_DATA} params={urlParams}>Log in to fieldmaster.com.ua</LinkTo>
                        </Button>
                    </>
                )}
            </div>
        );
    }
}
