import React from 'react';
import './style/languageView.scss';
import {Select} from 'antd';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {initialLocale} from '../../locale/IntlProvider';
import {DEFAULT_LOCALE, SUPPORTED_LOCALES} from "fm-shared-locale/src/common/SupportedLocales";
import {LanguageRecord} from "fm-shared-locale/src/common/LanguageRecord";

const Option = Select.Option;

type StoreProps = {
    appStore: AppStore;
};

interface Props extends StoreProps {
    test?: string;
}

@inject('appStore')
@observer
export default class LanguageController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    componentDidMount(): void {
        this.props.appStore.loadLocale(initialLocale);
    }

    onChangeLocale = (newLocale: string) => {
        this.props.appStore.loadLocale(newLocale);
    };

    render() {
        const {appStore} = this.props;
        const {isLocaleLoaded, locale} = appStore;
        return (
            <div className={'lang-container'}>
                <Select
                    defaultValue={DEFAULT_LOCALE.code}
                    style={{width: 60}}
                    onChange={this.onChangeLocale}
                    value={locale}
                    loading={!isLocaleLoaded}
                    disabled={!isLocaleLoaded}
                >
                    {SUPPORTED_LOCALES.map((language: LanguageRecord) => {
                        return (
                            <Option value={language.code} key={language.code}>
                                {language.shortName}
                            </Option>
                        );
                    })}
                </Select>
            </div>
        );
    }
}
