import DataProvider from '../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {stores} from '../../../../../../../../app/mobx/Stores';
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {DictionaryConfig} from "../../../../../../../../common/widgets/dictionary/DictionaryConfig";
import EmployeeEmailItemRenderer from "../../contacts/email/form/EmployeeEmailItemRenderer";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import React from "react";

class EmployeeEmailsProvider extends DataProvider<DictionaryRawRecord<EmployeeEmailRecord>[]> {
    get(): DictionaryRawRecord<EmployeeEmailRecord>[] {
        const {dataStore, employeeViewStore} = stores;
        return dataStore.employeeEmail
            .filter((record: EmployeeEmailRecord) => !!record.confirmed_on && record.employee_id === employeeViewStore.selectedEntity.id)
            .map((record: EmployeeEmailRecord) => {
                return {
                    id: record.email,
                    name: record.email,
                    record
                };
            });
    }
}

const EmployeeEmailLabelRendererFunction = (dictionaryRawRecord: DictionaryRawRecord<EmployeeEmailRecord>): React.ReactNode => {
    return new EmployeeEmailItemRenderer().get(dictionaryRawRecord.record!);
};

export const DICTIONARY_DYNAMIC_EMPLOYEE_CONFIRMED_EMAILS: DictionaryConfig<EmployeeEmailRecord> = {
    dictionaryKey: 'employee_confirmed_emails',
    rawRecordsProvider: new EmployeeEmailsProvider(),
    labelRendererFunction: EmployeeEmailLabelRendererFunction
};

