import React from 'react';
import {inject, observer} from 'mobx-react'
import {AppStore} from '../../../app/mobx/AppStore';
import {EquipmentViewStore} from './mobx/EquipmentViewStore';
import {URLRouteProps} from '../../../routing/URLRouteProps';
import EquipmentPageView from "./EquipmentPageView";
import {EquipmentURLData} from "./EquipmentURLData";
import isEmpty from 'lodash/isEmpty';
import {goToPage} from '../../../routing/AppRouting';
import {decryptObject} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";

type StoreProps = {
    appStore: AppStore;
    equipmentViewStore: EquipmentViewStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'equipmentViewStore')
@observer
export default class EquipmentController extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('FieldPageController: constructor', performance.now());
    }

    async componentDidMount(): Promise<any> {
        console.log('FieldPageController: componentDidMount', performance.now());
        const {equipmentViewStore} = this.props;
        if (!equipmentViewStore.isDataLoaded) {
            await equipmentViewStore.loadData();
        } else {
            const urlData: EquipmentURLData | undefined = decryptObject<EquipmentURLData>(this.props.match.params.data);
            if (!isEmpty(urlData?.equipmentBarCode)) {
                equipmentViewStore.universalFilter = urlData!.equipmentBarCode!;
                equipmentViewStore.clearFilters();
                goToPage(AppRoutes.FM_FIELD);
            } else if (equipmentViewStore.isViewReady) {
                // back to this page with prev state w/o update
                // employeeViewStore.initFilters(true); // or  init filters again
            } else {
                equipmentViewStore.applyFiltersToView();
            }
        }
    }

    componentDidUpdate(): void {
        const {equipmentViewStore} = this.props;
        const urlData: EquipmentURLData | undefined = decryptObject<EquipmentURLData>(this.props.match.params.data);
        console.log('FieldPageController: componentDidUpdate. urlData is ', urlData, ', isViewReady:', equipmentViewStore.isViewReady, performance.now());
        if (!isEmpty(urlData?.equipmentBarCode)) {
            equipmentViewStore.universalFilter = urlData!.equipmentBarCode!;
            equipmentViewStore.clearFilters();
            goToPage(AppRoutes.FM_FIELD);
        } else if (isEmpty(urlData?.equipmentBarCode) && !equipmentViewStore.isViewReady) {
            equipmentViewStore.applyFiltersToView();
        }
    }

    componentWillUnmount(): void {
        // const {employeeViewStore} = this.props;
        console.log(
            'FieldPageController: componentWillUnmount',
            performance.now(),
        );
        // employeeViewStore.isViewReady = undefined; // force update view on back
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('FieldPageController: componentDidCatch', performance.now());
    }

    render() {
        const {equipmentViewStore} = this.props;
        const {isViewReady, isDataLoaded} = equipmentViewStore;

        if (!isDataLoaded) {
            return <p>Load equipment data....</p>;
        }
        if (!isViewReady) {
            return <p>Calculating equipment view....</p>;
        }

        return (
            <EquipmentPageView/>
        );
    }
}
