import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../../../common/types/Command";
import {LogDataSearchParams} from "fm-shared-data/src/types/db/account/justlog/LogDataSearchParams";
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";
import {Moment} from "moment";

export default class LoadLogDataCommand extends Command<LogDataRecord[]> {
    constructor(private readonly searchParams: LogDataSearchParams<Moment | null>) {
        super();
    }

    execute(): Promise<LogDataRecord[]> {
        return axiosGetInstance().post<LogDataRecord[]>('/justlog/data/search', this.searchParams)
            .then((response: AxiosResponse<LogDataRecord[]>) => response.data);
    }
}
