import {StringDate} from "../../core/StringDate";
import {Moment} from 'moment';
import {MomentUtils} from "fm-shared-utils/src/utils/datatime/MomentUtils";

export interface ReportFilterParams<D = StringDate | null | undefined> {
    start_date: D;
    end_date: D;

    employee_id?: number[];
    work_id?: number[];
    field_id?: number[];
    equipment_id?: number[];
}

// export function getReportFilterParams(dateStart: Moment | null,
//                                       dateEnd: Moment | null,
//                                       employee_id: string | undefined,
//                                       work_id: string | undefined,
//                                       field_id: string | undefined,
//                                       equipment_id: string | undefined)
export function getReportFilterParams<P extends ReportFilterParams<Moment | null>>(props: P): ReportFilterParams {
    const {start_date, end_date, employee_id, work_id, field_id, equipment_id, } = props;
    return {
        start_date: start_date ? MomentUtils.momentToDate(start_date) : null,    // TODO: control TZ of search params here
        end_date: end_date ? MomentUtils.momentToDate(end_date) : null,           // TODO: control TZ of search params here
        employee_id,
        work_id,
        field_id,
        equipment_id
    } as ReportFilterParams;
}
