import React, {MouseEvent} from 'react';
import {Checkbox, List} from 'antd';
import './style/checkedList.scss';
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {NoData} from '../noData/NoData';

interface Props<T> {
    data: T[];
    selectedRecords: T[];
    onEntitySelect?: (records: T[]) => void;
    renderItem: (item: T) => React.ReactNode;
}

export default class CheckedList<T extends DataRecord> extends React.Component<Props<T>> {
    onEntitySelect = (record: T) => (e: MouseEvent) => {
        const htmlInputElement: HTMLInputElement = e.target as HTMLInputElement;
        if (htmlInputElement.type === 'checkbox') {
            if (htmlInputElement.checked) {
                this.props.onEntitySelect!(this.props.selectedRecords.concat(record));
            } else {
                this.props.onEntitySelect!(
                    this.props.selectedRecords.filter((rec: T) => record.id !== rec.id) ||
                    [],
                );
            }
            console.info(`${record.id} checked: ${htmlInputElement.checked}`);
        } else {
            if (this.props.selectedRecords.length < 2) {
                this.props.onEntitySelect!([record]);
            }
        }
    };

    render() {
        return (
            <div className="entity-list">
                <List locale={{emptyText: <NoData/>}}
                      dataSource={this.props.data}
                      renderItem={item => (
                          <List.Item key={item.id}
                                     className="entity-list__item"
                                     onClick={this.onEntitySelect(item)}>
                              <Checkbox checked={this.props.selectedRecords.some((rec: T) => item.id === rec.id)}/>
                              {this.props.renderItem(item)}
                          </List.Item>
                      )}/>
            </div>
        );
    }
}
