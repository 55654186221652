import {AxiosResponse} from "axios";
import Command from "../../../../../common/types/Command";
import {axiosGetInstance} from "../../../../../common/utils/AxiosUtils";
import {EmployeeEmailConfirmationURLData} from "fm-shared-data/src/types/api/web/EmployeeEmailConfirmationURLData";
import {delay} from "fm-shared-utils/src/utils/TestUtils";

export default class ConfirmEmployeeEmailCommand extends Command<boolean, EmployeeEmailConfirmationURLData> {
    async execute(record: EmployeeEmailConfirmationURLData): Promise<boolean> {
        await delay(2000);
        return axiosGetInstance().post<boolean>(`/employee/email/confirm`, record)
            .then((response: AxiosResponse<boolean>) => response.data);
    }
}
