import DataProvider from '../../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {stores} from '../../../../../../../../../app/mobx/Stores';
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {DictionaryConfig} from "../../../../../../../../../common/widgets/dictionary/DictionaryConfig";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";

class DynamicDictionaryEmployeeEmailDomains extends DataProvider<DictionaryRawRecord[]> {
    get(): DictionaryRawRecord[] {
        const {dataStore} = stores;
        const uniqTypes: Set<string> = new Set<string>();
        uniqTypes.add('gmail.com');
        dataStore.employeeEmail.forEach((item: EmployeeEmailRecord) => {
            const emailDomain: string = item.email.split('@')[1];
            uniqTypes.add(emailDomain.toLowerCase());
        });
        return Array.from(uniqTypes).map((domain: string) => {
                return {
                    id: domain,
                    name: domain
                };
            }
        );
    }
}

export const DICTIONARY_DYNAMIC_EMPLOYEE_EMAIL_DOMAINS: DictionaryConfig = {
    dictionaryKey: 'employee_email_domains',
    rawRecordsProvider: new DynamicDictionaryEmployeeEmailDomains(),
};

