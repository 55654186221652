import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";

export default class DeleteCalendarCommand extends Command<CalendarRecord[], CalendarRecord> {
    execute(CalendarRecord: CalendarRecord): Promise<CalendarRecord[]> {
        return axiosGetInstance().post<CalendarRecord[]>(`/calendar/delete`, CalendarRecord)
            .then((response: AxiosResponse<CalendarRecord[]>) => response.data);
    }
}
