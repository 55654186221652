import PredefinedFilterItem from '../../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import isEmpty from 'lodash/isEmpty';
import {LocalStorage, LocalStorageKey} from "../../../../../common/utils/LocalStorageUtils";
import {DateUtils} from "fm-shared-utils/src/utils/datatime/DateUtils";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import {FieldMapMetadata} from "../../../../../common/widgets/mapField/FieldMapMetadata";

export const PREDEFINED_FIELD_FILTERS: PredefinedFilterItem<FieldRecord>[] = [
    {
        key: 'filter_new',
        titleKey: 'employee.filters.predefined.filter_new',
        filterFunction: (record: FieldRecord) => {
            return DateUtils.diffDayDates(record.created_on) <= 7;
        }
    },
    {
        key: 'filter_recent',
        titleKey: 'employee.filters.predefined.filter_recent',
        filterFunction: (record: FieldRecord) => {
            const {edited_on} = record;
            return !!edited_on && DateUtils.diffDayDates(edited_on) <= 7;
        }
    },
    {
        key: 'filter_not_in_tree',
        titleKey: 'employee.filters.predefined.filter_not_in_tree',
        filterFunction: (record: FieldRecord) => {
            return record.tree_ids.length === 0;
        }
    },
    {
        key: 'filter_map',
        titleKey: 'field.filters.predefined.filter_no_map',
        filterFunction: (record: FieldRecord) => {
            if (!record.map_metadata || isEmpty(record.map_metadata)) {
                return false;
            }
            const fieldMapMetadata: FieldMapMetadata = JSON.parse(record.map_metadata);
            return isEmpty(fieldMapMetadata.coords);
        }
    },
    {
        key: 'filter_without_avatar',
        titleKey: 'employee.filters.predefined.filter_without_avatar',
        filterFunction: (record: FieldRecord) => {
            return !record.avatar;
        }
    },
];

export function restoreFieldPredefinedFilter(): PredefinedFilterItem<FieldRecord>[] {
    const availableKeys: string[] = PREDEFINED_FIELD_FILTERS.map((item: PredefinedFilterItem<FieldRecord>) => item.key);
    const validKeys: string[] = (LocalStorage.getItem(LocalStorageKey.FIELD_PREDEFINED_FILTERS) || "")
        .split(',')
        .filter((saved: string) => availableKeys.includes(saved));
    if (isEmpty(validKeys)) {
        return [];
    }
    return PREDEFINED_FIELD_FILTERS.filter((item: PredefinedFilterItem<FieldRecord>) => validKeys.includes(item.key));
}
