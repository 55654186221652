import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EventWorklogInfoRecord} from 'fm-shared-data/src/types/db/account/sync/EventWorklogRequest';

export default class LoadEventWorklogInfoRecordsCommand extends Command<EventWorklogInfoRecord[], number> {
    execute(sync_process_id: number): Promise<EventWorklogInfoRecord[]> {
        return axiosGetInstance().post<EventWorklogInfoRecord[]>(`/sync/worklog/event/${sync_process_id}`)
            .then((response: AxiosResponse<EventWorklogInfoRecord[]>) => response.data);
    }
}
