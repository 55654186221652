import React from 'react';
import {Col, Divider, Row} from 'antd';
import {inject, observer} from 'mobx-react';
import {DataStore} from '../../../../../../../app/mobx/DataStore';
import {ScannerViewStore} from '../../../../mobx/ScannerViewStore';
import {CoreStore} from '../../../../../../core/mobx/CoreStore';
import CEDAvatarsController from '../../../../../../../common/widgets/CEDAvatars/CEDAvatarsController';
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {TagFields} from "fm-shared-data/src/types/db/common/TagFields";
import {AvatarFields} from "fm-shared-data/src/types/db/common/AvatarFields";
import ManageTreeWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTreeWidget";
import ManageTagWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageTagWidget";
import {getAvatar} from "../../../../../employee/EmployeeUtils";
import ManageAvatarWidget from "../../../../../employee/components/tabs/items/general/widgets/ManageAvatarWidget";
import {ScannerRecord} from "fm-shared-data/src/types/db/account/scanner/ScannerRecord";
import UpdateTreeIdsCommand from "../../../../../commands/tree/UpdateTreeIdsCommand";
import UpdateTagsCommand from "../../../../../commands/UpdateTagsCommand";
import UpdateAvatarCommand from "../../../../../commands/UpdateAvatarCommand";
import ScannerTagAutoCompleteProvider from "./ScannerTagAutoCompleteProvider";

type StoreProps = {
    coreStore: CoreStore;
    dataStore: DataStore;
    scannerViewStore: ScannerViewStore;
};

type Props = StoreProps;

@inject('scannerViewStore', 'dataStore', 'coreStore')
@observer
export default class ScannerTabGeneral extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    updateTree = (updateDataRecord: UpdateDataRecord<TreeFields>): Promise<boolean> => {
        return new UpdateTreeIdsCommand<ScannerRecord>('scanner', updateDataRecord).execute().then((scannerRecord: ScannerRecord) => {
            const {dataStore, scannerViewStore} = this.props;
            dataStore.updateScanner(scannerRecord);
            scannerViewStore.applyFiltersToView(updateDataRecord.id);
            return Promise.resolve(true);
        });
    };

    updateTag = (updateDataRecord: UpdateDataRecord<TagFields>): Promise<boolean> => {
        return new UpdateTagsCommand<ScannerRecord>('scanner', updateDataRecord).execute().then((scannerRecord: ScannerRecord) => {
            const {dataStore, scannerViewStore} = this.props;
            dataStore.updateScanner(scannerRecord);
            return scannerViewStore.refreshScannerTags(updateDataRecord.id).then(() => {
                return Promise.resolve(true);
            });
        });
    };

    updateAvatar = (updateDataRecord: UpdateDataRecord<AvatarFields>): Promise<boolean> => {
        console.info(updateDataRecord);
        return new UpdateAvatarCommand<ScannerRecord>('scanner', updateDataRecord).execute().then((scannerRecord: ScannerRecord) => {
            const {dataStore, scannerViewStore} = this.props;
            dataStore.updateScanner(scannerRecord);
            scannerViewStore.applyFiltersToView(scannerRecord.id);
            return Promise.resolve(true);
        });
    };

    render() {
        const {scannerViewStore, coreStore} = this.props;
        const {scannerTreeData, selectedEntity} = scannerViewStore;

        return (
            <span className={'maxWidth'}>
                <Row gutter={[10, 30]}>
                    <Col span={16}>
                        <Row gutter={[10, 30]}>
                            <Col span={24}>
                                <Divider orientation="left">Structure</Divider>
                                <ManageTreeWidget selectedEntity={selectedEntity} treeData={scannerTreeData} updateCommand={this.updateTree}/>
                            </Col>
                            <Col span={24}>
                                <Divider orientation="left">Tags</Divider>
                                <ManageTagWidget selectedEntity={selectedEntity} treeData={scannerTreeData} updateCommand={this.updateTag}
                                                 isAdminUser={coreStore.isAdminUser}
                                                 autoCompleteDataProvider={new ScannerTagAutoCompleteProvider()}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={7}>
                        <ManageAvatarWidget selectedEntity={selectedEntity} updateCommand={this.updateAvatar}
                                            emptyAvatar={getAvatar(selectedEntity)}/>
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <CEDAvatarsController table={'scanner'} record={selectedEntity}/>
                        </div>
                    </Col>
                </Row>
            </span>
        );
    }
}
