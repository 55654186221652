import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EquipmentWorklogInfoRecord} from "fm-shared-data/src/types/db/account/sync/EquipmentWorklogRequest";

export default class LoadEquipmentWorklogRecordsCommand extends Command<EquipmentWorklogInfoRecord[], number> {
    async execute(sync_process_id: number): Promise<EquipmentWorklogInfoRecord[]> {
        return axiosGetInstance().post<EquipmentWorklogInfoRecord[]>(`/sync/worklog/equipment/${sync_process_id}`)
            .then((response: AxiosResponse<EquipmentWorklogInfoRecord[]>) => response.data);
    }
}
