import React from 'react';
import {Alert, Button, Col, Form as AntForm, Modal, Popconfirm, Row, Spin} from 'antd';
import {FMOptionType, FormFieldConfig} from './type/FormFieldConfig';
import {CatchError} from '../../types/CatchError';
import {formatMessage} from '../../locale/IntlProvider';
import {Field, Form, Formik, FormikErrors, FormikProps} from 'formik';
import {FormikHelpers} from 'formik/dist/types';
import {ModalFormConfig} from './type/ModalFormConfig';
import './style/modalForm.scss';
import isEmpty from 'lodash/isEmpty';
import {validateSync, validateSyncDataRecord} from './validators/sync/SyncValidatorUtils';
import {FormFieldRender} from './type/FormFieldRender';
import {FieldValidatorResult} from './validators/FieldValidatorTypes';
import DataProvider from "./type/DataProvider";
import {withRecord} from "../tab/CustomRenderer";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {inject, observer} from "mobx-react";
import {DictionaryStore} from "../../../pages/core/mobx/DictionaryStore";
import {DictionaryDecorator} from "../dictionary/DictionaryDecorator";
import {getFormFieldRender} from "./FormikUtils";

type StoreProps = {
    dictionaryStore: DictionaryStore;
};

interface PropsT<T extends DataRecord> {
    config: ModalFormConfig<T>;
    initialValues: T;
    saveCommand?: (upsertValue: UpsertValue<T>) => Promise<UpsertResponse<T>>;
    deleteCommand?: (record: T) => Promise<UpsertResponse<T>>;
    deleteEnabledRule?: DataProvider<boolean, T>;
    onSave: (upsertValue: UpsertValue<T>) => void;
    onCancel: () => void;
    titleSuffix?: string;
}

type Props<T extends DataRecord> = PropsT<T> & StoreProps;

interface State<T extends DataRecord> {
    // record: T;
    formValidatorResult: FieldValidatorResult;
    updateInProgress: boolean;
    deleteInProgress: boolean;
    notLoadedDictionaryKeys: string[];
}

@inject('dictionaryStore')
@observer
export default class ModalFormik<T extends DataRecord> extends React.Component<Props<T>, State<T>> {
    static defaultProps = {} as StoreProps;

    getUniqNotLoadedDictionaries = (): DictionaryDecorator[] => {
        const {config, dictionaryStore} = this.props;
        const maps: Map<string, DictionaryDecorator> = config.fieldConfig
            .filter((fieldConfig: FormFieldConfig) => {
                const {config} = fieldConfig;
                return !!config?.dictionaryDecorator
                    && !config?.dictionaryDecorator.isStatic
                    && !dictionaryStore.getDictionary(config!.dictionaryDecorator.dictionaryKey);
            }).reduce((prev: Map<string, DictionaryDecorator>, fieldConfig: FormFieldConfig) => {
                const {config} = fieldConfig;
                if (config?.dictionaryDecorator && !prev.has(config.dictionaryDecorator.dictionaryKey)) {
                    prev.set(config.dictionaryDecorator.dictionaryKey, config.dictionaryDecorator);
                }
                return prev;
            }, new Map<string, DictionaryDecorator>());
        return Array.from(maps.values());
    }

    constructor(props: Props<T>) {
        super(props);
        this.state = {
            // record: props.value,
            formValidatorResult: null,
            updateInProgress: false,
            deleteInProgress: false,
            notLoadedDictionaryKeys: this.getUniqNotLoadedDictionaries().map((dictionaryDecorator: DictionaryDecorator) => dictionaryDecorator.dictionaryKey)
        };
    }

    componentDidMount(): void {
        console.log('ModalFormController: componentDidMount', performance.now());
        this.getUniqNotLoadedDictionaries().forEach((dictionaryDecorator: DictionaryDecorator) => dictionaryDecorator.load());
    }

    componentDidUpdate(): void {
        console.log('ModalFormController: componentDidUpdate', performance.now());
    }

    componentWillUnmount(): void {
        console.log('ModalFormController: componentWillUnmount', performance.now());
    }

    get isEdit(): boolean {
        return !!this.props.initialValues.id;
    }

    handleFormSubmit = async (formikValues: T, formikHelpers: FormikHelpers<T>) => {
        if (this.state.updateInProgress) {
            return;
        }
        console.info('handleFormSubmit:', formikValues);
        // this.setState({record: values});
        const formikErrors: FormikErrors<T> = await formikHelpers.validateForm(formikValues);
        console.info(`handleFormSubmit: (${!isEmpty(formikErrors)})`, formikErrors);
        if (!isEmpty(formikErrors)) {
            return;
        }

        // this.setState({updateInProgress: true, record: values});
        this.setState({updateInProgress: true}, async () => {
            await this.doSubmit(formikValues);
        });
        // try {
        //     await this.doSubmit();
        //     this.props.onCancel();
        // } catch (e) {
        //     this.setState({updateInProgress: true});
        // }
    };

    handleFormValidate = (values: T): Promise<FormikErrors<T>> => {
        console.info('handleFormValidate: values: ', values);
        const formValidatorResult: FieldValidatorResult = validateSyncDataRecord<T>(values, this.props.config.syncValidators || []);
        console.info('handleFormValidate: formValidatorResult: ', formValidatorResult);
        this.setState({formValidatorResult});
        // return delay(10000).then(() => Promise.resolve({}));
        return formValidatorResult ? Promise.resolve({formValidatorResult}) : Promise.resolve({});
    };

    doSubmit = async (formikValues: T) => {
        // this.setState({lockContent: true});
        const {saveCommand, onSave, initialValues} = this.props;

        // let saveData: T = {...formikValues};
        // if (!this.isEdit) {
        //     //add getNewRecord data with override by record (that user changed)
        //     saveData = {...initialValues, ...saveData};
        // }

        const upsertValue: UpsertValue<T> = {
            initialValue: initialValues,
            updatedValue: formikValues // saveData
        };
        if (saveCommand) {
            //TODO: ref error handling
            await saveCommand(upsertValue).then((value: UpsertResponse<T>) => {
                this.setState({
                    updateInProgress: false
                });
                onSave(upsertValue);
            }).catch((error: CatchError) => {
                console.error(error);
                this.setState({
                    updateInProgress: false
                });
            });
        } else {
            onSave(upsertValue);
        }
    };

    handleCancel = () => {
        console.log('Clicked cancel button');
        if (this.state.updateInProgress || this.state.deleteInProgress) {
            return;
        }

        // if (this.formRef?.current?.isFieldsTouched()) {
        //     //are you sure? Data was touched
        // }

        this.props.onCancel();
    };

    handleDelete = () => {
        if (this.state.updateInProgress || this.state.deleteInProgress) {
            return;
        }
        this.setState({deleteInProgress: true});
        this.executeDelete().then(() => {
            this.setState({deleteInProgress: false});
            this.handleCancel();
        });
    };

    executeDelete = (): Promise<UpsertResponse<T>> => {
        return this.props.deleteCommand!(this.props.initialValues);
    };

    getTitle = (): string => {
        const {config, titleSuffix = ""} = this.props;
        const prefix: string = this.isEdit ? formatMessage('modalForm.title.edit') : formatMessage('modalForm.title.new');
        return `${prefix} ${config.title ? formatMessage(config.title) : ""} ${titleSuffix}`;
    };

    getOkText = (): string => {
        return this.isEdit
            ? this.state.updateInProgress ? formatMessage('modalForm.button.edit.process') : formatMessage('modalForm.button.edit')
            : this.state.updateInProgress ? formatMessage('modalForm.button.new.process') : formatMessage('modalForm.button.new');
    };

    getDeleteText = (): string => {
        return this.state.deleteInProgress ? formatMessage('modalForm.button.delete.process') : formatMessage('modalForm.button.delete');
    };

    handleOnFormChange = (e: any) => {
        console.info(e);
    };

    renderContent = (formikProps: FormikProps<T>): React.ReactNode => {
        console.info('renderContent: formikProps.isValid', formikProps.isValid, formikProps);
        return (
            <Form onSubmit={formikProps.handleSubmit} onChange={this.handleOnFormChange}>
                <div className={'modal-form__body'} key={1}>
                    <Spin spinning={this.state.updateInProgress || this.state.deleteInProgress}>
                        {/* AntForm - just for formatting */}
                        <AntForm layout={'vertical'}>
                            {/*labelCol={{span: 8}} wrapperCol={{span: 16}}*/}
                            {this.props.config.fieldConfig.map((fieldConfig: FormFieldConfig) => this.renderFormItem(fieldConfig, formikProps))}
                        </AntForm>
                        {this.renderFormInvalidMessage()}
                        {this.props.children}
                    </Spin>
                </div>
                {this.renderFooter(formikProps)}
            </Form>
        );
    };

    getFormItem = (fieldConfig: FormFieldConfig, record: T): React.ComponentType<any> => {
        if (fieldConfig.renderer && fieldConfig.renderType === FormFieldRender.CUSTOM_RENDERER) {
            return withRecord(fieldConfig.renderer, {record});
        }
        return getFormFieldRender(fieldConfig.renderType);
    };

    getOptions = (fieldConfig: FormFieldConfig, value: T): FMOptionType[] => {
        switch (fieldConfig.renderType) {
            case FormFieldRender.CHECKBOX:
                return fieldConfig.config?.dictionaryDecorator?.checkOptionsProvider || []
            case FormFieldRender.RADIO:
                return fieldConfig.config?.dictionaryDecorator?.checkOptionsProvider || []
            case FormFieldRender.MULTI_SELECT:
            case FormFieldRender.SINGLE_SELECT:
                return fieldConfig.config?.dictionaryDecorator?.selectOptionsProvider || [];
            case FormFieldRender.AUTO_COMPLETE:
                // return fieldConfig.config?.dictionaryDecorator?.autoCompleteOptionsProvider || []
                return fieldConfig.config?.autoCompleteOptionsProvider?.get() || [];
            case FormFieldRender.MENTIONS:
                return fieldConfig.config?.dictionaryDecorator?.selectOptionsProvider || []
        }
        return [];
    };

    renderFormItem = (fieldConfig: FormFieldConfig, formikProps: FormikProps<T>): React.ReactNode => {
        const record: T = formikProps.values;
        const readonlyValue: FieldValidatorResult | undefined = fieldConfig.readonly?.get(record);
        const options: FMOptionType[] = this.getOptions(fieldConfig, record);
        const disabled: boolean = !!readonlyValue || readonlyValue === '';

        return (
            <Field component={this.getFormItem(fieldConfig, record)}
                   label={formatMessage(fieldConfig.label)}
                   validate={validateSync(fieldConfig.syncValidators)}
                // validate={validateAsync(fieldConfig.asyncValidators)}
                // validate={mergeValidators(fieldConfig)}
                   key={fieldConfig.field_name}
                   name={fieldConfig.field_name}
                   required={!fieldConfig.optional}
                   formItemTooltip={readonlyValue}
                   options={options}
                   disabled={disabled}
                   inputProps={fieldConfig.config?.inputProps}/>
        );
    };

    renderFormInvalidMessage = (): React.ReactNode => {
        const {formValidatorResult} = this.state;
        if (!formValidatorResult) {
            return null;
        }
        return <Alert message={this.state.formValidatorResult} type="error"/>;
    };

    renderFooter = (formikProps: FormikProps<T>): React.ReactNode => {
        return (
            <div className="ant-modal-footer">
                <Row wrap={false}>
                    {this.isEdit && this.props.deleteEnabledRule?.get(this.props.initialValues) && this.props.deleteCommand && <Col flex="none">
                        <Popconfirm
                            placement={'bottom'}
                            okType={'primary'}
                            disabled={this.state.deleteInProgress || this.state.updateInProgress}
                            title={formatMessage('SCRUDActions.delete.confirmation')}
                            okText={formatMessage('common.yes')}
                            cancelText={formatMessage('common.no')}
                            onConfirm={this.handleDelete}>
                            <Button type={'default'}
                                    danger={true}
                                    disabled={this.state.updateInProgress}
                                    loading={this.state.deleteInProgress}>{this.getDeleteText()}</Button>
                        </Popconfirm>
                    </Col>
                    }
                    <Col flex="auto">
                        <Button type={'default'}
                                disabled={this.state.updateInProgress || this.state.deleteInProgress}
                                onClick={this.handleCancel}>{formatMessage('common.cancel')}</Button>
                        <Button type={'primary'}
                                htmlType={'submit'}
                                disabled={this.state.deleteInProgress}
                            // disabled={!formikProps.isValid}
                                loading={this.state.updateInProgress}>{this.getOkText()}</Button>
                    </Col>
                </Row>
            </div>
        );
    };

    handleOnClose = () => {
        if (!this.state.updateInProgress || this.state.deleteInProgress) {
            // TODO: if form touched or initial values !== current =>> confirm
            this.props.onCancel();
        }
    };

    render() {
        if (this.state.notLoadedDictionaryKeys.some((dictionaryKey: string) => !this.props.dictionaryStore.getDictionary(dictionaryKey))) {
            return <Spin>Load dictionaries...</Spin>
        }
        return (
            <Modal className={'modal-form'}
                   title={this.getTitle()}
                   visible={true}
                   centered={true}
                   maskClosable={false}
                   destroyOnClose={true}
                   onCancel={this.handleOnClose}
                   closable={!this.state.updateInProgress && !this.state.deleteInProgress}
                   footer={null}>
                <Formik<T> initialValues={this.props.initialValues}
                           component={this.renderContent}
                           onSubmit={this.handleFormSubmit}
                           validate={this.handleFormValidate}/>
            </Modal>
        );
    }
}
