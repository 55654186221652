import React from 'react';
import {Card} from 'antd';
import SplitterLayout from 'react-splitter-layout';
import {loadValue, onSplitterResize,} from '../../../common/utils/SplitterUtils';
import {intl} from '../../../common/locale/IntlProvider';
import FieldFiltersComponent from "./components/filters/FieldFiltersComponent";
import FieldListComponent from "./components/list/FieldListComponent";
import FieldTabController from "./components/tabs/FieldTabController";
import './style/fieldView.scss';

export default class FieldPageView extends React.Component {
    render() {
        return (
            <SplitterLayout
                primaryIndex={1}
                primaryMinSize={200}
                secondaryMinSize={220}
                secondaryInitialSize={loadValue('field-sp-1', 250)}
                onSecondaryPaneSizeChange={onSplitterResize('field-sp-1')}>
                <Card size="small" title={intl().formatMessage({id: 'employee.filters.title'})} bodyStyle={{padding: 0}} bordered={false}>
                    <FieldFiltersComponent/>
                </Card>
                <SplitterLayout
                    primaryIndex={1}
                    primaryMinSize={400}
                    secondaryMinSize={250}
                    secondaryInitialSize={loadValue('field-sp-2', 350)}
                    onSecondaryPaneSizeChange={onSplitterResize('field-sp-2')}>
                    <div className="flexh100">
                        <FieldListComponent/>
                    </div>
                    <FieldTabController/>
                </SplitterLayout>
            </SplitterLayout>
        );
    }
}
