import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";

export default class LoadEmployeeEmailCommand extends Command<EmployeeEmailRecord[]> {
    execute(): Promise<EmployeeEmailRecord[]> {
        return axiosGetInstance().post<EmployeeEmailRecord[]>('/employee/email')
            .then((response: AxiosResponse<EmployeeEmailRecord[]>) => response.data);
    }
}
