import React from 'react';
import {Route, Switch} from 'react-router';
import {CoreStore} from '../pages/core/mobx/CoreStore';
import {inject, observer} from 'mobx-react';
import {URLRouteProps} from '../routing/URLRouteProps';
import SignInPageController from '../pages/common/login/signIn/SignInPageController';
import FMLoader from '../common/widgets/fmLoader/FMLoader';
import Page404Controller from '../pages/common/page404/Page404Controller';
import ErrorPageController from '../pages/common/error/ErrorPageController';
import CoreRouter from '../pages/core/CoreRouter';
import SetPasswordPageController from '../pages/common/login/setPassword/SetPasswordPageController';
import ResetPasswordPageController from '../pages/common/login/resetPassword/ResetPasswordPageController';
import SignUpPageController from '../pages/common/login/signUp/SignUpPageController';
import EmployeeConfirmationPage from "../pages/account/employee/emailConfirmationPage/EmployeeConfirmationPage";
import {AppRoutes} from "fm-shared-data/src/types/routing/AppRoutes";
import {AppStore} from "./mobx/AppStore";
import {parseAppPage} from "../routing/AppPageUtils";

type StoreProps = {
    appStore: AppStore;
    coreStore: CoreStore;
};

type Props = URLRouteProps & StoreProps;

@inject('appStore', 'coreStore')
@observer
export default class AppRouter extends React.Component<Props> {
    static defaultProps = {} as StoreProps;

    componentDidMount(): void {
        console.log(
            'AppRouter: componentDidMount',
            performance.now(),
            this.props
        );
        this.updateCurrentPage();
    }

    componentDidUpdate(): void {
        console.log(
            'AppRouter: componentDidUpdate',
            performance.now(),
            this.props
        );
        this.updateCurrentPage();
    }

    updateCurrentPage = () => {
        this.props.appStore.currentPage = parseAppPage(this.props.location.pathname.toString());
    };

    render() {
        if (!this.props.coreStore.isLocaleExists) {
            return <FMLoader text='Loading initial locale....'/>;
        }
        return (
            <Switch key={this.props.coreStore.locale}>
                <Route path={AppRoutes.PAGE404} exact={true} component={Page404Controller}/>
                <Route path={[AppRoutes.ERROR, AppRoutes.ERROR_DATA]} exact={true} component={ErrorPageController}/>

                <Route path={AppRoutes.SIGNUP} exact={true} component={SignUpPageController}/>
                <Route path={AppRoutes.SET_PASSWORD_DATA} exact={true} component={SetPasswordPageController}/>
                <Route path={[AppRoutes.SIGNIN, AppRoutes.SIGNIN_DATA, AppRoutes.LOGOUT]} exact={true} component={SignInPageController}/>
                <Route path={[AppRoutes.RESET_PASSWORD, AppRoutes.RESET_PASSWORD_DATA]} exact={true} component={ResetPasswordPageController}/>

                <Route path={AppRoutes.FM_EMPLOYEE_EMAIL_CONFIRMATION_DATA} exact={true} component={EmployeeConfirmationPage}/>

                <Route component={CoreRouter}/>
            </Switch>
        );
    }
}
