import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../common/utils/AxiosUtils";
import Command from "../../../../common/types/Command";
import {UpdateDataRecord} from "fm-shared-data/src/types/db/common/UpdateDataRecord";
import {TreeFields} from "fm-shared-data/src/types/db/common/TreeFields";
import {DataRecord} from "fm-shared-data/src/types/db/common/DataRecord";

export default class UpdateTreeIdsCommand<T extends DataRecord> extends Command<T, UpdateDataRecord<TreeFields>> {

    constructor(private readonly table: string, private readonly updateDataRecord: UpdateDataRecord<TreeFields>) {
        super();
    }

    async execute(): Promise<T> {
        return axiosGetInstance().post<T>(`/tree/update/ids/${this.table}`, this.updateDataRecord)
            .then((response: AxiosResponse<T>) => response.data);
    }

}
