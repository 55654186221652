import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import UpdateDataCommand from "../../../../../../common/types/UpdateDataCommand";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";

export default class UpdateWorkCommand extends UpdateDataCommand<WorkRecord> {

    constructor(private readonly upsertValue: UpsertValue<WorkRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<WorkRecord>> {
        return axiosGetInstance().post<UpsertResponse<WorkRecord>>(`/work/update`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<WorkRecord>>) => response.data);
    }

}
