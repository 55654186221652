import {AxiosResponse} from 'axios';
import Command from '../../../../common/types/Command';
import {axiosGetInstance} from '../../../../common/utils/AxiosUtils';
import {secureBody} from "fm-shared-utils/src/utils/crypto/CryptoUtils";
import {SignupBody} from "fm-shared-data/src/types/auth/SignupBody";
import {JWTToken} from "fm-shared-data/src/types/auth/JWTToken";

export default class ResetPasswordCommand extends Command<JWTToken, SignupBody> {
    async execute(signupBody: SignupBody): Promise<JWTToken> {
        return axiosGetInstance().post<JWTToken>('/auth/reset', secureBody(signupBody))
            .then((response: AxiosResponse<JWTToken>) => response.data);
    }

}
