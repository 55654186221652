import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";

export default class DeleteEmployeeCommand extends Command<EmployeeRecord[], EmployeeRecord> {
    execute(employeeRecord: EmployeeRecord): Promise<EmployeeRecord[]> {
        return axiosGetInstance().post<EmployeeRecord[]>(`/employee/delete`, employeeRecord)
            .then((response: AxiosResponse<EmployeeRecord[]>) => response.data);
    }
}
