import DataProvider from '../../../../../common/widgets/modalForm/type/DataProvider';
import {stores} from '../../../../../app/mobx/Stores';
import {toCamelCaseSingle} from "fm-shared-utils/src/utils/SimpleUtils";
import {AutoCompleteOptionType} from '../../../../../common/widgets/modalForm/formItems/AutoCompleteOptionType';
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";

export default class CalendarNameOptionsProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        const uniqNames: Set<string> = new Set<string>();
        dataStore.calendar.forEach((item: CalendarRecord) => uniqNames.add(toCamelCaseSingle(item.name)));
        return Array.from(uniqNames).map((value: string) => {
            return {value};
        });
    }
}
