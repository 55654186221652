import {AxiosResponse} from "axios";
import UpdateDataCommand from "../../../../../../../../../common/types/UpdateDataCommand";
import {axiosGetInstance} from "../../../../../../../../../common/utils/AxiosUtils";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";

export default class UpdateEmployeeAddressCommand extends UpdateDataCommand<EmployeeAddressRecord> {

    constructor(private readonly upsertValue: UpsertValue<EmployeeAddressRecord>) {
        super();
    }

    execute(): Promise<UpsertResponse<EmployeeAddressRecord>> {
        return axiosGetInstance().post<UpsertResponse<EmployeeAddressRecord>>(`/employee/address/update`, this.upsertValue)
            .then((response: AxiosResponse<UpsertResponse<EmployeeAddressRecord>>) => response.data);
    }

}
