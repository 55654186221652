import {AxiosResponse} from "axios";
import {DictionaryRawRecord} from "fm-shared-data/src/types/dictionary/DictionaryRawRecord";
import Command from "../../../types/Command";
import {axiosGetInstance} from "../../../utils/AxiosUtils";

export default class LoadUnitCommand extends Command<DictionaryRawRecord[]> {
    execute(): Promise<DictionaryRawRecord[]> {
        return axiosGetInstance().get<DictionaryRawRecord[]>('/dictionary/unit')
            .then((response: AxiosResponse<DictionaryRawRecord[]>) => response.data);
    }
}
