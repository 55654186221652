import {FormFieldRender} from '../../../../../common/widgets/modalForm/type/FormFieldRender';
import {ModalFormConfig} from '../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LengthValidator} from '../../../../../common/widgets/modalForm/validators/sync/LengthValidator';
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import LogTypeNameOptionsProvider from "./LogTypeNameOptionsProvider";
import {UniqLogTypeValidator} from "../../../../../common/widgets/modalForm/validators/sync/UniqLogTypeValidator";
import {DICTIONARY_ICOFONT} from "../../../../../common/widgets/dictionary/StaticDictionatryConfig";
import {DictionaryDecorator} from "../../../../../common/widgets/dictionary/DictionaryDecorator";

export const LOGTYPE_FORM_CONFIG: ModalFormConfig<LogTypeRecord> = {
    title: 'logType.form.title',
    tableName: 'log_type',
    getNewRecord: () => {
        return {
            field_index1: 1,
            field_index2: 2,
            field_index3: 3,
            field_index4: 4,
            field_index5: 5,
            field_index6: 6,
            field_index7: 7,
        } as LogTypeRecord;
    },
    fieldConfig: [
        {
            label: 'tree.field.name',
            field_name: 'name',
            renderType: FormFieldRender.AUTO_COMPLETE,
            syncValidators: [new NonEmptyValidator(), new LengthValidator()],
            config: {
                autoCompleteOptionsProvider: new LogTypeNameOptionsProvider()
            }
        },
        {
            label: 'tree.field.icon',
            field_name: "icon",
            renderType: FormFieldRender.SINGLE_SELECT,
            optional: true,
            config: {
                dictionaryDecorator: new DictionaryDecorator(DICTIONARY_ICOFONT),
                inputProps: {
                    // size: "large",
                    showSearch: true,
                    placeholder: 'Иконка'
                }
            }
        },
        {
            label: 'tree.field.color',
            field_name: "color",
            optional: true,
            renderType: FormFieldRender.COLOR_PICKER,
        },
    ],
    syncValidators: [new UniqLogTypeValidator()]
};
