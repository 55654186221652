import {AxiosResponse} from "axios";
import {axiosGetInstance} from "../../../../../../common/utils/AxiosUtils";
import Command from "../../../../../../common/types/Command";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";

export default class DeleteLogTypeCommand extends Command<LogTypeRecord[], LogTypeRecord> {
    execute(LogTypeRecord: LogTypeRecord): Promise<LogTypeRecord[]> {
        return axiosGetInstance().post<LogTypeRecord[]>(`/justlog/type/delete`, LogTypeRecord)
            .then((response: AxiosResponse<LogTypeRecord[]>) => response.data);
    }
}
