import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../../../../app/mobx/AppStore';
import {DataStore} from '../../../../app/mobx/DataStore';
import isEmpty from 'lodash/isEmpty';
import PredefinedFilterItem from '../../../../common/widgets/predefinedFilters/PredefinedFilterItem';
import {LocalStorage, LocalStorageKey} from '../../../../common/utils/LocalStorageUtils';
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {findNodeById} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {isBarCode} from "fm-shared-utils/src/utils/barcode/BarCodeUtils";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import {restoreFieldPredefinedFilter} from "../components/filters/PredefinedFieldFilters";
import LoadTagsCommand from "../../commands/LoadTagsCommand";
import {TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {FieldMapMetadata} from "../../../../common/widgets/mapField/FieldMapMetadata";
import UpdateFieldMapCommand from "../../../../common/widgets/mapField/UpdateFieldMapCommand";

export class FieldViewStore {
    isViewReady: Date | undefined;

    treeSelectedNode: TreeData<TreeRecord> = {} as TreeData<TreeRecord>;
    expandedKeys: number[] = [];

    selectedPredefinedFilters: PredefinedFilterItem<FieldRecord>[] = [];

    visibleEntities: FieldRecord[] = [];
    selectedEntities: FieldRecord[] = [];

    universalFilter: string = '';
    initFiltersDone: boolean = false;

    fieldTags: string[] = [];
    selectedFieldTags: string[] = [];
    isFieldTagsLoaded: Date | undefined;
    isFieldTagsLoading: boolean = true;

    constructor(private readonly appStore: AppStore, private readonly dataStore: DataStore) {
        makeObservable(this, {
            isViewReady: observable,

            treeSelectedNode: observable,
            setTreeSelectedNode: action,
            expandedKeys: observable,

            universalFilter: observable,

            visibleEntities: observable,
            selectedEntities: observable,
            selectedEntity: computed,
            setSelectedEntities: action,

            fieldTags: observable,
            selectedFieldTags: observable,
            isFieldTagsLoaded: observable,
            isFieldTagsLoading: observable,

            selectedPredefinedFilters: observable,
            setPredefinedFilters: action,

            loadData: action,
            // initFilters: action,
            clearFilters: action,
            applyFiltersToView: action,
            // applyUniversalFilter: action,
            updateFieldTreeRecord: action,
            setSelectedFieldTags: action,
            refreshFieldTags: action,

            selectedEntityIds: computed,
            visibleFieldCount: computed,
            isDataLoaded: computed,
            isSelectedAll: computed,
            isTreeRootNodeSelected: computed,
            fieldTreeData: computed,
            selectedFieldTreeRecord: computed,

            setField: action,
            updateMap: action,
        });
    }

    get selectedEntityIds(): number[] {
        return this.selectedEntities.map(item => item.id);
    }

    get visibleFieldCount(): number {
        return this.visibleEntities.length;
    }

    get isDataLoaded(): boolean {
        return !!this.dataStore.isFieldLoaded && !!this.dataStore.isFieldTreeLoaded && !!this.isFieldTagsLoaded;
    }

    get isSelectedAll(): boolean {
        return this.visibleFieldCount > 0 && this.visibleFieldCount === this.selectedEntities.length;
    }

    get isTreeRootNodeSelected(): boolean {
        return this.treeSelectedNode.id === this.dataStore.fieldTreeRootId;
    }

    get fieldTreeData(): TreeData<TreeRecord>[] {
        return this.dataStore.fieldTreeData;
    }

    get selectedFieldTreeRecord(): TreeRecord {
        return this.dataStore.fieldTree.find((record: TreeRecord) => record.id === this.treeSelectedNode.id)!;
    }

    async loadData() {
        const loaders: Promise<any>[] = [];
        if (!this.dataStore.isFieldLoaded) {
            loaders.push(this.dataStore.loadField());
        }
        if (!this.dataStore.isFieldTreeLoaded) {
            loaders.push(this.dataStore.loadFieldTree());
        }
        if (!this.isFieldTagsLoaded) {
            loaders.push(this.loadFieldTags());
        }
        if (!isEmpty(loaders)) {
            await Promise.all(loaders);
            // runInAction(() => {
            //     this.initFilters(true);
            // });
            // } else {
            //     this.initFilters(true);
        }
    }

    async loadFieldTags() {
        this.isFieldTagsLoading = true;
        const tags: string[] = await new LoadTagsCommand('field').execute();
        runInAction(() => {
            this.fieldTags = tags;
            this.isFieldTagsLoading = false;
            this.isFieldTagsLoaded = new Date();
        });
    }

    clearFilters() {
        //tree:
        this.treeSelectedNode = findNodeById(this.dataStore.fieldTreeData, this.dataStore.fieldTreeRootId)!;
        LocalStorage.removeItem(LocalStorageKey.FIELD_TREE_ID);

        //predefined:
        this.selectedPredefinedFilters = [];
        LocalStorage.removeItem(LocalStorageKey.FIELD_PREDEFINED_FILTERS);

        //tags:
        this.selectedFieldTags = [];
        LocalStorage.removeItem(LocalStorageKey.FIELD_TAGS_FILTERS);

        this.isViewReady = undefined;
    }

    treeFilterFunc = (field: FieldRecord) => (field.tree_ids).includes(this.treeSelectedNode.id);

    // tagFilterFuncOR = (employee: FieldRecord) => !isEmpty(employee.tags.filter(Boolean).filter((eTag: string) => this.selectedFieldTags.filter(Boolean).includes(eTag)));
    tagFilterFuncAND = (field: FieldRecord) => this.selectedFieldTags.filter(Boolean).every((eTag: string) => field.tags.filter(Boolean).includes(eTag));

    applyFiltersToView(trySelectId?: number) {
        if (!this.initFiltersDone) {
            this.initFilters();
        }

        const filters: any[] = [];
        if (this.treeSelectedNode.id !== this.dataStore.fieldTreeRootId) {
            filters.push(this.treeFilterFunc);
        }
        this.selectedPredefinedFilters.forEach((predefinedFilterItem: PredefinedFilterItem<FieldRecord>) => filters.push(predefinedFilterItem.filterFunction));

        if (!isEmpty(this.selectedFieldTags)) {
            filters.push(this.tagFilterFuncAND);
        }

        this.visibleEntities = isEmpty(filters)
            ? this.dataStore.field
            : this.dataStore.field.filter((field: FieldRecord) => {
                return filters.every((fun: any) => fun(field));
            });

        // in the end: apply local filters for visibleEntities
        this.applyUniversalFilter();
        const newSelection: FieldRecord | undefined = this.visibleEntities.find((field: FieldRecord) => field.id === trySelectId);
        this.selectedEntities = newSelection ? [newSelection] : [];
        this.isViewReady = new Date();
    }

    updateFieldTreeRecord(fieldTreeRecords: TreeRecord[]) {
        this.dataStore.setFieldTree(fieldTreeRecords);
        const treeSelectedNode: TreeData<TreeRecord> | undefined = findNodeById(this.dataStore.fieldTreeData, this.treeSelectedNode.id);
        if (treeSelectedNode) {
            this.treeSelectedNode = treeSelectedNode;
        } else {
            // selected tree node not found after update
            this.setTreeSelectedNode(findNodeById(this.dataStore.fieldTreeData, this.dataStore.fieldTreeRootId)!);
        }
    }

    setTreeSelectedNode(treeSelectedNode: TreeData<TreeRecord>) {
        this.treeSelectedNode = treeSelectedNode;
        this.applyFiltersToView();
    }

    setSelectedFieldTags(tags: string[]) {
        this.selectedFieldTags = tags;
        //TODO: keep selected employee?
        if (this.selectedEntities.length === 1) {
            this.applyFiltersToView(this.selectedEntity.id);
        } else {
            this.applyFiltersToView();
        }
    }

    // call it after: delete employee with tags, delete tag
    async refreshFieldTags(trySelectId?: number) {
        await this.loadFieldTags();
        runInAction(() => {
            this.selectedFieldTags = this.restoreAndValidateTags();
            this.applyFiltersToView(trySelectId);
        });
    }

    setPredefinedFilters(items: PredefinedFilterItem<FieldRecord>[]) {
        this.selectedPredefinedFilters = items;
        this.applyFiltersToView();
    }

    setField(data: FieldRecord[], trySelectId?: number) {
        this.dataStore.setField(data);
        this.applyFiltersToView(trySelectId);
    }

    setSelectedEntities(selectedFields: FieldRecord[]) {
        this.selectedEntities = selectedFields;
    }

    get selectedEntity(): FieldRecord {
        return this.selectedEntities[0];
    }

    private initFilters() {
        // tree:
        let selectedTreeId: number = this.dataStore.fieldTreeRootId;
        // validate availability and accessibility of saved id
        const savedTreeId: string | null = LocalStorage.getItem(LocalStorageKey.FIELD_TREE_ID);
        if (savedTreeId) {
            const savedTreeIdNumber: number | undefined = Number.parseInt(savedTreeId);
            if (this.dataStore.fieldTree.some((item: TreeRecord) => item.id === savedTreeIdNumber)) {
                selectedTreeId = savedTreeIdNumber;
            }
        }
        this.treeSelectedNode = findNodeById(this.dataStore.fieldTreeData, selectedTreeId)!;
        this.expandedKeys = (LocalStorage.getItem(LocalStorageKey.FIELD_TREE_NODES) || this.dataStore.fieldTreeRootId.toString())
            .split(',')
            .map((str: string) => Number.parseInt(str));
        //TODO: check that selectedTreeId will we expanded or expand it

        // predefined:
        this.selectedPredefinedFilters = restoreFieldPredefinedFilter();

        // tags:
        this.selectedFieldTags = this.restoreAndValidateTags();

        this.initFiltersDone = true;
    }

    private restoreAndValidateTags(): string[] {
        const savedTags: string[] = (LocalStorage.getItem(LocalStorageKey.FIELD_TAGS_FILTERS) || '').split(',');
        const existingTags: Set<string> = new Set<string>(this.fieldTags);
        const validTags: string[] = savedTags.filter((oldTag: string) => existingTags.has(oldTag));
        LocalStorage.setItem(LocalStorageKey.FIELD_TAGS_FILTERS, validTags.join(','));
        return validTags;
    }

    private applyUniversalFilter() {
        if (!this.universalFilter.trim()) {
            return;
        }

        if (isBarCode(this.universalFilter)) {
            const entityId: number = Number.parseInt(this.universalFilter.substr(-7));
            this.visibleEntities = this.visibleEntities.filter((field: FieldRecord) => entityId === field.id);
        } else { // full text search
            this.visibleEntities = this.visibleEntities.filter((field: FieldRecord) => {
                const searchString: string = field.name.toLowerCase();
                return searchString.includes(this.universalFilter.toLowerCase());
            });
        }
        // this.selectedEntities = []; // clear any selections
    }

    async updateMap(fieldMapMetadata: FieldMapMetadata, id: number) {
        const result: FieldRecord[] = await new UpdateFieldMapCommand(4, id).execute(fieldMapMetadata);
        runInAction(() => {
            this.setField(result, id);
        });
    }

}
