import DataProvider from '../../../../../../../../common/widgets/modalForm/type/DataProvider';
import {AutoCompleteOptionType} from '../../../../../../../../common/widgets/modalForm/formItems/AutoCompleteOptionType';
import {stores} from '../../../../../../../../app/mobx/Stores';
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";

export default class AddressTypeAutoCompleteProvider extends DataProvider<AutoCompleteOptionType[]> {
    get(): AutoCompleteOptionType[] {
        const {dataStore} = stores;
        const uniqTypes: Set<string> = new Set<string>();
        dataStore.employeeAddress.forEach((item: EmployeeAddressRecord) => uniqTypes.add(item.address_type));
        return ['Home', 'Registration', 'Office', 'Backup', ...Array.from(uniqTypes)].map((value: string) => {
            return {value};
        });
    }
}
