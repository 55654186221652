import {SyncValidator} from "./SyncValidator";
import {formatMessage} from "../../../../locale/IntlProvider";
import {FieldValidatorResult} from "../FieldValidatorTypes";

export interface LengthValidatorConfig {
    min?: number;
    max?: number;
}

export class LengthValidator extends SyncValidator<string | any[] | undefined, LengthValidatorConfig> {

    constructor(config?: LengthValidatorConfig) {
        super(config || {min: 2, max: 255});
    }

    validate(value: string | any[] | undefined): FieldValidatorResult {
        const length: number = (value || []).length;
        const invalidMessage: string[] = [];

        // It's not case on this validator. Use NonEmptyValidator
        if (length === 0) {
            return null;
        }

        if (this.config.min && length < this.config.min) {
            invalidMessage.push(formatMessage('modalForm.validation.length.min', {min: this.config.min}));
        }

        if (this.config.max && length > this.config.max) {
            invalidMessage.push(formatMessage('modalForm.validation.length.max', {max: this.config.max}));
        }

        return invalidMessage.length ? invalidMessage.join('; ') : null;
    }

}