import {FormFieldRender} from "../../../../../../../common/widgets/modalForm/type/FormFieldRender";
import {ModalFormConfig} from '../../../../../../../common/widgets/modalForm/type/ModalFormConfig';
import {NonEmptyValidator} from '../../../../../../../common/widgets/modalForm/validators/sync/NonEmptyValidator';
import {LogDataRecord} from "fm-shared-data/src/types/db/account/justlog/LogDataRecord";

export const LOGDATA_FORM_CONFIG: ModalFormConfig<LogDataRecord> = {
    title: '', // logType name
    tableName: 'log_data',
    getNewRecord: () => {
        return {
            // log_type: logTypeRecord!.id,
            log_date: new Date(),
        } as LogDataRecord;
    },
    fieldConfig: [
        {
            label: 'logData.field.log_date',
            field_name: 'log_date',
            renderType: FormFieldRender.DATETIME,
            syncValidators: [new NonEmptyValidator()]
        }
    ],
    // syncValidators: [new UniqLogTypeValidator()]
};
