import React from 'react';
import {Card, Checkbox, List} from 'antd';
import {intl} from '../../../../../common/locale/IntlProvider';
import CheckedList from '../../../../../common/widgets/list/CheckedList';
import EmployeeAvatar from '../../../../../common/widgets/employeeAvatar/EmployeeAvatar';
import SCRUDActions from '../../../../../common/widgets/scrudActions/SCRUDActions';
import {EMPLOYEE_FORM_CONFIG} from './EmployeeFormConfig';
import {inject, observer} from 'mobx-react';
import {EmployeeViewStore} from './../../mobx/EmployeeViewStore';
import ModalFormik from '../../../../../common/widgets/modalForm/ModalFormik';
import AddEmployeeCommand from './commands/AddEmployeeCommand';
import UpdateEmployeeCommand from './commands/UpdateEmployeeCommand';
import DeleteEmployeeCommand from './commands/DeleteEmployeeCommand';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {UpsertValue} from "fm-shared-data/src/types/db/common/UpsertValue";
import {UpsertResponse} from "fm-shared-data/src/types/db/common/UpsertResponse";
import {getEmployeeName} from '../../EmployeeUtils';

type StoreProps = {
    employeeViewStore: EmployeeViewStore;
};

type Props = StoreProps;

interface State {
    modalFormVisible: boolean;
    modalFormData: EmployeeRecord;
}

@inject('employeeViewStore')
@observer
export default class EmployeeListComponent extends React.Component<Props, State> {
    static defaultProps = {} as StoreProps;

    constructor(props: Readonly<Props>) {
        super(props);
        console.log('EmployeeListComponent: constructor', new Date());
        this.state = {
            modalFormVisible: false,
            modalFormData: {} as EmployeeRecord
        };
    }

    handleOnFilter = (value: string) => {
        console.log(`Filter: ${value}`);
        const {employeeViewStore} = this.props;
        employeeViewStore.universalFilter = value;
        employeeViewStore.applyFiltersToView();
    };

    onAdd = () => {
        const {selectedEmployeeTreeRecord} = this.props.employeeViewStore;
        const newEmployeeRecord: EmployeeRecord = EMPLOYEE_FORM_CONFIG.getNewRecord();
        newEmployeeRecord.tree_ids = [selectedEmployeeTreeRecord.id];
        this.setState({modalFormVisible: true, modalFormData: newEmployeeRecord});
    };

    onEdit = () => {
        console.log(`Edit:`);
        const {employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;
        this.setState({modalFormVisible: true, modalFormData: selectedEntity});
    };

    onDelete = async () => {
        console.log(`Delete:`);
        const {employeeViewStore} = this.props;
        const {selectedEntity} = employeeViewStore;
        const employeeRecords: EmployeeRecord[] = await new DeleteEmployeeCommand().execute(selectedEntity);
        //unselect from selectedEntities ???
        employeeViewStore.setEmployee(employeeRecords);
    };

    saveEmployeeRecordCommand = async (upsertValue: UpsertValue<EmployeeRecord>): Promise<UpsertResponse<EmployeeRecord>> => {
        const {employeeViewStore} = this.props;
        const isUpdate: boolean = !!upsertValue.updatedValue.id;
        const upsertResponse: UpsertResponse<EmployeeRecord> = isUpdate
            ? await new UpdateEmployeeCommand(upsertValue).execute()
            : await new AddEmployeeCommand(upsertValue).execute();
        employeeViewStore.setEmployee(upsertResponse.list, upsertResponse?.value.id);
        return Promise.resolve(upsertResponse);
    };

    handleOnSaveEmployeeRecord = async (upsertValue: UpsertValue<EmployeeRecord>) => {
        this.handleOnModalFormClosed();
    };

    handleOnModalFormClosed = () => {
        this.setState({modalFormVisible: false});
    };

    componentDidMount(): void {
        console.log('EmployeeListComponent: componentDidMount', new Date());
    }

    componentDidUpdate(): void {
        console.log('EmployeeListComponent: componentDidUpdate', new Date());
    }

    handleOnEntitySelect = (selectedEmployees: EmployeeRecord[]) => {
        const {employeeViewStore} = this.props;
        employeeViewStore.setSelectedEntities(selectedEmployees);
    };

    onSelectAll = () => {
        const {employeeViewStore} = this.props;
        if (employeeViewStore.selectedEntities.length === 0) {
            employeeViewStore.selectedEntities = employeeViewStore.visibleEntities;
        } else {
            employeeViewStore.selectedEntities = [];
        }
    };

    render() {
        const {employeeViewStore} = this.props;
        const {visibleEntities, isSelectedAll, visibleEmployeeCount, selectedEntities} = employeeViewStore;
        const isOneEntitySelected: boolean = selectedEntities.length === 1;
        const areManyEntitiesSelected: boolean = selectedEntities.length > 1;
        const isEmptyList: boolean = visibleEmployeeCount === 0;

        return (
            <div className="entity-container">
                <Card size="small"
                      className="fm-card"
                      title={
                          <>
                              <Checkbox onChange={this.onSelectAll}
                                        checked={isSelectedAll}
                                        indeterminate={isSelectedAll ? undefined : employeeViewStore.selectedEntities.length > 0}/>
                              <span> {intl().formatMessage({id: 'employee.list.title'})}</span>
                          </>
                      }
                      bodyStyle={{padding: 0}} bordered={false}
                      extra={
                          <SCRUDActions
                              onFilter={this.handleOnFilter}
                              filterValue={employeeViewStore.universalFilter}
                              onAdd={this.onAdd}
                              onEdit={this.onEdit}
                              onDelete={this.onDelete}
                              isFilterEnabled={!isEmptyList} //bug: list could be empty because of filter! && !filterValue
                              isAddEnabled={true}
                              isEditEnabled={isOneEntitySelected}
                              isDeleteEnabled={isOneEntitySelected || areManyEntitiesSelected}/>}>
                    <div className="list-container">
                        <CheckedList selectedRecords={employeeViewStore.selectedEntities}
                                     onEntitySelect={this.handleOnEntitySelect}
                                     data={visibleEntities}
                                     renderItem={(item: EmployeeRecord) => (
                                         <List.Item.Meta avatar={<EmployeeAvatar employee={item} size={50} hidePreview={true}/>}
                                                         title={getEmployeeName(item)}
                                                         description={item.position}/>)}/>
                        {this.state.modalFormVisible && <ModalFormik config={EMPLOYEE_FORM_CONFIG}
                                                                     initialValues={this.state.modalFormData}
                                                                     saveCommand={this.saveEmployeeRecordCommand}
                                                                     onSave={this.handleOnSaveEmployeeRecord}
                                                                     onCancel={this.handleOnModalFormClosed}/>
                        }
                    </div>
                </Card>
            </div>
        );
    }
}
