export enum AppRoutes {
    ROOT = '/',
    PAGE404 = '/404',
    ERROR = '/error', ERROR_DATA = '/error/:data', // use it for crash app also

    // unsecured area
    FM_EMPLOYEE_EMAIL_CONFIRMATION_DATA = '/employee/email/confirmation/:data',

    // LoginArea:
    SIGNIN = '/signin', SIGNIN_DATA = '/signin/:data',
    LOGOUT = '/logout',
    SIGNUP = '/signup',
    SET_PASSWORD_DATA = '/setpass/:data',
    RESET_PASSWORD = '/resetpass', RESET_PASSWORD_DATA = '/resetpass/:data',

    // CoreArea:
    USER_PROFILE = '/userprofile',
    ACCOUNT_PROFILE = '/accountprofile',

    // AdminArea:
    BARCODE = '/barcode',

    // FMArea:
    FM_HOME = '/fm',

    FM_EMPLOYEE = '/employee',
    FM_EMPLOYEE_DATA = '/employee/:data',

    FM_FIELD = '/field',
    FM_FIELD_DATA = '/field/:data',

    FM_EQUIPMENT = '/equipment',
    FM_EQUIPMENT_DATA = '/equipment/:data',

    FM_WORK = '/work',
    FM_WORK_DATA = '/work/:data',

    FM_LOG_TYPE = '/logtype',
    FM_LOG_TYPE_DATA = '/logtype/:data',

    FM_CALENDAR = '/calendar',
    FM_CALENDAR_DATA = '/calendar/:data',

    FM_SCANNER = '/scanner',
    FM_SCANNER_DATA = '/scanner/:data',

    FM_REPORTS = '/reports',
    FM_REPORTS_DATA = '/reports/:data',

    FM_REPORT = '/report',
    FM_REPORT_DATA = '/report/:data',
}
