import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import LoadEmployeeCommand from '../../pages/account/employee/commands/LoadEmployeeCommand';
import LoadTreeCommand from '../../pages/account/commands/tree/LoadTreeCommand';
import LoadEmployeePhoneCommand from '../../pages/account/employee/commands/LoadEmployeePhoneCommand';
import LoadEmployeeEmailCommand from '../../pages/account/employee/commands/LoadEmployeeEmailCommand';
import LoadEmployeeAddressCommand from '../../pages/account/employee/commands/LoadEmployeeAddressCommand';
import LoadFilesCommand from '../../pages/account/employee/commands/LoadFilesCommand';
import {computedFn} from 'mobx-utils';
import LoadAccountFileTypesCommand from '../../pages/account/employee/commands/LoadAccountFileTypesCommand';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {TreeData} from "fm-shared-data/src/types/db/common/TreeData";
import {EmployeePhoneRecord} from "fm-shared-data/src/types/db/account/employee/EmployeePhoneRecord";
import {EmployeeEmailRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeEmailRecord";
import {EmployeeAddressRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeAddressRecord";
import {FileDetailed} from "fm-shared-data/src/types/db/common/FileDetailed";
import {isRootRecord, TreeRecord} from "fm-shared-data/src/types/db/common/TreeRecord";
import {calcTreeCounts, convertTreeRecordsToTreeData} from "fm-shared-utils/src/utils/tree/TreeUtils";
import {FieldRecord} from "fm-shared-data/src/types/db/account/field/FieldRecord";
import LoadFieldCommand from "../../pages/account/field/commands/LoadFieldCommand";
import {EntityKey} from "fm-shared-data/src/types/db/common/EntityKey";
import {EquipmentRecord} from "fm-shared-data/src/types/db/account/equipment/EquipmentRecord";
import LoadEquipmentCommand from "../../pages/account/equipment/commands/LoadEquipmentCommand";
import {WorkRecord} from "fm-shared-data/src/types/db/account/work/WorkRecord";
import LoadWorkCommand from "../../pages/account/work/commands/LoadWorkCommand";
import {CalendarRecord} from "fm-shared-data/src/types/db/account/calendar/CalendarRecord";
import LoadCalendarCommand from "../../pages/account/calendar/commands/LoadCalendarCommand";
import {LogTypeRecord} from "fm-shared-data/src/types/db/account/justlog/LogTypeRecord";
import LoadLogTypeCommand from "../../pages/account/justlog/commands/LoadLogTypeCommand";
import {BarcodeEntityId} from "fm-shared-data/src/types/static/db/BarcodeEntityId";
import {LogTypeField, LogTypeFieldType} from "fm-shared-data/src/types/db/account/justlog/LogTypeField";
import {readLogTypeFields} from "fm-shared-data/src/types/db/account/justlog/LogTypeUtils";
import {getFMEntity} from "fm-shared-data/src/types/db/core/FMEntity";
import {ScannerRecord} from 'fm-shared-data/src/types/db/account/scanner/ScannerRecord';
import LoadScannerListCommand from '../../pages/account/scanner/commands/LoadScannerListCommand';
import {ReportRecord} from 'fm-shared-data/src/types/db/account/report/ReportRecord';
import LoadReportCommand from '../../pages/account/report/commands/LoadReportCommand';

export class DataStore {
    // employee

    isEmployeeLoaded: Date | undefined;
    employee: EmployeeRecord[] = [];

    isEmployeeTreeLoaded: Date | undefined;
    employeeTree: TreeRecord[] = [];
    employeeTreeData: TreeData<TreeRecord>[] = [];

    isEmployeePhoneLoaded: Date | undefined;
    employeePhone: EmployeePhoneRecord[] = [];

    isEmployeeEmailLoaded: Date | undefined;
    employeeEmail: EmployeeEmailRecord[] = [];

    isEmployeeAddressLoaded: Date | undefined;
    employeeAddress: EmployeeAddressRecord[] = [];

    files: Map<string, FileDetailed[] | undefined> = new Map<string, FileDetailed[] | undefined>();
    fileTypes: string[] = [];

    // field

    isFieldLoaded: Date | undefined;
    field: FieldRecord[] = [];

    isFieldTreeLoaded: Date | undefined;
    fieldTree: TreeRecord[] = [];
    fieldTreeData: TreeData<TreeRecord>[] = [];

    // equipment

    isEquipmentLoaded: Date | undefined;
    equipment: EquipmentRecord[] = [];

    isEquipmentTreeLoaded: Date | undefined;
    equipmentTree: TreeRecord[] = [];
    equipmentTreeData: TreeData<TreeRecord>[] = [];

    // work

    isWorkLoaded: Date | undefined;
    work: WorkRecord[] = [];

    isWorkTreeLoaded: Date | undefined;
    workTree: TreeRecord[] = [];
    workTreeData: TreeData<TreeRecord>[] = [];

    // calendar

    isCalendarLoaded: Date | undefined;
    calendar: CalendarRecord[] = [];

    isCalendarTreeLoaded: Date | undefined;
    calendarTree: TreeRecord[] = [];
    calendarTreeData: TreeData<TreeRecord>[] = [];

    // logType

    isLogTypeLoaded: Date | undefined;
    logType: LogTypeRecord[] = [];

    isLogTypeTreeLoaded: Date | undefined;
    logTypeTree: TreeRecord[] = [];
    logTypeTreeData: TreeData<TreeRecord>[] = [];

    // scanner

    isScannerLoaded: Date | undefined;
    scanner: ScannerRecord[] = [];

    isScannerTreeLoaded: Date | undefined;
    scannerTree: TreeRecord[] = [];
    scannerTreeData: TreeData<TreeRecord>[] = [];

    // work

    isReportLoaded: Date | undefined;
    report: ReportRecord[] = [];

    isReportTreeLoaded: Date | undefined;
    reportTree: TreeRecord[] = [];
    reportTreeData: TreeData<TreeRecord>[] = [];

    // other

    constructor() {
        makeObservable(this, {
            isBaseDataLoaded: computed,
            loadBaseData: action,

            // Employee
            isEmployeeLoaded: observable,
            loadEmployee: action,
            employee: observable,
            setEmployee: action,
            updateEmployee: action,

            isEmployeeTreeLoaded: observable,
            loadEmployeeTree: action,
            employeeTree: observable,
            employeeTreeData: observable,
            setEmployeeTree: action,
            employeeTreeRootId: computed,
            calcEmployeeTreeCounts: action,

            isEmployeePhoneLoaded: observable,
            employeePhone: observable,
            loadEmployeePhone: action,
            setEmployeePhone: action,

            isEmployeeEmailLoaded: observable,
            employeeEmail: observable,
            loadEmployeeEmail: action,
            setEmployeeEmail: action,

            isEmployeeAddressLoaded: observable,
            employeeAddress: observable,
            loadEmployeeAddress: action,
            setEmployeeAddress: action,

            // Files

            files: observable,
            fileTypes: observable,
            loadFiles: action,
            setFiles: action,
            // areFilesLoaded: observable, // test it! in case ok ref employee comments etc

            // Field

            isFieldLoaded: observable,
            loadField: action,
            field: observable,
            setField: action,
            updateField: action,

            isFieldTreeLoaded: observable,
            loadFieldTree: action,
            fieldTree: observable,
            fieldTreeData: observable,
            setFieldTree: action,
            fieldTreeRootId: computed,
            calcFieldTreeCounts: action,

            // Equipment

            isEquipmentLoaded: observable,
            loadEquipment: action,
            equipment: observable,
            setEquipment: action,
            updateEquipment: action,

            isEquipmentTreeLoaded: observable,
            loadEquipmentTree: action,
            equipmentTree: observable,
            equipmentTreeData: observable,
            setEquipmentTree: action,
            equipmentTreeRootId: computed,
            calcEquipmentTreeCounts: action,

            // Work

            isWorkLoaded: observable,
            loadWork: action,
            work: observable,
            setWork: action,
            updateWork: action,

            isWorkTreeLoaded: observable,
            loadWorkTree: action,
            workTree: observable,
            workTreeData: observable,
            setWorkTree: action,
            workTreeRootId: computed,
            calcWorkTreeCounts: action,

            // Calendar
            isCalendarLoaded: observable,
            loadCalendar: action,
            calendar: observable,
            setCalendar: action,
            updateCalendar: action,

            isCalendarTreeLoaded: observable,
            loadCalendarTree: action,
            calendarTree: observable,
            calendarTreeData: observable,
            setCalendarTree: action,
            calendarTreeRootId: computed,
            calcCalendarTreeCounts: action,

            // LogType

            isLogTypeLoaded: observable,
            loadLogType: action,
            logType: observable,
            setLogType: action,
            updateLogType: action,

            isLogTypeTreeLoaded: observable,
            loadLogTypeTree: action,
            logTypeTree: observable,
            logTypeTreeData: observable,
            setLogTypeTree: action,
            logTypeTreeRootId: computed,
            calcLogTypeTreeCounts: action,

            // Scanner

            isScannerLoaded: observable,
            loadScanner: action,
            scanner: observable,
            setScanner: action,
            updateScanner: action,

            isScannerTreeLoaded: observable,
            loadScannerTree: action,
            scannerTree: observable,
            scannerTreeData: observable,
            setScannerTree: action,
            scannerTreeRootId: computed,
            calcScannerTreeCounts: action,

            // Report

            isReportLoaded: observable,
            loadReport: action,
            report: observable,
            setReport: action,
            updateReport: action,

            isReportTreeLoaded: observable,
            loadReportTree: action,
            reportTree: observable,
            reportTreeData: observable,
            setReportTree: action,
            reportTreeRootId: computed,
            calcReportTreeCounts: action,

            // other

        });
    }

    // works with isBaseDataLoaded
    async loadBaseData() {
        console.info('loadBaseData: loadData....');
        this.loadLogType();
    }

    get isBaseDataLoaded(): boolean {
        return !!this.isLogTypeLoaded;
    }

    //employee

    get employeeTreeRootId(): number {
        const employeeTreeRecord: TreeRecord | undefined = this.employeeTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return employeeTreeRecord?.id || -1;
    }

    async loadEmployee() {
        console.info('loadEmployee: loadData....');
        const records: EmployeeRecord[] = await new LoadEmployeeCommand().execute();
        runInAction(() => {
            this.setEmployee(records);
        });
    }

    setEmployee(data: EmployeeRecord[]) {
        this.employee = data;
        this.calcEmployeeTreeCounts();
        this.isEmployeeLoaded = new Date();
    }

    updateEmployee(employeeRecord: EmployeeRecord) {
        this.employee = this.employee.map((record: EmployeeRecord) => record.id === employeeRecord.id ? employeeRecord : record);
        this.calcEmployeeTreeCounts();
        this.isEmployeeLoaded = new Date();
    }

    async loadEmployeeTree() {
        console.info('loadEmployeeTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('employee_tree').execute();
        runInAction(() => {
            this.setEmployeeTree(records);
        });
    }

    setEmployeeTree(data: TreeRecord[]) {
        this.employeeTree = data;
        this.employeeTreeData = convertTreeRecordsToTreeData(this.employeeTree);
        this.calcEmployeeTreeCounts();
        this.isEmployeeTreeLoaded = new Date();
    }

    calcEmployeeTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.employee.forEach((e: EmployeeRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.employeeTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.employeeTreeData = [...this.employeeTreeData]; // manual refresh
    }

    async loadEmployeePhone() {
        console.info('loadEmployeePhone: loadData....');
        const records: EmployeePhoneRecord[] = await new LoadEmployeePhoneCommand().execute();
        runInAction(() => {
            this.setEmployeePhone(records);
        });
    }

    setEmployeePhone(data: EmployeePhoneRecord[]) {
        this.employeePhone = data;
        this.isEmployeePhoneLoaded = new Date();
    }

    async loadEmployeeEmail() {
        console.info('loadEmployeeEmail: loadData....');
        const records: EmployeeEmailRecord[] = await new LoadEmployeeEmailCommand().execute();
        runInAction(() => {
            this.setEmployeeEmail(records);
        });
    }

    setEmployeeEmail(data: EmployeeEmailRecord[]) {
        this.employeeEmail = data;
        this.isEmployeeEmailLoaded = new Date();
    }

    async loadEmployeeAddress() {
        console.info('loadEmployeeAddress: loadData....');
        const records: EmployeeAddressRecord[] = await new LoadEmployeeAddressCommand().execute();
        runInAction(() => {
            this.setEmployeeAddress(records);
        });
    }

    setEmployeeAddress(data: EmployeeAddressRecord[]) {
        this.employeeAddress = data;
        this.isEmployeeAddressLoaded = new Date();
    }

    // field

    get fieldTreeRootId(): number {
        const fieldTreeRecord: TreeRecord | undefined = this.fieldTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return fieldTreeRecord?.id || -1;
    }

    async loadField() {
        console.info('loadField: loadData....');
        const records: FieldRecord[] = await new LoadFieldCommand().execute();
        runInAction(() => {
            this.setField(records);
        });
    }

    setField(data: FieldRecord[]) {
        this.field = data;
        this.calcFieldTreeCounts();
        this.isFieldLoaded = new Date();
    }

    updateField(fieldRecord: FieldRecord) {
        this.field = this.field.map((record: FieldRecord) => record.id === fieldRecord.id ? fieldRecord : record);
        this.calcFieldTreeCounts();
        this.isFieldLoaded = new Date();
    }

    async loadFieldTree() {
        console.info('loadFieldTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('field_tree').execute();
        runInAction(() => {
            this.setFieldTree(records);
        });
    }

    setFieldTree(data: TreeRecord[]) {
        this.fieldTree = data;
        this.fieldTreeData = convertTreeRecordsToTreeData(this.fieldTree);
        this.calcFieldTreeCounts();
        this.isFieldTreeLoaded = new Date();
    }

    calcFieldTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.field.forEach((e: FieldRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.fieldTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.fieldTreeData = [...this.fieldTreeData]; // manual refresh
    }

    // equipment

    get equipmentTreeRootId(): number {
        const equipmentTreeRecord: TreeRecord | undefined = this.equipmentTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return equipmentTreeRecord?.id || -1;
    }

    async loadEquipment() {
        console.info('loadEquipment: loadData....');
        const records: EquipmentRecord[] = await new LoadEquipmentCommand().execute();
        runInAction(() => {
            this.setEquipment(records);
        });
    }

    setEquipment(data: EquipmentRecord[]) {
        this.equipment = data;
        this.calcEquipmentTreeCounts();
        this.isEquipmentLoaded = new Date();
    }

    updateEquipment(equipmentRecord: EquipmentRecord) {
        this.equipment = this.equipment.map((record: EquipmentRecord) => record.id === equipmentRecord.id ? equipmentRecord : record);
        this.calcEquipmentTreeCounts();
        this.isEquipmentLoaded = new Date();
    }

    async loadEquipmentTree() {
        console.info('loadEquipmentTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('equipment_tree').execute();
        runInAction(() => {
            this.setEquipmentTree(records);
        });
    }

    setEquipmentTree(data: TreeRecord[]) {
        this.equipmentTree = data;
        this.equipmentTreeData = convertTreeRecordsToTreeData(this.equipmentTree);
        this.calcEquipmentTreeCounts();
        this.isEquipmentTreeLoaded = new Date();
    }

    calcEquipmentTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.equipment.forEach((e: EquipmentRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.equipmentTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.equipmentTreeData = [...this.equipmentTreeData]; // manual refresh
    }

    // work

    get workTreeRootId(): number {
        const workTreeRecord: TreeRecord | undefined = this.workTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return workTreeRecord?.id || -1;
    }

    async loadWork() {
        console.info('loadWork: loadData....');
        const records: WorkRecord[] = await new LoadWorkCommand().execute();
        runInAction(() => {
            this.setWork(records);
        });
    }

    setWork(data: WorkRecord[]) {
        this.work = data;
        this.calcWorkTreeCounts();
        this.isWorkLoaded = new Date();
    }

    updateWork(workRecord: WorkRecord) {
        this.work = this.work.map((record: WorkRecord) => record.id === workRecord.id ? workRecord : record);
        this.calcWorkTreeCounts();
        this.isWorkLoaded = new Date();
    }

    async loadWorkTree() {
        console.info('loadWorkTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('work_tree').execute();
        runInAction(() => {
            this.setWorkTree(records);
        });
    }

    setWorkTree(data: TreeRecord[]) {
        this.workTree = data;
        this.workTreeData = convertTreeRecordsToTreeData(this.workTree);
        this.calcWorkTreeCounts();
        this.isWorkTreeLoaded = new Date();
    }

    calcWorkTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.work.forEach((e: WorkRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.workTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.workTreeData = [...this.workTreeData]; // manual refresh
    }

    // calendar

    get calendarTreeRootId(): number {
        const calendarTreeRecord: TreeRecord | undefined = this.calendarTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return calendarTreeRecord?.id || -1;
    }

    async loadCalendar() {
        console.info('loadCalendar: loadData....');
        const records: CalendarRecord[] = await new LoadCalendarCommand().execute();
        runInAction(() => {
            this.setCalendar(records);
        });
    }

    setCalendar(data: CalendarRecord[]) {
        this.calendar = data;
        this.calcCalendarTreeCounts();
        this.isCalendarLoaded = new Date();
    }

    updateCalendar(calendarRecord: CalendarRecord) {
        this.calendar = this.calendar.map((record: CalendarRecord) => record.id === calendarRecord.id ? calendarRecord : record);
        this.calcCalendarTreeCounts();
        this.isCalendarLoaded = new Date();
    }

    async loadCalendarTree() {
        console.info('loadCalendarTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('calendar_tree').execute();
        runInAction(() => {
            this.setCalendarTree(records);
        });
    }

    setCalendarTree(data: TreeRecord[]) {
        this.calendarTree = data;
        this.calendarTreeData = convertTreeRecordsToTreeData(this.calendarTree);
        this.calcCalendarTreeCounts();
        this.isCalendarTreeLoaded = new Date();
    }

    calcCalendarTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.calendar.forEach((e: CalendarRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.calendarTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.calendarTreeData = [...this.calendarTreeData]; // manual refresh
    }

    // logType

    get logTypeTreeRootId(): number {
        const logTypeTreeRecord: TreeRecord | undefined = this.logTypeTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return logTypeTreeRecord?.id || -1;
    }

    async loadLogType() {
        console.info('loadLogType: loadData....');
        const records: LogTypeRecord[] = await new LoadLogTypeCommand().execute();
        runInAction(() => {
            this.setLogType(records);
        });
    }

    setLogType(data: LogTypeRecord[]) {
        this.logType = data;
        this.calcLogTypeTreeCounts();
        this.isLogTypeLoaded = new Date();
    }

    updateLogType(logTypeRecord: LogTypeRecord) {
        this.logType = this.logType.map((record: LogTypeRecord) => record.id === logTypeRecord.id ? logTypeRecord : record);
        this.calcLogTypeTreeCounts();
        this.isLogTypeLoaded = new Date();
    }

    async loadLogTypeTree() {
        console.info('loadLogTypeTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('log_type_tree').execute();
        runInAction(() => {
            this.setLogTypeTree(records);
        });
    }

    setLogTypeTree(data: TreeRecord[]) {
        this.logTypeTree = data;
        this.logTypeTreeData = convertTreeRecordsToTreeData(this.logTypeTree);
        this.calcLogTypeTreeCounts();
        this.isLogTypeTreeLoaded = new Date();
    }

    calcLogTypeTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.logType.forEach((e: LogTypeRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.logTypeTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.logTypeTreeData = [...this.logTypeTreeData]; // manual refresh
    }

    // scanner

    get scannerTreeRootId(): number {
        const scannerTreeRecord: TreeRecord | undefined = this.scannerTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return scannerTreeRecord?.id || -1;
    }

    async loadScanner() {
        console.info('loadScanner: loadData....');
        const records: ScannerRecord[] = await new LoadScannerListCommand().execute();
        runInAction(() => {
            this.setScanner(records);
        });
    }

    setScanner(data: ScannerRecord[]) {
        this.scanner = data;
        this.calcScannerTreeCounts();
        this.isScannerLoaded = new Date();
    }

    updateScanner(scannerRecord: ScannerRecord) {
        this.scanner = this.scanner.map((record: ScannerRecord) => record.id === scannerRecord.id ? scannerRecord : record);
        this.calcScannerTreeCounts();
        this.isScannerLoaded = new Date();
    }

    async loadScannerTree() {
        console.info('loadScannerTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('scanner_tree').execute();
        runInAction(() => {
            this.setScannerTree(records);
        });
    }

    setScannerTree(data: TreeRecord[]) {
        this.scannerTree = data;
        this.scannerTreeData = convertTreeRecordsToTreeData(this.scannerTree);
        this.calcScannerTreeCounts();
        this.isScannerTreeLoaded = new Date();
    }

    calcScannerTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.scanner.forEach((e: ScannerRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.scannerTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.scannerTreeData = [...this.scannerTreeData]; // manual refresh
    }

    // file

    fileMapKey = (fileEntityKey: EntityKey): string => {
        return `${fileEntityKey.barcode_entity_id}-${fileEntityKey.entity_id}`;
    };

    areFilesLoaded = computedFn((fileEntityKey: EntityKey): boolean => {
        return this.files?.has(this.fileMapKey(fileEntityKey)) || false;
    });
    getFiles = computedFn((fileEntityKey: EntityKey): FileDetailed[] => {
        return this.files.get(this.fileMapKey(fileEntityKey)) || [];
    });

    async loadFiles(fileEntityKey: EntityKey) {
        console.info(`loadFiles: fileEntityKey ${fileEntityKey}`);
        const fileRecords: FileDetailed[] = await new LoadFilesCommand().execute(fileEntityKey);
        runInAction(() => {
            this.setFiles(fileEntityKey, fileRecords);
        });
    }

    setFiles(fileEntityKey: EntityKey, fileRecords: FileDetailed[]) {
        this.files.set(this.fileMapKey(fileEntityKey), fileRecords);
        this.refreshFileTypes();
    }

    refreshFileTypes() {
        new LoadAccountFileTypesCommand().execute().then((fileTypes: string[]) => {
            this.fileTypes = fileTypes;
        });
        // const fileTypes: string[] = await new LoadAccountFileTypesCommand().execute();
        // this.fileTypes = fileTypes;
    }

    getLogTypeRecordTabs = (barcode_entity_id: BarcodeEntityId): LogTypeRecord[] => {
        return this.logType.reduce((prev: LogTypeRecord[], logTypeRecord: LogTypeRecord) => {
            const logTypeFields: LogTypeField[] = readLogTypeFields(logTypeRecord);
            if (logTypeFields.some((logTypeField: LogTypeField) =>
                logTypeField.field_type === LogTypeFieldType.FM_ENTITY
                && logTypeField.field_dict_id === getFMEntity(barcode_entity_id)
                && !!logTypeField.field_tab)) {
                prev.push(logTypeRecord);
            }
            return prev;
        }, []);
    }

    // Report

    get reportTreeRootId(): number {
        const reportTreeRecord: TreeRecord | undefined = this.reportTree.find((treeRecord: TreeRecord) => isRootRecord(treeRecord));
        return reportTreeRecord?.id || -1;
    }

    async loadReport() {
        console.info('loadReport: loadData....');
        const records: ReportRecord[] = await new LoadReportCommand().execute();
        runInAction(() => {
            this.setReport(records);
        });
    }

    setReport(data: ReportRecord[]) {
        this.report = data;
        this.calcReportTreeCounts();
        this.isReportLoaded = new Date();
    }

    updateReport(reportRecord: ReportRecord) {
        this.report = this.report.map((record: ReportRecord) => record.id === reportRecord.id ? reportRecord : record);
        this.calcReportTreeCounts();
        this.isReportLoaded = new Date();
    }

    async loadReportTree() {
        console.info('loadReportTree: loadData....');
        const records: TreeRecord[] = await new LoadTreeCommand<TreeRecord>('report_tree').execute();
        runInAction(() => {
            this.setReportTree(records);
        });
    }

    setReportTree(data: TreeRecord[]) {
        this.reportTree = data;
        this.reportTreeData = convertTreeRecordsToTreeData(this.reportTree);
        this.calcReportTreeCounts();
        this.isReportTreeLoaded = new Date();
    }

    calcReportTreeCounts() {
        const emTreeCounts: Map<number, number> = new Map<number, number>();
        this.report.forEach((e: ReportRecord) => {
            e.tree_ids.forEach((treeId: number) => {
                const cnt: number = emTreeCounts.get(treeId) || 0;
                emTreeCounts.set(treeId, cnt + 1);
            });
        });

        this.reportTreeData.forEach((treeData: TreeData<TreeRecord>) => {
            treeData.count = calcTreeCounts(treeData.children, emTreeCounts);
        });
        this.reportTreeData = [...this.reportTreeData]; // manual refresh
    }

}
