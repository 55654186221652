export enum TAG_PREFIX {
    SUCCESS = '+',
    PROCESSING = '-',
    WARNING = '!',
    ERROR = '?'
}

export const TAG_SYSTEM_PREFIX: string[] = [`${TAG_PREFIX.SUCCESS}${TAG_PREFIX.SUCCESS}`, `${TAG_PREFIX.PROCESSING}${TAG_PREFIX.PROCESSING}`, `${TAG_PREFIX.WARNING}${TAG_PREFIX.WARNING}`, `${TAG_PREFIX.ERROR}${TAG_PREFIX.ERROR}`];
export const TAG_USER_PREFIX: string[] = [TAG_PREFIX.SUCCESS, TAG_PREFIX.PROCESSING, TAG_PREFIX.WARNING, TAG_PREFIX.ERROR];

// export const TAG_ALL_PREFIX: string[] = [...TAG_SYSTEM_PREFIX, ...TAG_USER_PREFIX];

export function hasSystemPrefix(tag: string): boolean {
    if (tag.length < 2) {
        return false;
    }
    const prefix: string = `${tag[0]}${tag[1]}`;
    return TAG_SYSTEM_PREFIX.includes(prefix);
}

export function hasUserPrefix(tag: string): boolean {
    if (tag.length === 0) {
        return false;
    }
    const prefix: string = tag[0];
    return TAG_USER_PREFIX.includes(prefix);
}

export function getTagColor(tag: string): string {
    const prefix: string = hasSystemPrefix(tag) || hasUserPrefix(tag) ? tag[0] : '';
    switch (prefix) {
        case TAG_PREFIX.SUCCESS:
            return 'success';
        case TAG_PREFIX.PROCESSING:
            return 'processing';
        case TAG_PREFIX.WARNING:
            return 'warning';
        case TAG_PREFIX.ERROR:
            return 'error';
        default:
            return 'default';
    }
}

export function hidePrefix(tag: string): string {
    if (hasSystemPrefix(tag)) {
        return tag.substring(2);
    }
    if (hasUserPrefix(tag)) {
        return tag.substring(1);
    }
    return tag;
}

function cutLongTag(tag: string, maxLength: number): string {
    if (tag.length > maxLength) {
        return `${tag.substring(0, maxLength)}...`
    }
    return tag;
}

export function getTagLabel(tag: string, maxLength: number = 75): string {
    return cutLongTag(hidePrefix(tag), maxLength);
}