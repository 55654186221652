import React from 'react';
import {EmployeeRecord} from "fm-shared-data/src/types/db/account/employee/EmployeeRecord";
import {getEmployeeAvatar} from "../../../pages/account/employee/EmployeeUtils";
import AvatarWidget, {AvatarWidgetProps} from "../avatarWidget/AvatarWidget";

interface Props extends AvatarWidgetProps {
    employee: EmployeeRecord;
}

export default class EmployeeAvatar extends React.Component<Props> {
    render() {
        return (
            <AvatarWidget {...this.props} src={getEmployeeAvatar(this.props.employee)}/>
        );
    }
}
